<template>
    <section class="grid-container">
        <div class="item" style="flex-direction: column; text-align: left">
            <p style="width: 100%;"><b>WMS capabilities URL: <br></b><a
                href="http://147.251.21.81/geoserver/wms?service=wms&version=1.1.1&request=GetCapabilities">http://147.251.21.81/geoserver/wms?service=wms&version=1.1.1&request=GetCapabilities</a>
            </p><br>
            <p style="width: 100%;">You can access all of your existing layers via the authenticated connection to this url</p>
            <p  style="width: 100%;"><strong>Monthly Sentinel-2 cloudless mosaics</strong> as well as other public sources are available to
                all visitors by default.</p>
        </div>
        <div class="item">
            <p><b>Change language:</b></p>
            <select @change="onChangeLanguage()" class="form-control mr-2 mb-2 mt-2" id="type" v-model="lang">
                <option value="en">English</option>
                <option value="cz">Czech</option>
            </select>
        </div>
        <div class="item">
            <p><b>Contacts:</b></p>
            <p><strong>ICS MU contact person: </strong> <a href="mailto:rebok@ics.muni.cz">rebok@ics.muni.cz</a>
            </p>
            <p><strong>Czechglobe contact person: </strong><a href="mailto:homolova.l@czechglobe.cz">homolova.l@czechglobe.cz</a>
            </p>
        </div>
    </section>
</template>

<script>
export default {
    name: "SettingsView",
    data() {
        return {
            lang: "en",
        }
    },

    methods: {
        onChangeLanguage() {
            this.$i18n.locale = this.lang;
            localStorage.setItem('pref_lang', this.lang);
        }
    },

    mounted() {
        this.lang = this.$i18n.locale;
    },
}
</script>

<style scoped>
.item {
    display: flex;
    margin: 1rem 0;
    gap: 10px;
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 500px;
    background: #ffebeb99;
    padding: 1em;
    border-radius: 20px;
}
</style>