<template>
    <div class="tile-div">
        <slot name="body"/>
    </div>
</template>

<script>
export default {
    name: "Tile"
}
</script>

<style scoped>

.tile-div {
    padding: 30px 35px 35px 35px;
    border-radius: 40px;
    background: var(--bgTile);
    box-shadow:  8px -8px 27px #cacaca,
             -8px 8px 27px #f6f6f6;
}

/*.tile-div:hover {*/
/*    box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),*/
/*        inset -6px -6px 10px 0 rgba(255, 255, 255, 0.5);*/
/*}*/

</style>