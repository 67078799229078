<template>
    <li @click="whoami" style="height: 20px; background-color: #00b89c"><p>{{ index }}</p></li>
</template>

<script>
export default {
    name: "ImageEditorElement",
    props: {
        file_object: Object,
        index: Number
    },

    data() {
        return {
            counter: 0,
        }
    },

    methods: {
        whoami() {
            this.counter++;
            console.log("I AM ", this.index, " counter: ", this.counter,  "....: ", JSON.stringify(this.file_object));
        }
    }
}
</script>
<style scoped>

</style>