import {createStore} from 'vuex'
import {API, getAPI, geoserverAPI, longAPI} from "../assets/js/axio-api";
import {map_store} from "./map_store";
import {veg_store} from "./veg_store";
import {file_store} from "./file_store";
import {time} from "geotiff/src/logging";
import {useTransitionState} from "vue";
import axios from "axios";
import {charAt} from "core-js/internals/string-multibyte";
import router from "@/router";

// After user reloads main screen tokens in vuex storage are lost, TODO: FIX!
// possible solution https://stackoverflow.com/questions/46633357/vuex-store-losing-data-on-page-regresh
const store = createStore({
    state: {
        displayedView: "home",
        status: "Waiting",
        accessToken: localStorage.getItem('access_token'),
        refreshToken: localStorage.getItem('refresh_token'),
        userCloudSpace: 81,
        filesToUpload: [],
        userStorage: [],
        userLayers: localStorage.getItem("userLayers") ? JSON.parse(localStorage.getItem("userLayers")) : [],
        userDetails: null,
        maxFilesize: "3000MB",
        imageEditorActive: false,
        userJobs: [],
        userJobInfo: [],
        jobInfoViewOn: false,
        currentDir: "",
        systemLuts: [{name: "psenica2020", croptype: "psenica", created_on: "1.5.2021"}],
        newJobSubmitted: false,
        lightTheme: true,
        dirContents: new Map(),
        pinnedViews: [],
        appNews: null, // null when awaiting news - [] no news - [..] news present and displayed
        downloads: new Map(),
        filePreviews: [],
        publicStyles: [],
        personalStyles: [],
        userGroups: [],
        wmsConnections: []
    },
    mutations: {
        UPDATE_WMS_CONNECTIONS(state, connections) {
            state.wmsConnections = connections;
        },
        UPDATE_USER_GROUPS(state, groups) {
            state.userGroups = groups;
        },
        UPDATE_STYLES(state, styles_obj) {
            if (styles_obj.public === true) {
                state.publicStyles = styles_obj.styles;
                return;
            }
            state.personalStyles = styles_obj.styles;
        },
        CHANGE_DISPLAYED_VIEW(state, view) {
            state.displayedView = view;
        },
        ADD_FILE_PREVIEW(state, preview) {
            state.filePreviews.push(preview)
        },
        DELETE_FILE_PREVIEW(state, index) {
            state.filePreviews.splice(index, 1);
        },
        EMPTY_FILE_PREVIEWS(state) {
            state.filePreviews = [];
        },
        UPDATE_DOWNLOAD_ITEM(state, download_obj) {
            state.downloads.set(download_obj.name, {progress: download_obj.progress, note: download_obj.note})
        },
        DELETE_DOWNLOAD(state, download_name) {
            state.downloads.delete(download_name)
        },
        EMPTY_DOWNLOADS(state) {
            state.downloads = new Map();
        },
        UPDATE_APP_NEWS(state, news) {
            state.appNews = news.map(news_item => {
                news_item.created = new Date(Date.parse(news_item.created)).toLocaleDateString("en-UK") + " : " + new Date(Date.parse(news_item.created)).toLocaleTimeString("en-UK");
                return news_item;
            });
        },
        DELETE_APP_NEWS(state) {
            state.appNews = [];
        },
        UPDATE_DIR_CONTENTS(state, obj) {
            if (obj.dirContent.length === 0 && obj.dirName === "") {
                return;
            }
            state.dirContents.set(obj.dirName, obj.dirContent);
        },
        DELETE_DIR_CONTENT(state, dirId) {
            if (!state.pinnedViews.includes(dirId)) {
                state.dirContents.delete(dirId);
            }
        },
        PIN_DIR_VIEW(state, dirId) {
            state.pinnedViews.push(dirId);
        },
        UNPIN_DIR_VIEW(state, dirId) {
            let index = state.pinnedViews.indexOf(dirId);
            state.pinnedViews.splice(index, 1);
        },
        UPDATE_STORAGE(state, {access, refresh}) {
            state.accessToken = access;
            state.refreshToken = refresh;
        },
        DESTROY_TOKEN(state) {
            state.accessToken = null;
            state.refreshToken = null;
        },
        ADD_FILE_TO_UPLOAD(state, obj) {
            state.filesToUpload.push(obj);
        },
        REMOVE_FILE_FROM_UPLOAD(state, index) {
            state.filesToUpload.splice(index, 1);
        },
        CLEAR_STORAGE(state) {
            state.accessToken = null;
            state.refreshToken = null;
            state.filesToUpload = [];
        },
        UPDATE_USER_STORAGE(state, obj) {
            state.userStorage = obj ? obj : [];
        },
        UPDATE_USER_LAYERS(state, obj) {
            state.userLayers = obj ? obj : [];
        },
        DELETE_SELECTED_LAYERS(state, layerNames) {
            state.userLayers = state.userLayers.filter(elem => !layerNames.includes(elem.name));
        },
        DELETE_SELECTED_FILES(state, fileNames) {
            state.userStorage = state.userStorage.filter(elem => !fileNames.includes(elem));
        },

        UPDATE_STATUS(state, obj) {
            state.status = obj;
        },
        UPDATE_USER_DETAILS(state, obj) {
            state.userDetails = obj;
        },
        UPDATE_MAX_FILESIZE(state, size) {
            state.maxFilesize = size + "MB";
        },
        SWITCH_IMAGE_EDITOR_ACTIVE(state) {
            state.imageEditorActive = !state.imageEditorActive;
        },
        UPDATE_USER_JOBS(state, jobs) {
            state.userJobs = jobs.map(lut => {
                lut.created_on = new Date(Date.parse(lut.created_on)).toLocaleDateString("en-UK") + " : " + new Date(Date.parse(lut.created_on)).toLocaleTimeString("en-UK");
                return lut;
            });
        },
        UPDATE_USER_JOB_INFO(state, message) {
            state.userJobInfo.push(message);
        },
        DELETE_USER_JOB_INFO(state, index) {
            state.userJobInfo.splice(index, 1);
        },
        UPDATE_CURRENT_DIRECTORY(state, dir) {
            if (dir === "") {
                state.currentDir = "";
                return;
            }
            state.currentDir = dir;
        },
        SWICTH_NEW_JOB_SUBMISSION(state) {
            state.newJobSubmitted = !state.newJobSubmitted;
        },
        SWITCH_JOB_VIEW(state) {
            state.jobInfoViewOn = !state.jobInfoViewOn;
        },
        CHANGE_THEME(state) {
            state.lightTheme = !state.lightTheme;
        }
    },
    getters: {
        wmsConnections(state) {
            return state.wmsConnections;
        },
        userGroups(state) {
            return state.userGroups;
        },
        publicStyles(state) {
            return state.publicStyles;
        },
        personalStyles(state) {
            return state.personalStyles;
        },
        displayedView(state) {
            return state.displayedView;
        },
        filePreviews(state) {
            return state.filePreviews;
        },
        downloads(state) {
            return state.downloads;
        },
        appNews(state) {
            return state.appNews;
        },
        pinnedDirViews(state) {
            return state.pinnedViews;
        },
        dirContents(state) {
            return state.dirContents;
        },
        imageEditorActive(state) {
            return state.imageEditorActive;
        },
        userDetails(state) {
            return state.userDetails;
        },
        loggedIn(state) {
            return state.accessToken != null;
        }
        ,
        spaceUsed(state) {
            return state.userCloudSpace;
        }
        ,
        fileNamesToUpload(state) {
            // returns only filenames in order to search the one we want to delete, bcs id do not work
            return state.filesToUpload.map(val => val.name);
        }
        ,
        filesToUpload(state) {
            return state.filesToUpload;
        }
        ,
        userStorage(state) {
            return state.userStorage;
        }
        ,
        userLayers(state) {
            return state.userLayers;
        }
        ,
        userLayersNames(state) {
            return state.userLayers ? state.userLayers.map(elem => elem.name) : [];
        }
        ,
        status(state) {
            return state.status;
        },
        userMaxFileSize(state) {
            return state.maxFilesize;
        },
        userJobs(state) {
            return state.userJobs;
        },
        newJobSubmitted(state) {
            return state.newJobSubmitted
        },
        systemLuts(state) {
            return state.systemLuts;
        },
        getUserJobInfo(state) {
            return state.userJobInfo;
        },
        getJobInfoViewOn(state) {
            return state.jobInfoViewOn;
        },
        getCurrentDirectory(state) {
            return state.currentDir;
        },
        // method style access
        getUserFilesWithExtension: (state) => (extension) => {
            // extension is an array of extensions, if there's trailing dot at the beginning we will get rid of it
            // automatically
            if (extension == null)
                return []
            extension.forEach(function (ext, index) {
                if (this[index][0] === '.')
                    this[index] = ext.split('.').pop();
            }, extension)
            return state.userStorage.filter(file => extension.includes(file.name.split('.').pop()));
        },
        getTheme(state) {
            return state.lightTheme;
        }
    },

    actions: {
        userRegister(context, usercredentials) {
            /*
            *  Post request to the server with basic credentials.
            *    - email is unique
            */
            return new Promise((resolve, reject) => {
                getAPI
                    .post("/register/", {
                        email: usercredentials.email,
                        user_name: usercredentials.username,
                        password: usercredentials.password
                    })
                    .then(() => {
                        resolve("success");
                    })
                    .catch(() => {
                        reject('error');
                    });
            });
        },

        userLogin(context, usercredentials) {
            /*
            *  User credentials are sent to the server and as a response
            *  we obtain pair of tokens (access and refresh). Both are saved within
            *  Vuex store and in localStorage as a workaround to avoid losing tokens on refresh.
            *  After successful login, user's data (his files and created layers) is requested and rendered on the widget.
            */
            return new Promise((resolve, reject) => {
                getAPI
                    .post("/api/token/", {
                        email: usercredentials.email,
                        password: usercredentials.password
                    })
                    .then(response => {
                        localStorage.setItem('access_token', response.data.access);
                        localStorage.setItem('refresh_token', response.data.refresh);
                        API.defaults.headers['Authorization'] = 'JWT ' + localStorage.getItem('access_token');
                        getAPI.defaults.headers['Authorization'] = 'JWT ' + localStorage.getItem('access_token');
                        longAPI.defaults.headers['Authorization'] = 'JWT ' + localStorage.getItem('access_token');
                        geoserverAPI.defaults.headers['Authorization'] = 'JWT ' + localStorage.getItem('access_token');
                        context.commit("UPDATE_STORAGE", {
                            access: response.data.access,
                            refresh: response.data.refresh
                        });
                        context.dispatch('fetchUserStorage');
                        context.dispatch('fetchUserLayers');
                        context.dispatch('fetchUserDetails');
                        context.dispatch('fetchUserJobs');
                        router.push("dashboard");
                        // context.dispatch('fetchAppNews');
                        // context.dispatch('fetchStyles');
                        resolve();
                    })
                    .catch(err => {
                        alert("Failed to login, please try again.");
                        console.log(err);
                        reject();
                    });
            });
        },

        userLogout(context) {
            /*
            *  The logout action consists of :
            *       - post request which blacklists current refresh token and prevents future usage of this token
            *       - deleting both (access and refresh) tokens from localStorage and vuex storage
            */
            const response = API
                .post("/logout/blacklist/", {
                    refresh_token: localStorage.getItem("refresh_token")
                });
            API.defaults.headers['Authorization'] = null;
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            context.commit("DESTROY_TOKEN");
            router.push("/");
        },

        fetchUserDetails(context) {
            /*
            *  Get user's account data that are stored on the server.
            *  Not every user is allowed this feature.
            *  The received data structure is as follows : { 'name': str, 'email': str, is_admin: bool, groups: [str] }
            */
            API.get("/user/details/")
                .then(response => {
                    context.commit("UPDATE_USER_DETAILS", response.data);
                    if (response.data["groups"].includes("Czechglobe")) {
                        context.commit("UPDATE_MAX_FILESIZE", 25000);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },

        fetchUserStorage(context) {
            /*
            *  Get user's data that are stored on the server.
            *  Not every user is allowed this feature.
            *  The received data structure is as follows : { 'files': [ {'name': str, 'type': 'dir'|'file' } ] }
            *  Only tiff files are currently supported.
            */
            API.get("/user/storage/", {params: {path: context.getters.getCurrentDirectory, display_all: false}})
                .then(response => {
                    context.commit("UPDATE_USER_STORAGE", response.data.files);
                })
                .catch(err => {
                    // alert("Failed to fetch user storage.");
                    console.log("Error: " + err);
                });
        },
        async fetchImageFilePreview(context, file) {
            let response = "";
            let fileStatistics = {stats: [], raster_profile: ""};
            try {
                response = await longAPI.get("/user/storage/preview/", {
                    params: {
                        path: file.path,
                        width: (window.screen.width * 0.7).toFixed(0),
                        height: (window.screen.height * 0.55).toFixed(0)
                    }
                });
                response = response.data.plot;
            } catch (e) {
                console.log("Error fetching preview" + e);
            }

            try {
                fileStatistics = await context.dispatch("fetchFileStatistics", file.path);
            } catch (e) {
                console.log("Error fetching image statistics" + e);
            }

            await context.commit(
                "ADD_FILE_PREVIEW", {
                    plot: "data:image/jpeg;base64," + response,
                    name: file.name,
                    statistics: fileStatistics.stats,
                    raster_profile: fileStatistics.raster_profile
                });

        },
        async fetchDirContent(context, path) {
            try {
                let response = await API.get("/user/storage/", {params: {path: path, display_all: true}});
                if (response.data.files.length === 0) {
                    context.commit("UNPIN_DIR_VIEW", path);
                    context.commit("DELETE_DIR_CONTENT", path);
                    return;
                }
                context.commit("UPDATE_DIR_CONTENTS", {dirName: path, dirContent: response.data.files});
            } catch (e) {
                console.log("Error " + e);

            }
            // API.get("/user/storage/", {params: {path: path, display_all: true}})
            //     .then(response => {
            //         context.commit("UPDATE_DIR_CONTENTS", {dirName: path, dirContent: response.data.files});
            //     })
            //     .catch(err => {
            //         // alert("Failed to fetch content for dir " + path);
            //         console.log("Error " + err);
            //     });
        },

        async fetchStyles(context) {
            try {
                let response = await API.get("geoserver/style/");
                if (response.data) {
                    context.commit("UPDATE_STYLES", {styles: response.data.public_styles, public: true});
                    context.commit("UPDATE_STYLES", {styles: response.data.personal_styles, public: false});
                }
            } catch (err) {
                console.log("Error " + err);
            }
        },
        async fetchStyleSld(context, styleDetails) {
            try {
                let response = await API.get("geoserver/style/sld/", {
                    params: {
                        style_name: styleDetails.styleName,
                        style_type: styleDetails.type
                    }
                });
                if (response.data) {
                    return response.data;
                    // context.commit("UPDATE_STYLES", {styles: response.data.public_styles, public: true});
                    // context.commit("UPDATE_STYLES", {styles: response.data.personal_styles, public: false});
                }
            } catch (err) {
                console.log("Error " + err);
            }
        },
        async createStyle(context, config) {
            let succ = false;
            try {
                let response = await API.post("geoserver/style/", {
                    style_name: config.name,
                    config: config.data,
                    modify: config.modify
                });
                succ = response.status == 201 || response.status == 200;
                if (response.data) {
                    console.log(JSON.stringify(response.data));
                    await context.dispatch("fetchStyles");
                }
            } catch (err) {
                if (config.modify) {
                    alert("Could not modify selected style")
                } else {
                    alert("Could not create style with given configuration")
                }
            }

            return new Promise((resolve, reject) => {
                resolve({status: succ})
            });
        },

        async deleteStyles(context, styles) {
            try {
                let response = await API.delete("geoserver/style/", {data: {styles: styles}});
                await context.dispatch("fetchStyles");
            } catch (err) {
                console.log("DELETE OF STYLES FAILED: " + err);
            }
        },

        async fetchFileStatistics(context, filepath) {
            try {
                let response = await longAPI.post("/user/storage/files/info/", {filepath: filepath,});
                if (response.data) {
                    return response.data;
                    // context.commit("UPDATE_STYLES", {styles: response.data.public_styles, public: true});
                    // context.commit("UPDATE_STYLES", {styles: response.data.personal_styles, public: false});
                }
            } catch (err) {
                console.log("Error fetching file statistics " + err);
            }
        },
        // fetchLayersDetails(context) {
        //     let layers = context.getters.userLayers;
        //     let promises = [];
        //     let results = [];
        //     // Here we shall use the Layer objects..
        //     for (let i = 0; i < layers.length; i++) {
        //         layers[i]["workspace"] = layers[i].href.match("(workspaces\/)(.*)(\/\layers)")[2];
        //         layers[i]["storeName"] = layers[i].name.slice(0, layers[i].name.lastIndexOf("_layer_"));
        //         layers[i]["layer_url"] = "rest/workspaces/" + layers[i].workspace + "/coveragestores/" + layers[i].storeName + "/coverages/" + layers[i].name + ".json";
        //         promises.push(geoserverAPI.get(layers[i].layer_url).then(response => {
        //             results.push({
        //                 i: i,
        //                 latLonBoundingBox: response.data['coverage']['latLonBoundingBox'],
        //                 type: response.data['coverage']['title'].match("[^_]*")[0]
        //             });
        //         }).catch(e => {
        //             console.log("Layer details: ", e)
        //         })); // check cors
        //     }
        //     // Here update Layer objects with the details, didn't include it in the constructor
        //     // since the constructor cannot be async (I don't know if it's possible to request details concurrently like
        //     // here -- My guess: it is NOT possible) -> and that's why I wouldn't change this and only update the object
        //     // or create the Layer objs here...
        //     return Promise.all(promises).then(() => {
        //         for (let i = 0; i < results.length; ++i) {
        //             let detail = results[i];
        //             layers[detail.i]["latLonBoundingBox"] = detail.latLonBoundingBox;
        //             layers[detail.i]["type"] = detail.type;
        //         }
        //         return layers;
        //     });
        // },

        fetchUserLayers(context) {
            /*
            *  Get user's layers that are stored on the geoserver (requests are done over
            *  the django api in order to authenticate the user). Not every user is allowed this feature.
            *  The received data structure is as follows : {'layer': [ { 'name' : str, 'href' : str } ] }
            *  if user does not have any layer the response will always be {'layer': [] }
            * */
            API.get("geoserver/layer/")
                .then(response => {
                    if (response) {
                        context.commit("UPDATE_USER_LAYERS", response.data.layer);
                        // context.dispatch("fetchLayersDetails").then(response => {
                        //     context.commit("UPDATE_USER_LAYERS", response);
                        //     localStorage.setItem("userLayers", JSON.stringify(context.getters.userLayers));
                        // });
                    }
                })
                .catch(err => {
                    console.log("LAYER FAILED: " + err);
                    console.log("You don't have permission to manipulate layers, please contact admin.");
                });

        },
        async periodicallyFetchJobs(context, submissionTime) {
            let time_intervals = [60, 120, 300, 600, 1800, 3600, 5400, Infinity]
            let polling_times = [7, 10, 20, 40, 60, 120, 240, 300]
            let no_of_jobs = context.getters.userJobs.length;
            context.commit("SWICTH_NEW_JOB_SUBMISSION");
            let jobSwitch = context.getters.newJobSubmitted;

            while (true) {
                await context.dispatch("fetchUserJobs");
                // if (context.getters.userJobs.length > no_of_jobs) {
                //     return;
                // }
                if (context.getters.newJobSubmitted !== jobSwitch) {
                    return;
                }
                for (let i = 0; i < time_intervals.length; i++) {
                    if ((Date.now() - submissionTime) / 1000 <= time_intervals[i]) {
                        // console.log("Waiting " + polling_times[i] + " seconds.")
                        await new Promise(r => setTimeout(r, polling_times[i] * 1000));
                        break;
                    }
                }
            }
        },

        fetchUserJobs(context) {
            // fetch when: site reload, login, queue new job
            API.get("user/jobs/")
                .then(response => {
                    // in future implement query for custom number of jobs
                    // while (response.data.length > 10) {
                    //     response.data.pop();
                    // }
                    context.commit("UPDATE_USER_JOBS", response.data);

                })
                .catch(err => {
                    context.commit('UPDATE_USER_JOB_INFO', 'Could not fetch jobs.')
                    context.commit("UPDATE_USER_JOBS", []);
                })
        },

        async fetchJobInfo(context, index) {
            // context.commit("SWITCH_JOB_VIEW");
            try {
                let response = await API.post("user/jobs/", {job_id: context.getters.userJobs[index].job_id});
                context.commit("UPDATE_USER_JOB_INFO", response.data);

            } catch (e) {
                console.log("Error: " + e)

            }
            // .then(response => {
            //     context.commit("UPDATE_USER_JOB_INFO", response.data);
            //     // context.commit("UPDATE_USER_JOBS", []);
            //
            // })
            // .catch(err => {
            //     // context.commit('UPDATE_USER_JOB_INFO', 'Could not fetch job info.')
            //     // context.commit("UPDATE_USER_JOBS", []);
            //     alert("Could not fetch job info");
            // })
        },

        async deleteFiles(context, obj) {

            // let filenames = files.map(index => context.getters.userStorage[index]);
            try {
                let response = await API.delete("user/storage/", {data: {filenames: obj.files, path: obj.path}});
                context.dispatch('fetchUserStorage');
                context.dispatch('fetchUserLayers'); // TODO: necessary?
                await context.dispatch('fetchDirContent', obj.path);
            } catch (e) {
                console.log("DELETE OF FILES FAILED: " + e);
            }
            // API.delete("user/storage/", {data: {filenames: obj.files, path: obj.path}})
            //     .then(response => {
            //         // context.commit("DELETE_SELECTED_FILES", response.data.filenames);
            //         context.dispatch('fetchUserStorage');
            //         context.dispatch('fetchUserLayers'); // TODO: necessary?
            //     })
            //     .catch(err => {
            //         console.log("DELETE OF FILES FAILED: " + err);
            //     });
        },

        //TODO: STORE NAME CAN BE DETERMINED FROM GET HREF...
        deleteLayers(context, payload) {
            let names = payload.layers.map(index => context.getters.userLayers[index].name);
            let stores = payload.layers.map(index => context.getters.userLayers[index].storeName);
            let _types = payload.layers.map(index => context.getters.userLayers[index].type);

            API.delete("geoserver/store/", {data: {stores: stores, types: _types}})
                .then(response => {
                    context.commit("DELETE_SELECTED_LAYERS", names);
                    context.dispatch('fetchUserStorage');
                })
                .catch(err => {
                    console.log("DELETE OF LAYERS FAILED: " + err);
                })

            if (_types.includes("WMS")) {
                API.delete("geoserver/layer/", {data: {layers: names, types: _types}})
                    .then(response => {
                        context.commit("DELETE_SELECTED_LAYERS", names);
                    })
                    .catch(err => {
                        console.log("DELETE OF LAYERS FAILED: " + err);
                    })
            }

        },

        createLayer(context, obj) {
            // let filenames = obj.filenames.map(index => context.getters.getCurrentDirectory + "/" + context.getters.userStorage[index]['name']);
            API.post("geoserver/layer/", {
                file_names: obj.filenames,
                layer_names: obj.layernames,
                type: obj._type,
                projection: obj.proj
            })
                .then(response => {
                    console.log("RESPONSE: " + response.data);
                    context.dispatch('fetchUserLayers');
                    context.dispatch('fetchUserStorage');
                })
                .catch(err => {
                    console.log("LAYER FAILED: " + err);
                });
        },

        uploadFilesPermanently(context) {
            /*
            *  Tell server to move the uploaded files to the user's directory.
            */
            API.post("/store/", {
                files: context.getters.filesToUpload,
                root: context.getters.getCurrentDirectory
            })
                .then(() => {
                    context.dispatch('fetchUserStorage');
                    context.dispatch('fetchDirContent', context.getters.getCurrentDirectory);
                    return true;
                })
                .catch(err => alert("Duplicate file(s) could not be uploaded."));
            return false;
        },

        forceFileDownload(response, title) {

            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        },

        download_selected_files(context, files) {
            let filenames = files.map(fileobj => {
                return {name: fileobj.path, type: fileobj.type};
            });
            let bulkSize = 0;
            for (let i = 0; i < files.length; i++) {
                bulkSize += parseFloat(files[i].size);
            }
            let note = bulkSize > 1000 ? "- this may take a few minutes for bigger downloads" : "";

            let title = "download_" + new Date().toJSON() + ".zip";
            console.log("FILES: " + JSON.stringify(files))
            context.commit("UPDATE_DOWNLOAD_ITEM", {name: title, progress: 0, note: note});

            const source = axios.CancelToken.source();
            longAPI.get("/download/", {
                params: {
                    files: JSON.stringify(filenames),
                    current_dir: context.getters.getCurrentDirectory,
                },
                responseType: 'blob',
                CancelToken: source.token,
                onDownloadProgress: progressEvent => {
                    if (context.getters.downloads.get(title) !== undefined) {
                        context.commit("UPDATE_DOWNLOAD_ITEM", {
                            name: title,
                            progress: (progressEvent.loaded * 100 / progressEvent.total).toFixed(0),
                            note: ""
                        });
                    } else {
                        source.cancel('Operation canceled by the user.');
                    }
                }
            })
                .then((response) => {

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', title);
                    document.body.appendChild(link);
                    link.click();

                })
                .catch(e => console.log('error occured: ' + e))
        },
        deleteJobs(context, job_indices) {
            let jobs = job_indices.jobs.map(i => context.getters.userJobs[i].job_id);
            API.delete("user/jobs/delete/", {data: {jobs: jobs}})
                .then(response => {
                    context.dispatch('fetchUserJobs');
                })
                .catch(err => {
                    console.log("DELETE OF JOBS FAILED: " + err);
                })
        }
        ,
        saveJob(context, config) {
            // Config has to be object containing attributes: name | type | request (specific job configuration)
            API.post("user/jobs/save/", {
                data: {
                    job_name: config.name,
                    job_type: config.type,
                    config: config.request
                }
            })
                .then(response => {
                    context.dispatch('fetchUserJobs');
                })
                .catch(err => {
                    console.log("SAVING OF JOB FAILED: " + err);
                })
        }
        ,
        fetchAppNews(context) {

            API.get("news/")
                .then(response => {
                    context.commit("UPDATE_APP_NEWS", response.data);
                })
                .catch(err => {
                    console.log("NEWS UPDATE FAILED " + err);
                    // no news fetched -> switch state from null to no news
                    context.commit("UPDATE_APP_NEWS", []);
                })
        }
        ,

        renameFile(context, obj) {
            API.post("user/storage/", {original_path: obj.original_path, new_name: obj.new_name})
                .then(response => {
                    context.dispatch('fetchUserStorage');
                })
                .catch(err => {
                    console.log("RENAMING FAILED: " + err);
                })
        },
        renameLayer(context, obj) {
            obj.change_name = true;
            API.put("geoserver/layer/", {config: obj})
                .then(response => {
                    context.dispatch('fetchUserLayers');
                })
                .catch(err => {
                    console.log("RENAMING FAILED: " + err);
                })
        },

        async get_wms_connection_layers(context, obj) {
            try {
                let response = await API.get("geoserver/wms_connection/", {params: obj});
                return response.data;
            } catch (err) {
                console.log("CONNECTION FAILED: " + err);
                return "";
            }
        },
        async get_geofence_rules(context, layer_name) {
            try {
                let response = await API.get("geoserver/geofence/", {params: layer_name});
                return response.data.rules;
            } catch (err) {
                console.log("COULD NOT FETCH RULES: " + err);
                return "";
            }
        },
        async saveLayerAttributions(context, obj) {
            try {
                obj.edit_attributions = true;
                let response = await API.put("geoserver/layer/", {config: obj});
                return response.data;
            } catch (err) {
                console.log("CONNECTION FAILED: " + err);
                return "";
            }
        },
        async saveLayerAccessRights(context, config) {
            // save new access rights settings, specified by config obj
            // for details check LayerInfo/saveAccessRights
            try {
                config.edit_layer_access_rights = true;
                let response = await API.put("geoserver/layer/", {config: config});
                return response.data;
            } catch (err) {
                console.log("ACCESS RIGHTS CHANGE FAILED: " + err);
                return "";
            }
        },
        async inviteToGroup(context, obj) {
            try {
                let response = await API.post("geoserver/group_registration/", obj);
                return response.data;
            } catch (err) {
                console.log("INVITATION FAILED: " + err);
                return "";
            }
        },
        async fetchUserGroups(context) {
            try {
                let response = await API.get("geoserver/group/");
                context.commit("UPDATE_USER_GROUPS", response.data.groups);
            } catch (err) {
                console.log("COULD NOT FETCH USER GROUPS:  " + err);
            }
        },
        async fetchWMSConnections(context) {
            try {
                let response = await API.get("geoserver/wms_connections/");
                context.commit("UPDATE_WMS_CONNECTIONS", response.data.wms_connections);
            } catch (err) {
                console.log("COULD NOT FETCH USER GROUPS:  " + err);
            }
        },
        async deleteWMSConnection(context, wms_connection) {
            try {
                let response = await API.delete("geoserver/wms_connections/", {params: {connection: wms_connection}});
                context.dispatch("fetchWMSConnections");
            } catch (err) {
                console.log("COULD NOT FETCH USER GROUPS:  " + err);
            }
        },
        async createWMSStoreLayers(context, obj) {
            try {
                let response = await API.post("geoserver/wms_layers/", obj);
                context.dispatch("fetchWMSConnections");
                context.dispatch("fetchUserLayers");
            } catch (err) {
                console.log("COULD NOT FETCH USER GROUPS:  " + err);
            }
        },
        async createNewGroup(context, obj) {
            try {
                let response = await API.post("geoserver/group/", obj);
                return response.status;
            } catch (error) {
                return error.response.status;
            }
        },

        async renameGroup(context, obj) {
            obj.change_name = true;
            try {
                let response = await API.put("geoserver/group/", {config: obj});
                return response.status;
            } catch (error) {
                return error.response.status;
            }

        },

        async deleteUserFromGroup(context, obj) {
            try {
                let response = await API.delete("geoserver/group/", {data: obj});
                return response.status;
            } catch (error) {
                return error.response.status;
            }
        },

        async promoteToGroupAdmin(context, obj) {
            // obj -> {group_name: group_name, user: user}
            try {
                let response = await API.post("geoserver/group/members/", obj);
                return response.status;
            } catch (error) {
                return error.response.status;
            }

        },

        moveCopyTo(context, obj) {
            // move or copy files & dirs from obj.sourceDirPath to obj.dest
            API.put("user/storage/",  obj)
                .then(response => {
                    context.dispatch('fetchUserStorage');
                    context.dispatch('fetchDirContent', obj.dest);
                    context.dispatch('fetchDirContent', obj.sourceDirPath);
                })
                .catch(err => {
                    console.log("RENAMING FAILED: " + err);
                })

        }


    },

    modules: {
        map_store,
        veg_store,
        file_store,
    }
});

export default store;
