
export const file_store = {
    namespaced: true,
    state: {
        selectedFiles: new Map(), // key - dir, value - index of file in this dir
    },

    mutations: {
        UPDATE_SELECTED_FILES(state, {dirPath: dir, index: index}) {
            if (index === -100) {
                state.selectedFiles.clear();
                return;
            }

            if (state.selectedFiles.has(dir)) {
                // already tracking files from this dir
                // check if file in this dir is already tracked
                let i = state.selectedFiles.get(dir).indexOf(index);
                // i>-1 -> file is already there removed, else add it
                if (i > -1) {
                    state.selectedFiles.get(dir).splice(i, 1);
                    if (state.selectedFiles.get(dir).length === 0)
                        state.selectedFiles.delete(dir); // stop tracking
                }
                else {
                    state.selectedFiles.get(dir).push(index);
                }
                return;
            }
            // track this dir
            state.selectedFiles.set(dir, [index]);
        }
    },

    getters: {
        selectedFiles(state) {
            return state.selectedFiles;
        },

        selectedFilesAsArray(state, getters, rootState, rootGetters) {
            let res = [];
            let dirContents = rootGetters.dirContents; // in this store ?
            for (const [key, values] of state.selectedFiles) {
                values.forEach(v => res.push(dirContents.get(key)[v]))
            }
            return res;
        },

        // keep it in getters
        fileObj: (state, getters, rootState, rootGetters) => (dir, index) => {
            return rootGetters.dirContents.get(dir)[index];
        },

        numOfSelectedFiles(state) {
            let i = 0;
            // null check... just in case ;)
            for (const [_, value] of state.selectedFiles) {
                i += value.length;
            }
            return i;
        }
    },

    modules: {}

};