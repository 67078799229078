import {API, longAPI} from "../assets/js/axio-api";


export const veg_store = {
    namespaced: true,
    state: {
        rootDirectory: "/",
        active_veg_modal: false,
        acceptable_filetypes: ["hdr", "bsq", "bil", "bip", "geotiff", "tif", "tiff"],
        picked_units: "nm",
        wavelengths: [],
        fwhms: [],
        reflectance_scale: "",
        resampling_model: "gauss",
        detected_number_of_bands: "",
        selected_files: [],
        active_lut_modal: false,
        lutFilePreviews: [],
        lutDatabases: [],
        previews: []
    },
    mutations: {
        UPDATE_ROOT_DIRECTORY(state, new_dir) {
            state.rootDirectory = new_dir;
        },

        UPDATE_LUT_FILE_PREVIEWS(state, previews) {
            state.lutFilePreviews = previews;
        },
        SWITCH_ACTIVE_VEG_MODAL(state) {
            state.active_veg_modal = !state.active_veg_modal;
        },
        SWITCH_ACTIVE_LUT_MODAL(state) {
            state.active_lut_modal = !state.active_lut_modal;
        },
        UPDATE_PICKED_UNITS(state, unit) {
            state.picked_units = unit;
        },
        UPDATE_WAVELENGTHS(state, new_content) {
            state.wavelengths = new_content;
        },
        UPDATE_FWHMS(state, new_content) {
            state.fwhms = new_content;
        },
        UPDATE_REFLECTANCE_SCALE(state, scale) {
            state.reflectance_scale = scale;
        },
        UPDATE_RESAMPLING_MODEL(state, model) {
            state.resampling_model = model;
        },
        UPDATE_DETECTED_NUMBER_OF_BANDS(state, bands) {
            state.detected_number_of_bands = bands;
        },
        UPDATE_SELECTED_FILES(state, files) {
            state.selected_files = files;
        },
        UPDATE_LUT_DATABASES(state, data) {
            state.lutDatabases = data.map(lut => {
                lut.created_on = new Date(Date.parse(lut.created_on)).toLocaleDateString("en-UK");
                return lut;
            });
        },
        UPDATE_PREVIEWS(state, data) {
            state.previews.push(data);
            let images = state.previews.filter(image => image !== 'loading');
            let loadings = state.previews.filter(image => image === 'loading');
            if (data !== "loading") {
                loadings.pop();
            }
            images = images.concat(loadings);
            state.previews = images;
        },
        REMOVE_PREVIEW_PLOT(state, index) {
            state.previews.splice(index, 1);
        }
    },
    getters: {
        get_root_dir(state) {
            return state.rootDirectory;
        },
        get_lut_databases(state) {
            return state.lutDatabases;
        },
        get_selected_files(state) {
            return state.selected_files;
        },
        get_detected_number_of_bands(state) {
            return state.detected_number_of_bands;
        },
        get_wavelengths(state) {
            return state.wavelengths;
        },
        get_fwhms(state) {
            return state.fwhms;
        },
        get_wavelengths_as_string(state) {
            return state.wavelengths.map(elem => elem.toString()).join(", ");
        },
        get_fwhms_as_string(state) {
            return state.fwhms.map(elem => elem.toString()).join(", ");
        },
        get_reflectance_scale(state) {
            return state.reflectance_scale;
        },
        get_is_active_veg_modal(state) {
            return state.active_veg_modal;
        },
        get_is_active_lut_modal(state) {
            return state.active_lut_modal;
        },
        get_acceptable_filetypes(state) {
            return state.acceptable_filetypes;
        },
        get_picked_units(state) {
            return state.picked_units;
        },
        get_resampling_model(state) {
            return state.resampling_model;
        },
        is_valid_file_selection: (state, getters, rootState, rootGetters) => (selected_indices, storageFiles, noTIFF) => {
            let nonTiffExt = ["hdr", "bsq", "bil", "bip"];
            if (selected_indices.length === 0 || selected_indices.length > 2) {
                return false;
            }
            let files_objects = selected_indices.map(index => storageFiles[index]);
            let _types = files_objects.map(file => file.name.split(".").pop())
            let _names = files_objects.map(file => file.name.split(".")[0])
            // const reducer = (accumulator, current_value) => accumulator || current_value;
            // let has_header = _types.map(_type => _type === "hdr").reduce(reducer);

            if (_types.filter(_type => _type === "tif").length > 1 || _types.filter(_type => _type === "hdr").length > 1 ||
                (_types.length > 1 && _types.includes("tif"))) {
                // return false;
                return false;
            }
            if (_types.length === 1 && _types[0] !== "tif") {
                let correspondingPair = storageFiles.filter(obj => obj.name.split(".")[0] === _names[0]);
                if (correspondingPair.length !== 2) {
                    return false;
                }
            }
            for (let i = 0; i < _types.length; i++) {
                if (!state.acceptable_filetypes.includes(_types[i]) || (noTIFF && !nonTiffExt.includes(_types[i]))) {
                    state.no_hdr = false;
                    return false;
                }
            }
            return true;
        },
        getLutFilePreview(state) {
            return state.lutFilePreviews;
        },
        getPreviews(state) {
            return state.previews;
        }

    },
    actions: {
        // {dispatch, commit, getters, rootGetters}
        convertToGeoTiff(context, obj) {
            let file_objects = obj.selected_indices.map(index => obj.storageFiles[index]);
            if (file_objects.length === 1) {
                let correspondingPair = obj.storageFiles.filter(file => file.name.split(".")[0] === file_objects[0].name.split(".")[0]);
                if (correspondingPair.length !== 2) {
                    return false;
                }
                file_objects = correspondingPair;
            }
            console.log("PAIR: ", JSON.stringify(file_objects));
            API.post("/vparam/filetype/convert/", {
                files: file_objects.map(file => file.path)
            })
                .then(response => {
                    // context.dispatch("fetchFileStorage", { root: true })
                })
                .catch(err => {
                    console.log(err);
                });
        },
        async fetch_lut_databases(context) {
            let databases = await API.get("/vparam/lut/databases");
            context.commit("UPDATE_LUT_DATABASES", databases.data);
        },
        mount_lut_database(context, config) {
            API.post("/vparam/lut/mount/", {
                config: config
            })
                .then(response => {
                    console.log("RESPO2:>>" + response.data + "<<");
                })
                .catch(err => {
                    console.log(err);
                });
        },
        deleteLuts(context, payload) {
            let luts = payload.lut_objects.map(i => context.getters.get_lut_databases[i].lut_id);
            console.log("LUTS: " + JSON.stringify(payload.lut_objects.map(i => context.getters.get_lut_databases[i])));
            API.delete("/vparam/lut/delete", {data: {luts: luts, mode: payload.mode}})
                .then(response => {
                    context.dispatch('fetch_lut_databases');
                })
                .catch(err => {
                    console.log("DELETE OF LUTS FAILED: " + err);
                })

        },
        async fetchLutFilePreview(context, checkedFiles) {
            let _payload = {};
            _payload.filenames = checkedFiles;
            let response = await API.get("/vparam/lut/previews", {params: _payload});
            context.commit("UPDATE_LUT_FILE_PREVIEWS", response.data.previews);
        },

        async fetchPreviews(context, obj) {
            let _payload = {};
            let detail_object = obj.details;
            let current_dir = obj.current_dir;
            _payload.layer = detail_object.layer_details;
            _payload.hdr_data = {
                picked_units: context.getters.get_picked_units,
                wavelengths: context.getters.get_wavelengths,
                fwhms: context.getters.get_fwhms,
                reflectance_scale: context.getters.get_reflectance_scale,
                resampling_model: context.getters.get_resampling_model,
            };
            if (detail_object["image_type"] === "satelite") {
                _payload["hdr_data"] = {sentinel: true};
            } else {
                _payload["hdr_data"].sentinel = false;
            }
            _payload.input_file = current_dir + "/" + context.getters.get_selected_files.filter(file => {
                let filename = file.name.split(".");
                return filename[filename.length - 1] !== "hdr";
            })[0].name;

            let preview = detail_object.preview_details;
            _payload.preview = detail_object.preview_details;
            try {
                let response = await longAPI.post("/vparam/computation/previews/", _payload);
                preview.plot = "data:image/png;base64," + response.data.plot;
                preview.info = [...preview.info, ...response.data.info, ...response.data.metrics];
                context.commit("UPDATE_PREVIEWS", preview);
                return true;
            } catch (err) {
                context.commit("REMOVE_PREVIEW_PLOT", context.getters.getPreviews.indexOf("loading"));
                return false;
            }


        },
        async submit_vparam_computations(context, payload) {
            payload["hdr_data"] = {
                sentinel: false,
                picked_units: context.getters.get_picked_units,
                wavelengths: context.getters.get_wavelengths,
                fwhms: context.getters.get_fwhms,
                reflectance_scale: context.getters.get_reflectance_scale,
                resampling_model: context.getters.get_resampling_model,
            };
            if (payload["image_type"] === "satelite") {
                payload["hdr_data"] = {sentinel: true};
            } else {
                payload["hdr_data"].sentinel = false
            }
            payload["input_file"] = context.getters.get_selected_files.filter(file => {
                let filename = file.name.split(".");
                return filename[filename.length - 1] !== "hdr";
            })[0].path;
            try {
                let response = await API.post("/vparam/computation/submit/", payload);
                return response.data;
            } catch (err){
                console.log(err);
                return "The waiting timeout has passed (check the result in jobs tab), or the output with specified configuration could not be created. Possible reasons are that image does not contain bands with sufficient wavelengths for computation of specified VI, or the selected function could not be fitted to the data.";
            }


            //API.post("/vparam/computation/submit/", payload)
            //    .then(response => {
            //        console.log("RESPO:>>" + response.data + "<<");
            //        return response.data;
            //    })
            //    .catch(err => {
            //        console.log(err);
            //        return "Could not create output with specified configuration. Possible reasons are that image does not contain bands with sufficient wavelengths for computation of specified VI, or the selected function could not be fitted to the data.";
            //    });
        },
        async fetch_image_specifications({dispatch, commit, getters, rootGetters}) {
            let filenames = getters.get_selected_files.map(file => {
                return {name: file.name, path: file.path}
            });
            let _payload = {};
            if (filenames.length === 1) {
                let correspondingPair = rootGetters.dirContents.get(getters.get_root_dir).filter(fileObj =>
                    fileObj.type === "file" && fileObj.name.split(".")[0] === filenames[0].name.split(".")[0]);

                if (correspondingPair.length < 2 || filenames[0].name.split(".")[1] === "tif") {
                    _payload.hdr = "NO_HDR";
                    _payload.image = filenames[0].path;
                } else {
                    filenames = [...correspondingPair.map(obj => {
                        return {name: obj.name, path: obj.path}
                    })];
                }
            }
            if (filenames.length > 1 && filenames[0].name.split(".").pop() === "hdr") {
                _payload.hdr = filenames[0].path;
                _payload.image = filenames[1].path;
            } else if (filenames.length > 1) {
                _payload.hdr = filenames[1].path;
                _payload.image = filenames[0].path;
            }
            let response = await API.get("/vparam/hdr/", {params: _payload});
            return response.data;
        }

    },
    modules: {}
};
