<template>
    <div class="main-content">
        <p class="text-map">Base map</p>
        <button @click="$store.dispatch('map_store/update_basemap', 'OSM')" class="button">OpenStreetMap</button>
        <button @click="$store.dispatch('map_store/update_basemap', 'ESRI')" class="button">Esri</button>
        <button @click="$store.dispatch('map_store/update_basemap', 'Sentinel-2')" class="button">Sentinel-2
            Czechglobe
        </button>
        <button id="export-png" @click="downloadPNG" class="button" style="margin-top: 2rem; width: 70%; align-self:center">Download PNG</button>
        <!--        <a id="export-png" @click="downloadPNG" class="btn btn-default"><i class="fa fa-download"></i> Download PNG</a>-->
        <a id="image-download" download="map.png"></a>
    </div>
</template>

<script>
export default {
    name: "Others",
    methods: {
        downloadPNG() {
            // download a PNG of current map view (with layers, features, etc.)
            let map = this.$store.getters['map_store/mapObject'];
            map.once('rendercomplete', function () {
                const mapCanvas = document.createElement('canvas');
                const size = map.getSize();
                mapCanvas.width = size[0];
                mapCanvas.height = size[1];
                const mapContext = mapCanvas.getContext('2d');
                Array.prototype.forEach.call(
                    map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
                    function (canvas) {
                        if (canvas.width > 0) {
                            const opacity =
                                canvas.parentNode.style.opacity || canvas.style.opacity;
                            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                            let matrix;
                            const transform = canvas.style.transform;
                            if (transform) {
                                // Get the transform parameters from the style's transform matrix
                                matrix = transform
                                    .match(/^matrix\(([^\(]*)\)$/)[1]
                                    .split(',')
                                    .map(Number);
                            } else {
                                matrix = [
                                    parseFloat(canvas.style.width) / canvas.width,
                                    0,
                                    0,
                                    parseFloat(canvas.style.height) / canvas.height,
                                    0,
                                    0,
                                ];
                            }
                            // Apply the transform to the export map context
                            CanvasRenderingContext2D.prototype.setTransform.apply(
                                mapContext,
                                matrix
                            );
                            mapContext.drawImage(canvas, 0, 0);
                        }
                    }
                );
                if (navigator.msSaveBlob) {
                    // link download attribute does not work on MS browsers
                    navigator.msSaveBlob(mapCanvas.msToBlob(), 'map.png');
                } else {
                    const link = document.getElementById('image-download');
                    link.href = mapCanvas.toDataURL();
                    link.click();
                }
            });
            map.renderSync();
        },
    }
}
</script>

<style scoped>
.text-map {
    font-size: 20px;
    line-height: 1.71429;
    font-weight: 400;
    letter-spacing: 1.25px;
    margin-bottom: 14px;
}

.main-content {
    display: flex;
    flex-direction: column;
}
</style>