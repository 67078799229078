<template>
    <main>
        <div class="outer">
            <p class="inner" style="font-size: 1.8rem;font-weight: bold;">
                {{ hours }} : {{ minutes }} : {{ seconds }}
            </p>
            <p class="inner" style="font-size: 2rem; font-weight: bold;">{{ greet }}</p>
            <p class="inner" style="font-size: 1.5rem;">{{ username }}</p>
        </div>
        <button class="logOutBtn" @click="logout">
            {{ $t("home_view.logout") }}
        </button>
    </main>
</template>

<script>
export default {
    name: "LoggedInComponent",
    data() {
        return {
            username: "",
            hours: "",
            minutes: "",
            seconds: "",
            greet: "",
        };
    },

    mounted() {
        this.updateDateTime();
    },

    methods: {
        updateDateTime: function updateDateTime() {
            let now = new Date();
            this.hours = this.getZeroPad(now.getHours());
            this.minutes = this.getZeroPad(now.getMinutes());
            this.seconds = this.getZeroPad(now.getSeconds());
            this.getDayTime(this.hours);
            setTimeout(this.updateDateTime, 1000);
        },

        getZeroPad: function getZeroPad(n) {
            return (parseInt(n, 10) >= 10 ? "" : "0") + n;
        },

        getDayTime: function getDayTime(h) {
            if (h < 4) this.greet = "Time to head to the Land of Nod";
            else if (h < 11) this.greet = "Good Morning";
            else if (h < 17) this.greet = "Good Afternoon";
            else this.greet = "Good Evening";
        },

        logout() {
            // method created to enforce the refresh of the spa
            // to clear file pond -- ANY OTHER WORKAROUND ?
            this.$store.dispatch("userLogout").then(() => {
                // location.reload();
                console.log("reload disabled");
            });
        },
    },

    created() {
        try {
            this.username = JSON.parse(atob(localStorage.getItem("access_token").split(".")[1]))["username"];
        } catch (err) {
            console.log("detected change in access_token");
            store.dispatch("userLogout").then(() => {
            window.location.href = "/";
            });
        }
    }

};
</script>

<style scoped>
.outer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.inner {
    text-align: left;
    width: 100%;
    height: 100%;
}

@keyframes blink {
    50% {
        color: transparent;
    }
}

.tile-logout {
    width: 100%;
    background-color: lightslategray;
}

.tile-logout:hover {
    background-color: white;
    transition: 0.5s;
}

.loader__dot {
    animation: 1s blink infinite;
}

.loader__text {
    animation: 6.5s blink infinite;
}

.loader__dot:nth-child(2) {
    animation-delay: 250ms;
}

.loader__dot:nth-child(3) {
    animation-delay: 500ms;
}

.title-custom {
    text-align: left;
}


.logOutBtn {
    height: 3em;
    width: 8em;
    font-size: 1em;
    margin-top: 1em;
    border: none;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}


.logOutBtn:hover {
    background: #7b525a;
}
</style>
