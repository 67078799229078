import axios from "axios";
import store from "@/store/index";

function decodeToken(token) {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (err) {
        console.log("ERROR")
        store.dispatch("userLogout").then(() => {
            window.location.href = "/";
        });
    }
    return null;
}

const URL = process.env.VUE_APP_BASE_URL;

const getAPI = axios.create({
    baseURL: URL, // django
    timeout: 10000,
});

const API = axios.create({
    baseURL: URL + "/api",
    timeout: 10000,
    headers: {
        Authorization: localStorage.getItem("access_token")
            ? "JWT " + localStorage.getItem("access_token")
            : null,
        "Content-Type": "application/json",
        accept: "application/json",
    },
});

const longAPI = axios.create({
    baseURL: URL + "/api",
    timeout: 86400000, // 24 hours
    headers: {
        Authorization: localStorage.getItem("access_token")
            ? "JWT " + localStorage.getItem("access_token")
            : null,
        "Content-Type": "application/json",
        accept: "application/json",
    },
});

const geoserverAPI = axios.create({
    baseURL: URL + "/geoserver",
    timeout: 20000,
    headers: {
        // Authorization: localStorage.getItem('access_token')
        //     ? 'JWT ' + localStorage.getItem('access_token')
        //     : null,
        "Content-Type": "application/json",
        accept: "application/json",
    },
});

API.interceptors.response.use(
    (response) => {
        return response;
    },
    async function(error) {
        const originalRequest = error.config;

        if (typeof error.response === "undefined") {
            console.log(
                "A server/network error occurred. " +
                "Server might be down." +
                "Sorry about this - we will get it fixed shortly."
            );
            return Promise.reject(error);
        }

        if (
            error.response.status === 401 &&
            originalRequest.url === API.defaults.baseURL + "/token/refresh/"
        ) {
            window.location.href = "/";
            return Promise.reject(error);
        }

        if (
            error.response.data.code === "token_not_valid" &&
            error.response.status === 401 &&
            error.response.statusText === "Unauthorized"
        ) {
            const refreshToken = localStorage.getItem("refresh_token");

            if (refreshToken) {
                const tokenParts = decodeToken(refreshToken);

                // exp date in token is expressed in seconds, while now() returns milliseconds:
                const now = Math.ceil(Date.now() / 1000);
                console.log(tokenParts.exp);

                if (tokenParts.exp > now) {
                    return API.post("/token/refresh/", { refresh: refreshToken })
                        .then((response) => {
                            localStorage.setItem("access_token", response.data.access);

                            API.defaults.headers["Authorization"] =
                                "JWT " + response.data.access;
                            originalRequest.headers["Authorization"] =
                                "JWT " + response.data.access;

                            return API(originalRequest);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    console.log("Refresh token is expired", tokenParts.exp, now);
                    store.dispatch("userLogout").then(() => {
                        window.location.href = "/";
                    });
                    // this.$store.dispatch('userLogout').then(() => {
                    //     // location.reload();
                    //     window.location.href = '/';
                    // });
                }
            } else {
                console.log("Refresh token not available.");
                window.location.href = "/";
            }
        }

        // specific error handling done elsewhere
        return Promise.reject(error);
    }
);

getAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    async function(error) {
        const originalRequest = error.config;

        if (typeof error.response === "undefined") {
            console.log(
                "A server/network error occurred. " +
                "Server might be down." +
                "Sorry about this - we will get it fixed shortly."
            );
            return Promise.reject(error);
        }

        if (
            error.response.status === 401 &&
            originalRequest.url === API.defaults.baseURL + "/token/refresh/"
        ) {
            window.location.href = "/";
            return Promise.reject(error);
        }

        if (
            error.response.data.code === "token_not_valid" &&
            error.response.status === 401 &&
            error.response.statusText === "Unauthorized"
        ) {
            const refreshToken = localStorage.getItem("refresh_token");

            if (refreshToken) {
                const tokenParts = decodeToken(refreshToken);

                // exp date in token is expressed in seconds, while now() returns milliseconds:
                const now = Math.ceil(Date.now() / 1000);
                console.log(tokenParts.exp);

                if (tokenParts.exp > now) {
                    return API.post("/token/refresh/", { refresh: refreshToken })
                        .then((response) => {
                            localStorage.setItem("access_token", response.data.access);

                            API.defaults.headers["Authorization"] =
                                "JWT " + response.data.access;
                            originalRequest.headers["Authorization"] =
                                "JWT " + response.data.access;

                            return API(originalRequest);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    console.log("Refresh token is expired", tokenParts.exp, now);
                    store.dispatch("userLogout").then(() => {
                        window.location.href = "/";
                    });
                    // this.$store.dispatch('userLogout').then(() => {
                    //     // location.reload();
                    //     window.location.href = '/';
                    // });
                }
            } else {
                console.log("Refresh token not available.");
                window.location.href = "/";
            }
        }

        // specific error handling done elsewhere
        return Promise.reject(error);
    }
);

// TODO: longApi is not needed for file download, API might be used
longAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    async function(error) {
        const originalRequest = error.config;

        if (typeof error.response === "undefined") {
            console.log(
                "A server/network error occurred. " +
                "Server might be down." +
                "Sorry about this - we will get it fixed shortly."
            );
            return Promise.reject(error);
        }

        if (
            error.response.status === 401 &&
            originalRequest.url === longAPI.defaults.baseURL + "/token/refresh/"
        ) {
            window.location.href = "/";
            return Promise.reject(error);
        }

        if (
            error.response.data.code === "token_not_valid" &&
            error.response.status === 401 &&
            error.response.statusText === "Unauthorized"
        ) {
            const refreshToken = localStorage.getItem("refresh_token");

            if (refreshToken) {

                const tokenParts = decodeToken(refreshToken);

                // exp date in token is expressed in seconds, while now() returns milliseconds:
                const now = Math.ceil(Date.now() / 1000);
                console.log(tokenParts.exp);

                if (tokenParts.exp > now) {
                    return longAPI
                        .post("/token/refresh/", { refresh: refreshToken })
                        .then((response) => {
                            localStorage.setItem("access_token", response.data.access);

                            API.defaults.headers["Authorization"] =
                                "JWT " + response.data.access;
                            originalRequest.headers["Authorization"] =
                                "JWT " + response.data.access;

                            return longAPI(originalRequest);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    console.log("Refresh token is expired", tokenParts.exp, now);
                    store.dispatch("userLogout").then(() => {
                        window.location.href = "/";
                    });
                    // this.$store.dispatch('userLogout').then(() => {
                    //     // location.reload();
                    //     window.location.href = '/';
                    // });
                }
            } else {
                console.log("Refresh token not available.");
                window.location.href = "/";
            }
        }

        // specific error handling done elsewhere
        return Promise.reject(error);
    }
);

export { getAPI, API, geoserverAPI, longAPI };
