<template>
    <!-- NOT ALL PORTED FROM RDMenu -->
    <div class="canvasActions">
        <label for="type">{{ $t("type") }}: &nbsp;</label>
        <select class="form-control mr-2 mb-2 mt-2" id="type" v-model="selectedDrawing">
            <option value="Point">Point</option>
            <option value="LineString">LineString</option>
            <option value="Polygon">Polygon</option>
            <option value="Circle">Circle</option>
            <option value="Box">Box</option>
            <option value="None">None</option>
        </select>
        <div></div>
        <input class="form-control mr-2 mb-2 mt-2" type="button" :value="$t('map.clear_canvas')" @click="clearCanvas">
        <input class="form-control mr-2 mb-2 mt-2" type="button" :value="$t('map.undo_canvas')" id="undo" @click="undo">
        <div></div>
        <!-- WCS REQUESTS -->
<!--        <button v-show="$store.getters['map_store/getActiveLayers'].length === 1" @click="downloadSelection"-->
<!--                class="button">Download selection-->
<!--        </button>-->
        <button @click="analyzeSelection" v-show="$store.getters['map_store/getActiveLayers'].length === 1"
                class="button">Analyze selection
        </button>

    </div>


</template>

<script>
// imports fully ported
import Draw from "ol/interaction/Draw";
import ol_filter_Crop from "ol-ext/filter/Crop";
import {transform, transformExtent} from "ol/proj";
import 'ol/ol.css';
import {getArea} from 'ol/sphere';
import {Polygon} from 'ol/geom';
import proj4 from 'proj4';
import {geoserverAPI} from "@/assets/js/axio-api";
import GeoTIFF, {fromUrl, fromBlob} from "geotiff";

// TODO: PORTED FUNCTIONALITY: DRAWING, CLEAR CANVAS, EDITING

export default {
    // data fully ported
    data() {
        return {
            selectedDrawing: 'None',
            freeDrawing: false,
            palette: 'yiorrd',
        }
    },

    // watch not fully ported -- missing 2 last methods
    watch: {
        /*
        *  When change is detected, last interaction is deleted from the map
        *  and new type of interaction is created.
        */
        selectedDrawing: function (newDraw, _) {
            console.log("SELECTED DRAWING w TYPE: " + newDraw + " AND FREEHAND: " + this.freeDrawing)
            this.$store.getters['map_store/mapObject'].removeInteraction(this.$store.getters['map_store/getDraw']);
            this.$store.dispatch('map_store/addInteraction', {type: newDraw, freeHand: this.freeDrawing});
        },
    },

    // not fully ported -- last two methods
    methods: {
        getAreaDetails(){
            // perhpas use inside _WcsUrl
            let features = this.$store.getters["map_store/getSource"].getFeatures();
            let geometry = features[0].getGeometry();
            let area = (Math.round((getArea(geometry) / 1000000) * 100) / 100);
            let coords = geometry.getCoordinates()[0]; // 2D array

            // extract minx, miny, maxx, maxy
            let [minx, maxx] = [coords[0][0], coords[0][0]];
            let [miny, maxy] = [coords[0][1], coords[0][1]];
            for (let i = 0; i < coords.length; ++i) {
                minx = minx > coords[i][0] ? coords[i][0] : minx;
                maxx = maxx < coords[i][0] ? coords[i][0] : maxx;
                miny = miny > coords[i][1] ? coords[i][1] : miny;
                maxy = maxy < coords[i][1] ? coords[i][1] : maxy;
            }
            // move this somewhere or add definitions here if we are going to use others
            proj4.defs("EPSG:32633", "+proj=utm +zone=33 +ellps=WGS84 +datum=WGS84 +units=m +no_defs");
            let fromProj = new proj4.Proj('EPSG:900913');
            let toProj = new proj4.Proj('EPSG:32633');

            let minValues = proj4(fromProj, toProj, [minx, miny]);
            let maxValues = proj4(fromProj, toProj, [maxx, maxy]);

            return {area: area, minValues: minValues, maxValues: maxValues};
        },

        _WcsUrl(maxArea) {
            let features = this.$store.getters["map_store/getSource"].getFeatures();

            if (features.length === 0) {
                alert("Please draw a polygon");
                return;
            }

            let geometry = features[0].getGeometry();

            if (!(geometry instanceof Polygon)) {
                alert("Drawn feature must be a polygon");
                return;
            }

            // km2 - MAX AREA
            let area = (Math.round((getArea(geometry) / 1000000) * 100) / 100);
            console.log("area of selection(km2): " + area);
            console.log("drawn polygon : \n" + JSON.stringify(geometry.getCoordinates()));

            if (area > maxArea) {
                alert(`Area too big, max area is ${maxArea}km2, your is ${area}km2`);
                return null;
            }

            // transform coords to crs of the layer, for now it's only UTM33
            // let selectedLayer = this.$store.getters['map_store/getSelectedLayers'][0];
            let selectedLayer = this.$store.getters['map_store/getActiveLayers'][0];
            // let layerObject = this.$store.getters['map_store/mapLayerObjects'][selectedLayer];
            let layerDescription = this.$store.getters.userLayers[selectedLayer];

            let coords = geometry.getCoordinates()[0]; // 2D array

            // extract minx, miny, maxx, maxy
            let [minx, maxx] = [coords[0][0], coords[0][0]];
            let [miny, maxy] = [coords[0][1], coords[0][1]];
            for (let i = 0; i < coords.length; ++i) {
                minx = minx > coords[i][0] ? coords[i][0] : minx;
                maxx = maxx < coords[i][0] ? coords[i][0] : maxx;
                miny = miny > coords[i][1] ? coords[i][1] : miny;
                maxy = maxy < coords[i][1] ? coords[i][1] : maxy;
            }
            // move this somewhere or add definitions here if we are going to use others
            proj4.defs("EPSG:32633", "+proj=utm +zone=33 +ellps=WGS84 +datum=WGS84 +units=m +no_defs");
            let fromProj = new proj4.Proj('EPSG:900913');
            let toProj = new proj4.Proj('EPSG:32633');

            let minValues = proj4(fromProj, toProj, [minx, miny]);
            let maxValues = proj4(fromProj, toProj, [maxx, maxy]);

            return `/ows?service=WCS&version=2.0.1&request=GetCoverage&coverageId=${layerDescription.workspace}__${layerDescription.name}&subset=E(${minValues[0].toFixed(1)},${maxValues[0].toFixed(1)})&subset=N(${minValues[1].toFixed(1)},${maxValues[1].toFixed(1)})`;
        },

        async analyzeSelection() {
            let url = this._WcsUrl(10);
            if (url == null) {
                return;
            }
            let resp = await geoserverAPI(url, {responseType: 'blob',
            Authorization: "Basic " + window.btoa(this.$store.getters.userDetails.email + ":" + this.$store.getters.userDetails.uid)});

            const tiff = await fromBlob(resp.data);
            console.log(JSON.stringify(tiff));
            const image = await tiff.getImage();

            console.log(JSON.stringify(image));
            // basic image properties
            console.log("Width: " + image.getWidth());
            console.log("Height: " + image.getHeight());
            console.log("Tile width : " + image.getTileWidth());
            const imgData = await image.readRasters();
            console.log("IMG>>>", imgData);
            // PLOT CHARTS ONTO GRAPH VIEW
            let data = {};
            for (let i = 0; i < imgData.length; i++) {
                data["channel" + i] = imgData[i];
            }
            this.$store.commit('map_store/UPDATE_GRAPH_DATA', {key: 'view' + this.$store.getters['map_store/graphData'].size, value: data, type: "area_feature", info: this.getAreaDetails()});
        },

        downloadSelection() {
            let url = this._WcsUrl(1000);
            if (url == null) {
                return;
            }
            // Build the wcs request
            geoserverAPI.get(url, {responseType: 'blob'})
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'slection.tif'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(err => {
                    console.log(err);
                });
        },

        undo() {
            this.$store.getters['map_store/getDraw'].removeLastPoint();
        },

        clearCanvas() {
            this.$store.commit('map_store/DELETE_DRAWING_LAYER');
            this.selectedDrawing = 'None';
            this.freeDrawing = false;
        },
    }
}
</script>

<style scoped>

.canvasActions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 0px;
    padding-left: 5px;

}

</style>