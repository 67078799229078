<template>
    <div class="accordion-header" @click="listDetails">
        <label class="accordion-hdr-text">{{ groupObj.group_id }}</label>
        <i class="arrow" :style="rotateStyle"></i>
    </div>
    <transition>
        <div class="accordion-content" v-show="active">
            <!--            <p><strong>Group name: </strong> {{ groupObj.group_name }} </p>-->
            <p><strong>Owner: </strong> {{ groupDetails.owner }}</p>
            <p><strong>Admins </strong></p>
            <div style="background: #ffe1e1; padding: 1rem; border-radius: 0.3rem; width: 100%; text-align: left">
                <div v-for="admin in groupDetails.admins">
                    <p style="display: inline-block">{{ admin }}</p>
                </div>
            </div>
            <p><strong>Members </strong></p>
            <div style="background: #ffe1e1; padding: 1rem; border-radius: 0.3rem; width: 100%; text-align: left">
                <div v-for="member in groupDetails.members">
                    <p style="display: inline-block">{{ member }}</p>
                    <!-- Delete member -->
                    <button v-show="isGroupAdmin"
                            class="closeViewBtn" style="float: right"
                            @click="changeUserGroupStatus(member, 'delete')">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas"
                             data-icon="times-circle"
                             class="close_svg svg-inline--fa fa-times-circle fa-w-16" role="img"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 512 512">
                            <path fill="currentColor"
                                  d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
                        </svg>
                    </button>
                    <!-- Assign admin role -->
                    <button class="closeViewBtn" style="float: right"
                            v-show="isGroupAdmin"
                            @click="changeUserGroupStatus(member, 'promote')">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-lock"
                             class="close_svg svg-inline--fa fa-user-lock fa-w-20 svg-icon" role="img"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-v-0142c081="">
                            <path fill="currentColor"
                                  d="M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm96 64a63.08 63.08 0 0 1 8.1-30.5c-4.8-.5-9.5-1.5-14.5-1.5h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h280.9a63.54 63.54 0 0 1-8.9-32zm288-32h-32v-80a80 80 0 0 0-160 0v80h-32a32 32 0 0 0-32 32v160a32 32 0 0 0 32 32h224a32 32 0 0 0 32-32V320a32 32 0 0 0-32-32zM496 432a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm32-144h-64v-80a32 32 0 0 1 64 0z"
                                  data-v-0142c081=""></path>
                        </svg>
                    </button>


                </div>
                <div v-if="invitingTabOn">
                    <hr>
                    <p style="font-size: 1.5rem; font-weight: 600; margin: 1.1rem 0"><b>Invite users</b></p>
                    <input class="invite_input" v-for="(user, index) in invitedUsers" type="email"
                           placeholder="user email" v-model="invitedUsers[index]">
                    <button class="closeViewBtn" style="height: 3rem" @click="deleteLastInvitation">
                        <svg class="close_svg svg-inline--fa fa-user-lock fa-w-20 svg-icon" style="fill: currentColor"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 30" x="0px" y="0px"><title>minus</title>
                            <rect x="1" y="10" width="22" height="4" rx="1.5" ry="1.5"/>
                        </svg>
                    </button>
                    <button class="closeViewBtn" style="height: 3rem" @click="invitedUsers.push('')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                             xml:space="preserve" version="1.0"
                             class="close_svg svg-inline--fa fa-user-lock fa-w-20 svg-icon"
                             style="shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;"
                             viewBox="0 0 222 277.5" x="0px" y="0px" fill-rule="evenodd" clip-rule="evenodd">
                            <g><path style="fill: currentColor" d="M21 89l68 0 0 -68c0,-28 44,-28 44,0l0 68 68 0c28,0 28,44 0,44l-68 0 0 68c0,28 -44,28 -44,0l0 -68 -68 0c-28,0 -28,-44 0,-44z"/></g>
                         </svg>
                    </button>
                </div>
                <button v-if="!invitingTabOn" style="float: right" class="renameBtn" v-show="isGroupAdmin"
                        @click="addInvitee">
                    Add
                </button>
                <button v-if="invitingTabOn && invitedUsers.length > 0 && validUserEmails" style="float: right"
                        class="renameBtn" v-show="isGroupAdmin"
                        @click="inviteUsers">
                    Invite
                </button>
            </div>

            <button class="renameBtn"
                    @click="changeUserGroupStatus($store.getters.userDetails.email, 'delete')">
                Leave group
            </button>

            <!--Controls-->
            <div v-if="isGroupAdmin" style="width: 100%">
                <hr>
                <p style="font-size: 1.5rem; font-weight: 600; margin: 1.1rem 0"><b>Settings</b></p>
                <div>
                    <!--DISABLED ON PURPOSE-->
                    <button style="display: inline-block" class="renameBtn"
                            v-show="false && renameActive===false && isGroupAdmin"
                            @click="renameActive=true;">
                        Rename
                    </button>
                </div>

                <!--Rename-->
                <div style=" width:100%; text-align: left; padding: 1em" v-if="renameActive">
                    <button class="closeRenameBtn" style="float: right"
                            @click="renameActive=false; contextMessage=''">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas"
                             style="height: 1.5rem; float: right"
                             data-icon="times-circle"
                             class="close_svg svg-inline--fa fa-times-circle fa-w-16" role="img"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 512 512">
                            <path fill="currentColor"
                                  d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
                        </svg>
                    </button>
                    <p style="font-size: 1.5rem; font-weight: 600"><b>Rename</b></p>
                    <hr>
                    <p><b>From: </b>{{ groupObj.group_id }}</p>
                    <input class="text_input_field"
                           v-model="newName"
                           placeholder="To"
                           type="text">
                    <button
                        @click="renameGroup"
                        class="renameBtn">Rename
                    </button>
                </div>
            </div>

            <p class="has-text-danger" v-show="contextMessage">{{ contextMessage }}</p>


            <!--            <p style="margin: 1rem 0 0.5rem 0"><strong>Source for WMS requests</strong></p>-->
            <!--            <p><strong>Url:</strong> https://envision.cerit-sc.cz/geoserver/wms <strong><a-->
            <!--                @click="copyToClipboard('https://envision.cerit-sc.cz/geoserver/wms')"> Copy</a></strong></p>-->
            <!--            <p><strong>Layer:</strong> {{ layerObj.workspace + ":" + layerObj.name }} <strong><a-->
            <!--                @click="copyToClipboard(layerObj.workspace + ':' + layerObj.name)"> Copy</a></strong></p>-->
        </div>
    </transition>
</template>

<script>
import {API} from "@/assets/js/axio-api";

export default {

    props: {
        id: Number,
    },

    data() {
        return {
            groupObj: null,
            groupDetails: {},
            active: true,
            deg: -135,
            renameActive: false,
            newName: "",
            contextMessage: "",
            invitingTabOn: false,
            invitedUsers: []
        }
    },
    methods: {
        deleteLastInvitation() {
            this.invitedUsers.pop();
            if (this.invitedUsers.length === 0) {
                this.invitingTabOn = false;
                // will delete also if there's rename error but it's less confusing for the user
                this.contextMessage = "";
            }
        },
        async inviteUsers() {
            this.contextMessage = "";
            // verification can be done in bckend and erroneous users returned
            let data = await this.$store.dispatch("inviteToGroup", {
                users: this.invitedUsers,
                group_name: this.groupObj.group_id,
                byAdmin: this.$store.getters.userDetails.email
            });
            if (!data) {
                this.contextMessage = "An error occured";
            }
            if (data.result) {
                this.invitedUsers = data.result;
                this.contextMessage = "Could not invite non-existing users.";
            }
        },

        listDetails() {
            if (this.active)
                this.deg = 45;
            else
                this.deg = -135;
            this.active = !this.active;
        },

        addInvitee() {
            this.invitedUsers.push("");
            this.invitingTabOn = true;
        },

        // add admin or kick from group or leave group (username == name of the kicked user)
        async changeUserGroupStatus(user, action) {
            if (action === 'delete')
                await this.$store.dispatch('deleteUserFromGroup', {group_name: this.groupObj.group_id, user_to_del: user});
            else
                await this.$store.dispatch('promoteToGroupAdmin', {group_name: this.groupObj.group_id, user: user});

            if (this.$store.getters.userDetails.email === user) {
                this.$store.dispatch('fetchUserGroups');
                this.$emit("deleted");
                return;
            }
            this.$store.dispatch('fetchUserGroups');
            this.fetchDetails();
        },

        async renameGroup() {
            if (!this.newName) {
                this.contextMessage = "Name cannot be empty."
                return;
            }

            // or ?
            let reg = /^[0-9a-zA-Z]+$/;
            if (!this.newName.match(reg)) {
                this.contextMessage = "Name of the group can contain only alpha numeric characters";
                return;
            }

            let result = await this.$store.dispatch('renameGroup', {
                group_name: this.groupObj.group_id,
                new_name: this.newName
            });

            switch (result) {
                case 201:
                    this.contextMessage = "Rename successful.";
                    break;
                case 400:
                    this.contextMessage = "Something went wrong, try again later.";
                    break;
                case 403:
                    this.contextMessage = "Only owner can change the name of the group.";
                    break;
                case 409:
                    this.contextMessage = "This name cannot be used.";
                    break;
                default:
                    this.contextMessage = "This is interesting...";
                    break;
            }

            this.newName = "";
            this.$store.dispatch('fetchUserGroups');
            this.renameActive = false;
        },

        fetchDetails() {
            API.get("geoserver/group/members/", { params: { group_name: this.groupObj.group_id } })
            .then(response => {
                this.groupDetails = response.data;
            })
            .catch(err => {
                console.log("GROUP DETAILS FAILED: " + err);
            });
        }
    },

    computed: {
        rotateStyle() {
            return {transform: 'rotate(' + this.deg + 'deg)'}
        },
        isGroupAdmin() {
            return this.$store.getters.userGroups[this.id].is_admin;
            // return this.groupObj.admins.includes(this.$store.getters.userDetails.email);
        },
        validUserEmails() {
            this.contextMessage = "";
            for (let i = 0; i < this.invitedUsers.length; i++) {
                if (this.invitedUsers[i] === "") {
                    this.contextMessage = "User emails must not be empty.";
                    return false;
                }
            }
            return true;
        }
    },

    created() {
        this.groupObj = this.$store.getters.userGroups[this.id];
    },

    mounted() {
        // fetch info when the object is clicked
       this.fetchDetails();
    }

}
</script>

<style scoped>
.invite_input {
    margin: 0.5rem;
    width: 25%;
}

.text_input_field {
    border-radius: 0.5em;
    border: none;
    background: #e2e2e2;
    height: 2rem;
    margin: 0.5rem 1rem 0.5rem 0;

}

.accordion-header {
    display: flex;
    align-items: center;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    background: #7b525a;
    padding: 10px;
    cursor: pointer;
}


.accordion-hdr-text {
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    color: #fffcf2;
}


.accordion-content {
    background-color: white;
    border-radius: 0px 0px 5px 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.arrow {
    margin-left: auto;
    cursor: pointer;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transition: all .4s linear;
}

.closeRenameBtn {
    position: relative;
    background: none;
    border: none;
    color: #FF7673FF;
}

.closeRenameBtn:hover {
    color: #7b525a;
}


.renameBtn {
    height: 3em;
    width: auto;
    /*margin-top: 1em;*/
    border: none;
    margin: 10px;
    padding: 5px 10px;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}

.renameBtn:hover {
    background: #7b525a;

}


.closeViewBtn {
    position: relative;
    background: none;
    border: none;
    color: #FF7673FF;
}

.closeViewBtn:hover {
    color: #7b525a;
}

.close_svg {
    height: 1rem;
    margin-left: 3rem
}

</style>