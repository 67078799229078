<template>
    <div id="FileUploader">
        <FilePond
            name="filepond"
            ref="pond"
            :label-idle="$t('file_view.drop_files')"
            credits="{}"
            allow-drop="true"
            allow-reorder="true"
            allow-remove="true"
            allow-browse="true"
            instant-upload="true"
            check-validity="true"
            allow-image-preview="false"
            max-file-size="max_filesize"
            allow-multiple="true"
            allow-file-type-validation="true"
            accepted-file-types='image/tif, image/tiff, text/csv, *hdr, *bsq, *bil, *bip, *jp2, image/jp2'
            v-bind:file-validate-type-detect-type="resolveType"
            v-bind:server="myServer"
            v-bind:files="myFiles"
            v-on:init="handleFilePondInit"
            v-on:processfile="handleFilePondProcessFile"
            v-on:removefile="handleFilePondRemoveFile"
        />
        <button
            class="storeBtn"
            v-show="$store.getters.filesToUpload.length > 0"
            @click="storeFiles"
        >
            Store
        </button>
        <!--                    v-bind:file-validate-type-detect-type="resolveType"-->
        <!--                v-on:addfile="listFiles"-->
        <!--            v-on:addfile="resolveType"-->
        <!-- accepted-file-types=""     server="/api" -->
    </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import "@/../node_modules/filepond/dist/filepond.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateSize
);
// FilePond.registerPlugin(FilePondPluginFileValidateType);

export default {
    name: "FileUploader",
    data: function () {
        return {
            myFiles: [],
            uploadedFiles: [],
            myServer: {
                // instantUpload: true,
                url: process.env.VUE_APP_BASE_URL + "/fp",
                timeout: 5500000,
                process: "/process/",
                patch: "/patch/",
                revert: "/revert/",
                fetch: "/fetch/?target=",
                load: "/load/",
            },
        };
    },
    computed: {
        max_filesize() {
            return this.$store.getters.userMaxFileSize;
        },
    },
    methods: {
        user_max_file_size() {
            return this.$store.getters.userMaxFileSize;
        },

        resolveType: (source, type) =>
            new Promise((resolve, reject) => {
                // Do custom type detection here and return with promise
                let allowed_types = /.*tif|.*tiff|.*bsq|.*bil|.*bip|.*csv|.*hdr|.*jp2/;
                if (allowed_types.test(type)) {
                    resolve(type);
                    return;
                }
                let source_name = source.name.split(".");
                if (allowed_types.test(source_name[source_name.length - 1])) {
                    resolve("image/tiff");
                    return;
                }
                reject();
            }),
        handleFilePondInit: function () {
            console.log("FilePond has initialized");
            // FilePond instance methods are available on `this.$refs.pond`
        },
        handleFilePondProcessFile: function (error, file) {
            this.$store.commit("ADD_FILE_TO_UPLOAD", {
                id: file.serverId,
                name: file.filename,
            });
            this.$nextTick();
        },
        handleFilePondRemoveFile: function (error, file) {
            let index = this.$store.getters.fileNamesToUpload.indexOf(
                file.filename
            );
            if (index > -1) {
                this.$store.commit("REMOVE_FILE_FROM_UPLOAD", index);
            }
            this.$nextTick();
        },
        storeFiles() {
            if (this.$store.dispatch("uploadFilesPermanently")) {
                this.$refs.pond.removeFiles();
                return;
            }
            alert("Error while storing files, please try again");
        },
    },
    components: {
        FilePond,
    },
};
</script>

<style scoped>
.storeBtn {
    /*position: relative;*/
    color: #fffcf2;
    border: none;
    background: #7b525a;
    margin-bottom: 2em;
    border-radius: 1em;
    height: 3em;
    width: 4em;
    position: relative;
}

.storeBtn:hover {
    background: #eb4e6a;
    /*color: #0a0a0a;*/
}

#FileUploader {
    background: #f1f0ef;
    border-radius: 1em;
    margin-bottom: 2em;
}
</style>
