<template>
  <nav class="navbarCustom">
    <div :class="{'navOpened': isActive, 'navClosed': !isActive}" class="navOpened navbarContent">
        <transition name="fade" mode="out-in">
            <!-- Main content -->
            <div v-show="isActive" class="content__">
                <!--Horizontal menu for sections-->
                <div class="tabs is-centered pick-menu">
                    <img @click="goBackHome" style="width: 25px; cursor: pointer" src="@/assets/imgs/map-go-home.svg">
                    <ul>
                        <li :class="{'is-active': page===0}" @click="togglePage(0)"><a>Layers</a></li>
                        <li :class="{'is-active': page===1}" @click="togglePage(1)"><a>Draw</a></li>
                        <li :class="{'is-active': page===2}" @click="togglePage(2)"><a>Other</a></li>
                    </ul>
                </div>
                <!-- Content driven by pages that might be triggered in menu above -->
                <div v-show="page === 0">
                    <LayerBox />
                </div>
                <div v-show="page === 1">
                    <DrawBox />
                </div>
                <div v-show="page === 2">
                    <Others/>
                </div>
            </div>
        </transition>
        <div class="navbarArrow" @click="open"></div>
    </div>
  </nav>
<!--    <nav class="navbarCustom" :class="[isActive === false ? '' : 'navbarCustomActive']">-->
<!--        <ul class="navbar-nav">-->
<!--            <li class="nav-item" @click="toggleNav(0)">-->
<!--                <a href="#" class="nav-link">-->
<!--                    <svg-->
<!--                        aria-hidden="true"-->
<!--                        focusable="false"-->
<!--                        data-prefix="fad"-->
<!--                        data-icon="angle-double-right"-->
<!--                        role="img"-->
<!--                        xmlns="http://www.w3.org/2000/svg"-->
<!--                        viewBox="0 0 448 512"-->
<!--                        class="svg-inline&#45;&#45;fa fa-angle-double-right fa-w-14 fa-5x"-->
<!--                        :class="{rotatedButton: isActive}"-->
<!--                    >-->
<!--                        <g class="fa-group">-->
<!--                            <path-->
<!--                                fill="currentColor"-->
<!--                                d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"-->
<!--                                class="fa-secondary"-->
<!--                            ></path>-->
<!--                            <path-->
<!--                                fill="currentColor"-->
<!--                                d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"-->
<!--                                class="fa-primary"-->
<!--                            ></path>-->
<!--                        </g>-->
<!--                    </svg>-->
<!--                </a>-->
<!--            </li>-->

<!--            &lt;!&ndash;LAYERS&ndash;&gt;-->
<!--            <li class="nav-item" v-show="page === 0" @click="toggleNav(1)">-->
<!--                <a href="#" class="nav-link">-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"-->
<!--                         data-icon="layer-group" class="svg-inline&#45;&#45;fa fa-layer-group fa-w-16" role="img"-->
<!--                         viewBox="0 0 512 512">-->
<!--                        <path fill="currentColor"-->
<!--                              d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"/>-->
<!--                    </svg>-->
<!--                    <span v-show="isActive" class="link-text">{{ $t("map.layers") }}</span>-->
<!--                </a>-->
<!--            </li>-->

<!--            <li class="nav-item action-button" v-show="isActive && page === 1">-->
<!--                <LayerBox/>-->
<!--            </li>-->

<!--            &lt;!&ndash; DRAW UTILS &ndash;&gt;-->
<!--            <li class="nav-item" v-if="page === 0" @click="toggleNav(2)">-->
<!--                <a href="#" class="nav-link">-->
<!--                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pencil-alt"-->
<!--                         class="svg-inline&#45;&#45;fa fa-pencil-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"-->
<!--                         viewBox="0 0 512 512">-->
<!--                        <path fill="currentColor"-->
<!--                              d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"/>-->
<!--                    </svg>-->
<!--                    <span v-if="isActive" class="link-text">{{ $t("map.draw") }}</span>-->
<!--                </a>-->
<!--            </li>-->

<!--            <li class="nav-item action-button" v-show="isActive && page === 2">-->
<!--                <p>{{ $t("map.draw_util") }}</p>-->
<!--                <DrawBox/>-->
<!--            </li>-->

<!--            &lt;!&ndash; SENTINEL JOB &ndash;&gt;-->
<!--            <li class="nav-item" v-if="page === 0" @click="$emit('showSentinelModal')">-->
<!--                <a href="#" class="nav-link">-->
<!--                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="satellite"-->
<!--                         class="svg-inline&#45;&#45;fa fa-satellite fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"-->
<!--                         viewBox="0 0 512 512">-->
<!--                        <path fill="currentColor"-->
<!--                              d="M502.60969,310.04206l-96.70393,96.71625a31.88151,31.88151,0,0,1-45.00765,0L280.572,326.34115l-9.89231,9.90759a190.56343,190.56343,0,0,1-5.40716,168.52287c-4.50077,8.50115-16.39342,9.59505-23.20707,2.79725L134.54715,400.05428l-17.7999,17.79929c.70324,2.60972,1.60965,5.00067,1.60965,7.79793a32.00544,32.00544,0,1,1-32.00544-32.00434c2.79735,0,5.18838.90637,7.7982,1.60959l17.7999-17.79929L4.43129,269.94287c-6.798-6.81342-5.70409-18.6119,2.79735-23.20627a190.58161,190.58161,0,0,1,168.52864-5.407l9.79854-9.79821-80.31053-80.41716a32.002,32.002,0,0,1,0-45.09987L201.96474,9.29814A31.62639,31.62639,0,0,1,224.46868,0a31.99951,31.99951,0,0,1,22.59759,9.29814l80.32615,80.30777,47.805-47.89713a33.6075,33.6075,0,0,1,47.50808,0l47.50807,47.50645a33.63308,33.63308,0,0,1,0,47.50644l-47.805,47.89713L502.71908,265.036A31.78938,31.78938,0,0,1,502.60969,310.04206ZM219.56159,197.433l73.82505-73.82252-68.918-68.9-73.80942,73.80689Zm237.74352,90.106-68.90233-68.9156-73.825,73.82252,68.918,68.9Z"/>-->
<!--                    </svg>-->

<!--                    <span :class="[isActive ? 'link-text' : 'link-text-none']">Sentinel</span>-->
<!--                </a>-->
<!--            </li>-->

<!--            &lt;!&ndash; RETURN TO DASHBOARD, ALWAYS LAST! &ndash;&gt;-->
<!--            <li class="nav-item nav-item-last">-->
<!--                <a class="nav-link"-->
<!--                   v-if="$route.path === '/map'"-->
<!--                   id="return-home"-->
<!--                   @click="goBackHome()"-->
<!--                >-->
<!--                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="reply-all"-->
<!--                         class="svg-inline&#45;&#45;fa fa-reply-all fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg"-->
<!--                         viewBox="0 0 576 512">-->
<!--                        <path fill="currentColor"-->
<!--                              d="M136.309 189.836L312.313 37.851C327.72 24.546 352 35.348 352 56.015v82.763c129.182 10.231 224 52.212 224 183.548 0 61.441-39.582 122.309-83.333 154.132-13.653 9.931-33.111-2.533-28.077-18.631 38.512-123.162-3.922-169.482-112.59-182.015v84.175c0 20.701-24.3 31.453-39.687 18.164L136.309 226.164c-11.071-9.561-11.086-26.753 0-36.328zm-128 36.328L184.313 378.15C199.7 391.439 224 380.687 224 359.986v-15.818l-108.606-93.785A55.96 55.96 0 0 1 96 207.998a55.953 55.953 0 0 1 19.393-42.38L224 71.832V56.015c0-20.667-24.28-31.469-39.687-18.164L8.309 189.836c-11.086 9.575-11.071 26.767 0 36.328z"/>-->
<!--                    </svg>-->
<!--                </a>-->
<!--            </li>-->

<!--        </ul>-->
<!--    </nav>-->
</template>

<script>
import router from "@/router";
import LayerBox from "@/components/map_enviroment/layers/LayerBox";
import DrawBox from "@/components/map_enviroment/DrawBox.vue";
import Others from "@/components/map_enviroment/Others";


export default {
    name: "Navbar",
    components: {Others, LayerBox, DrawBox},
    data() {
        return {
            isActive: false,
            page: 0,
        }
    },
    methods: {
        open() {
          this.isActive = !this.isActive;
        },
        togglePage(page) {
            this.page = page;
        },
        goBackHome() {
            router.push("dashboard");
        },
    }
}
</script>

<style scoped>
/*SVG's are Not aligned with text*/
.navbarCustom {
    height: 100%;
    position: absolute;
    display: flex;
    padding: 20px 20px 30px;
    pointer-events: none;
    z-index: 99;
}

.navOpened {
    width: 340px;
    transition: width .7s ease;
}

.navClosed {
    width: 0;
    transition: width .7s ease;
}

.navbarContent {
    height: 100%;
    background-color: #ffd1d1eb;
    border-radius: 10px;
    pointer-events: auto;
}

.navbarArrow {
    position: absolute;
    -webkit-box-align: center;
    align-items: center;
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgb(106, 116, 133);
    border-radius: 1px;
    color: rgb(41, 50, 60);
    display: flex;
    height: 20px;
    right: -8px;
    top: 20px;
    width: 20px;
}

.pick-menu {
    padding: 0 10px 0 10px;
}

.content__ {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.fade-enter-active {
  transition: all .7s ease;
}
.fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-enter, .fade-leave-to {

  opacity: 0;
}

/*.menu {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    gap: 10px;*/
/*}*/

/*.navbar-nav {*/
/*    list-style: none;*/
/*    padding: 0;*/
/*    margin: 0;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    overflow: hidden;*/
/*    height: 100%;*/
/*}*/

/*.nav-item.action-button {*/
/*    width: 100%;*/
/*}*/

/*.link-text {*/
/*    display: block;*/
/*}*/

/*.link-text-none {*/
/*    display: none;*/
/*}*/

/*!* .nav-item:last-child {*/
/*    margin-top: auto;*/
/*}*!*/
/*.nav-item-last {*/
/*    margin-top: auto;*/
/*}*/

/*.nav-link {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    height: 5rem;*/
/*    text-decoration: none;*/
/*}*/

/*.nav-link svg {*/
/*    min-width: 1.5rem;*/
/*    max-width: 1.5rem;*/
/*    margin: 0 1.5rem;*/
/*}*/

/*.navbarCustomActive {*/
/*    width: 18rem;*/
/*}*/

</style>