<template xmlns="http://www.w3.org/1999/html">
    <LayerHeaderMenu  :hide-button="true" :add-layer="true" :name="$t('map.available_layers')"
                      @arrow-click="showLayers = $event" @add-layer-click="addCustomOpened = !addCustomOpened"/>
    <ul class="customDrag" id="itemsAll" v-show="showLayers">
        <!-- Add custom layer option -->
        <transition name="slide">
            <div class="addLayerArea" v-if="addCustomOpened">
                <div class="addLayerItem" style="grid-area: a;">
                    <input class="addLayerItem" type="text" placeholder="WMS link">
                </div>
                <div class="addLayerItem" style="grid-area: b;">
                    <input class="addLayerItem" type="text" placeholder="Name">
                </div>
                <div style="grid-area: c;">
                    <button class="addLayerItem">Add</button>
                </div>
            </div>
        </transition>

        <!-- List existing layers -->
        <LayerItem v-for="(item, index) in  $store.getters.userLayers" :key="index" :data-id="index" :class="{disable: loadedLayers[index]}"
                   :name="item.name" :myId="index" :ref="'item' + index" @listDetails="loadedLayers[index] = !loadedLayers[index]" @dblclick="zoomLayer(index, item)" />
    </ul>
    <br>
    <LayerHeaderMenu :hide-button="false" :add-layer="false" :name="$t('map.active_layers')" />
    <ul class="customDrag" id="itemsActive"></ul>
</template>

<script>
import Sortable from 'sortablejs';
import "ol/ol.css";
import {fromLonLat} from "ol/proj";
import LayerItem from "@/components/map_enviroment/layers/LayerItem";
import LayerHeaderMenu from "@/components/map_enviroment/layers/LayerHeaderMenu";

export default {
    name: "LayerBox",
    components: {
        LayerItem,
        LayerHeaderMenu
    },
    data() {
        return {
            showLayers: true,
            addCustomOpened: false,
            compareLayers: false,
            loadedLayers: [], // arr of booleans initialized in mounted hook
        }
    },

    methods: {
        toggleLayers() {
            this.showLayers = !this.showLayers;
        },

        setActiveLayers(layers) {
            this.$store.commit('map_store/UPDATE_ACTIVE_LAYERS', layers);
        },

        zoomLayer(index, layer) {
            if (!this.$store.getters["map_store/getActiveLayers"].includes(index))
                return;
            const map = this.$store.getters["map_store/mapObject"];
            const bb = layer["latLonBoundingBox"];
            const centerPoint = [(bb["minx"] + bb["maxx"]) / 2, (bb["miny"] + bb["maxy"]) / 2];
            let zoom = 9;
            
            if (Math.max(bb["maxx"] - bb["minx"], bb["maxy"] - bb["miny"]) < 0.5)
                zoom = 15;

            map.getView().animate({
                zoom: zoom,
                center: fromLonLat(centerPoint),
                duration: 600
            });
        },

    },

    mounted() {
        this.$store.commit("map_store/CLEAR_SELECTED_LAYERS");
        this.loadedLayers = Array(this.$store.getters.userLayers.length).fill(false);
        let iall = document.getElementById('itemsAll');
        let iacc = document.getElementById('itemsActive');
        let iright = document.getElementById('rightLayers');
        let ileft = document.getElementById('leftLayers');

        const ourThis = this;
        this.allLayers = Sortable.create(iall, {
        group: 'shared',
        handle: '.layer-handler',
        animation: 150,
        filter: '.disable',
        preventOnFilter: false,
        onMove: event => {
            return !event.related.classList.contains('disabled');
        },
        
        store: {
            set: function (sortable) {
                let inactiveLayers = sortable.toArray().map(elem => {
                    let eInt = parseInt(elem);
                    ourThis.$refs["item" + elem].unpin();
                    return eInt;
                });
                // Disable 'click-state' on element that have been moved to Layers(inactive on map) section
                // ourThis.$store.commit('map_store/REMOVE_SELECTED_LABELS', inactiveLayers);
                // if (ourThis.$store.getters["map_store/getAnalyticView"]) {
                //     ourThis.$store.commit('map_store/UPDATE_ANALYTIC_VIEW');
                // }
            }
        }
        });

        this.activeLayers = Sortable.create(iacc, {
        group: 'shared',
        handle: '.layer-handler',
        animation: 150,
        filter: '.disable',
        preventOnFilter: false,
        onMove: event => {
            return !event.related.classList.contains('disabled');
        },
        store: {
            /**
            * Save the order of elements. Called onEnd (when the item is dropped).
            * @param {Sortable}  sortable
            */
            set: function (sortable) {
                ourThis.setActiveLayers(sortable.toArray().map(elem => parseInt(elem)));
                ourThis.$store.dispatch('map_store/update_map_layers');
            }
        }
        });
        console.log("refs", this.$refs);
    }
}
</script>

<style scoped>

ul {
    background-color:  rgba(255,235,235,.2);
}

.customDrag {
    display: flex;
    list-style: none;
    flex-direction: column;
    width: 100%;
    min-height: 2rem;
    overflow-y: scroll; /* not showing because it's explicitly disable in App.vue */
    padding: 5px 10px 5px 10px;
}

/* start: Add custom layer section*/
.addLayerArea {
    display: grid;
    padding: 2px 2px 2px 2px;
    border-radius: 5px;
    gap: 5px;
    grid-template-areas:
        'a b'
        'c c';
    background-color: rgb(255, 233, 233);

}

.addLayerItem {
    width: 100%;
}

/* box transition */
.slide-enter-from, .slide-leave-to{
    transform: translateY(-65%);
    opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
    transition: all .5s ease;
}
/* end: Add custom layer section*/

#itemsAll {
    max-height: 10rem;
}

#itemsActive{
    overflow-y: auto;
    max-height: 60vh;
}
</style>