<template>
    <div class="image_div_wrap" v-if="true">
        <div class="image_div" style="text-align: center">
            <div class="image_div_content" v-if="true">
                <div class="image_div_header"><p>{{ styleName }}</p>
                </div>
                <br>
                <!--                &lt;!&ndash;                Layer properties&ndash;&gt;-->
                <!--                <p style="font-size: 1.3rem">Layer properties</p>-->
                <!--                <p>Number of bands: {{ 3 }}</p>-->
                <hr>
                <p style="font-size: 1.3rem">Map algebra (Jiffle experssion)</p>
                <br>
                <!--                Jiffle calc-->
                <JiffleCalculator v-on:syntaxCheck="updateJiffleStatus" :init_script="jiffleStatus.script"
                                  :override="overrideScript"
                                  style="height: 15%"></JiffleCalculator>
                <!--                channel selection-->
                <br><br>
                <div class="editor-section" v-if="jiffleStatus.script === ''">
                    <br>
                    <p style="font-size: 1.3rem">Channel selection</p>
                    <br>
                    <input class="inputField" type="radio" id="channels_default" value=""
                           v-model="channelSelection.type">
                    <label for="channels_default">Default</label>
                    <input class="inputField" type="radio" id="rgb_selection" value="rgb"
                           v-model="channelSelection.type">
                    <label for="rgb_selection">RGB channels</label>
                    <input class="inputField" type="radio" id="grayscale_selection" value="grayscale"
                           v-model="channelSelection.type">
                    <label for="grayscale_selection">Grayscale channel</label>
                    <div v-if="channelSelection.type === 'rgb'">
                        <br>
                        <label for="red_channel">Red channel: </label>
                        <input class="inputField" id="red_channel" placeholder="band number" type="number"
                               v-model="channelSelection.red">
                        <br>
                        <label for="green_channel">Green channel: </label>
                        <input class="inputField" id="green_channel" placeholder="band number" type="number"
                               v-model="channelSelection.green">
                        <br>
                        <label for="blue_channel">Blue channel: </label>
                        <input class="inputField" id="blue_channel" placeholder="band number" type="number"
                               v-model="channelSelection.blue">
                    </div>
                    <div v-if="channelSelection.type === 'grayscale'">
                        <br>
                        <label for="grayscale_selection">Grayscale channel: </label>
                        <input class="inputField" id="gray_channel" placeholder="band number" type="number"
                               v-model="channelSelection.gray">

                    </div>

                </div>
                <div v-else><p>Channel selection is available only without applied jiffle expression</p></div>
                <br>
                <!--    brightness & contrast enhancement-->
                <div class="editor-section">
                    <br>
                    <p style="font-size: 1.3rem">Contrast Enhancement</p>
                    <br>
                    <input class="inputField" type="radio" id="contrast_disabled" value=""
                           v-model="contrastEnhancement.type">
                    <label for="contrast_disabled">Do not apply</label>
                    <br>
                    <input class="inputField" type="radio" id="normalize" value="normalize"
                           v-model="contrastEnhancement.type">
                    <label for="normalize">Normalize</label>
                    <select class="inputField" v-if="contrastEnhancement.type==='normalize'"
                            v-model="contrastEnhancement.algorithm">
                        <option value="Default">Default</option>
                        <option>StretchToMinimumMaximum</option>
                        <option>ClipToMinimumMaximum</option>
                        <option>ClipToZero</option>
                    </select>
                    <input class="inputField" placeholder="Min"
                           v-if="contrastEnhancement.type==='normalize' && contrastEnhancement.algorithm !== 'Default'"
                           type="number"
                           v-model="contrastEnhancement.minValue">
                    <span
                        v-if="contrastEnhancement.type==='normalize' && contrastEnhancement.algorithm !== 'Default'">-</span>
                    <input class="inputField" placeholder="Max"
                           v-if="contrastEnhancement.type==='normalize' && contrastEnhancement.algorithm !== 'Default'"
                           type="number"
                           v-model="contrastEnhancement.maxValue">
                    <br>
                    <input class="inputField" type="radio" id="histogram" value="histogram"
                           v-model="contrastEnhancement.type">
                    <label for="histogram">Histogram equalization</label>
                    <br>
                    <input class="inputField" type="radio" id="gamma_value" value="gamma_value"
                           v-model="contrastEnhancement.type">
                    <label for="gamma_value">Gamma value</label>
                    <input class="inputField" placeholder="value" v-if="contrastEnhancement.type==='gamma_value'"
                           type="number"
                           v-model="contrastEnhancement.gammaValue">
                    <br>
                </div>
                <br><br>
                <div class="editor-section">
                    <p style="font-size: 1.3rem">Color map</p>
                    <br>
                    <!--                Color map-->
                    <ColorMap v-if="colorMapApplicable" v-on:colorMapUpdated="updateColorMap"
                              :init_colormap="{colorMapEntries: colorMapEntries, colorMapMode: colorMapMode}"
                              :override="overrideColorMap" :locked="false"></ColorMap>
                    <div v-show="!colorMapApplicable">
                        <p>Color map can be applied only to: </p>
                        <ul style="margin-left: 3%">
                            <li>- if input file has only single band</li>
                            <li>- if grayscale channel selected previously
                                <button class="addEntryBtn"
                                        @click="channelSelection.type='grayscale';channelSelection.gray=1">Apply
                                </button>
                            </li>
                            <li>- if Jiffle expression was applied, defining one single "dest" band on the
                                output
                                <button class="addEntryBtn"
                                        @click="overrideScript=true; jiffleStatus.script='dest=src[1];'">Apply
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr>
                <div class="editor-section"><label for="style_name">Style name</label>
                    <input class="inputField" id="style_name" placeholder="Name"
                           v-if="true"
                           type="text"
                           v-model="name"></div>
                <br><br>
            </div>
            <button class="closeBtn" @click="createStyle(false)">Create new</button>
            <button class="closeBtn" v-show="isPersonal && styleName" @click="createStyle(true)">Apply</button>
            <button class="closeBtn" @click="$emit('closeStyleEditor')">Close</button>
        </div>
    </div>
</template>

<script>
import ColorMap from "@/components/ColorMap";
import JiffleCalculator from "@/components/JiffleCalculator";

export default {
    props: {
        styleName: String,
        styleType: String
    },
    name: "StyleEditor",
    components: {JiffleCalculator, ColorMap},
    data() {
        return {
            name: "",
            contrastEnhancement: {type: "", algorithm: "Default", minValue: "", maxValue: "", gammaValue: ""},
            channelSelection: {type: "", red: "", green: "", blue: "", gray: ""},
            jiffleStatus: {status: true, script: ""},
            overrideScript: false,
            colorMapEntries: [{color: '#2140d9', quantity: 0, opacity: 1},
                {color: '#edd400', quantity: 50, opacity: 1}, {color: '#ef2929', quantity: 100, opacity: 1}],
            colorMapMode: "ramp",
            overrideColorMap: false
        }
    },
    computed: {
        colorMapApplicable() {
            // if there's only 1 band
            // or jiffle is active
            // or grayscale channel is chosen
            return (this.jiffleStatus.status && this.jiffleStatus.script !== "") ||
                (this.channelSelection.type === "grayscale" && this.validateNumValue(this.channelSelection.gray, 0, Infinity));
        },

        isPersonal() {
            return this.styleType === "personal";
        }
    },
    methods: {
        updateColorMap(content) {
            this.colorMapEntries = content.entries;
            this.colorMapMode = content.mode;
        },
        updateJiffleStatus(content) {
            if (content) {
                this.jiffleStatus = content;
            }
        },
        validateNumValue(value, min, max) {
            if (value === "") {
                return false;
            }
            return min <= parseFloat(value) && parseFloat(value) <= max;
        },

        validateStyleConfig() {

            if (!this.jiffleStatus.status) {
                alert("Invalid Jiffle expression!");
                return false;
            }

            if (this.channelSelection.type === "grayscale" && (!this.channelSelection.gray || this.channelSelection.gray < 1)) {
                alert("Invalid gray channel value");
                return false;
            }
            if (this.channelSelection.type === "rgb" && (!this.channelSelection.red || !this.channelSelection.green
                || !this.channelSelection.blue || this.channelSelection.red < 1 || this.channelSelection.green < 1
                || this.channelSelection.blue < 1)) {
                alert("Invalid rgb channel value");
                return false;
            }


            if (this.contrastEnhancement.type === "normalize" && this.contrastEnhancement.algorithm !== "Default") {
                let _min = this.contrastEnhancement.minValue;
                let _max = this.contrastEnhancement.maxValue;
                if (_min === "" || _max === "" || _min >= _max || _min < 0 || _max < 0) {
                    alert("Normalize input values out of bounds");
                    return false;
                }
            }

            if (this.contrastEnhancement.type === "gamma_value" && (!this.contrastEnhancement.gammaValue == null || this.contrastEnhancement.gammaValue < 0)) {
                alert("Bad gamma value");
                return false;
            }

            if (this.colorMapEntries === 1) {
                alert("Applied color map must have at least 2 entries.");
                return;
            }

            for (let i = 0; i < this.colorMapEntries.length; ++i) {
                let obj = this.colorMapEntries[i];
                if ((!obj.quantity && obj.quantity !== 0) || (!obj.opacity && obj.opacity !== 0) || obj.quantity < 0 || !(0 <= obj.opacity <= 1)) {
                    alert("Color map entry values out of bounds");
                    return false;
                }
            }

            if (this.name === "") {
                alert("Please fill in name input");
                return false;
            }
            return true;

        },

        createStyle(modify) {
            if (modify && this.styleName !== this.name) {
                this.name = this.styleName;
            } else if (!modify && this.styleName === this.name) {
                alert("Please change name of the new style");
                return;
            }

            // Jiffle is superior
            if (this.jiffleStatus.script && this.jiffleStatus.status) {
                this.channelSelection.type = "";
            }

            if (!this.validateStyleConfig()) {
                return;
            }
            if (this.channelSelection.type !== "grayscale" && !this.jiffleStatus.script) {
                this.colorMapEntries = [];
                this.colorMapMode = "";
            }

            let config = {
                jiffleScript: this.jiffleStatus.script,
                contrastEnhancement: this.contrastEnhancement,
                channelSelection: this.channelSelection,
                colorMapEntries: this.colorMapEntries,
                colorMapMode: this.colorMapMode

            };
            this.$store.dispatch("createStyle", {name: this.name, data: config, modify: modify})
                .then(data => {
                    if (data.status) {
                        this.$store.dispatch("fetchStyles");
                        this.$emit('closeStyleEditor');
                    }
                    if (modify) {
                        this.$emit("modified");
                    }
                })
                .catch(_ => {
                    console.log("Creation failed");
                });
        },
    },
    async mounted() {
        // initialize all the variables in case a non-empty initial styleName was passed in
        // f.e. when modifying an existing style
        if (this.styleName) {
            try {
                let response = await this.$store.dispatch("fetchStyleSld", {
                    styleName: this.styleName,
                    type: this.styleType
                });
                let config = response.config;

                if (config.contrastEnhancement) {
                    this.contrastEnhancement = config.contrastEnhancement
                }
                if (config.channelSelection) {
                    this.channelSelection = config.channelSelection
                }
                if (config.jiffleStatus) {
                    this.jiffleStatus = config.jiffleStatus
                }
                if (config.colorMapEntries) {
                    this.colorMapEntries = config.colorMapEntries
                }
                if (config.colorMapMode) {
                    this.colorMapMode = config.colorMapMode
                }
            } catch (err) {
                console.log("Could not fetch style configuration", err);
            }
        }
    }
}
</script>

<style scoped>

.image_div_wrap {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
}

.image_div_header {
    padding: 0.5rem;
    font-size: 1.5rem;
    width: 100%;
    height: 3rem;
    text-align: center;
    color: white;
    background: #7b525a;
    border-radius: 0.5rem;
}

.image_div {
    /*background: #232121;*/
    border-radius: 0.8rem;
    background: #5e5757;
    color: white;
    width: 75%;
    overflow: auto;
    height: 85%;
    margin: 5% auto 5% auto;
    padding: 20px 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.image_div_content {
    overflow: auto;
    height: 90%;
}


.closeBtn {
    height: 3em;
    width: 6em;
    margin-top: 1em;
    border: none;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
    margin: 5px;
}

.closeBtn:hover {
    background: #7b525a;

}

.inputField {
    margin: 10px 1rem;
}

.editor-section {
    text-align: left;
    background: #9e9e9e75;
    padding: 3%;
    border-radius: 1em;
}

.addEntryBtn {
    border: none;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}

.addEntryBtn:hover {
    background: #7b525a;

}
</style>