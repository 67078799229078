<template>
    <div class="image_div_wrap" v-if="true">
        <div class="image_div">
            <div class="image_div_header"><p>WMS Connection</p></div>
            <div class="wms_settings_div">
                <!--                <p style="font-size: 1.5rem; font-weight: bold">Connection settings</p>-->
                <!--                <hr>-->
                <label :for="'wms_url_input'">WMS url </label>
                <input style="width: 60%; margin-top: 1rem" type="url" id="wms_url_input"
                       :placeholder="'wms connection url'"
                       v-model="wmsUrl"><br>
                <label :for="'wms_name_input'">Connection name </label>
                <input type="url" id="wms_name_input"
                       :placeholder="'name'"
                       v-model="connectionName"><br>

                <br>
                <p style="font-size: 1.2rem; font-weight: bold">Authentication</p>
                <hr>
                <button class="renameBtn" style="float: right" v-if="true" @click="connectToWms">Reconnect</button>
                <button class="renameBtn" style="float: right" v-if="addNew" @click="createWMSObjects('store')">Save
                </button>
                <input id="no_authentication" type="checkbox" v-model="no_auth" true-value="true"  false-value="false">
                <label for="no_authentication">No authentication</label>
                <br>
                <span v-if="no_auth === 'false'">
                <label :for="'wms_username_input'">Username </label>
                <input type="text" id="wms_username_input" :placeholder="'username'" v-model="username"><br>
                <label :for="'wms_password_input'">Password </label>
                    <input type="password" :placeholder="'password'" id="wms_password_input" v-model="password"><br>
                </span>
                <div>
                    <p style="font-weight: 400; color: #bd2424; margin: 1rem 0" v-if="contextMessage">
                        {{ contextMessage }}</p>
                </div>
            </div>
            <div v-if="wmsLayers.length > 0"><p style="font-size: 1.5rem; font-weight: bold; margin-top: 2rem">Available
                layers</p>
                <hr>
                <div class="layer_elem" v-for="(layer, index) in wmsLayers">
                    <input style="display: inline-block; margin-right: 1rem; margin-right: 1rem" type="checkbox"
                           v-model="checked" :value="layer">
                    <p style="display: inline-block"><strong>Name: </strong> {{ layer.Name }}</p>

                </div>
            </div>
            <button class="renameBtn" @click="$emit('close');">Close</button>
            <button class="renameBtn" v-if="checked.length > 0" @click="createWMSObjects('layers')"> Import</button>
            <div v-if="false">
                <div class="loader">Loading...</div>
            </div>
            <br>
        </div>
    </div>
</template>

<script>
import {API} from "@/assets/js/axio-api";

export default {
    props: {
        wmsUrl: String,
        addNew: Boolean,
        userName: String,
        storeName: String
    },
    name: "WMSConnections",
    data() {
        return {
            wmsLayers: [],
            checked: [],
            no_auth: "false",
            username: this.userName,
            password: "",
            contextMessage: "",
            connectionName: this.storeName
        };
    },
    methods: {
        async connectToWms() {
            this.wmsLayers = [];
            this.contextMessage = "";
            let response = await this.$store.dispatch("get_wms_connection_layers", {
                wms_url: this.wmsUrl,
                no_auth: this.no_auth === "true",
                username: this.username,
                password: this.password
            });

            if (response && response.layers && response.layers.length > 0) {
                this.wmsLayers = response.layers;
            } else {
                this.contextMessage = "Could not establish connection via the specified WMS url";
                return false;
            }
            return true;
        },
        createWMSObjects(objType) {
            // possible WMS object types: store, layers, store_and_layers
            let types = [objType];
            if (objType === "layers" && this.addNew) {
                types.push("store");
            }
            if (!this.connectionName && (objType === "store" || types.length > 1)) {
                this.contextMessage = "Connection name must not be empty";
                return false;
            }
            if (!this.connectToWms()) {
                return false;
            }
            this.$store.dispatch("createWMSStoreLayers", {
                object_types: types,
                layers: this.checked,
                wms_url: this.wmsUrl,
                no_auth: this.no_auth === "true",
                username: this.username,
                password: this.password,
                connection_name: this.connectionName
            });
            // this.contextMessage = "Action successfull";
            this.$emit('close');
            return true;
        },
    },
    async mounted() {
        this.connectToWms();

    }
}
</script>

<style scoped>

.image_div_wrap {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
}

.image_div_header {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    width: 100%;
    height: 2rem;
    color: white;
    background: #7b525a;
    border-radius: 0.5rem;
}

.image_div {
    /*background: #232121;*/
    border-radius: 0.8rem;
    background: #bea4a4;
    color: white;
    margin-top: 10%;
    width: 75%;
    overflow: auto;
    height: 60%;
    margin: 10% auto 0px auto;
    padding: 20px 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.image_div_content {
    overflow: auto;
    height: 90%;
}

.wms_settings_div {
    text-align: left;
    padding: 1rem;
    border-radius: 1rem;
    width: 100%;
    background: #b28888;
}

.closeViewBtn {
    position: relative;
    background: none;
    border: none;
    color: #7b525a;
}

.closeViewBtn:hover {
    color: #eb4e6a;
}

.renameBtn {
    height: 3em;
    width: auto;
    /*margin-top: 1em;*/
    border: none;
    margin: 10px;
    padding: 5px 10px;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}

.renameBtn:hover {
    background: #7b525a;
}


.loader,
.loader:before,
.loader:after {
    background: #eb4e6a;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 3em;
    height: 12em;
}

.loader {
    border: none;
    border-radius: 0;
    color: #eb4e6a;
    text-indent: -9999em;
    margin: 8rem auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    position: absolute;
    top: 0;
    content: '';
}

.loader:before {
    left: -4.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader:after {
    left: 4.5em;
}

@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 12em;
    }
    40% {
        box-shadow: 0 -6em;
        height: 15em;
    }
}

@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 12em;
    }
    40% {
        box-shadow: 0 -6em;
        height: 15em;
    }
}

.layer_elem {
    padding: 0.5rem;
    text-align: left;
    background: #987373;
    margin: 0.3rem 0;
    width: 100%;
    height: 3rem;
    border-radius: 0.5rem;
}
</style>