import Dashboard from "../views/Dashboard.vue";
// @ts-ignore
import MapView from "../views/MapView";
import PageNotFound from "../views/PageNotFound.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LogInComponent from "@/components/user_auth/LogInComponent.vue";


const routes: Array<RouteRecordRaw> = [
        {
        path: "/",
        name: "Intro",
        component: LogInComponent
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard
    },
    {
        path: "/map",
        name: "Map",
        component: () => import(/* webpackChunkName: "about" */ "../views/MapView.vue")
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue")
    },
    {
        path:  "/:pathMatch(.*)*",
        component: PageNotFound
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes
});

export default router;
