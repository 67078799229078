import App from "./App.vue";
import { createApp } from "vue";
import router from "./router";
import { createI18n } from 'vue-i18n'
// @ts-ignore
import store from "./store";
import "./assets/main.scss";
// @ts-ignore
import { loadLocaleMessages } from "@/i18n";

import VueTour from 'v3-tour';

require('v3-tour/dist/vue-tour.css');


const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: loadLocaleMessages(),
});

const app = createApp(App);
// Config may be modified over app.config.
// app.config.productionTip = false;
app.use(store).use(router).use(i18n).use(VueTour).mount('#app');
