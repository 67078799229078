<template>
    <div class="accordion-header" @click="listDetails">
        <label class="accordion-hdr-text">{{ dirPath === "" ? "/" : dirPath }}</label>
        <button class="deselectBtn" @click.stop="deselectAll()" v-if="selectedFiles.length > 0 && active">Deselect
        </button>
        <i class="arrow" :style="rotateStyle"></i>
    </div>
    <transition>
        <div class="accordion-content" v-show="active" @contextmenu.prevent>
            <button class="closeViewBtn" @click="closeDir">
                <svg style="margin-bottom: 1rem;height: 2em" aria-hidden="true" focusable="false" data-prefix="fas"
                     data-icon="times-circle"
                     class="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512">
                    <path fill="currentColor"
                          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
                </svg>
            </button>
            <!--                     @mouseup.right="console.log('mouseup')"-->

            <div class="parento" @contextmenu.prevent>
                <div class="childo"
                     v-for="(item, index) in $store.getters.dirContents.get(dirPath)"
                     @click="selectItem(item, index, $event)"
                     @dblclick="previewFile(item, index)"
                     @mousedown.right="openContextMenu(item, index, $event)"
                     @contextmenu.prevent>
                    <div :class="{ 'selected': selectedFiles.includes(index)}"
                         style="width: 4em; height: 4em; border-radius: 0.5em">
                        <img :class="{ 'selected_icon': selectedFiles.includes(index)}"
                             style="height: 3.5em; margin-top: 0.5em" v-if="item.type==='file'"
                             src="@/assets/imgs/file_icon.svg"
                             alt="File icon">
                        <img :class="{ 'selected_icon': selectedFiles.includes(index)}" style="height: 4.5em;"
                             v-if="item.type==='dir'"
                             src="@/assets/imgs/directory_icon.svg"
                             alt="Directory icon">
                    </div>
                    <p>{{ item.name }}</p>
                </div>
            </div>
            <div class="image_div_wrap" v-if="filePreviewIndex > -2">
                <div class="image_div">
                    <div class="image_div_content" v-if="filePreviewIndex >= 0">
                        <div class="image_div_header"><p>
                            {{ $store.getters.filePreviews[filePreviewIndex].name }}</p>
                        </div>
                        <img v-if="!bandViewActive && $store.getters.filePreviews[filePreviewIndex].plot"
                             style="height: 90%; max-width: none"
                             :src="$store.getters.filePreviews[filePreviewIndex].plot"
                             alt="Preview is not available for this file.">
                        <!--                        IMAGE STRUCTURE - BANDS & PROFILE-->
                        <div v-if="bandViewActive">
                            <div
                                v-if="$store.getters.filePreviews[filePreviewIndex] && $store.getters.filePreviews[filePreviewIndex].raster_profile">
                                <p>Raster profile</p>
                                <div class="raster_info_div">
                                    <p class="image_structure_item"
                                       v-for="(value, key) in $store.getters.filePreviews[filePreviewIndex].raster_profile">
                                        <strong>{{ key }}: </strong>{{ value }}</p>
                                </div>
                                <hr>
                            </div>
                            <div
                                v-if="$store.getters.filePreviews[filePreviewIndex] && $store.getters.filePreviews[filePreviewIndex].statistics">
                                <p>Raster bands</p>
                                <div class="raster_info_div"
                                     v-for="(stats, band_index) in $store.getters.filePreviews[filePreviewIndex].statistics">
                                    <p style="font-weight: 500; font-size: 1.3rem;">Band number {{ band_index }}</p>
                                    <hr style="margin: 0.7rem 0;">
                                    <p :class="key === 'description'? 'image_structure_desc_item' : 'image_structure_item'"
                                       v-for="(value, key) in stats">
                                        <strong>{{ key }}: </strong>{{ value }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="renameBtn" @click="filePreviewIndex=-2; bandViewActive=false">Close</button>
                    <button class="renameBtn" @click="bandViewActive=!bandViewActive">
                        {{ bandViewActive ? "Image view" : "Structure view" }}
                    </button>
                    <div v-if="filePreviewIndex < 0">
                        <div class="loader">Loading...</div>
                    </div>
                    <br>
                </div>
            </div>
            <button
                class="closeViewBtn"
                @click="pinDirectoryView" v-if="!$store.getters.pinnedDirViews.includes(dirPath)">
                <svg style="margin-top: 1rem; height: 2em" aria-hidden="true" focusable="false" data-prefix="fas"
                     data-icon="thumbtack"
                     class="svg-inline--fa fa-thumbtack fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 384 512">
                    <path fill="currentColor"
                          d="M298.028 214.267L285.793 96H328c13.255 0 24-10.745 24-24V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v48c0 13.255 10.745 24 24 24h42.207L85.972 214.267C37.465 236.82 0 277.261 0 328c0 13.255 10.745 24 24 24h136v104.007c0 1.242.289 2.467.845 3.578l24 48c2.941 5.882 11.364 5.893 14.311 0l24-48a8.008 8.008 0 0 0 .845-3.578V352h136c13.255 0 24-10.745 24-24-.001-51.183-37.983-91.42-85.973-113.733z"/>
                </svg>
            </button>


            <div id="contextMenu" class="context-menu" v-if="contextMenuOn"
                 v-bind:style="{top: + coordinates_y + 'px', left: coordinates_x + 'px'}">
                <ul class="menu" v-if="contextMenuView === 'menu'">
                    <!--                    <li @click="" class="jump_dir"><a><i class="fa fa-share" aria-hidden="true"></i> Jump to-->
                    <!--                        directory</a></li>-->
                    <!--                    <li class="share"><a><i class="fa fa-share" aria-hidden="true"></i> Share</a></li>-->
                    <li @click="contextMenuView='rename'; rightClickedFile.newName=''" class="rename"><a><i
                        class="fa fa-pencil"
                        aria-hidden="true"></i> Rename</a>
                    </li>
                    <!--                    <li @click="contextMenuView='move_copy_to'" class="copy"><a><i class="fa fa-copy"-->
                    <!--                                                                                   aria-hidden="true"></i> Copy to</a>-->
                    <!--                    </li>-->
                    <li @click="contextMenuView='move_copy_to'" class="paste"><a><i class="fa fa-paste"
                                                                                    aria-hidden="true"></i> Copy or move</a>
                    </li>
                    <li @click="downloadSelectedFiles" class="download"><a><i class="fa fa-download"
                                                                              aria-hidden="true"></i> Download</a></li>
                    <li @click="deleteSelectedFiles" class="trash"><a><i class="fa fa-trash" aria-hidden="true"></i>
                        Delete</a></li>
                    <li @click="contextMenuView='properties'" class="properties"><a><i class="fa fa-paste"
                                                                                       aria-hidden="true"></i>Properties</a>
                    </li>
                    <hr>
                    <li @click="createLayerFromSelected('GeoTIFF')" class="create_layers"
                        :class="valid_files_for_layer_creation? '':'disabled'"><a><i class="fa fa-download"
                                                                                     aria-hidden="true"></i>
                        Create
                        layers</a></li>
                    <li :class="$store.getters['file_store/numOfSelectedFiles'] > 0 ? '':'disabled'"
                        class="image_editor" @click="$emit('open-img-editor')">
                        <a>
                            <i class="fa fa-download" aria-hidden="true"></i> Open in image editor
                        </a>
                    </li>
                    <hr>
                    <li :class="$store.getters['veg_store/is_valid_file_selection'](this.selectedFiles, this.dirFiles, true) ? '':'disabled'"
                        @click="$store.dispatch('veg_store/convertToGeoTiff', {selected_indices: this.selectedFiles, storageFiles: this.dirFiles})"
                        class="vegetation"><a><i
                        class="fa fa-link" aria-hidden="true"></i> Convert to GeoTIFF</a>
                    </li>
                    <li :class="$store.getters['veg_store/is_valid_file_selection'](this.selectedFiles, this.dirFiles, false) ? '':'disabled'"
                        @click="openVparamModal" class="vegetation"><a href="#"><i
                        class="fa fa-link" aria-hidden="true"></i> Analyze vegetation parameters</a>
                    </li>
                    <li @click="$store.commit('veg_store/SWITCH_ACTIVE_LUT_MODAL')" class="lut_database"
                        :class="is_valid_lut && is_authenticated_staff ? '':'disabled'"><a><i
                        class="fa fa-download" aria-hidden="true"></i> Mount as LUT
                        database</a></li>
                </ul>
                <div style="text-align: left; padding: 1em" class="menu" v-if="contextMenuView ==='properties'">
                    <p><b>Properties</b></p>
                    <hr>
                    <p><b class="properties_title">Name: </b>{{ rightClickedFile.obj.name }}</p>
                    <p><b class="properties_title">Type: </b>{{ rightClickedFile.obj.type }}</p>
                    <p><b class="properties_title">Path: </b>{{ rightClickedFile.obj.path }}</p>
                    <p v-if="rightClickedFile.obj.size">
                        <b class="properties_title">Size: </b>{{ parseFloat(rightClickedFile.obj.size).toFixed(3) }}MB
                    </p>
                </div>
                <div style="text-align: left; padding: 1em" class="menu" v-if="contextMenuView ==='move_copy_to'">
                    <DirectoryBrowser v-on:close="contextMenuView ='menu'" v-on:FilesUpdated="updateSelectedFiles"
                                      :currentDir="$store.getters.getCurrentDirectory" :mode="'single-select'"
                                      :controls="['move', 'copy', 'close']" :global="false"
                                      :calledForObjects="this.selectedFiles.map(index => this.dirFiles[index])"></DirectoryBrowser>
                </div>


                <!--                    Rename modal-->
                <div style="text-align: left; padding: 1em" class="menu" v-if="contextMenuView === 'rename'">
                    <button class="closeRenameBtn"
                            @click="contextMenuOn=false">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas"
                             style="height: 1.5rem; float: right"
                             data-icon="times-circle"
                             class="close_svg svg-inline--fa fa-times-circle fa-w-16" role="img"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 512 512">
                            <path fill="currentColor"
                                  d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
                        </svg>
                    </button>
                    <p><b>Rename</b></p>
                    <hr>
                    <p><b>From: </b>{{ rightClickedFile.obj.name }}</p>
                    <br><br>
                    <input style="margin-top:0.5em;border-radius: 0.5em; border: none;height: 1.5em"
                           v-model="rightClickedFile.newName"
                           placeholder="To"
                           type="text">
                    <button
                        @click="renameFile"
                        class="renameBtn"> {{ 'Rename' }}
                    </button>
                    <p class="has-text-danger" v-show="contextMenuMessage">{{ contextMenuMessage }}</p>
                </div>
            </div>
            <!--            <FileContextMenu v-if="contextMenuOn" :style="{top: + coordinates_y + 'px', left: coordinates_x + 'px'}" :dirPath="dirPath"-->
            <!--                             :rightClickedFile="rightClickedFile" :selectedFiles="selectedFiles"></FileContextMenu>-->
        </div>
    </transition>
</template>
<script>

import Modal from "@/components/tiles/modal";
import DirectoryBrowser from "@/components/system_views/file_view_components/DirectoryBrowser";

export default {
    components: {DirectoryBrowser, Modal},
    props: {
        content: Object,
        dirPath: String,
    },

    data() {
        return {
            dirObj: null,
            active: true,
            deg: -135,
            detailed_view: false,
            selectedFiles: [],
            contextMenuOn: false,
            contextMenuView: "menu",
            coordinates_x: 0,
            coordinates_y: 0,
            rightClickedFile: {index: -1, obj: null, newName: ""},
            filePreviewIndex: -2,
            bandViewActive: false,
            contextMenuMessage: ""
        }
    },

    methods: {
        updateSelectedFiles(newSelectedFiles) {
            console.log(newSelectedFiles);
        },
        async openVparamModal() {
            await this.$store.commit('veg_store/UPDATE_ROOT_DIRECTORY', this.dirPath);
            this.$store.commit('veg_store/SWITCH_ACTIVE_VEG_MODAL');
        },

        renameFile() {
            if (!this.rightClickedFile.newName) {
                this.contextMenuMessage = "Name cannot be empty."
                return;
            }
            this.$store.dispatch('renameFile', {
                original_path: this.rightClickedFile.obj.path,
                new_name: this.rightClickedFile.newName
            });
            this.contextMenuMessage = "";
            this.contextMenuOn = false;
            this.$store.dispatch("fetchDirContent", this.dirPath);
            this.deselectAll();
        },
        openContextMenu(item, index, event) {
            this.contextMenuView = 'menu';
            if (this.selectedFiles.length === 0) {
                this.selectedFiles.push(index);
                if (this.$store.getters.dirContents.get(this.dirPath)[index].type === "file")
                    this.$store.commit("file_store/UPDATE_SELECTED_FILES", {dirPath: this.dirPath, index: index});
                this.$store.commit("veg_store/UPDATE_SELECTED_FILES", this.selectedFiles.map(index => this.$store.getters.dirContents.get(this.dirPath)[index]));
            }
            if (this.rightClickedFile.index === index) {
                this.contextMenuOn = false;
                this.rightClickedFile.index = -1;
                return;
            }
            if (this.selectedFiles.length > 0 && !this.selectedFiles.includes(index)) {
                this.selectedFiles = [index];
            }

            this.rightClickedFile.index = index;
            this.rightClickedFile.obj = item;
            this.coordinates_x = event.clientX;
            this.coordinates_y = event.clientY;
            this.contextMenuOn = true;
        },
        async previewFile(item) {
            let allowed_extensions = /.tif|.tiff|.bsq|.bil|.bip/;
            if (!allowed_extensions.test(item.name)) {
                console.log("Unsupported file type");
                return;
            }
            if (/.bsq|.bil|.bip/.test(item.name)) {
                this.bandViewActive = true;
            }
            this.filePreviewIndex = -1;
            await this.$store.dispatch("fetchImageFilePreview", item);
            this.filePreviewIndex = this.$store.getters.filePreviews.length - 1;
        },
        selectItem(item, index, event) {
            if (event && event.shiftKey) {
                this.selectItem(item, index, null);
                this.selectRange(Math.min(...this.selectedFiles, index), Math.max(...this.selectedFiles, index), "range");
                this.selectItem(item, index, null);

            }
            this.contextMenuOn = false;
            this.contextMenuView = 'menu';
            if (this.selectedFiles.includes(index)) {
                let i = this.selectedFiles.indexOf(index);
                this.selectedFiles.splice(i, 1);
            } else {
                this.selectedFiles.push(index);
            }
            if (this.$store.getters.dirContents.get(this.dirPath)[index].type === "file")
                this.$store.commit("file_store/UPDATE_SELECTED_FILES", {dirPath: this.dirPath, index: index});
            this.$store.commit("veg_store/UPDATE_SELECTED_FILES", this.selectedFiles.map(index => this.$store.getters.dirContents.get(this.dirPath)[index]));
        },
        selectRange(fromIndex, toIndex, flag) {
            if (this.selectedFiles.length === 0) {
                return;
            }
            if (flag === 'all' && this.selectedFiles.length === this.dirFiles.length) {
                this.deselectAll();
                return;
            }
            for (let i = fromIndex; i < toIndex; i++) {
                if (!this.selectedFiles.includes(i)) {
                    this.selectItem(null, i, null);
                }
            }
        },
        deselectAll() {
            this.selectedFiles = [];
            this.$store.commit("file_store/UPDATE_SELECTED_FILES", {dirPath: this.dirPath, index: -100});
            this.$store.commit("veg_store/UPDATE_SELECTED_FILES", this.selectedFiles);
        },
        pinDirectoryView() {
            this.$store.commit("PIN_DIR_VIEW", this.dirPath);
        },
        closeDir() {
            this.$store.commit("UNPIN_DIR_VIEW", this.dirPath);
            this.$store.commit("DELETE_DIR_CONTENT", this.dirPath);
            this.$emit("closed", this.dirPath);
        },
        listDetails() {
            if (this.active)
                this.deg = 45;
            else
                this.deg = -135;
            this.active = !this.active;
        },
        createLayerFromSelected(_type) {
            this.$store.dispatch('createLayer', {
                filenames: this.selectedFiles.map(index => this.$store.getters.dirContents.get(this.dirPath)[index].path),
                layernames: [],
                _type: _type,
                proj: "EPSG:4326"
            });
            this.deselectAll();
            this.contextMenuOn = false;
        }
        ,
        async deleteSelectedFiles() {
            await this.$store.dispatch('deleteFiles', {
                files: this.selectedFiles.map(index => this.dirFiles[index]),
                path: this.dirPath
            });
            this.contextMenuOn = false;
            this.deselectAll();
            this.contextMenuOn = false;
        },
        downloadSelectedFiles() {
            this.$store.dispatch('download_selected_files', this.selectedFiles.map(index => this.dirFiles[index]));
            this.contextMenuOn = false;
            this.deselectAll();
        },
    },
    computed: {
        rotateStyle() {
            return {transform: 'rotate(' + this.deg + 'deg)'}
        },
        dirFiles() {
            return this.$store.getters.dirContents.get(this.dirPath);
        },
        valid_files_for_layer_creation() {
            let allowed_extensions = /.tif|.tiff/;
            try {
                if (this.selectedFiles.length < 1) {
                    return false;
                }
                for (let i = 0; i < this.selectedFiles.length; i++) {
                    if (!allowed_extensions.test(this.dirFiles[this.selectedFiles[i]].name)) return false;
                }
                return true;
            } catch (e) {
                console.log("Error: " + e);
            }
        },
        async is_authenticated_staff() {
            if (!this.$store.getters.loggedIn) return false;
            if (!this.$store.getters.userDetails) {
                await this.$store.dispatch("fetchUserDetails");
                while (!this.$store.getters.userDetails) {
                    await new Promise(r => setTimeout(r, 500));
                }
            }
            return this.$store.getters.userDetails["groups"].includes("Czechglobe");
        },
        is_valid_lut() {
            try {
                //this.selectedFiles.length !== 1
                if (this.selectedFiles.length !== 2 && this.selectedFiles.length !== 3) {
                    return false;
                }
                let selectedFileObjects = this.selectedFiles.map(index => this.dirFiles[index]);
                let filenames = selectedFileObjects.map(file => file.name.split("."));
                if (this.selectedFiles.length === 1) {

                    return filenames[0][filenames[0].length - 1] === "csv";
                }
                if (this.selectedFiles.length === 3 && filenames[2][filenames[2].length - 1] !== "csv") {
                    return false;
                }
                return filenames[0][filenames[0].length - 1] === "csv" && filenames[1][filenames[1].length - 1] === "csv";
            } catch (e) {
                console.log("Error: " + e);

            }

        },
        editable_selection() {
            try {
                let selectedFileObjects = this.selectedFiles.map(index => this.dirFiles[index].name);
                if (selectedFileObjects.length === 0) {
                    return false;
                }

                let hdr_files = selectedFileObjects.filter(filename => filename.endsWith(".hdr"));
                let multispectral_files = selectedFileObjects.filter(filename => {
                    let split_name = filename.split(".");
                    return ["bsq", "bil", "bip"].includes(split_name[split_name.length - 1]);
                });
                let other_allowed_filetypes = selectedFileObjects.filter(filename => {
                    let split_name = filename.split(".");
                    return ["jp2", "tif"].includes(split_name[split_name.length - 1]);
                });

                return (hdr_files.length === 0 || hdr_files.length === multispectral_files.length) &&
                    hdr_files.length + multispectral_files.length + other_allowed_filetypes.length === selectedFileObjects.length;
            } catch (e) {
                console.log("Error: " + e);
            }

        },
    },
    mounted() {
        // this.$store.commit('UPDATE_CURRENT_DIRECTORY', this.$store.getters.getCurrentDirectory + "/" + this.$store.getters.userStorage[index].name);
        // this.$store.dispatch('fetchUserStorage');

        // this.dirObj = this.$store.getters.userStorage[this.id];
        // this.dirPath = this.dirParentPath + this.dirObj.name;
        // this.$store.dispatch('fetchDirContent', this.dirPath);


        this._keyListener = function (e) {
            if (e.key === "a" && (e.ctrlKey || e.metaKey)) {
                e.preventDefault(); // present "Save Page" from getting triggered.
                this.selectRange(0, this.dirFiles.length, 'all');

            }

        };

        document.addEventListener('keydown', this._keyListener.bind(this));
    }
    ,
    beforeDestroy() {
        document.removeEventListener('keydown', this._keyListener);
    }
    ,

}
</script>

<style scoped>

.image_structure_item {
    width: 40%;
    display: inline-block;
}

.image_structure_desc_item {
    display: inline-block;
}

.loader,
.loader:before,
.loader:after {
    background: #eb4e6a;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 3em;
    height: 12em;
}

.loader {
    border: none;
    border-radius: 0;
    color: #eb4e6a;
    text-indent: -9999em;
    margin: 8rem auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    position: absolute;
    top: 0;
    content: '';
}

.loader:before {
    left: -4.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader:after {
    left: 4.5em;
}

@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 12em;
    }
    40% {
        box-shadow: 0 -6em;
        height: 15em;
    }
}

@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 12em;
    }
    40% {
        box-shadow: 0 -6em;
        height: 15em;
    }
}


.image_div_wrap {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
}

.image_div_header {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    width: 100%;
    height: 2rem;
    color: white;
    background: #7b525a;
    border-radius: 0.5rem;
}

.image_div {
    /*background: #232121;*/
    border-radius: 0.8rem;
    background: black;
    color: white;
    margin-top: 10%;
    width: 75%;
    overflow: auto;
    height: 75%;
    margin: 5% auto 0px auto;
    padding: 20px 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.image_div_content {
    overflow: auto;
    height: 90%;
}

.closeViewBtn {
    position: relative;
    background: none;
    border: none;
    color: #7b525a;
}

.closeViewBtn:hover {
    color: #eb4e6a;
}


.parento {
    /*align-content: first;*/
    width: 100%;
    display: grid;
    /* grid-gap: 10px; */
    grid-template-columns: repeat(3, 1fr);
}

.childo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.childo:hover {
    /*background-color: rgb(255, 118, 115);*/
    transform: scale(1.2);
    transition: transform 80ms ease-in-out;

}

.selected {
    background-color: #a9485a;
    color: #fffcf2;
}

.selected_icon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(116%) hue-rotate(168deg) brightness(113%) contrast(90%);
}

@media (max-width: 800px) {
    .parento {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
    }
}

.accordion-header {
    display: flex;
    align-items: center;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    background: #7b525a;
    padding: 10px;
    cursor: pointer;
}


.accordion-hdr-text {
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    color: #fffcf2;
}


.accordion-content {
    background-color: white;
    border-radius: 0px 0px 5px 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.arrow {
    margin-left: auto;
    cursor: pointer;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transition: all .4s linear;
}


.context-menu {
    position: absolute;
    z-index: 10;
}

.menu {
    display: flex;
    flex-direction: column;
    background: #c5bbbb;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgb(64 64 64 / 5%);
    padding: 10px 0;
}

.menu > li > a {
    font: inherit;
    border: 0;
    padding: 10px 30px 10px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: unset;
    color: #000;
    font-weight: 500;
    transition: 0.5s linear;
    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -ms-transition: 0.2s linear;
    -o-transition: 0.2s linear;
}

.menu > li > a:hover {
    background: #7b525a;
    color: #fffcf2;
}

.menu > li > a > i {
    padding-right: 10px;
}

.menu > li.trash > a:hover {
    color: #fffcf2;
    background: #d0455e;
}

.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
}

.properties_title {
    color: #7b525a;
}

.renameBtn {
    height: 3em;
    width: auto;
    /*margin-top: 1em;*/
    border: none;
    margin: 10px;
    padding: 5px 10px;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}

.renameBtn:hover {
    background: #7b525a;

}


.deselectBtn {
    margin-left: auto;
    width: 6em;
    border: none;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}

.deselectBtn:hover {
    background: #7b525a;
}

.raster_info_div {
    text-align: left;
    background: #756c6c;
    margin: 1rem;
    padding: 0.5rem 4% 0.5rem;
    border-radius: 10px;
}

.closeRenameBtn {
    position: relative;
    background: none;
    border: none;
    color: #FF7673FF;
}

.closeRenameBtn:hover {
    color: #7b525a;
}


</style>