<template>
    <div class="shadow">
        <div class="prompt-wrap">
            <div class="prompt">
                <h1>Welcome to Mosveg</h1>
                <h3>First let's setup the language</h3>
                <br>
                <br>
                <div class="flags">
                     <img @click="selectLanguage('en')" class="flag" src="https://flagcdn.com/gb.svg" 
                        :class="{'flag-clicked': selected == 'en'}" width="200" alt="English">
                     <img @click="selectLanguage('cz')" class="flag" src="https://flagcdn.com/cz.svg" 
                        :class="{'flag-clicked': selected == 'cz'}" width="155" alt="Czech">
                </div>
                <button @click="confirmLang()" v-show="selected !== null" class="button butt">Confirm</button>
                <p class="butt" v-show="selected == null"><strong>Please select language</strong></p>
            </div>
        </div>
    </div>
    
</template>

<script>
import modal from "@/components/tiles/modal";

export default {
    components: {
        modal,
    },

    data() {
        return {
            selected: null,
        }
    },

    methods: {
        selectLanguage(lang) {
            if (lang == this.selected)
                this.selected = null;
            else
                this.selected = lang;
            console.log (this.selected);
        },

        confirmLang() {
            localStorage.setItem('pref_lang', this.selected);
            this.$i18n.locale = this.selected;
            this.$emit('close');
        }
    },
}
</script>

<style scoped>
.shadow {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.prompt-wrap {
  display: table-cell;
  vertical-align: middle;
  overflow: scroll;
}

.prompt {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 45%;
    height: 50%;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #e9dddd;
    border-radius: 2px;
    transition: all 0.3s ease;
}

.flags {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5rem;
}
.flag {
    transition: all 1s;
    transform-origin: 50% 50%;
}

.flag:hover {
    transform: scale(1.2);
}

.flag-clicked {
    transform: scale(1.2);
}

.butt {
    margin-top: auto;    
}
</style>