<template>
    <main>
        <div class="layers-top">
            <div :class="{placeholderView: layersEmpty}" class="layerWrap">
                <p v-show="layersEmpty">
                    <strong>You haven't created any layer yet.</strong>
                </p>
                <table class="table is-hoverable is-fullwidth"
                       style="text-align: left; overflow-y: auto border-radius: 0.8em;"
                       v-if="!layersEmpty">
                    <thead style="background-color: #7b525a;color: #fffcf2;">
                    <tr>
                        <th style="color: #fffcf2">{{ $t("layer_view.layer") }}</th>
                        <th>
                            <button v-if="selectedLayers.length > 0" @click="deleteSelectedLayers" class="switchBtn">
                                Delete
                                selected
                            </button>
                        </th>
                        <th style="color: #fffcf2">{{ $t("type") }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        @click="selectItem(index, 'layer')"
                        :class="{ 'selected': selectedLayers.includes(index) }"
                        v-for="(item, index) in $store.getters.userLayers"
                        :key="index">
                        <td class="table_cell">{{ item.name }}</td>
                        <td class="table_cell"></td>
                        <td class="table_cell">{{ item.type }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <!--            WMS CONNECTIONS SECTION-->
            <div :class="{placeholderView: wmsConnectionsEmpty}" class="layerWrap">
                <div v-show="wmsConnectionsEmpty">
                    <p>
                        <strong>You have no active WMS connections yet.</strong>
                    </p>
                    <button @click="selectedWMSConections=[]; addNewWMS=true; editWMSModal=true;"
                            class="switchBtn">
                        Create new
                    </button>
                </div>

                <table class="table is-hoverable is-fullwidth"
                       style="text-align: left; overflow-y: auto border-radius: 0.8em;"
                       v-if="!wmsConnectionsEmpty">
                    <thead style="background-color: #7b525a;color: #fffcf2;">
                    <tr>
                        <th style="color: #fffcf2">WMS Connection</th>
                        <th>
                            <button @click="selectedWMSConections=[]; addNewWMS=true; editWMSModal=true;"
                                    class="switchBtn">
                                New
                            </button>
                        </th>
                        <th>
                            <button v-if="selectedWMSConections.length === 1"
                                    @click="addNewWMS=false; editWMSModal=true"
                                    class="switchBtn">
                                Edit
                            </button>
                        </th>
                        <th>
                            <button v-if="selectedWMSConections.length === 1" @click="deleteSelectedWMSConections"
                                    class="switchBtn">
                                Delete
                            </button>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        @click="selectItem(index, 'wms')"
                        :class="{ 'selected': selectedWMSConections.includes(index) }"
                        v-for="(item, index) in $store.getters.wmsConnections"
                        :key="index">
                        <td class="table_cell">{{ item.name }}</td>
                        <td class="table_cell"></td>
                        <td class="table_cell"></td>
                        <td class="table_cell"></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <WMSConnections v-if="editWMSModal"
                            :wmsUrl="selectedWMSConections.length > 0 ? $store.getters.wmsConnections[selectedWMSConections[0]].wmsURL : ''"
                            :userName="selectedWMSConections.length > 0 ? $store.getters.wmsConnections[selectedWMSConections[0]].username : ''"
                            :addNew="addNewWMS"
                            :storeName="selectedWMSConections.length > 0 ? $store.getters.wmsConnections[selectedWMSConections[0]].name: ''"
                            v-on:close="editWMSModal=false; addNewWMS=true;"></WMSConnections>

            <!-- STYLE SECTION -->
            <div class="layerWrap">
                <table class="table is-hoverable is-fullwidth" style="text-align: left; border-radius: 0.8em;"
                       v-if="!stylesEmpty">
                    <thead style="background-color: #7b525a;color: #fffcf2;">
                    <tr>
                        <th style="color: #fffcf2">Styles</th>
                        <th>
                            <button @click="switchStyles" class="switchBtn"> Switch to
                                {{ showPublicStyles ? "personal" : "public" }} styles
                            </button>
                        </th>
                        <th>
                            <button @click="openStyleModal({name: ''})" class="switchBtn">Create new style</button>
                        </th>
                        <th>
                            <button v-show="selectedStyles.length > 0" @click="deleteStyles" class="switchBtn">Delete
                                selected
                            </button>
                        </th>
                    </tr>
                    </thead>
                    <tbody v-if="showPublicStyles">
                    <tr
                        @dblclick="openStyleModal(item)"
                        v-for="(item, index) in $store.getters.publicStyles"
                        :key="index"
                    >
                        <td class="table_cell">{{ item.name }}</td>
                        <td class="table_cell"></td>
                        <td class="table_cell"></td>
                        <td class="table_cell"></td>
                    </tr>
                    </tbody>
                    <tbody v-if="!showPublicStyles">
                    <tr
                        @click="selectItem(item.name, 'style')"
                        @dblclick="openStyleModal(item)"
                        :class="{ 'selected': selectedStyles.includes(item.name) }"
                        v-for="(item, index) in $store.getters.personalStyles"
                        :key="index"
                    >
                        <td class="table_cell">{{ item.name }}</td>
                        <td class="table_cell"></td>
                        <td class="table_cell"></td>
                        <td class="table_cell"></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ul class="layersinfo">
            <li v-show="selectedLayers.length === 0">
                <strong>{{ $t("layer_view.no_lay_selected") }}</strong>
            </li>
            <li v-for="(item, index) in selectedLayers">
                <!-- v-for selected send id to component and inside parse the data and show it -->
                <LayerInfo :id="item"></LayerInfo>
            </li>
        </ul>
    </main>
    <StyleEditor v-on:closeStyleEditor="editStyleModal=false" v-if="editStyleModal" :styleName="styleName"
                 :styleType="showPublicStyles ? 'public': 'personal'"></StyleEditor>
</template>

<script>
import LayerInfo from "@/components/system_views/layer_view_components/LayerInfo";
import StyleEditor from "@/components/StyleEditor";
import WMSConnections from "@/components/system_views/WMSConnections";

export default {
    name: "LayerView",
    components: {
        WMSConnections,
        StyleEditor,
        LayerInfo,
    },
    data() {
        return {
            selectedLayers: [],
            selectedStyles: [],
            selectedWMSConections: [],
            showPublicStyles: true,
            editStyleModal: false,
            styleName: "",
            editWMSModal: false,
            addNewWMS: true,
        };
    },
    methods: {
        selectItem(id, flag) {
            let arr = null;
            if (flag === "layer") {
                arr = this.selectedLayers;
            } else if (flag === "style") {
                arr = this.selectedStyles;
            } else if (flag === "wms") {
                if (this.selectedWMSConections.length === 1 && this.selectedWMSConections[0] !== id) {
                    this.selectedWMSConections = [];
                }
                arr = this.selectedWMSConections;
            }
            if (arr.includes(id)) {
                // deselect item
                let i = arr.indexOf(id);
                arr.splice(i, 1);
            } else {
                arr.push(id);
            }
        },
        deleteSelectedLayers() {
            this.$store.dispatch("deleteLayers", {layers: this.selectedLayers});
            this.selectedLayers = [];
        },
        deleteSelectedWMSConections() {
            this.$store.dispatch("deleteWMSConnection", this.$store.getters.wmsConnections[this.selectedWMSConections[0]].name);
            this.selectedWMSConections = [];
        },
        switchStyles() {
            this.showPublicStyles = !this.showPublicStyles;
            this.selectedStyles = [];
        },
        openStyleModal(item) {
            this.styleName = item.name;
            this.editStyleModal = true;
        },
        deleteStyles() {
            this.$store.dispatch('deleteStyles', this.selectedStyles);
        }
    },

    computed: {
        layersEmpty() {
            return this.$store.getters.userLayers.length === 0;
        },

        stylesEmpty() {
            return this.$store.getters.publicStyles.length === 0 && this.$store.getters.personalStyles.length === 0;
        },
        wmsConnectionsEmpty() {
            return this.$store.getters.wmsConnections.length === 0;

        }
    },
    created() {
        this.$store.dispatch('fetchUserLayers');
        this.$store.dispatch('fetchWMSConnections');
        this.$store.dispatch('fetchStyles');
    }
};
</script>

<style scoped>
main {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    gap: 0 10px;
    height: 100%;
    /* align-items: */
}

@media only screen and (max-width: 800px) {
    main {
        display: grid;
        grid-template-columns: 1fr;
        padding: 1em;
        grid-row-gap: 25px;
        height: 100%
    }
}

.selected {
    background-color: #a9485a;
    color: #fffcf2;
}

li {
    margin-bottom: 5px;
}

.placeholderView {
    background: #ffebeb99;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 25px;
}

.table_cell {
    border: none;
}

/*.layers-top {*/
/*    display: grid;*/
/*    grid-template-rows: 1fr 1fr;*/
/*}*/


.switchBtn {
    height: 3em;
    width: auto;
    margin: 0.2em 0.2em;
    padding: 0 0.5em;
    border: none;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}

.switchBtn:hover {
    background: #7b525a;
    cursor: pointer;
}

.layerWrap {
    max-height: 40vh;
    margin-bottom: 1.5rem;
    overflow: auto;
}

</style>
