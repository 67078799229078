<template>
    <modal id="veg_modal_scrollable" style="text-align: left;" :key="tab_key">
        <template v-slot:header>
            <p class="title is-2 title-custom">Vegetation parameters</p>
        </template>
        <template v-slot:body>
            <div class="tabs">
                <ul>
                    <li :class="[current_tab === 'image_specs' ? 'is-active' : '']"><a
                        @click="changeTab('image_specs')">Input specification</a></li>
                    <li :class="[current_tab === 'vparams' ? 'is-active' : '']"><a
                        @click="changeTab('vparams')">Output specification</a>
                    </li>
                    <li v-if="$store.getters['veg_store/getPreviews'].length > 0"
                        :class="[current_tab === 'previews' ? 'is-active' : '']"><a
                        @click="changeTab('previews')">Fit previews</a>
                    </li>
                    <li v-if="help_information.length > 0"
                        :class="[current_tab === 'help' ? 'is-active' : '']"><a
                        @click="changeTab('help')">Help</a>
                    </li>
                </ul>
            </div>
            <div style="overflow-x: auto" v-if="current_tab==='image_specs'">

                <p style="color: hsl(348, 100%, 61%)" v-if="errors.length"><b>Please correct the following error(s):</b>
                </p>
                <ul>
                    <li style="color: hsl(348, 100%, 61%)" v-for="error in errors">{{ error }}</li>
                </ul>
                <div>
                    <!--                    v-if="image_type === null"-->
                    <button class="button" @click="switch_img_mode('satelite')" disabled='true'>
                        Satelite imagery (Sentinel-2)
                    </button>
                    <button class="button" @click="switch_img_mode('plane')">
                        Airborne and UAV imagery
                    </button>
                    <br>
                    <hr>
                    <br>
                </div>
                <div v-if="image_type === 'plane'">

                    <form id="image_specs_form" @submit="validate_inputs">

                        <br>
                        <p class="title is-4 title-custom">Input image</p>
                        <br>
                        <p>Detected number of bands: {{ detected_number_of_bands }}</p>
                        <p>Detected units</p>
                        <!--                    <input type="radio" id="mm" value="mm" v-model="picked_units">-->
                        <!--                    <label for="mm"> mm</label>-->
                        <!--                    <br>-->
                        <input type="radio" id="micrm" value="μm" v-model="picked_units">
                        <label for="micrm"> μm</label>
                        <br>
                        <input type="radio" id="nm" value="nm" v-model="picked_units">
                        <label for="nm"> nm</label>
                        <br>
                        <p>Detected wavelengths of particular bands</p>
                        <textarea
                            v-model="wavelengths"
                            ref="textarea_wavelengths"
                            placeholder="Detected wavelengths"
                            rows="1"
                            @focus="resize('textarea_wavelengths')"
                            @keyup="resize('textarea_wavelengths')"

                        >
                        </textarea>

                        <!--                        <textarea-autosize class="auto_textarea"-->
                        <!--                                           placeholder="Detected wavelengths"-->
                        <!--                                           ref="myTextarea"-->
                        <!--                                           v-model="wavelengths"-->
                        <!--                        />-->
                        <!--                    <textarea v-model="wavelengths" placeholder="Detected wavelengths"></textarea>-->
                        <br>
                        <p>Detected fwhms</p>

                        <textarea
                            v-model="fwhms"
                            ref="textarea_fwhms"
                            placeholder="Detected fwhms"
                            rows="1"
                            @focus="resize('textarea_fwhms')"
                            @keyup="resize('textarea_fwhms')"
                        ></textarea>

                        <br>

                        <p>Detected reflectance scale</p>
                        <input v-model="reflectance_scale" placeholder="Reflectance scale" type="number">
                        <br>


                    </form>
                    <br>


                    <!--                    <button class="button" @click="close_veg_modal">-->
                    <!--                        Exit-->
                    <!--                    </button>-->
                    <button class="button" @click="fetch_hdr_data">
                        Reset
                    </button>
                    <button class="button" @click="changeTab('vparams')">
                        Next
                    </button>
                    <!--                <button class="button" @click="$store.commit('veg_store/SWITCH_ACTIVE_VEG_MODAL')">-->
                    <!--                    Recalculate specifications-->
                    <!--                </button>-->
                    <!--                <button class="button" @click="$store.commit('veg_store/SWITCH_ACTIVE_VEG_MODAL')">-->
                    <!--                    Resample specification-->
                    <!--                </button>-->
                </div>
                <div v-if="image_type === 'satelite'">
                    <p class="title is-5">Sentinel input images do not require any additional settings.</p>
                    <!--                    <button class="button" @click="close_veg_modal">-->
                    <!--                        Exit-->
                    <!--                    </button>-->
                    <button class="button" @click="changeTab('vparams')">
                        Next
                    </button>
                </div>
            </div>
            <!--Layers-->
            <br>
            <div style="overflow-x: auto ;height: 100%; overflow-y: auto;" v-if="current_tab==='vparams'">
                <input
                    id="dirmode"
                    type="checkbox"
                    v-model="selected_output_mode"
                    true-value="filemode"
                    false-value="dirmode"
                >
                <label for="dirmode"> Switch to multi-band image structure</label>
                <br>
                <p style="color: hsl(115,100%,43%)" v-if="info_messages.length"><b>Information for user:</b>
                <ul>
                    <li style="color: hsl(115,100%,43%)" v-for="message in info_messages">{{ message }}</li>
                </ul>
                </p>
                <p style="color: hsl(348, 100%, 61%)" v-if="errors.length"><b>Please correct the following error(s):</b>
                <ul>
                    <li style="color: hsl(348, 100%, 61%)" v-for="error in errors">{{ error }}</li>
                </ul>
                </p>
                <hr>
                <ul id="output_files">
                    <li v-for="(file, index) in output_files" :key="index"
                        :data-id="index"
                        style="overflow: hidden; margin-bottom: 1.5em;">
                        <div class="tile is-parent  is-vertical box" style="background-color: #c2c9d2">
                            <div class="tile is-parent">
                                <div class="tile is-child  is-6">
                                    <p>{{ output_mode[selected_output_mode].outer_tag }}:</p>
                                    <input v-model="output_files[index].filename"
                                           :placeholder="output_mode[selected_output_mode].outer_tag"
                                           type="text">
                                </div>
                                <div class="tile is-child"></div>
                                <!--                                <div class="tile is-child  is-3">-->
                                <button class="button"
                                        @click="add_layer(index)">
                                    <!--                                    {index:index, layer: {type: '', veg_i: '', param: ''}}-->
                                    Add {{ output_mode[selected_output_mode].inner }}
                                </button>
                                <button class="button"
                                        @click="remove_file(index)">
                                    Remove {{ output_mode[selected_output_mode].outer }}
                                </button>
                                <!--                                </div>-->
                            </div>
                            <hr>
                            <div class="tile is-parent is-vertical">
                                <!--                                        index + '_' + layer_index-->
                                <ul style="width: 100%;" :ref="'file_'+ index">
                                    <li v-for="(layer, layer_index) in file.layers" :key="layer.layer_id"
                                        :data-id="layer.layer_id"
                                        style="overflow: hidden;padding-bottom: 1.5em;">
                                        <div class="tile is-parent is-vertical box is-12"
                                             style="width:100%; padding: 0 0 0.8em;">
                                            <div class="tile is-child" style="margin: 0.6em 0.8em 0.3em !important;">
                                                <p>Type: {{ compute_layer_type(layer) }}</p>
                                                <p style="color: hsl(168,84%,34%)" v-if="layer.lut_filters.length > 0">
                                                    Custom filters are set on LUT parameters. You can edit them in the
                                                    preview window.</p>
                                                <span v-if="layer.param !== ''">
                                                <input type="checkbox" :id="'normalize_' + index" v-model="layer.normalize[0]"
                                                       true-value="true" false-value="false">
                                                <label :for="'normalize_' + index">  Rescale the results to specified range, using the Min-Max scaling </label>
                                                </span>
                                                <input type="number" style="width: 4rem" v-if="layer.normalize[0] === 'true'"
                                                            v-model="layer.normalize[1]"
                                                            placeholder="From"
                                                            class="flex-child">
                                                <input type="number" style="width: 4rem" v-if="layer.normalize[0] === 'true'"
                                                            v-model="layer.normalize[2]"
                                                            placeholder="To"
                                                            class="flex-child">
                                            </div>
                                            <div class="tile is-parent" style="padding: 0.2rem 0.8rem 0.3rem;">
                                                <div class="tile is-child">
                                                    <select
                                                        v-model="layer.veg_i"
                                                    >
                                                        <option disabled value="">Vegetation index</option>
                                                        <option>Aoki</option>
                                                        <option>Datt2</option>
                                                        <option>Cri1</option>
                                                        <option>Cri2</option>
                                                        <option>Tcari-Osavi</option>
                                                        <option>DCI</option>
                                                        <option>Lcai</option>
                                                        <option>SR</option>
                                                    </select>
                                                </div>

                                                <div class="tile is-child">
                                                    <select
                                                        v-if="layer.veg_i !== ''"

                                                        v-model="layer.lut">
                                                        <option disabled value="">Lut database</option>
                                                        <option>None</option>

                                                        <option
                                                            v-for="lut in $store.getters['veg_store/get_lut_databases']"
                                                            :value="lut">
                                                            {{ lut.lut_name }}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div class="tile is-child">
                                                    <select v-if="layer.lut !== ''"
                                                            v-model="layer.param"
                                                    >
                                                        <option disabled value="">Vegetation parameter
                                                        </option>
                                                        <option>None</option>
                                                        <option
                                                            v-for="parameter in layer.lut.approximated_parameters"
                                                            :value="parameter">
                                                            {{ parameter }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="tile is-child">
                                                    <select v-if="layer.param !== ''"
                                                            v-model="layer.empiric_function"
                                                            class="flex-child">
                                                        <option disabled value="">Empiric function
                                                        </option>
                                                        <option>Default</option>
                                                        <option>Linear regression</option>
                                                        <option>Linear</option>
                                                        <option>Quadratic</option>
                                                        <option>Cubic</option>
                                                        <option>Polynomial</option>
                                                        <option>Exponential</option>
                                                        <option>Exponential^2</option>
                                                        <option>Exponential^3</option>
                                                        <option>Exponential^n</option>
                                                        <option>Logarithm</option>
                                                        <option>Logarithm^2</option>
                                                        <option>Logarithm^3</option>
                                                        <option>Logarithm^n</option>
                                                    </select>
                                                </div>
                                                <div class="tile is-child">
                                                    <input type="number" v-if="layer.empiric_function === 'Linear regression'"
                                                            v-model="layer.poly_features"
                                                            placeholder="Polynomial features"
                                                            class="flex-child"
                                                    min="1" max="20">
                                                </div>
                                                <button class="button"
                                                        @click="preview_fits(index, layer_index, null)">
                                                    Preview
                                                </button>
                                                <button v-if="layer.lut_filters.length > 0" class="button"
                                                        @click="layer.lut_filters = []">
                                                    Reset LUT filters
                                                </button>
                                                <!--                                                <div class="tile is-1 is-0-tablet"></div>-->
                                                <button class="button"
                                                        @click="remove_layer(index, layer_index)">
                                                    Remove {{ output_mode[selected_output_mode].inner }}
                                                </button>
                                                <svg v-if="layer.veg_i !== '' && layer.lut.author_information"
                                                     @click="display_layer_help(layer.lut.author_information)"
                                                     style="width: 1.2rem; margin: 0.6rem; color: #f51f1f"
                                                     aria-hidden="true" focusable="false" data-prefix="fas"
                                                     data-icon="question-circle"
                                                     class="svg-inline--fa fa-question-circle fa-w-16"
                                                     role="img" xmlns="http://www.w3.org/2000/svg"
                                                     viewBox="0 0 512 512">
                                                    <path fill="currentColor"
                                                          d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"/>
                                                </svg>
                                            </div>

                                        </div>

                                    </li>
                                </ul>
                            </div>
                        </div>

                    </li>

                </ul>


                <!--                <button class="button" @click="close_veg_modal">-->
                <!--                    Exit-->
                <!--                </button>-->
                <!--                v-if="$store.getters['veg_store/get_output_files'].length === 0"-->
                <button class="button"
                        @click="create_new_output_file">
                    Create new output {{ output_mode[selected_output_mode].outer }}
                </button>
                <button class="button" v-if="true" @click="send_job">
                    Submit job
                </button>

            </div>

            <div style="overflow-x: auto ;height: 100%; overflow-y: auto;" v-if="current_tab==='previews'">
                <div v-for="(preview, index) in $store.getters['veg_store/getPreviews']"><br><br>
                    <hr>
                    <br>
                    <div style="text-align: center" v-if="preview ==='loading'">
                        <p class="title is-5">Computing preview</p>
                        <br>
                        <div class="lds-facebook">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <br><br>
                        <!--                        <button class="button"-->
                        <!--                                @click="delete_preview(index)">-->
                        <!--                            Cancel preview-->
                        <!--                        </button>-->
                    </div>
                    <div v-else>
                        <img :src="preview.plot" alt="Plot not available.">
                        <div :id="'informations' + index ">
                            <p v-for="info in preview.info"
                               :style=" info[0] !== '*' ? 'color: hsl(348, 100%, 61%)': ''">{{ info }}</p>
                        </div>

                        <div v-if="preview.editing_lut_filters">
                            <p class="title is-5">Filter simulated LUT data</p>
                            <p>If you leave any of the bounds unfilled, interval bounds will be used instead.</p>
                            <br>
                            <div
                                v-for="(_filter, filter_index) in preview.lut_filters">
                                <p>
                                <div style="color: hsl(98,87%,41%)"
                                     v-if="output_files[preview.file_index].layers[preview.layer_index].lut_filters.filter(x => x.param === _filter.param).length > 0">
                                    {{ '✔' }}
                                </div>

                                <select style="margin-right: 0.4%" v-model="_filter.param">
                                    <option disabled value="">Lut parameter</option>
                                    <option
                                        v-for="param in lut_parameters_split(preview.file_index, preview.layer_index, preview)"
                                        :disabled="applied_lut_filter(preview.file_index, preview.layer_index, preview, _filter, param)"
                                        :value="param">
                                        {{ param }}
                                    </option>
                                </select>

                                <input style="margin-right: 0.4%" v-model="_filter.from"
                                       placeholder="From"
                                       type="number">
                                <input style="margin-right: 0.4%" v-model="_filter.to"
                                       placeholder="To"
                                       type="number">
                                {{
                                    "[" + preview.lut_bounds[_filter.param][0] + " - " + preview.lut_bounds[_filter.param][1] + "]"
                                }}
                                <button style="margin-left: 1.5%"
                                        class="button"
                                        @click="remove_parameter_preview(index, filter_index)">
                                    Remove
                                </button>
                                </p>
                            </div>
                            <p v-if="output_files[preview.file_index].layers[preview.layer_index].lut_filters.length > 0">
                                Filters are applied to a {{ selected_output_mode === 'dirmode' ? 'file' : 'layer' }} in
                                an output specification window.</p>
                            <br>
                            <div v-if="preview.display_applied_filters">
                                <hr>
                                <p class="title is-6">Applied filters:</p>
                                <p v-for="applied_filter in output_files[preview.file_index].layers[preview.layer_index].lut_filters">
                                    {{
                                        applied_filter.param + "- [" + applied_filter.from + "," + applied_filter.to + "]"
                                    }}</p>
                                <hr>
                            </div>
                            <button style="margin-right: 4%" class="button"
                                    @click="add_parameter_preview(index)">
                                Add filter
                            </button>
                            <button class="button"
                                    @click="output_files[preview.file_index].layers[preview.layer_index].lut_filters=JSON.parse(JSON.stringify(preview.lut_filters))">
                                Apply to {{ selected_output_mode === 'dirmode' ? 'file' : 'layer' }}
                            </button>
                            <button class="button"
                                    @click="output_files[preview.file_index].layers[preview.layer_index].lut_filters=[]">
                                Reset {{ selected_output_mode === 'dirmode' ? 'file' : 'layer' }} presets
                            </button>
                            <button style="margin-right: 4%" class="button"
                                    @click="preview.display_applied_filters = ! preview.display_applied_filters">
                                {{ preview.display_applied_filters ? "Hide" : "Show" }} filters applied to
                                {{ selected_output_mode === 'dirmode' ? 'file' : 'layer' }}
                            </button>
                            <hr>
                        </div>
                        <br><br>
                        <div v-if="preview.editing_plot">
                            <p>Simulated data colour:</p>
                            <select v-model="preview.bg_colour">
                                <option disabled value="">Simulated data colour
                                </option>
                                <option>Red</option>
                                <option>Green</option>
                                <option>Blue</option>
                                <option>Cyan</option>
                                <option>Yellow</option>
                                <option>Magenta</option>
                                <option>Black</option>
                            </select>
                            <select style="margin-right: 0.8%;" v-model="preview.bg_type">
                                <option disabled value="">Function shape
                                </option>
                                <option>Point markers</option>
                                <option>Pixel markers</option>
                            </select>
                            <br><br>
                            <p>X-scale type</p>
                            <select v-model="preview.xscaletype">
                                <option disabled value="">X-scale type
                                </option>
                                <option>Linear</option>
                                <option>Logarithmic</option>
                                <option>Symmetrical log</option>
                                <!--                                <option>Logit</option>-->
                                <!--                                <option>Quadratic</option>-->
                            </select>
                            <p>Y-scale type</p>
                            <select v-model="preview.yscaletype">
                                <option disabled value="">Y-scale type
                                </option>
                                <option>Linear</option>
                                <option>Logarithmic</option>
                                <option>Symmetrical log</option>
                                <!--                                <option>Logit</option>-->
                                <!--                                <option>Quadratic</option>-->
                            </select>
                            <p>Custom scale</p>
                            <input
                                id="customscale"
                                type="checkbox"
                                v-model="preview.customscale"
                                true-value="true"
                                false-value="false"
                            >
                            <div v-if="preview.customscale === 'true'">
                                <p>X-scale</p>
                                <input style="margin-right: 0.4%" v-model="preview.xscale[0]" placeholder="X-scale from"
                                       type="number">
                                <input style="margin-right: 0.4%" v-model="preview.xscale[1]" placeholder="X-scale to"
                                       type="number">
                                <input style="margin-right: 0.4%" v-model="preview.xscale[2]" placeholder="X-step"
                                       type="number">
                                <button class="button"
                                        @click="preview.xscale = ['','','']">
                                    Reset
                                </button>
                                <p>Y-scale</p>
                                <input style="margin-right: 0.4%" v-model="preview.yscale[0]" placeholder="Y-scale from"
                                       type="number">
                                <input style="margin-right: 0.4%" v-model="preview.yscale[1]" placeholder="Y-scale to"
                                       type="number">
                                <input style="margin-right: 0.4%" v-model="preview.yscale[2]" placeholder="Y-step"
                                       type="number">
                                <button class="button"
                                        @click="preview.yscale = ['','','']">
                                    Reset
                                </button>
                                <hr>
                            </div>
                            <br><br>
                            <div style="background: #f5f5f5; padding: 1.5%; margin-bottom: 0.8%"
                                 v-for="(curve, curve_index) in preview.curves">
                                <!--                                <p>Function:</p>-->
                                <select style="margin-right: 0.8%;" v-model="curve.function">
                                    <option disabled value="">Empiric function
                                    </option>
                                    <option>Linear regression</option>
                                    <option>Linear</option>
                                    <option>Quadratic</option>
                                    <option>Cubic</option>
                                    <option>Polynomial</option>
                                    <option>Exponential</option>
                                    <option>Exponential^2</option>
                                    <option>Exponential^3</option>
                                    <option>Exponential^n</option>
                                    <option>Logarithm</option>
                                    <option>Logarithm^2</option>
                                    <option>Logarithm^3</option>
                                    <option>Logarithm^n</option>
                                </select>
                                <input type="number" v-if="curve.function === 'Linear regression'"
                                                            v-model="curve.poly_features"
                                                            placeholder="Polynomial features"
                                                            class="flex-child"
                                                            min="1" max="20">

                                <select style="margin-right: 0.8%;" v-model="curve.colour">
                                    <option disabled value="">Function colour
                                    </option>
                                    <option>Red</option>
                                    <option>Green</option>
                                    <option>Blue</option>
                                    <option>Cyan</option>
                                    <option>Yellow</option>
                                    <option>Magenta</option>
                                    <option>Black</option>
                                </select>
                                <!--                                <br>-->
                                <!--                                <p>Function shape:</p>-->
                                <select style="margin-right: 0.8%;" v-model="curve.curve_type">
                                    <option disabled value="">Function shape
                                    </option>
                                    <option v-if="curve.function !== 'Interpolate'">Solid line</option>
                                    <option v-if="curve.function !== 'Interpolate'">Dashed line</option>
                                    <option v-if="curve.function !== 'Interpolate'">Dash-dotted line</option>
                                    <option v-if="curve.function !== 'Interpolate'">Dotted line</option>
                                    <option v-if="curve.function === 'Interpolate'">Point markers</option>
                                    <option v-if="curve.function === 'Interpolate'">Pixel markers</option>
                                </select>
                                <input v-if="curve.function !== 'Interpolate'" style="margin-right: 0.4%"
                                       v-model="curve.linewidth" placeholder="Linewidth"
                                       type="number">
                                <button v-if="preview.curves.length > 1" class="button"
                                        @click="remove_function_preview(index, curve_index)">
                                    Remove
                                </button>
                            </div>
                            <br><br>
                            <button class="button"
                                    @click="add_function_preview(index)">
                                Add function
                            </button>
                            <hr>
                        </div>
                        <br><br>
                        <button class="button"
                                @click="delete_preview(index)">
                            Delete preview
                        </button>
                        <button class="button"
                                @click="preview.editing_plot=!preview.editing_plot">
                            {{ !preview.editing_plot ? "Edit plot" : "Close plot editor" }}
                        </button>
                        <button class="button"
                                @click="preview.editing_lut_filters=!preview.editing_lut_filters">
                            {{ !preview.editing_lut_filters ? "Edit lut settings" : "Close lut editor" }}
                        </button>
                        <button v-if="preview.editing_plot || preview.editing_lut_filters" class="button"
                                @click="rerender_preview(preview, index)">
                            Submit changes
                        </button>
                    </div>
                    <br>
                    <hr>
                </div>

                <!--                <button class="button" @click="close_veg_modal">-->
                <!--                    Exit-->
                <!--                </button>-->
            </div>
            <div style="overflow-x: auto ;height: 100%; overflow-y: auto;" v-if="current_tab==='help'">

                <div v-for="(text, index) in help_information">
                    <pre v-html="text"></pre>
                    <br>
                    <svg @click="delete_help_hint(index)"
                         style="width: 1.2rem; color: #f51f1f; position: relative; left: 98%" aria-hidden="true"
                         focusable="false" data-prefix="fas"
                         data-icon="times-circle"
                         class="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 512 512">
                        <path fill="currentColor"
                              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
                    </svg>
                </div>
                <hr>
                <br>
            </div>
            <button class="button" @click="close_veg_modal">
                Exit
            </button>

        </template>
        <template v-slot:footer>
            <div></div>
        </template>
    </modal>
</template>


<script>
import Modal from "@/components/tiles/modal";
import Tile from "@/components/tiles/Tile";
import Sortable from 'sortablejs';
import {longAPI} from "@/assets/js/axio-api";
import OuterBox from "@/components/tiles/OuterBox";

// layer: { layer_id: int, type: '', veg_i: '', param:  '' }
// file: { filename: "", layers: [   { layer_id: int, type: '', veg_i: '', param:  '' }  ], sortable: Obj }


// TODO: IF TEXT AREA COULD NOT BE PARSED !
export default {
    name: "Vegetation_modal",
    data() {
        return {
            tab_key: 0,
            image_type: null,
            errors: [],
            info_messages: [],
            current_tab: "image_specs",
            picked_units: "nm",
            wavelengths: "",
            fwhms: "",
            reflectance_scale: "",
            detected_number_of_bands: "",
            resampling_model: "gauss",
            layer_id: 0,
            output_files: [],
            selected_output_mode: 'dirmode',
            output_mode: {
                'filemode': {'outer_tag': 'Filename', 'outer': 'file', 'inner': 'layer'},
                'dirmode': {'outer_tag': 'Directory name', 'outer': 'directory', 'inner': 'file'}
            },
            help_information: []
        }
    },
    computed: {
        wavelengths_array() {
            let wavelengths = String(this.wavelengths).replace(/^\s+|\s+$/g, '')
            if (!wavelengths) {
                return [];
            }
            let parsed = wavelengths.split(",");
            parsed = parsed.map(elem => parseFloat(elem));
            if (parsed.filter(elem => Number.isNaN(elem)).length > 0) {
                return [];
            }
            return parsed;
        },
        fwhms_array() {
            let fwhms = String(this.fwhms).replace(/^\s+|\s+$/g, '')
            if (!fwhms) {
                return [];
            }
            let parsed = fwhms.split(",");
            parsed = parsed.map(elem => parseFloat(elem));
            if (parsed.filter(elem => Number.isNaN(elem)).length > 0) {
                return [];
            }
            return parsed;

        }
    },
    watch: {
        reflectance_scale: function (new_scale, _) {
            // send resampling request
            this.$store.commit("veg_store/UPDATE_REFLECTANCE_SCALE", parseInt(new_scale));
            this.errors = [];
        },
        picked_units: function (new_unit, _) {
            // send resampling request
            this.$store.commit("veg_store/UPDATE_PICKED_UNITS", new_unit);
            this.errors = [];
        },
        resampling_model: function (new_model, _) {
            // send resampling request
            this.$store.commit("veg_store/UPDATE_RESAMPLING_MODEL", new_model);
            this.errors = [];
        },
        wavelengths: function (new_content, _) {
            this.$store.commit("veg_store/UPDATE_WAVELENGTHS", this.wavelengths_array);
            this.errors = [];            // validate format //
        },
        fwhms: function (new_content, _) {
            this.$store.commit("veg_store/UPDATE_FWHMS", this.fwhms_array);
            this.errors = [];            // validate format
        }
    },
    methods: {
        display_layer_help(help_text) {
            this.help_information.push(help_text)
            // this.current_tab = "help";
            this.changeTab("help");
        },
        delete_help_hint(index) {
            this.help_information.splice(index, 1);
            if (this.help_information.length === 0) {
                this.changeTab("vparams");
            }
        },
        lut_parameters_split(file_index, layer_index, preview) {
            let lut = this.output_files[file_index].layers[layer_index].lut;
            return lut.filtering_parameters;
        },
        applied_lut_filter(file_index, layer_index, preview, _filter, param) {
            let applied = preview.lut_filters.map(x => x.param);
            return applied.indexOf(param) !== -1 && _filter.param !== param;
        },
        switch_img_mode(mode) {
            this.image_type = mode;
            if (mode === 'satelite') {
                this.changeTab("vparams");
            }
            this.validate_inputs();
        },
        close_veg_modal() {
            this.$store.commit('veg_store/SWITCH_ACTIVE_VEG_MODAL');
            while (this.$store.getters['veg_store/getPreviews'].length > 0) {
                this.delete_preview(this.$store.getters['veg_store/getPreviews'].length - 1);
            }
        },
        async delete_preview(index) {
            // if (this.$store.getters['veg_store/getPreviews'].length === 1) {
            //     this.changeTab('vparam');
            // }
            await this.$store.commit('veg_store/REMOVE_PREVIEW_PLOT', index);

        },
        async add_function_preview(index) {
            let preview = this.$store.getters['veg_store/getPreviews'][index];
            preview.curves.push({'function': '', 'curve_type': '', 'poly_features': 2, 'colour': '', 'linewidth': ''});
            await this.delete_preview(index);
            // this.changeTab('previews');
            this.$store.commit("veg_store/UPDATE_PREVIEWS", preview);
        },
        async remove_function_preview(index, curve_index) {
            let preview = this.$store.getters['veg_store/getPreviews'][index];
            preview.curves.splice(curve_index, 1);
            await this.delete_preview(index);
            // this.changeTab('previews');
            this.$store.commit("veg_store/UPDATE_PREVIEWS", preview);
        },
        async add_parameter_preview(index) {
            let preview = this.$store.getters['veg_store/getPreviews'][index];
            preview.lut_filters.push({"param": "", "from": "", "to": ""});
            await this.delete_preview(index);
            // this.changeTab('previews');
            this.$store.commit("veg_store/UPDATE_PREVIEWS", preview);
        },
        async remove_parameter_preview(index, filter_index) {
            let preview = this.$store.getters['veg_store/getPreviews'][index];
            preview.lut_filters.splice(filter_index, 1);
            await this.delete_preview(index);
            // this.changeTab('previews');
            this.$store.commit("veg_store/UPDATE_PREVIEWS", preview);
        },
        async send_job() {
            if (!await this.validate_output_files()) {
                let upper_modal_mask = document.getElementById('veg_modal_scrollable');
                let container = upper_modal_mask.children[0].children[0];
                container.scrollTo(0, 0);
                return;
            }

            let processed_files = this.output_files.map(file => {
                return {filename: file.filename, layers: file.layers}
            });
            this.info_messages = [];
            this.info_messages.push("Job submitted!");
            let response = await this.$store.dispatch("veg_store/submit_vparam_computations",
                {
                    image_type: this.image_type,
                    output_files: processed_files,
                    output_mode: this.selected_output_mode,
                    current_dir: this.$store.getters.getCurrentDirectory
                })
            // uncomment in release -> only when sure everything works :)
            // this.$store.commit("veg_store/SWITCH_ACTIVE_VEG_MODAL");
            if (response){
                this.info_messages = [];
                this.errors = [];
                this.errors.push(response);
            }
            this.$store.dispatch("periodicallyFetchJobs", Date.now());

        },
        async remove_file(file_index) {
            this.output_files.splice(file_index, 1);
        },
        remove_layer(file_index, layer_index) {
            if (this.output_files[file_index].layers.length === 1) {
                this.remove_file(file_index);
                return;
            }
            this.output_files[file_index].layers.splice(layer_index, 1);
        },
        compute_layer_type(layer) {
            if (layer.lut === "None") {
                layer.lut = "";
                layer.param = "";
            }

            if (layer.param === "None") {
                layer.param = "";
            }
            if (layer.veg_i === "" && layer.param === "") {
                return "";
            }
            if (layer.veg_i === "") {
                layer.type = "missing_veg";
                return "Missing vegetation index...";
            }
            if (layer.param === "") {
                layer.type = "veg_index";
                return "Computed \"" + layer.veg_i + "\" layer";
            }
            layer.type = "veg_param";
            return "Computed \"" + layer.param + "\" layer";
        },
        async preview_fits(file_index, layer_index, preview) {
            let layer = this.output_files[file_index].layers[layer_index];
            this.validate_layer(layer, layer_index, file_index);
            // validate curves
            // this.errors = [];
            // if (layer.type === "") {
            //     this.errors.push('Layer number ' + layer_index + " in file number " + file_index + " has no attributes, " +
            //         "please choose at least vegetation index to create a layer of computed " +
            //         'values of chosen index or both index and parameter type to create a layer ' +
            //         'of approximated values of chosen parameter');
            //     return false;
            // }
            // if (layer.type === "missing_veg") {
            //     this.errors.push('Layer number ' + layer_index + " in file number " + file_index + " is missing a vegetation " +
            //         "index, please choose one to be used for approximation of values of the chosen parameter");
            //     return false;
            // }
            // if (layer.param === "") {
            //     this.errors.push('Layer number ' + layer_index + " in file number " + file_index + " is missing a vegetation parameter to be approximated " +
            //         ", please choose one.");
            //     return false;
            // }
            // if (layer.empiric_function === "" && layer.param !== "") {
            //     this.errors.push('Layer number ' + layer_index + " in file number " + file_index + " is missing an empiric function " +
            //         "for curve fitting, please choose one. If you are not sure, choose the default option.");
            //     return false;
            // }
            // if (layer.poly_features === "" && layer.empiric_function !== "") {
            //     this.errors.push('Layer number ' + layer_index + " in file number " + file_index + " is missing a value of polynomial features " +
            //         "for linear regression, please fill out the numerical input field. The interval is restricted to powers from 2 to 10.");
            //     return false;
            // }

            if (this.errors.length > 0) {
                let upper_modal_mask = document.getElementById('veg_modal_scrollable');
                let container = upper_modal_mask.children[0].children[0];
                container.scrollTo(0, 0);
                return;
            }

            if (preview === null) {
                preview = {
                    "file_index": file_index,
                    "layer_index": layer_index,
                    "lut_filters": JSON.parse(JSON.stringify(layer.lut_filters)),
                    "xscale": ["", "", ""],
                    "yscale": ["", "", ""],
                    "customscale": "false",
                    "xscaletype": "Linear",
                    "yscaletype": "Linear",
                    "editing_plot": false,
                    "editing_lut_filters": false,
                    "display_applied_filters": false,
                    "bg_colour": 'Black',
                    "bg_type": "Point markers",
                    "curves": [{
                        'function': layer.empiric_function,
                        'poly_features': layer.poly_features,
                        'curve_type': 'Solid line',
                        'colour': 'Red',
                        'linewidth': "1.5"
                    }],
                    "info": [],
                    "plot": "data:image/png;base64,"
                };
                preview.lut_bounds = (await longAPI.post("/vparam/fileutil/lut_parambounds/", {"lut_id": this.output_files[file_index].layers[layer_index].lut.lut_id})).data;
            }

            // console.log(JSON.stringify(this.output_files[file_index].layers[layer_index]));
            // console.log("BEFORE\n: " + JSON.stringify(this.$store.getters["veg_store/getPreviews"]));
            await this.$store.commit("veg_store/UPDATE_PREVIEWS", "loading");
            // console.log("AFTER\n: " +  JSON.stringify(this.$store.getters["veg_store/getPreviews"]));
            this.changeTab("previews");

            for (let i = 0; i < preview.curves.length; i++) {
                if (preview.curves[i].function === "Interpolate" && preview.info.indexOf("Interpolate option interpolates vegetation parameter values from the available simmulated data, using linear interpolation methods. Values falling out of range are extrapolated.") === -1) {
                    preview.info.push("*Interpolate option interpolates vegetation parameter values from the available simmulated data, using linear interpolation methods. Values falling out of range are extrapolated.");
                }

                //&& preview.curves[i].curve_type === 'Solid line'
                if (preview.curves[i].function === "Linear regression" && !preview.curves[i].poly_features) {
                    // preview.curves[i].curve_type = "Pixel markers";
                    //preview.curves[i].linewidth = "1";
                    preview.curves[i].poly_features = 2;
                }
                if (preview.curves[i].linewidth === "") {
                    preview.curves[i].linewidth = "1.5";
                }
                if (preview.curves[i].function === "") {
                    preview.curves[i].function = "Polynomial";
                }
                if (preview.curves[i].colour === "") {
                    preview.curves[i].colour = "Red";
                }
                if (preview.curves[i].curve_type === "") {
                    preview.curves[i].curve_type = "Solid line";
                }
            }
            upper_modal_mask = document.getElementById('veg_modal_scrollable');
            container = upper_modal_mask.children[0].children[0];
            container.scrollTop = container.scrollHeight;

            let result = await this.$store.dispatch("veg_store/fetchPreviews", {
                "details": {
                    "layer_details": layer,
                    "preview_details": preview,
                    "image_type": this.image_type
                }, "current_dir": this.$store.getters.getCurrentDirectory
            });
            if (!result) {
                this.changeTab("vparams");
                this.errors.push("Could not create a preview with specified configuration. Possible reasons are that image does not contain bands with sufficient wavelengths for computation of specified VI, or the selected function could not be fitted to the data.")
            }

            let upper_modal_mask = document.getElementById('veg_modal_scrollable');
            let container = upper_modal_mask.children[0].children[0];
            container.scrollTop = container.scrollHeight;
        }
        ,
        async rerender_preview(preview, index) {
            await this.delete_preview(index);
            preview.info = [];
            await this.preview_fits(preview.file_index, preview.layer_index, preview);
        }
        ,
        async fetch_hdr_data() {
            let resp_data = await this.$store.dispatch("veg_store/fetch_image_specifications");
            this.picked_units = resp_data.unit;
            this.detected_number_of_bands = resp_data.number_of_bands;
            this.wavelengths = resp_data.wavelengths.join(", ");
            this.fwhms = resp_data.fwhms.join(", ");
            this.reflectance_scale = resp_data.reflectance_scale;
        }
        ,
        changeTab(new_tab) {
            let previous = this.current_tab;
            this.current_tab = new_tab;
            if (!this.validate_inputs()) {
                this.current_tab = previous;
                // this.tab_key += 1;
            }
        }
        ,

        valid_num_text_area(area_content) {
            let re = new RegExp("^[.,0-9\s ]*$");
            return re.test(area_content);
        }
        ,
        recalculate_image_specs() {
            // todo
            // call store to make request
        }
        ,
        resample_image_specs() {
            // todo
        }
        ,
        async create_new_output_file() {
            this.output_files.push({filename: "", layers: []});
            let file_index = this.output_files.length - 1;
            this.add_layer(file_index);

            let dom_elem = null;
            while (dom_elem == null) {
                await new Promise(r => setTimeout(r, 300));
                dom_elem = this.$refs["file_" + file_index][0];
            }

            const our_this = this;
            our_this.output_files[file_index].sortable = Sortable.create(dom_elem, {
                group: 'shared',
                animation: 150,
                store: {
                    set: async function (sortable) {
                        let file_index = 0;

                        for (let i = 0; i < our_this.output_files.length; i++) {
                            if (our_this.output_files[i].sortable === sortable) {
                                file_index = i;
                            }
                        }
                        let addition = 0;


                        if (our_this.output_files[file_index].layers.length === sortable.toArray().length) {
                            let layer_ids = our_this.output_files[file_index].layers.map(layer => layer.layer_id);
                            our_this.output_files[file_index].layers = sortable.toArray().map(index => our_this.output_files[file_index].layers[layer_ids.indexOf(parseInt(index))]);
                        }

                        // ===========
                        if (our_this.output_files[file_index].layers.length < sortable.toArray().length) {
                            let layers = our_this.output_files[file_index].layers.map(layer_obj => layer_obj.layer_id);
                            sortable.toArray().forEach(layer_id => {
                                if (!layers.includes(parseInt(layer_id))) {
                                    addition = parseInt(layer_id);
                                }
                            });
                            let from_index = 0;
                            let from_file_i = 0;
                            for (let i = 0; i < our_this.output_files.length; i++) {
                                const reducer = (accumulator, current_value) => accumulator || current_value;
                                if (our_this.output_files[i].layers.map(layer => layer.layer_id === addition).reduce(reducer, false)) {
                                    our_this.output_files[i].layers.forEach((layer, j) => {
                                        if (layer.layer_id === addition) {
                                            from_index = j;
                                            from_file_i = i;
                                            addition = layer;
                                        }
                                    }); // now addition becomes obj of the layer to be taken
                                    our_this.output_files[i].layers.splice(from_index, 1);
                                }
                            }
                            our_this.output_files[file_index].layers.splice(sortable.toArray().map(index_str => parseInt(index_str)).indexOf(addition.layer_id), 0, addition);
                            if (our_this.output_files[from_file_i].layers.length === 0) {
                                await new Promise(r => setTimeout(r, 200));
                                await our_this.remove_file(from_file_i);
                            }
                        } // ============
                    }
                }
            });
        }
        ,
        add_layer(file_index) {
            this.output_files[file_index].layers.push({
                layer_id: this.layer_id,
                veg_i: "",
                lut: "",
                param: "",
                type: "",
                empiric_function: "",
                poly_features: 2,
                lut_filters: [],
                normalize: ["false", 0, 100],
            });
            this.layer_id += 1;

            // layer: {type: '', veg_i: '', param: ''}


        }
        ,
        async validate_output_files() {
            await new Promise(r => setTimeout(r, 300));
            this.errors = [];

            if (this.output_files.length === 0){
                this.errors.push("No output files specified!");
                return false;
            }

            this.output_files.forEach((file, index) => {
                if (file.filename.trim() === "") {
                    this.errors.push('Empty name field of file number ' + index);
                    return false;
                }
                file.layers.forEach((layer, l_index) => {
                    let valid_layer = this.validate_layer(layer, l_index, index);
                    //if (layer.type === "") {
                    //    this.errors.push('Layer number ' + l_index + " in file number " + index + " has no attributes, " +
                    //        "please choose at least vegetation index to create a layer of computed " +
                    //        'values of chosen index or both index and parameter type to create a layer ' +
                    //        'of approximated values of chosen parameter');
                    //    return false;
                    //}
                    //if (layer.type === "missing_veg") {
                    //    this.errors.push('Layer number ' + l_index + " in file number " + index + " is missing a vegetation " +
                    //        "index, please choose one to be used for approximation of values of the chosen parameter");
                    //    return false;
                    //}
                    //if (layer.empiric_function === "" && layer.param !== "") {
                    //    this.errors.push('Layer number ' + l_index + " in file number " + index + " is missing an empiric function " +
                    //        "for curve fitting, please choose one. If you are not sure, choose the default option.");
                    //    return false;
                    //}
                });
            });
            return this.errors.length === 0;
        }
        ,
        validate_layer(layer, layer_index, file_index){
            this.errors = [];
            if (layer.type === "") {
                this.errors.push('Layer number ' + layer_index + " in file number " + file_index + " has no attributes, " +
                    "please choose at least vegetation index to create a layer of computed " +
                    'values of chosen index or both index and parameter type to create a layer ' +
                    'of approximated values of chosen parameter');
                return false;
            }
            if (layer.type === "missing_veg") {
                this.errors.push('Layer number ' + layer_index + " in file number " + file_index + " is missing a vegetation " +
                    "index, please choose one to be used for approximation of values of the chosen parameter");
                return false;
            }
            if (layer.param === "" && layer.lut !== "") {
                this.errors.push('Layer number ' + layer_index + " in file number " + file_index + " is missing a vegetation parameter to be approximated" +
                    ", please choose one.");
                return false;
            }
            if (layer.empiric_function === "" && layer.param !== "") {
                this.errors.push('Layer number ' + layer_index + " in file number " + file_index + " is missing an empiric function " +
                    "for curve fitting, please choose one. If you are not sure, choose the default option.");
                return false;
            }
            if (layer.poly_features === "" && layer.empiric_function !== "") {
                this.errors.push('Layer number ' + layer_index + " in file number " + file_index + " is missing a value of polynomial features " +
                    "for linear regression, please fill out the numerical input field. The interval is restricted to powers from 1 to 20.");
                return false;
            }
            if (layer.poly_features && (layer.poly_features < 1 || layer.poly_features > 20)) {
                this.errors.push('Layer number ' + layer_index + " in file number " + file_index + " has specified power of polynomial features, " +
                    "which is outside of the allowed interval. The interval is restricted to powers from 1 to 20.");
                layer.poly_features = 2;
                return false;
            }

            return true;
        },

        validate_inputs() {
            this.errors = [];
            if (this.image_type === "satelite") {
                return true;
            }
            if (this.image_type === null) {
                this.errors.push('No imagery type chosen.');
                return false;
            }
            if (this.$store.getters["veg_store/get_selected_files"].length === 1 &&
                this.$store.getters["veg_store/get_selected_files"][0].name.split(".")[1] !== "tif") {

                // let correspondingPair = this.$store.getters.userStorage.filter(obj => obj.name.split(".")[0] === filename.split(".")[0]);
                // if (correspondingPair.length < 2) {
                //     this.errors.push('Application could not find corresponding header (hdr) or image (bsq, bil, bip) file. ' +
                //         'Please select one so it matches the content of the one currently selected.');
                //     return false;
                // }
            }

            if (!this.picked_units) {
                this.errors.push('No units are selected');
                return false;

            }
            if (!this.wavelengths_array) {
                this.errors.push('No wavelength values are present (required one for each band)');
                return false;

            } else if (this.wavelengths_array.length !== this.detected_number_of_bands) {
                this.errors.push('Number of wavelength values does not match detected number of bands');
                return false;

            } else if (!this.valid_num_text_area(this.wavelengths)) {
                this.errors.push('Text area for wavelength contains illegal characters');
                return false;

            }
            if (!this.fwhms_array) {
                this.errors.push('No fwhm values are present (required one for each band)');
                return false;

            } else if (this.wavelengths_array.length !== this.detected_number_of_bands) {
                this.errors.push('Number of fwhm values does not match detected number of bands');
                return false;

            } else if (!this.valid_num_text_area(this.fwhms)) {
                this.errors.push('Text area for fwhms contains illegal characters');
                return false;

            }
            if (!this.reflectance_scale) {
                this.errors.push('No reflectance scale value present');
                return false;

            }
            if (!this.resampling_model) {
                this.errors.push('No resampling model set');
                return false;

            }

            // e.preventDefault();
            return true;
        }
        ,
        resize(textarea_name) {
            let textarea = this.$refs[textarea_name];
            textarea.style.width = 100 + '%';
            textarea.style.height = textarea.scrollHeight + 'px';

        }

    },
    components: {
        OuterBox, Modal, Tile
    }
    ,
    mounted() {
        let modal_container = document.getElementsByClassName('modal-container');
        modal_container[0].style.height = "70rem";
        this.fetch_hdr_data();
        this.$store.dispatch("veg_store/fetch_lut_databases");
    }
}
</script>


<style scoped lang="scss">


.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #363636;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0s;
}

@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 64px;
    }
    50%, 100% {
        top: 24px;
        height: 32px;
    }
}


.auto_textarea {
    width: 100%;
}

.flex-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

</style>