<template>
    <div class="tile">
        <div class="tile is-parent is-vertical">
            <slot name="outer_content">
                default header
            </slot>
        </div>
        <div class="tile is-parent">
            <ul>
                <li v-for="(inner_box, index) in outer_box" :key="index">
                    <slot name="inner_box" :inner_box="inner_box">
                        default body
                    </slot>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "OuterBox",
    props: {
        outer_box: Object,
    },
}
</script>

<style scoped>

</style>