<template>
    <div style="height: 40rem; overflow: auto;">
        <div v-if="displayedLut !== ''" style="text-align: left">
            <p class="title is-2">Lut database</p>
            <hr>
            <p>If you find a mistake in the metadata then you can <b>"Unmount"</b> the database which
                will
                delete this entry
                and put reflectance and parameter files back in your filemanager.</p>
            <p>If you want to make an adjustment in the metadata while still keeping this version, then
                you can <b>"Duplicate files back to filemanager"</b> and mount new
                lut database instance whilst keeping the present one. Note that copying big files will
                take a bit longer than unmounting them.</p>
            <hr>
            <br><br>
            <p class="title is-4">Name:</p>
            <p>{{ displayedLut.lut_name }}</p>
            <br>
            <p class="title is-4">Crop type:</p>
            <p>{{ displayedLut.crop_type }}</p>
            <br>
            <p class="title is-4">Created:</p>
            <p>{{ displayedLut.created_on }}</p>
            <br>
            <p class="title is-4">Available parameters for approximation</p>
            <p>{{ displayedLut.approximated_parameters }}</p>
            <br><br>
            <p class="title is-4">Available parameters for filtering</p>
            <p>{{ displayedLut.filtering_parameters }}</p>
            <br><br>
            <hr>
            <br>
            <p class="title is-4">Reflectance file has header</p>
            <p>{{ displayedLut.has_header }}</p>
            <br>
            <p class="title is-4">Is fragmented</p>
            <p>{{ displayedLut.fragmented }}</p>
            <br>
            <p class="title is-4">Resampled to sentinel</p>
            <p>{{ displayedLut.resampled_to_sentinel }}</p>
            <br>
            <p class="title is-4">Number of records</p>
            <p>{{ displayedLut.no_of_lines }}</p>
            <br>
            <p class="title is-4">Is fragmented</p>
            <p>{{ displayedLut.fragmented }}</p>
            <br>
            <p class="title is-4">Delimeter</p>
            <p>{{ displayedLut.delimeter }}</p>
            <br>
            <p class="title is-4">Units</p>
            <p>{{ displayedLut.units }}</p>
            <br>
            <p class="title is-4">Simulated with step</p>
            <p>{{ displayedLut.simulation_step }}</p>
            <br>
            <br>
            <hr>
            <br><br>
            <button @click.prevent="closeLutView" class="button backButton">Back</button>
        </div>

        <table
            v-show="$store.getters['veg_store/get_lut_databases'].length !== 0 && displayedLut === ''"
            class="table is-hoverable is-fullwidth" style="text-align:left">
            <thead>
            <tr>
                <th>Name</th>
                <th>Crop-type</th>
                <th>Created</th>
            </tr>
            </thead>
            <tbody>
            <tr @click="selectItem(3, index)"
                @dblclick="displayedLut=$store.getters['veg_store/get_lut_databases'][index]"
                :class="{'is-selected': (selectedLuts.includes(index))}"
                v-for="(item, index) in $store.getters['veg_store/get_lut_databases']" :key="index">
                <td>{{ item.lut_name }}</td>
                <td>{{ item.crop_type }}</td>
                <td>{{ item.created_on }}</td>
            </tr>
            </tbody>
        </table>

        <button class="button" v-if="selectedLuts.length > 0" @click="deleteSelectedLuts('delete')">
            Delete selected luts
        </button>
        <button class="button" v-if="selectedLuts.length > 0" @click="deleteSelectedLuts('unmount')">
            Unmount
        </button>
        <button class="button" v-if="selectedLuts.length > 0" @click="deleteSelectedLuts('duplicate')">
            Duplicate files back to filemanager
        </button>

    </div>
</template>

<script>

export default {
    name: "LutView",
    data() {
        return {
            selectedLuts: [],
            displayedLut: "",
        };
    }, methods: {
        closeLutView() {
            this.displayedLut = "";
        },


        selectItem(flag, id) {
            // Based on flag value we work either with user's layers or files.
            let arr = [];
            switch (flag) {
                case 0:
                    arr = this.selectedFiles;
                    break;
                case 1:
                    arr = this.selectedLayers;
                    break;
                case 2:
                    arr = this.selectedJobs;
                    break;
                case 3:
                    arr = this.selectedLuts;
                    break;
            }

            if (arr.includes(id)) {
                let i = arr.indexOf(id);
                arr.splice(i, 1);
            } else {
                arr.push(id);
            }


            switch (flag) {
                case 0:
                    this.selectedFiles = arr;
                    break;
                case 1:
                    arr = this.selectedLayers = arr;
                    return;
                case 2:
                    arr = this.selectedJobs = arr;
                    return;
                case 3:
                    arr = this.selectedLuts = arr;
                    return;
            }

            this.selectedFiles = arr;
            this.$store.commit("veg_store/UPDATE_SELECTED_FILES", {
                indices: this.selectedFiles,
                userStorage: this.$store.getters.userStorage
            });
        }
        ,
        mountLutDatabase() {
            this.$store.dispatch('veg_store/mount_lut_database');
            this.$store.dispatch("fetchUserStorage");
        }
        ,
        deleteSelectedLuts(mode) {
            this.$store.dispatch('veg_store/deleteLuts', {lut_objects: this.selectedLuts, mode: mode});
            this.selectedLuts = [];
        },
        createLutFragment() {
            this.$store.dispatch('veg_store/createLutFragment', {luts: this.selectedLuts});
        },
        chageLutMetadata() {
            this.$store.dispatch('veg_store/changeLutMetadata', {luts: this.selectedLuts});
        },
    }
    ,  mounted() {
        this.$store.dispatch("veg_store/fetch_lut_databases");
    }
}
</script>

<style scoped>

</style>