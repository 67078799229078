<template>
    <textarea class="container" :class="isCorrect ? 'is-correct': 'is-incorrect'" v-model="script" rows="1"></textarea>
</template>

<script>
import {API} from "../assets/js/axio-api";

export default {
    name: "JiffleCalculator",
    props: {
        init_script: Object,
        overrideScript: Boolean
    },
    data() {
        return {
            script: "",
            isCorrect: true,
            awaitingSyntaxCheck: false,
            initialized: false
        }
    },

    watch: {
        init_script: function (newVal, _) {
            console.log("CHANGED : " + newVal);
            if (this.overrideScript || (!this.initialized && this.script.length === 0)) {
                this.initialized = true;
                this.script = this.init_script;
            }
        },
        script: function (val) {
            if (!this.awaitingSyntaxCheck) {
                setTimeout(() => {
                    // call api
                    API.post("geoserver/jiffle/", {
                        data: this.script
                    })
                        .then(response => {
                            console.log("succ syntex check: ", JSON.stringify(response.data.status));
                            this.isCorrect = response.data.status === "OK";
                            this.$emit("syntaxCheck", {status: this.isCorrect, script: this.script});
                        })
                        .catch(err => {
                            console.log("Syntax check failed");
                        });
                    this.awaitingSyntaxCheck = false;
                }, 1000);
            }
            this.awaitingSyntaxCheck = true;
        }
    },

}
</script>

<style scoped>
textarea {
    resize: none;
    /*border: none;*/
    border-radius: 0.2rem;
    border-width: 2px;
    padding: 3px;
    outline: none;
    overflow: auto;
    background: #ffffff9e;
}

.container {
    border-radius: 5px;
    border-width: 2px;
    border-color: black;
    width: 100%;
    height: 100%;
}

.is-correct {
    border-color: greenyellow;
    border-radius: 0.2rem;
    border-width: 2px;
}

.is-incorrect {
    border-color: rgba(245, 31, 31, 0.87);
    border-radius: 0.2rem;
    border-width: 2px;
}


</style>
