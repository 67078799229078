<template>
    <table class="table is-hoverable is-fullwidth" style="text-align: left; border-radius: 0.8em;">
        <p v-show="filesEmpty" style="margin: 0.5rem">
            <strong>No subfolders in this directory yet.</strong>
        </p>
        <thead style="background-color: #7b525a;color: #fffcf2;">
        <tr>
            <th style="color: #fffcf2">{{ $t("type") }}</th>
            <th style="color: #fffcf2">{{ $t("name_obj") }}</th>
            <th>
                <p @click="fetchCurrentDirContent"
                   style="color: #fffcf2; background: rgb(255, 118, 115); border-radius: 0.5em; padding: 0.4em; display: inline-block">
                    {{ getCurrentDir === "" ? "/" : getCurrentDir }}
                </p>
            </th>
            <th style="color: #fffcf2">
                <button
                    v-show="getCurrentDir !== ''"
                    @click="exitDirectory"
                    class="button customBtn"> {{ $t("back") }}
                </button>
            </th>
        </tr>
        </thead>
        <tbody>
        <tr
            @click="selectItem(item, index)"
            @dblclick="enterDirectory(item)"
            :class="{ selected: selectedFiles.includes(index) }"
            v-for="(item, index) in (this.global ? $store.getters.userStorage: localFiles)"
            :key="index">
            <td class="table_cell">
                <img
                    style="height: 2em;position:absolute;"
                    v-if="item.type === 'file'"
                    src="@/assets/imgs/file_icon.svg"
                    alt="File icon"
                /><img
                style="height: 2em; position:absolute;"
                v-if="item.type === 'dir'"
                src="@/assets/imgs/directory_icon.svg"
                alt="Directory icon"
            />
            </td>

            <td class="table_cell">{{ item.name }}</td>
            <td class="table_cell" style="text-align: end">
                <button
                    class="customBtn"
                    v-show="
                  selectedFiles.includes(index)"
                    @click.stop="enterDirectory(item)">
                    Open
                </button>
                <!--                    && $store.getters.dirContents.get(item.path) &&-->
                <!--                    $store.getters.dirContents-->
                <!--                      .get(item.path)-->
                <!--                      .filter((obj) => obj.type === 'dir').length > 0-->
                <!--                "-->
            </td>
            <td class="table_cell"></td>
        </tr>
        </tbody>
    </table>
    <button @click="moveCopyTo('move')" class="contextBtn"
            v-if="controls.includes('move')">Move to {{selectedFiles.length === 1 ? "selected": "current path"}}
    </button>
    <button @click="moveCopyTo('copy')" class="contextBtn"
            v-if="controls.includes('copy')">Copy to {{selectedFiles.length === 1 ? "selected": "current path"}}
    </button>
    <button class="contextBtn" v-if="controls.includes('save')">Save to</button>
    <button class="contextBtn" v-if="controls.includes('close')" @click="$emit('close')">Close</button>
</template>

<script>
import {API} from "@/assets/js/axio-api";

export default {
    name: "DirectoryBrowser",
    props: {
        currentDir: String,
        mode: String,
        controls: Array,
        global: Boolean,
        calledForObjects: Array // paths selected
    },
    data() {
        return {
            selectedFiles: [],
            originalCurrentDir: this.currentDir,
            localFiles: []
        };
    },
    computed: {
        filesEmpty() {
            if (this.global) {
                return this.$store.getters.userStorage.length === 0;
            }
            return this.localFiles.length === 0;
        },
        getCurrentDir() {
            if (this.global) {
                return this.$store.getters.getCurrentDirectory;
            }
            return this.originalCurrentDir;
        },
    },
    watch: {
        selectedFiles: {
            handler: function (newFiles, _) {
                this.$emit("filesUpdated", newFiles);
            },
            deep: true
        }
    },
    methods: {
        fetchCurrentDirContent() {
            if (this.global) {
                this.$store.dispatch("fetchDirContent", this.getCurrentDir);
            }
        },

        enterDirectory(item) {
            if (item.type !== "dir") {
                return;
            }
            if (this.global) {
                this.$store.commit("UPDATE_CURRENT_DIRECTORY", item.path);
                this.$store.dispatch("fetchUserStorage");
                for (let i = 0; i < this.selectedFiles.length; i++) {
                    let file_index = this.selectedFiles[i];
                    this.$store.commit(
                        "DELETE_DIR_CONTENT",
                        this.$store.getters.userStorage[file_index].path
                    );
                }
                this.selectedFiles = [];
                this.$store.dispatch("fetchDirContent", item.path);
                return;
            }
            this.originalCurrentDir = item.path;
            this.fetchLocalStorage();
            this.selectedFiles = [];
        },

        async fetchLocalStorage() {
            try {
                let response = await API.get("/user/storage/", {
                    params: {
                        path: this.originalCurrentDir,
                        display_all: false
                    }
                })
                this.localFiles = response.data.files;
            } catch (err) {
                console.log("Error: " + err);
            }
        },
        exitDirectory() {
            if ((this.global && this.$store.getters.getCurrentDirectory === "") || (!this.global && this.originalCurrentDir === "")) {
                return;
            }
            let dir = this.global ? this.$store.getters.getCurrentDirectory : this.originalCurrentDir;
            dir = dir
                .split("/")
                .slice(0, -1)
                .join("/");

            if (this.global) {
                // if (this.$store.getters.getCurrentDirectory === "") {
                //     return; // button hidden but just in case
                // }

                for (let i = 0; i < this.selectedFiles.length; i++) {
                    let file_index = this.selectedFiles[i];
                    this.$store.commit(
                        "DELETE_DIR_CONTENT",
                        this.$store.getters.userStorage[file_index].path
                    );
                }
                this.selectedFiles = [];
                this.$store.commit(
                    "DELETE_DIR_CONTENT",
                    this.$store.getters.getCurrentDirectory
                );
                this.$store.commit("UPDATE_CURRENT_DIRECTORY", dir);
                this.$store.dispatch("fetchUserStorage");
                return;
            }

            this.selectedFiles = [];
            this.originalCurrentDir = dir;
            this.fetchLocalStorage();


        },

        selectItem(item, index) {
            if (this.mode === "single-select" && this.selectedFiles.length === 1 && this.selectedFiles[0] !== index) {
                this.selectedFiles = [];
            }

            if (this.selectedFiles.includes(index)) {
                let i = this.selectedFiles.indexOf(index);
                this.selectedFiles.splice(i, 1);
                if (this.global) {
                    this.$store.commit("DELETE_DIR_CONTENT", item.path);
                }
            } else {
                this.selectedFiles.push(index);
                if (this.global) {
                    this.$store.dispatch("fetchDirContent", item.path);
                }
            }

            // this.$store.commit("veg_store/UPDATE_SELECTED_FILES",
            //     this.selectedFiles.map(index => this.$store.getters.dirContents[this.dirPath][index]));
        },
        moveCopyTo(mode) {
            // where the files are moved/copied
            let destination = this.selectedFiles.length === 1 ? this.localFiles[this.selectedFiles[0]].path : this.getCurrentDir;
            // from which directory path are we moving/copying
            let sourcePath = this.calledForObjects[0].path.substring(0, this.calledForObjects[0].path.lastIndexOf("/"));
            // sources -> paths of files we want to copy/move
            this.$store.dispatch("moveCopyTo", {
                sources: this.calledForObjects.map(obj => obj.path),
                dest: destination,
                mode: mode,
                sourceDirPath: sourcePath
            });
            this.$emit('close');
        }
    },
    mounted() {
        if (this.global) {
            this.$store.dispatch('fetchUserStorage');
            return;
        }
        this.fetchLocalStorage();
    }
    ,
    beforeUnmount() {
        // this.$store.commit("UPDATE_CURRENT_DIRECTORY", this.originalCurrentDir);
    }
}
</script>

<style scoped>
main {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    gap: 0 10px;
    /* align-items: */
}

@media only screen and (max-width: 800px) {
    main {
        display: grid;
        grid-template-columns: 1fr;
        padding: 1em;
        grid-row-gap: 25px;
    }
}

li {
    margin-bottom: 5px;
}

.selected {
    background-color: #a9485a;
    color: #fffcf2;
}

.table_cell {
    border: none;
}

.customBtn {
    border: none;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}

.contextBtn {
    height: 3em;
    width: auto;
    /*margin-top: 1em;*/
    border: none;
    margin: 10px;
    padding: 5px 10px;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}

.contextBtn:hover {
    background: #7b525a;

}
</style>