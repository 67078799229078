<template style="">
    <modal id="lut_modal_scrollable" style="text-align: left;">
        <template v-slot:header>
            <p class="title is-2 title-custom">Mount lut database</p>
        </template>
        <template v-slot:body>
            <div>

                <!--                <div><p class="title is-4" v-if="filemode === undefined">I have</p>-->
                <!--                    <br>-->
                <!--                    <button class="button" disabled @click="changeFilemode('single')">-->
                <!--                        Single file-->
                <!--                    </button>-->
                <!--                    <button class="button" @click="changeFilemode('double')">-->
                <!--                        Separate reflectance and parameter files-->
                <!--                    </button>-->
                <!--                </div>-->

                <div v-if="filemode === 'double'" style="overflow-x: auto">

                    <form id="image_specs_form" @submit="validate_inputs">
                        <br><br>
                        <p style="color: hsl(348, 100%, 61%)" v-if="errors.length"><b>Please correct the following
                            error(s):</b>
                        <ul>
                            <li style="color: hsl(348, 100%, 61%)" v-for="error in errors">{{ error }}</li>
                        </ul>
                        </p>
                        <br><br>
                        <p class="title is-5">Preview data</p>
                        <div v-for="(file, index) in $store.getters['veg_store/get_selected_files']">
                            <input type="checkbox" :id="'file'+index"
                                   :value="file['name']"
                                   v-model="checkedFiles">
                            <label :for="'file'+index">{{ file['name'] }}</label>
                            <br>
                        </div>
                        <!--                        <input type="checkbox" id="file1"-->
                        <!--                               :value="$store.getters['veg_store/get_selected_files'][0]['name']"-->
                        <!--                               v-model="checkedFiles">-->
                        <!--                        <label for="file1">{{ $store.getters['veg_store/get_selected_files'][0]['name'] }}</label>-->
                        <!--                        <br>-->
                        <!--                        <input type="checkbox" id="file2"-->
                        <!--                               :value="$store.getters['veg_store/get_selected_files'][1]['name']"-->
                        <!--                               v-model="checkedFiles">-->
                        <!--                        <label for="file2">{{ $store.getters['veg_store/get_selected_files'][1]['name'] }}</label>-->
                        <!--                        <br>-->
                        <!--                        <div v-if="$store.getters['veg_store/get_selected_files'].length === 3">-->
                        <!--                            <input type="checkbox" id="file3"-->
                        <!--                                   :value="$store.getters['veg_store/get_selected_files'][2]['name']"-->
                        <!--                                   v-model="checkedFiles">-->
                        <!--                            <label for="file3">{{ $store.getters['veg_store/get_selected_files'][2]['name'] }}</label>-->
                        <!--                        </div>-->
                        <br>
                        <hr>
                        <div v-if="checkedFiles.length !== 0">
                            <div v-for="(checked_file, index) in checkedFiles">
                                <p>Preview of <b>{{ checkedFiles[index] }}</b></p>
                                <br>
                                <p style="white-space: pre-wrap; height: 15rem; width: 70rem; overflow: auto">{{
                                        $store.getters['veg_store/getLutFilePreview'][checkedFiles[index]]
                                    }}</p>
                            </div>
                        </div>
                        <!--                        <div v-if="checkedFiles.length !== 0">-->
                        <!--                            <div v-if="checkedFiles.length > 0">-->
                        <!--                                <p>Preview of <b>{{ checkedFiles[0] }}</b></p>-->
                        <!--                                <br>-->
                        <!--                                <p style="white-space: pre-wrap; height: 15rem; width: 70rem; overflow: auto">{{-->
                        <!--                                        $store.getters['veg_store/getLutFilePreview'][checkedFiles[0]]-->
                        <!--                                    }}</p>-->
                        <!--                            </div>-->
                        <!--                            <br>-->
                        <!--                            <hr>-->

                        <!--                            <div v-if="checkedFiles.length > 1">-->
                        <!--                                <p>Preview of <b>{{ checkedFiles[1] }}</b></p>-->
                        <!--                                <br>-->
                        <!--                                <p style="white-space: pre-wrap; height: 15rem; width: 70rem; overflow: auto">{{-->
                        <!--                                        $store.getters['veg_store/getLutFilePreview'][checkedFiles[1]]-->
                        <!--                                    }}</p>-->
                        <!--                            </div>-->
                        <!--                            <br>-->
                        <!--                            <hr>-->
                        <!--                        </div>-->
                        <p class="title is-5">Choose which file contains parameter values</p>
                        <div
                            v-for="(file, index) in $store.getters['veg_store/get_selected_files'].filter(x =>x.name !== srfFile)">
                            <input type="radio" :id="'paramfile'+index" :key="index"
                                   :value="file['name']"
                                   v-model="paramFile">
                            <label :for="'paramfile'+index">{{ file['name'] }}</label>
                            <br>
                        </div>

                        <!--                        <input type="radio" id="paramfile01"-->
                        <!--                               :value="$store.getters['veg_store/get_selected_files'][0]['name']"-->
                        <!--                               v-model="paramFile">-->
                        <!--                        <label for="paramfile01">{{ $store.getters['veg_store/get_selected_files'][0]['name'] }}</label>-->
                        <!--                        <br>-->
                        <!--                        <input type="radio" id="paramfile02"-->
                        <!--                               :value="$store.getters['veg_store/get_selected_files'][1]['name']"-->
                        <!--                               v-model="paramFile">-->
                        <!--                        <label for="paramfile02">{{ $store.getters['veg_store/get_selected_files'][1]['name'] }}</label>-->
                        <!--                        -->


                        <br>
                        <hr>
                        <p class="title is-5">Reflectance file metadata</p>
                        <br>

                        <p>Reflectance file header units</p>
                        <input type="radio" id="nm"
                               value="nm"
                               v-model="lutUnits">
                        <label for="nm">nm</label>
                        <br>
                        <input type="radio" id="mcm"
                               value="mcm"
                               v-model="lutUnits">
                        <label for="mcm">µm</label>
                        <br>
                        <hr>
                        <p>Reflectance file is simulated in wavelength interval</p>

                        <input v-model="wavelengthInterval['low']" placeholder="From" type="number">
                        <input v-model="wavelengthInterval['high']" placeholder="To" type="number">
                        <br><br>
                        <hr>
                        <p>LUT scaling factor</p>
                        <input v-model="scalingFactor" placeholder="Scaling factor" type="number">
                        <br><br>
                        <p>Base interval for lut values is 0-1. If your values has been scaled by
                            multiplying by a certain scaling factor (e.g. 1000, 10000..) and fall out of this range,
                            please specify given constant.
                            If your values fulfill conditions scaling factor is 1.</p>
                        <br>
                        <hr>
                        <input
                            id="lutheader"
                            type="checkbox"
                            v-model="lutHeader"
                            true-value="true"
                            false-value="false"
                        >
                        <label for="lutheader">Reflectance file has header</label>
                        <br><br>
                        <p>Reflectance file column delimeter</p>
                        <input v-model="lutDelimeter" placeholder="Column delimeter" type="text">
                        <!--            <input v-model="lutDelimeters" placeholder="Decimal delimeter" type="text">-->


                        <!--           <input type="checkbox" id="lutheader" :value="$store.getters['veg_store/get_selected_files'][0]['name']"-->
                        <!--                 v-model="checkedFiles">-->
                        <!--          <label for="lutheader">{{ $store.getters['veg_store/get_selected_files'][0]['name'] }}</label>-->
                        <br>
                        <hr>
                        <p class="title is-5">Spectral response function (SRF)</p>
                        <p>Used to resampled LUT to sentinel wavelengths</p>

                        <div v-if="$store.getters['veg_store/get_selected_files'].length === 3">
                            <br>
                            <p class="title is-6">Choose which file contains SRF</p>
                            <div
                                v-for="(file, index) in $store.getters['veg_store/get_selected_files'].filter(x =>x.name !== paramFile)">
                                <input type="radio" :id="'srf'+index"
                                       :value="file['name']"
                                       v-model="srfFile">
                                <label :for="'srf'+index">{{ file['name'] }}</label>
                                <br>
                            </div>
                            <br><br>
                            <p class="title is-6">SRF is defined on wavelength interval</p>

                            <input v-model="srfWavelengthInterval[0]" placeholder="From" type="number">
                            <input v-model="srfWavelengthInterval[1]" placeholder="To" type="number">
                            <br><br>
                            <p class="title is-6">SRF file has a header</p>
                            <input
                                id="srfheader"
                                type="checkbox"
                                v-model="srfHasHeader"
                                true-value="true"
                                false-value="false"
                            >
                            <br><br>
                            <p class="title is-6">SRF file has an index column</p>
                            <p>File can contain an extra first column with indices of lines/records in file or with
                                corresponding wavelengths.</p>
                            <input
                                id="srfindices"
                                type="checkbox"
                                v-model="srfIsIndexed"
                                true-value="true"
                                false-value="false"
                            >
                        </div>
                        <div v-else>
                            <p>You have not selected a third file corresponding to a custom SRF. You can pick one of the
                                system SRF files to be used instead.</p>
                            <br>

                            <input type="checkbox" id="sentinel2" value="Sentinel-2" v-model="systemSrf">
                            <label for="sentinel2"> Sentinel-2</label>
                            <br>
                            <input type="checkbox" :disabled="true" id="landsat" value="Landsat" v-model="systemSrf">
                            <label for="landsat"> Landsat</label>
                        </div>
                        <br>
                        <hr>
                        <p class="title is-5">Parameter file metadata</p>

                        <!--            <input-->
                        <!--                id="paramheader"-->
                        <!--                type="checkbox"-->
                        <!--                v-model="paramHeader"-->
                        <!--                true-value="yes"-->
                        <!--                false-value="no"-->
                        <!--            >-->

                        <!--            <label for="paramheader">Parameters file has header</label>-->
                        <p>Parameter file column delimeter</p>
                        <input v-model="paramDelimeter" placeholder="Column delimeter" type="text">
                        <!--            <input v-model="paramDelimeters" placeholder="Decimal delimeter" type="text">-->
                        <br> <br>

                        <p>If accessible, columns from parametric file header are prefilled as parameter names.
                            If you want, you can edit these names in a more understandable format. Parameter names with
                            which this
                            form is submitted will be offered as parameter options when submitting vegetation parameter
                            approximation jobs and for lut database filtering in preview settings.
                            <b>If you wish to discard certain columns so they are not displayed between the options,
                                erase corresponding fields, to signify they they are
                                meant to be skipped. Such fields will be highlighted with red. On the contrary green
                                fields will be used.</b></p>
                        <br>
                        <p class="title is-6">Column names in parameter file header to be available for
                            approximation: </p>
                        <input type="text" :class="param.length > 0 ? 'is-filled': 'is-empty'"
                               :placeholder="origParamHeader.split(paramDelimeter)[param_index]"
                               v-model="paramHeader[param_index]"
                               v-for="(param, param_index) in paramHeader">
                        <br><br>
                        <p class="title is-6">Column names in parameter file header to be available for lut database
                            filtering: </p>
                        <input type="text" :class="fparam.length > 0 ? 'is-filled': 'is-empty'"
                               :placeholder="origParamHeader.split(paramDelimeter)[fparam_index]"
                               v-model="filterParamHeader[fparam_index]"
                               v-for="(fparam, fparam_index) in filterParamHeader">
                        <br>
                        <hr>
                        <p class="title is-5">LUT information</p>
                        <p>In this text field you can specify any additional information for the user.
                            <br><b>E.g.</b></p>
                        <p>
                            - additional information about the LUT that is currently being uploaded
                            <br>
                            - description of individual LUT parameters, meaning, uses
                            <br>
                            - hints about optimal database filter presets
                        </p>
                        <br>

                        <textarea
                            v-model="author_information"
                            ref="textarea_lut_authorinf"
                            placeholder="Information for the user"
                            rows="1"
                            @focus="resize('textarea_lut_authorinf')"
                            @keyup="resize('textarea_lut_authorinf')"
                        ></textarea>
                        <br>
                        <hr>
                        <p class="title is-5">Crop type</p>
                        <input type="radio" id="wheat" value="Wheat" v-model="cropType">
                        <label for="wheat">Wheat</label>
                        <br>
                        <input type="radio" id="corn" value="Corn" v-model="cropType">
                        <label for="corn">Corn</label>
                        <br>
                        <input type="radio" id="oil_seed_rape" value="Oil_seed_rape" v-model="cropType">
                        <label for="oil_seed_rape">Oil-seed rape</label>
                        <br>
                        <input type="radio" id="beet" value="Beet" v-model="cropType">
                        <label for="beet">Beet</label>
                        <br>
                        <input type="radio" id="forage" value="Forage" v-model="cropType">
                        <label for="forage">Forage</label>
                        <br>
                        <input type="radio" id="forest" value="Forest" v-model="cropType">
                        <label for="forage">Forest</label>
                        <br>
                        <hr>
                        <p class="title is-5">Lut name</p>
                        <input v-model="lutName" placeholder="Name under which lut will be mounted" type="text">
                        <br>
                        <hr>

                        <p class="title is-5">Advanced presets</p>
                        <input
                            id="advanced"
                            type="checkbox"
                            v-model="advanced"
                            true-value="true"
                            false-value="false"
                        >
                        <br>
                        <hr>
                        <div v-if="advanced ==='true'">
                            <p class="title is-5">Approximate number of records in lut (max allowed deviation is
                                {{ parseInt(no_of_partitions) * 1000 }} records)</p>
                            <p>Please leave unfilled if you are not sure !</p>
                            <br>

                            <input v-model="no_of_records" placeholder="Number of records" type="number">
                            <br>
                            <hr>
                            <br>

                            <p class="title is-5">Number of partitions</p>
                            <input v-model="no_of_partitions" placeholder="Split lut in X partitions"
                                   type="number">
                            <br>
                            <hr>
                            <br>

                            <p class="title is-5">Lut wavelength simulation step</p>

                            <input v-model="simulation_step" placeholder="Lut simulated with step of X nm"
                                   type="number">
                            <br> <br>
                            <hr>

                            <p class="title is-5">Resample lut to sentinel-2</p>
                            <input
                                id="resampleToS2"
                                type="checkbox"
                                v-model="resampleToS2"
                                true-value="true"
                                false-value="false"
                            >
                            <br>
                            <hr>
                            <br>
                            <p class="title is-5">Create lut fragments (improved performace for selected queries)</p>
                            <p>A cropped version of the database will be produced and used for queries falling into
                                given
                                wavelength interval</p>
                            <div v-for="(fragment, index) in lutFragmentIndices" :key="index">
                                <input v-model="lutFragmentBounds[index]['low']" placeholder="From" type="number">
                                <input v-model="lutFragmentBounds[index]['high']" placeholder="To" type="number">
                                <button type="button" class="button" @click="removeFragment(index)">
                                    Delete
                                </button>
                                <br>
                                <p class="title is-5">Used for vegetation indices:</p>
                                <input type="checkbox" :id="index + '_aoki'"
                                       :value="index + '_aoki'"
                                       @click="addVegIndex(index, 'aoki')">
                                <!--                                       v-model="fragment['veg_indices']">-->
                                <label :for="index + '_aoki'"> Aoki </label>
                                <input type="checkbox" :id="index + 'datt2'"
                                       :value="index + 'datt2'"
                                       @click="addVegIndex(index, 'datt2')">

                                <!--                                       v-model="fragment['veg_indices']">-->
                                <label :for="index + 'datt2'"> Datt2 </label>
                                <input type="checkbox" :id="index + 'cri1'"
                                       :value="index + 'cri1'"
                                       @click="addVegIndex(index, 'cri1')">

                                <!--                                       v-model="fragment['veg_indices']">-->
                                <label :for="index + 'cri1'"> Cri1 </label>
                                <input type="checkbox" :id="index + 'cri2'"
                                       :value="index + 'cri2'"
                                       @click="addVegIndex(index, 'cri2')">
                                <!--                                       v-model="fragment['veg_indices']">-->
                                <label :for="index + 'cri2'"> Cri2 </label>
                                <input type="checkbox" :id="index + 'tcari_osavi'"
                                       :value="index + 'Tcari-Osavi'"
                                       @click="addVegIndex(index, 'tcari_osavi')">
                                <!--                                       v-model="fragment['veg_indices']">-->
                                <label :for="index + 'tcari_osavi'"> Tcari-Osavi </label>
                                <input type="checkbox" :id="index + 'dci'"
                                       :value="index + 'dci'"
                                       @click="addVegIndex(index, 'dci')">
                                <!--                                       v-model="fragment['veg_indices']">-->
                                <label :for="index + 'dci'"> DCI </label>
                                <input type="checkbox" :id="index + 'lcai'"
                                       :value="index + 'lcai'"
                                       @click="addVegIndex(index, 'lcai')">
                                <!--                                       v-model="fragment['veg_indices']">-->
                                <label :for="index + 'lcai'"> LCAI </label>
                                <input type="checkbox" :id="index + 'sr'"
                                       :value="index + 'sr'"
                                       @click="addVegIndex(index, 'sr')">
                                <!--                                       v-model="fragment['veg_indices']">-->
                                <label :for="index + 'sr'"> SR </label>
                                <hr>
                                <br>
                            </div>
                            <hr>
                            <br> <br>
                            <button type="button" class="button" @click="addNewFragment">
                                Add new fragment
                            </button>

                        </div>


                    </form>
                    <br> <br>
                    <!--          <hr>-->
                    <!--          more chunks !?-->

                    <br>
                    <button class="button" v-if="validate_inputs" @click="mount_lut">
                        Mount lut
                    </button>
                </div>

                <button class="button" @click="$store.commit('veg_store/SWITCH_ACTIVE_LUT_MODAL')">
                    Exit
                </button>
            </div>
        </template>
        <template v-slot:footer>
            <div></div>
        </template>
    </modal>
</template>

<script>


import Modal from "@/components/tiles/modal";
import Tile from "@/components/tiles/Tile";
import {API} from "@/assets/js/axio-api";

export default {
    name: "LutModal",
    data() {
        return {
            filemode: 'double',
            errors: [],
            checkedFiles: [],
            lutHeader: "false",
            lutDelimeter: ",",
            paramDelimeter: ",",
            cropType: "",
            lutName: "",
            advanced: "false",
            no_of_records: 0,
            no_of_partitions: 5,
            simulation_step: 1,
            lutFragmentIndices: [],
            lutFragmentBounds: [],
            resampleToS2: "true",
            paramFile: "",
            paramHeader: [],
            filterParamHeader: [],
            author_information: "",
            origParamHeader: "",
            wavelengthInterval: {low: 400, high: 2500},
            scalingFactor: 1,
            lutUnits: "nm",
            systemSrf: ["Sentinel-2"],
            srfFile: "",
            srfWavelengthInterval: ["", ""],
            srfHasHeader: "false",
            srfIsIndexed: "false"
        };
    }, computed: {},
    watch: {
        paramHeader: {
            handler: function (newParams, _) {
                for (let i = 0; i < newParams.length; i++) {
                    if (this.filterParamHeader[i] && (newParams[i] || this.filterParamHeader[i].length === 1) && newParams[i] !== this.filterParamHeader[i]) {
                        this.filterParamHeader[i] = newParams[i];
                    }
                }
            },
            deep: true
        },
        lutFragmentIndices: {
            handler: function (newFragmentIndices, oldLutFragmentIndices) {

                if ((newFragmentIndices.length !== oldLutFragmentIndices.length)) {
                    return;
                }
                const veg_index_to_wavelengths = {
                    'aoki': ["550", "800"], 'datt2': ["850", "710", "680"], 'cri1': ["510", "550"],
                    'cri2': ["510", "700"], 'tcari_osavi': ["700", "670", "550", "800"],
                    'lcai': ["2165", "2205", "2330"], 'dci': ["705", "722"], 'sr': ["1705", "2155"]
                };

                let buffer = JSON.parse(JSON.stringify(newFragmentIndices));
                for (let i = 0; i < buffer.length; i++) {

                    let fragmentInterval = [];
                    buffer[i].forEach(index => fragmentInterval.push(...veg_index_to_wavelengths[index]));
                    // fragmentInterval.push(parseFloat(this.lutFragmentBounds[i].low));
                    // fragmentInterval.push(parseFloat(this.lutFragmentBounds[i].high));
                    this.lutFragmentBounds[i].low = Math.min(...fragmentInterval);
                    this.lutFragmentBounds[i].high = Math.max(...fragmentInterval);
                }
            },
            deep: true
        },
        srfFile: async function (newFilename, _) {
            this.srfFile = newFilename;
            this.autofill("srf");
        },
        paramFile: async function (newFilename, _) {
            this.paramFile = newFilename;
            this.autofill("param");
        },
    },
    methods: {
        resize(textarea_name) {
            let textarea = this.$refs[textarea_name];
            textarea.style.width = 100 + '%';
            textarea.style.height = textarea.scrollHeight + 'px';

        },

        async autofill(fileDetermined) {
            let paramFile;
            let reflectanceFile;
            let srfFile;
            let filenames = this.$store.getters['veg_store/get_selected_files'].map(file => file.name);
            if ((this.srfFile === "" && filenames.length !== 2) || this.paramFile === "") {
                return;
            }
            let regex = /^[0-9.,]+$/;
            // Param file header
            let previews = Object.entries(this.$store.getters["veg_store/getLutFilePreview"]);
            if (previews.length > 0) {
                for (let i = 0; i < previews.length; i++) {
                    if (previews[i][0] === this.paramFile) {
                        if (regex.test(previews[i][1].split("\n")[0])) {
                            this.paramHeader = [];
                            this.origParamHeader = [];
                            continue;
                        }
                        this.paramHeader = previews[i][1].split("\n")[0].split(this.lutDelimeter);
                        this.filterParamHeader = [...this.paramHeader];
                        this.origParamHeader = previews[i][1].split("\n")[0];
                        paramFile = previews[i];
                    } else if (previews[i][0] === this.srfFile) {
                        srfFile = previews[i];
                    } else {
                        reflectanceFile = previews[i];
                    }
                }
            }

            if (filenames.length === 3) {
                // srf has header
                this.srfHasHeader = "false";
                if (!regex.test(srfFile[1].split("\n")[0])) {
                    this.srfHasHeader = "true";
                }
                // srf is indexed
                this.srfIsIndexed = "false";
                if (srfFile[1].split("\n")[0].split(",").length !== 13) {
                    this.srfIsIndexed = "true";
                }
            }
            // Reflectance file has header + delimeter
            this.lutHeader = "false";
            if (!regex.test(reflectanceFile[1].split("\n")[0])) {
                this.lutHeader = "true";
            }
            // number of records
            let no_of_records = await API.get("/vparam/fileutil/countlines", {params: {param_name: this.paramFile}});
            this.no_of_records = no_of_records.data.count_of_lines;
            if (this.lutHeader === 'false') {
                this.no_of_records = parseInt(this.no_of_records) - 1;
            }
        },

        addVegIndex(index, veg_index) {
            let fragmentCopy = JSON.parse(JSON.stringify(this.lutFragmentIndices));
            if (fragmentCopy[index].includes(veg_index)) {
                console.log("SEARCHING FOR :" + veg_index + " IN " + JSON.stringify(fragmentCopy[index]) + " AND RESULT IS I: " + fragmentCopy[index].indexOf(veg_index))
                fragmentCopy[index].splice(fragmentCopy[index].indexOf(veg_index), 1);
            } else {
                fragmentCopy[index].push(veg_index);
            }
            this.lutFragmentIndices = fragmentCopy;
        },
        removeFragment(index) {
            let fragmentCopy = [...this.lutFragmentIndices];
            fragmentCopy.splice(index, 1);
            this.lutFragmentIndices = fragmentCopy;
            this.lutFragmentBounds.splice(index, 1);
        },
        addNewFragment() {
            let fragmentCopy = [...this.lutFragmentIndices];
            fragmentCopy.push([]);
            this.lutFragmentIndices = fragmentCopy;
            this.lutFragmentBounds.push({low: 510, high: 850});
        },
        changeFilemode(mode) {
            this.filemode = mode;
        },
        validate_inputs() {
            this.errors = [];
            let filenames = this.$store.getters['veg_store/get_selected_files'].map(file => file.name);


            if (!this.paramFile) {
                this.errors.push('Please specify which file contains parameter values.');
                return false;
            }
            if (!this.wavelengthInterval || !this.wavelengthInterval.low || !this.wavelengthInterval.high) {
                this.errors.push('Please correct wavelength interval.');
                return false;
            }
            if (!this.wavelengthInterval || !this.wavelengthInterval.low || !this.wavelengthInterval.high) {
                this.errors.push('Please correct wavelength interval.');
                return false;
            }
            if (!this.lutDelimeter || !this.paramDelimeter) {
                this.errors.push('Please make sure you filled all delimeter fields.');
                return false;
            }
            if (!this.paramHeader) {
                this.errors.push('Please provide column names for the parametric file. Order matters!');
                return false;
            }
            if (this.paramHeader.length !== this.origParamHeader.split(this.paramDelimeter).length) {
                this.errors.push('Number of specified parametric columns does not match parametric file header.');
                return false;
            }
            if (!this.cropType) {
                this.errors.push('Please select crop type that corresponds with the lut content.');
                return false;
            }
            if (!this.lutName) {
                this.errors.push('Lut needs a name.');
                return false;
            }
            if (this.scalingFactor <= 0 || this.scalingFactor > 1) {
                this.errors.push('Lut scaling factor is out of allowed interval (0,1]');
                return false;
            }
            if (this.srfFile === "" && filenames.length === 3) {
                this.errors.push('SRF file is not selected');
                return false;
            }
            if ((this.srfWavelengthInterval[0] === "" || this.srfWavelengthInterval[1] === "") && filenames.length === 3) {
                this.errors.push('SRF wavelength interval is not fully specified.');
                return false;
            }
            if (filenames.length === 2 && !this.systemSrf.length > 0) {
                this.errors.push('System SRF file is not selected.');
                return false;
            }
            return true;
        },
        mount_lut() {
            if (!this.validate_inputs()) {
                let upper_modal_mask = document.getElementById('lut_modal_scrollable');
                let container = upper_modal_mask.children[0].children[0];
                container.scrollTo(0, 0);
                return;
            }
            let config = {
                lutHeader: this.lutHeader,
                lutDelimeter: this.lutDelimeter,
                lutWavelengths: this.wavelengthInterval,
                lutFilename: this.$store.getters["veg_store/get_selected_files"].filter(file => file.name !== this.paramFile && file.name !== this.srfFile)[0].name,
                paramFilename: this.paramFile,
                approximatedParams: this.paramHeader.map(x => x === "" ? "xxx": x),
                filteringParams: this.filterParamHeader.map(x => x === "" ? "xxx": x),
                author_information: this.author_information,
                paramDelimeter: this.paramDelimeter,
                lutName: this.lutName,
                cropType: this.cropType,
                no_of_records: this.no_of_records ? this.no_of_records : 0,
                no_of_partitions: this.no_of_partitions ? this.no_of_partitions : 5,
                simulation_step: this.simulation_step ? this.simulation_step : 1,
                resampleToS2: this.resampleToS2,
                fragments: this.lutFragmentBounds,
                lutUnits: this.lutUnits,
                lutScalingFactor: this.scalingFactor,
                systemSrf: this.systemSrf,
                srfFilename: this.srfFile,
                srfWavelengths: this.srfWavelengthInterval,
                srfHeader: this.srfHasHeader,
                srfIndexed: this.srfIsIndexed
            };
            // fragments: this.lutFragmentBounds.map((o, i) => [o, this.lutFragmentIndices[i]])

            this.$store.dispatch("veg_store/mount_lut_database", config);
            this.$store.commit('veg_store/SWITCH_ACTIVE_LUT_MODAL');
        }
    }, mounted() {
        let modal_container = document.getElementsByClassName('modal-container');
        modal_container[0].style.height = "70rem";
        let filenames = this.$store.getters['veg_store/get_selected_files'].map(file => file.name);
        // if (filenames.length !== 3) {
        //     this.errors.push("Please return to the filemanager and select the 3rd file corresponding to SRF (spectral response function used for resampling of LUT to sentinel wavelengths).");
        // }
        this.$store.dispatch("veg_store/fetchLutFilePreview", filenames);
        this.addNewFragment();


    }, components: {Modal, Tile},
}
</script>

<style scoped>
.is-filled {
    border-color: greenyellow;
    border-radius: 0.2rem;
    border-width: 2px;
}

.is-empty {
    background-color: rgba(255, 115, 115, 0.22);
    border-color: rgba(245, 31, 31, 0.87);
    border-radius: 0.2rem;
    border-width: 2px;
}

</style>