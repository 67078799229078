<template>
    <select v-model="mode" v-show="!locked" class="minified">
        <option disabled value="">Mode</option>
        <option value="ramp">Gradient</option>
        <option value="intervals">Discrete intervals</option>
        <option value="values">Only specified values</option>
    </select>
    <div v-for="(entry, index) in colorEntries" class="entries_wrapper">
        <br>
        <input :class="{minified: !locked}" type="color" v-model="entry.color" style="margin: 0 10px">
        <br :class="!locked ? 'minified_label': 'minified_label_locked'">
        <label :class="{minified: !locked}" for="quantity" style="margin: 0 10px">Pixel value </label>
        <input :class="{minified: !locked}" id="quantity" placeholder="Pixel value" type="number"
               v-model="entry.quantity">
        <!--        slider [0;1] would fit better-->
        <br :class="!locked ? 'minified_label': 'minified_label_locked'">
        <label :class="{minified: !locked}" for="opacity" style="margin: 0 10px">Opacity </label>
        <input id="opacity" placeholder="Opacity" type="number" v-model="entry.opacity">
        <button v-show="!locked"
                class="closeViewBtn"
                @click="deleteEntry(index)">
            <svg aria-hidden="true" focusable="false" data-prefix="fas"
                 data-icon="times-circle"
                 class="close_svg svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 512 512">
                <path fill="currentColor"
                      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
            </svg>
        </button>
    </div>
    <button v-show="!locked" class="addEntryBtn" @click="addColorEntry">Add</button>
</template>

<script>
export default {
    name: "ColorMap",
    props: {
        init_colormap: Object,
        override: Boolean,
        locked: Boolean
    },
    data() {
        return {
            initialized: false,
            mode: "ramp",
            colorEntries: [{color: '#2140d9', quantity: 0, opacity: 1},
                {color: '#edd400', quantity: 50, opacity: 1}, {color: '#ef2929', quantity: 100, opacity: 1}]
        }
    },
    watch: {
        init_colormap: function (newVal, _) {
            const init_entries = [{color: '#2140d9', quantity: 0, opacity: 1},
                {color: '#edd400', quantity: 50, opacity: 1}, {color: '#ef2929', quantity: 100, opacity: 1}];
            // console.log("II:INITIALIZED: " + this.initialized);
            // console.log("II:INIT_COLORMAP: " + JSON.stringify(this.init_colormap));
            // console.log("II:EQUALS: " + (JSON.stringify(newVal.colorMapEntries) !== JSON.stringify(init_entries)
            //     || newVal.colorMapMode !== "ramp"));
            if (this.override || (!this.initialized &&
                (JSON.stringify(newVal.colorMapEntries) !== JSON.stringify(init_entries)
                    || newVal.colorMapMode !== "ramp"))) {
                this.initialized = true;
                this.colorEntries = newVal.colorMapEntries;
                this.mode = newVal.colorMapMode;
            }
        },

        colorEntries: {
            handler: function (newEntries, _) {
                this.$emit("colorMapUpdated", {entries: newEntries, mode: this.mode});
            },
            deep: true
        },
        mode: {
            handler: function (newMode, _) {
                this.$emit("colorMapUpdated", {entries: this.colorEntries, mode: newMode});
            }, deep: true
        }
    },
    methods: {
        addColorEntry() {
            this.colorEntries.push({color: '#000000', quantity: 0, opacity: 1});
        },
        deleteEntry(index) {
            this.colorEntries.splice(index, 1);
        }
    },
    mounted() {
        const init_entries = [{color: '#2140d9', quantity: 0, opacity: 1},
            {color: '#edd400', quantity: 50, opacity: 1}, {color: '#ef2929', quantity: 100, opacity: 1}];
        // console.log("INITIALIZED: " + this.initialized);
        // console.log("INIT_COLORMAP: " + JSON.stringify(this.init_colormap));
        // console.log("EQUALS: " + (JSON.stringify(this.init_colormap.colorMapEntries) !== JSON.stringify(init_entries)
        //     || this.init_colormap.colorMapMode !== "ramp"));
        if (this.override || (!this.initialized &&
            this.init_colormap &&
            (JSON.stringify(this.init_colormap.colorMapEntries) !== JSON.stringify(init_entries)
                || this.init_colormap.colorMapMode !== "ramp"))) {
            this.initialized = true;
            this.colorEntries = this.init_colormap.colorMapEntries;
            this.mode = this.init_colormap.colorMapMode;
        }
    }

}
</script>

<style scoped>
.minified_label {
    display: none;
}

.close_svg {
    height: 1rem;
    margin-left: 3rem
}

.entries_wrapper {
    overflow-y: auto;
    /*text-align: left;*/
}

.minified_label_locked {
    display: block;
    margin: 2px 0;
}

@media only screen and (max-width: 1200px) {
    .minified_label {
        display: block;
        margin: 2px 0;
    }
}

@media only screen and (max-width: 600px) {
    .close_svg {
        margin: 0;
    }

    .minified {
        display: block;
        margin: 2px 0;
    }
}

/*@media only screen and (max-width: 1000px) {*/
/*    .minified {*/
/*        display: block;*/
/*        margin: 2px 0;*/
/*    }*/
/*}*/


.addEntryBtn {
    height: 3em;
    width: 6em;
    border: none;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
    margin: 2em 5px;
}

.addEntryBtn:hover {
    background: #7b525a;

}

.closeViewBtn {
    position: relative;
    background: none;
    border: none;
    color: #FF7673FF;
}

.closeViewBtn:hover {
    color: #7b525a;
}


</style>