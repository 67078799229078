<template>
    <li>
        <p>{{ file }}</p>
        <button @click="deleteThisLayer">X</button>
    </li>
</template>

<script>
export default {
    name: "ImgEditorItem",
    props: {
        file: String,
        meta: Object,
        indexInFile: Number,
        newFileIndex: Number
    },

    methods: {
        deleteThisLayer() {
            this.$emit('delete-layer', {fileIndex: this.newFileIndex, layerIndex: this.indexInFile});
        }
    },


    mounted() {
        console.log("Element, new file index:", this.newFileIndex, "index of this layer:", this.indexInFile);
        if (Object.keys(this.meta).length === 0) {
            console.log("Init file, fetching meta data...");
            this.$emit("update-meta", {fetchInProgress: true});
        } else {
            console.log("Using cached values");
        }
    }
}
</script>

<style scoped>
li {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

button {
    margin-left: auto;
}
</style>