<template>
    <div class="card-b card-tall">

        <div class="loaderPlaceholder" v-if="!showNews">
            <!-- <h1>Loading</h1> -->
        </div>
        <div class="news-slideshow"
             v-if="this.$store.getters.appNews && this.$store.getters.appNews.length > 0 &&
             (this.currentSlide || this.currentSlide >= 0)">
            <div class="news_slide">
                <!--                    <div class="numbertext">1 / 3</div>-->
                <p style="position: relative; top: 1em;font-size: 2rem;">
                    {{ $store.getters.appNews[currentSlide].title }}</p>
                <p style="position: relative; top: 2em;">{{ $store.getters.appNews[currentSlide].created }}</p>
                <p style="position: relative; top: 3em;">{{ $store.getters.appNews[currentSlide].content }}</p>
            </div>

            <!-- Next and previous buttons -->
            <a class="prev" @click="plusSlides(-1)">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left"
                     class="new-nav-svg svg-inline--fa fa-chevron-left fa-w-10" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path fill="currentColor"
                          d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"/>
                </svg>
            </a>

            <a class="next" @click="plusSlides(+1)">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right"
                     class="new-nav-svg svg-inline--fa fa-chevron-right fa-w-10" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path fill="currentColor"
                          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/>
                </svg>
            </a>
        </div>

        <!-- The dots/circles -->
        <div style="text-align:center" v-if="$store.getters.appNews">
                <span class="dot" :class="currentSlide === index ? 'dot-active': ''" @click="currentSlide = index"
                      v-for="(item, index) in $store.getters.appNews"></span>
        </div>

    </div>
</template>

<script>
import router from "@/router";

export default {
    name: "NewsComponent",
    data() {
        return {
            currentSlide: 0,
            autoslide: true
        }
    },
    computed: {
        newsCount() {
            if (!this.$store.getters.appNews) {
                return 0;
            }
            return this.$store.getters.appNews.length;
        },
        showNews() {
            return this.$store.getters.appNews && this.$store.getters.appNews.length >= 0;
        }
    },

    methods: {
        plusSlides(step) {
            this.currentSlide = (((this.currentSlide + step) % this.newsCount) + this.newsCount) % this.newsCount;
        },
        async autoslideNews() {
            const delay = ms => new Promise(res => setTimeout(res, ms));
            while (this.autoslide) {
                await delay(15000);
                this.currentSlide = (((this.currentSlide + 1) % this.newsCount) + this.newsCount) % this.newsCount;
            }
        }
    },
    async created() {
        await this.$store.dispatch("fetchAppNews");
        this.autoslideNews();
    }
}
</script>


<style scoped>

.card-b {
    width: 100%;
    height: 100%;
    /*del after ?*/
    /*border: 1px solid;*/
    /*border-color: white;*/
    background: #ffebeb99;
    padding: 1em;
    border-radius: 20px;
}

.card-tall {
    grid-row: span 2 / auto;
}

.news-slideshow {
    overflow: auto;
    padding: 2em;
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 1em;
}

.new-nav-svg {
    width: 2.5em;
    position: relative;
    opacity: 0.05;
    color: #7b525a;
}

.new-nav-svg:hover {
    opacity: 1;
}

.next {
    left: 40%;
    top: 10%;
    position: relative;
}

.prev {
    right: 40%;
    top: 10%;
    position: relative;
}

.dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #5e5757;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    position: relative;
    bottom: 100%;
}


.dot-active {
    background-color: #ddd5d5;
    border-color: #7b525a;
}

.dot:hover {
    background-color: #FF7673FF;
}

.loaderPlaceholder {
    width: 100%;
    height: 100%;
    animation-name: color;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    border-radius: 1em;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@keyframes color {

    50% {
        background-color: #ffebeb;
    }

    100% {
        background-color: #ffebeb99;
    }

}
</style>
