<template>
    <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Reset password</p>
            </header>

            <div v-if="!codeSent">
                <section class="modal-card-body">
                    <label>Email</label>
                    <input class="input" v-model="email" type="email"
                           :class="{ 'is-success': (emailValidation(email) === 1), 'is-danger': (emailValidation(email) === 2)}"/>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="resetPassword">Reset</button>
                    <button class="button" @click="close">Cancel</button>
                    <p class="has-text-danger">{{ resetResult }}</p>
                </footer>
            </div>

            <div v-if="codeSent & !correctCode">
                <section class="modal-card-body">
                    <label>Code</label>
                    <input class="input" v-model="code" type="text"/>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="verifyCode">Verify</button>
                    <button class="button" @click="close">Cancel</button>
                    <p class="has-text-danger">{{ resetResult }}</p>
                </footer>
            </div>

            <div v-if="codeSent & correctCode">
                <section class="modal-card-body">
                    <label>New password</label>
                    <input v-bind:class="passwordClass" class="input" v-model="newPassword" type="password"/>
                    <label>Repeat new password</label>
                    <input v-bind:class="passwordClass" class="input" v-model="newPasswordCheck" type="password"/>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="saveNewPassword">Save new password</button>
                    <button class="button" @click="close">Cancel</button>
                    <p class="has-text-danger">{{ resetResult }}</p>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import {API} from "@/assets/js/axio-api";

export default {
    name: "ResetPassword",
    data() {
        return {
            email: "",
            code: null,
            codeSent: false,
            isEmailCorrect: false,
            correctCode: false,
            resetResult: "",
            newPassword: null,
            newPasswordCheck: null
        }
    },

    methods: {
        resetPassword() {
            let status = this.emailValidation(this.email);
            if (status === 0 || status === 2) {
                this.resetResult = "Invalid email format.";
                return;
            }

            this.resetResult = "";

            API.post('/reset-password/', {email: this.email})
                .then(response => {
                    if (response.data.result === "OK") {
                        this.codeSent = true;
                    } else if (response.data.result === "SUS") {
                        this.resetResult = "Something went wrong, please check if email is correct.";
                        window.open("https://www.youtube.com/watch?v=QFsw29t_7aQ");
                    }
                })
                .catch(err => {
                    this.resetResult = "Something went wrong, please try again.";
                });
        },
        verifyCode() {
            API.post('/verify-password/', {code: this.code, email: this.email})
                .then(response => {
                    if (response.data.result === "OK") {
                        this.correctCode = true;
                    } else if (response.data.result === "SUS") {
                        this.resetResult = "Incorrect code.";
                    }
                    this.resetResult = "";
                })
                .catch(err => {
                    this.resetResult = "Something went wrong, please try again.";
                });
        },
        saveNewPassword() {
            if (this.passwordClass !== "is-success") {
                return;
            }

            API.post('/change-password/', {password: this.newPassword, email: this.email})
                .then(response => {
                    if (response.data.result === "OK") {
                        this.resetResult = "";
                        this.close();
                    } else if (response.data.result === "SUS") {
                        this.resetResult = "Could not change the password.";
                    }
                })
                .catch(err => {
                    this.resetResult = "Something went wrong, please try again.";
                });
        },
        close() {
            this.$emit("close");
        },

        emailValidation(email) {
            if (email == null)
                return 0;
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email) ? 1 : 2;
        },
    },
    computed: {
        passwordClass() {
            if (this.newPassword === null) {
                return "";
            } else if (this.newPassword.length < 8) {
                this.resetResult = "Choose password at least 8 characters long"
                return 'is-danger';
            } else if (this.newPassword === this.newPasswordCheck) {
                this.resetResult = "";
                return 'is-success';
            }
            this.resetResult = "Passwords don't match";
            return 'is-danger';
        }
    }
}
</script>

<style scoped>
.modal {
    background-color: rgba(0, 0, 0, 0.7);
}

.delete {
    visibility: hidden;
}
</style>
