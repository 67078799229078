<template xmlns="http://www.w3.org/1999/html">
    <div>
        <ul>
            <li v-for="(file, f_index) in files" :key="file.file_id">

                <div class="tile is-parent  is-vertical box" style="background-color: #c2c9d2; margin-bottom: 1.5rem;">
                    <div class="tile is-parent">
                        <div class="tile is-child  is-5">
                            <p>Filename:
                                <input v-model="file.name" placeholder="Filename"
                                       type="text">
                            </p></div>
                        <div class="tile is-child"></div>
                        <!--                                <div class="tile is-child  is-3">-->
                        <button class="button"
                                v-if="file.rgb.length > 0"
                                @click="crop_to_rgb(f_index)">
                            Crop to RGB
                        </button>
                        <button class="button"
                                @click="delete_file(f_index)">
                            Remove file
                        </button>
                        <button class="button"
                                v-if="file.metadata.length > 0"
                                @click="duplicate_file(f_index)">
                            Duplicate file
                        </button>
                        <!--                                </div>-->
                    </div>
                    <hr>
                    <div class="tile is-parent is-vertical">
                        <!--                                        index + '_' + layer_index-->
                        <ul style="width: 100%;" :ref="'file_'+ f_index">
                            <li v-for="(layer, l_index) in file.metadata" :key="layer.layer_id"
                                :data-id="layer.layer_id"
                                style="overflow: hidden;padding-bottom: 1.5em;">
                                <div class="tile is-parent is-vertical box is-12"
                                     style="width:100%; padding: 0 0 0.8em;">
                                    <div class="tile is-child is-9" style="margin: 0.6em 0.8em 0.3em !important;">
                                        <p>{{ layer.description }}</p>
                                    </div>
                                    <div class="tile is-parent" style="padding: 0.2rem 0.8rem 0.3rem;">

                                        <div class="tile is-1 is-0-tablet"></div>
                                        <button class="button"
                                                @click="duplicate_band(f_index, l_index)">
                                            Duplicate band
                                        </button>
                                        <button class="button"
                                                @click="delete_band(f_index, l_index)">
                                            Delete band
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="tile is-child  is-5">
                        <p>Save as:
                            <select v-if="file.metadata.length > 0"
                                    v-model="file.format">
                                <option>geoTIFF</option>
                                <option>keep original</option>
                            </select>
                        </p>
                    </div>
                    <div class="tile is-child"></div>
                </div>
            </li>
        </ul>
        <button class="button"
                @click="create_file()">
            Add file
        </button>
<!--        <button class="button"-->
<!--                @click="submit()">-->
<!--            Submit-->
<!--        </button>-->
        <button class="button"
                @click="reset()">
            Reset
        </button>
        <button class="button"
                @click="$store.commit('SWITCH_IMAGE_EDITOR_ACTIVE')">
            Close
        </button>
    </div>
</template>


<script>
import Modal from "@/components/tiles/modal";
import Tile from "@/components/tiles/Tile";
import Sortable from 'sortablejs';
import {API} from "@/assets/js/axio-api";

export default {
    name: "ImageEditor",
    data() {
        return {
            files: []
        }
    },
    // props: {
    //     outer_boxes: Array,
    // },
    computed: {},
    watch: {},
    methods: {
        async fetchFilesMetadata(filenames) {
            /*
            *  Get metadata of each of the given files.
            *  The received data structure is as follows : { 'files': {'name': [Str (metadata) ]} }
            */
            let files = await API.post("/user/storage/files/info/", {filenames: filenames.map(file => file.name)});
            return files.data;
        }
        ,

        duplicate_band(file_index, layer_index) {
            let duplicate_band = JSON.parse(JSON.stringify(this.files[file_index].metadata[layer_index]));
            duplicate_band.layer_id += 1;
            this.files[file_index].metadata.splice(layer_index + 1, 0, duplicate_band);
            this.files.forEach((file, i) => {
                if (i >= file_index) {
                    file.metadata.forEach((band, j) => {
                        if (j > layer_index + 1) band.layer_id += 2;
                    });
                }
            });


        },
        crop_to_rgb(f_index) {
            let r = this.files[f_index].metadata[this.files[f_index].rgb[0] - 1];
            let g = this.files[f_index].metadata[this.files[f_index].rgb[1] - 1];
            let b = this.files[f_index].metadata[this.files[f_index].rgb[2] - 1];
            this.files[f_index].metadata = [r, g, b];
            this.files[f_index].rgb = [];
        },

        duplicate_file(file_index) {
            let orig_file = this.files[file_index];
            let duplicate_file = {
                "file_id": orig_file.file_id + 1,
                "metadata": JSON.parse(JSON.stringify(orig_file.metadata)),
                "rgb": JSON.parse(JSON.stringify(orig_file.rgb)),
                "format": JSON.parse(JSON.stringify(orig_file.format)),
                "name": orig_file.name.split(".")[0] + "_(" + orig_file.file_id + 1 + ")" + "." + orig_file.name.split(".")[1],
                "file_origin": JSON.parse(JSON.stringify(orig_file.file_origin))
            };

            this.create_file(duplicate_file);
            let new_file = this.files.pop()
            // duplicate_file.file_id += 1;
            this.files.splice(file_index + 1, 0, duplicate_file);
            this.files.forEach((file, i) => {
                if (i > file_index + 1) file.file_id += 1;
            });
            this.files.forEach((file, i) => {
                if (i > file_index) {
                    file.metadata.forEach(band => band.layer_id += 1);
                }
            });
        },
        async submit() {
            let processed_files = this.files.map(file => {
                return {
                    filename: file.name,
                    format: file.format === "geoTIFF" ? "tif" : file.format,
                    file_origin: file.file_origin,
                    bands: file.metadata.map(band => {
                        return {
                            band_num: band.band_num,
                            origin: band.origin,
                            description: band.description
                        }
                    })
                }
            });
            await API.post("/user/storage/image_editor/", processed_files);
            this.$store.commit("veg_store/SWITCH_ACTIVE_VEG_MODAL");
            await this.$store.dispatch("fetchUserStorage");

        },
        async reset() {
            this.files = [];
            let received_files = await this.fetchFilesMetadata(this.$store.getters['veg_store/get_selected_files']);
            received_files.forEach(file => {
                this.create_file(file);
            });
        },
        async rerender_files() {
            let files = this.files;
            this.files = [];
            console.log("this files>" + this.files + "<");
            for (let i = 0; i < files.length; i++) {
                files[i].sortable = null;
                console.log("FILE OBJ>" + JSON.stringify(files[i]))
                await this.create_file(files[i]);
            }
        },
        async delete_file(file_index) {
            this.files.splice(file_index, 1);
        },
        delete_band(file_index, layer_index) {
            if (this.files[file_index].metadata.length === 1) {
                this.delete_file(file_index);
                return;
            }
            this.files[file_index].metadata.splice(layer_index, 1);
        },
        async create_file(obj = null) {
            if (obj == null) {
                obj = {
                    file_id: this.files.length,
                    name: "",
                    metadata: [],
                    rgb: [],
                    format: "geoTIFF",
                    file_origin: "new"
                };
            }
            obj.file_origin = obj.file_origin ? obj.file_origin : obj.name;
            obj["format"] = "geoTIFF";
            obj.metadata.forEach(band => {
                band["origin"] = obj.file_origin;
                band["band_num"] = band.band_num
            });
            this.files.push(obj);
            let file_index = this.files.length - 1;

            let dom_elem = null;
            while (dom_elem == null) {
                await new Promise(r => setTimeout(r, 300));
                dom_elem = this.$refs["file_" + file_index][0];
            }

            const our_this = this;
            our_this.files[file_index].sortable = Sortable.create(dom_elem, {
                group: 'shared',
                animation: 150,
                store: {
                    set: async function (sortable) {
                        let file_index = -1;

                        for (let i = 0; i < our_this.files.length; i++) {
                            if (our_this.files[i].sortable === sortable) {
                                file_index = i;
                            }
                        }
                        let addition = 0;

                        if (our_this.files[file_index].metadata.length > sortable.toArray().length) {
                            return;
                        }

                        if (our_this.files[file_index].metadata.length === sortable.toArray().length) {
                            let layer_ids = our_this.files[file_index].metadata.map(layer => layer.layer_id);
                            our_this.files[file_index].metadata = sortable.toArray().map(index => our_this.files[file_index].metadata[layer_ids.indexOf(parseInt(index))]);
                        }

                        // ===========
                        if (our_this.files[file_index].metadata.length < sortable.toArray().length) {
                            let layers = our_this.files[file_index].metadata.map(layer_obj => layer_obj.layer_id);
                            sortable.toArray().forEach(layer_id => {
                                if (!layers.includes(parseInt(layer_id))) {
                                    addition = parseInt(layer_id);
                                }
                            });
                            let from_index = 0;
                            let from_file_i = 0;
                            for (let i = 0; i < our_this.files.length; i++) {
                                const reducer = (accumulator, current_value) => accumulator || current_value;
                                if (our_this.files[i].metadata.map(layer => layer.layer_id === addition).reduce(reducer, false)) {
                                    our_this.files[i].metadata.forEach((layer, j) => {
                                        if (layer.layer_id === addition) {
                                            from_index = j;
                                            from_file_i = i;
                                            addition = layer;
                                        }
                                    }); // now addition becomes obj of the layer to be taken
                                }
                            }
                            our_this.files[from_file_i].metadata.splice(from_index, 1);

                            // if (our_this.files[from_file_i].metadata.length === 1) {
                            //     // await new Promise(r => setTimeout(r, 100));
                            //     await our_this.delete_file(from_file_i);
                            //     file_index -= 1;
                            // } else {
                            //     our_this.files[from_file_i].metadata.splice(from_index, 1);
                            // }
                            // let new_meta = our_this.files[file_index].metadata;
                            // new_meta.splice(sortable.toArray().map(index_str => parseInt(index_str)).indexOf(addition.layer_id), 0, addition);
                            // our_this.files[file_index].metadata = [];
                            // our_this.files[file_index].metadata = new_meta;

                            our_this.files[file_index].metadata.splice(sortable.toArray().map(index_str => parseInt(index_str)).indexOf(addition.layer_id), 0, addition);
                            // await our_this.rerender_files();
                        } // ============
                    }
                }
            });
        },
    }
    ,
    components: {
        Modal, Tile
    }
    ,
    async mounted() {
        await this.reset();
        // let received_files = await this.fetchFilesMetadata(this.$store.getters['veg_store/get_selected_files']);
        // received_files.forEach(file => {
        //     console.log("FILE >>" + JSON.stringify(file) + "<<\n");
        //     this.create_file(file);
        // });
    }
}
</script>


<style scoped lang="scss">

</style>