<template>
    <div class="accordion-header" @click="listDetails">
        <label class="accordion-hdr-text">{{ jobObj.name }}</label>
        <i class="arrow" :style="rotateStyle"></i>
    </div>
    <transition>
        <div class="accordion-content" v-show="active">
            <p><strong>Job name: </strong> {{ jobObj.name }} </p>
            <p><strong>Started: </strong> {{ jobObj.created_on }} </p>
            <p><strong>Progress: </strong>{{ jobObj.progress }} </p>
            <br>
            <br>
            <button
                v-if="($store.getters.getUserJobInfo[jobInfoIndex] && $store.getters.getUserJobInfo[jobInfoIndex].result) && detailed_view === false"
                class="jobDetailsBtn"
                @click="displayDetails">
                Display details
            </button>

            <button
                class="jobDetailsBtn"
                @click="jumpToDir(jobObj)">
                Open directory
            </button>

            <div class="accordion-content"
                 v-if="detailed_view">
                <hr>
                <button class="closeViewBtn" style="background: none; border: none; position: relative; left: 98%"
                        @click="closeDetails">
                    <svg style="height: 2em" aria-hidden="true" focusable="false" data-prefix="fas"
                         data-icon="times-circle"
                         class="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 512 512">
                        <path fill="currentColor"
                              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
                    </svg>
                </button>
                <p class="title is-3">Execution details:</p>
                <br><br>
                <p class="title is-4">Elapsed time:</p>
                <p>{{ $store.getters.getUserJobInfo[jobInfoIndex]["result"]["time"] }}</p>
                <br>
                <hr>
                <p v-if="$store.getters.getUserJobInfo[jobInfoIndex]['result']['request']" class="title is-4">Request
                    parameters:
                    (to be further specialized)</p>
                <p v-if="$store.getters.getUserJobInfo[jobInfoIndex]['result']['request']">
                    {{ $store.getters.getUserJobInfo[jobInfoIndex]["result"]["request"] }}</p>
                <br>
                <hr>
                <p class="title is-4" v-if="$store.getters.getUserJobInfo[jobInfoIndex]['result']['notes']">Notes for
                    user: (to be
                    further specialized)</p>
                <p v-if="$store.getters.getUserJobInfo[jobInfoIndex]['result']['notes']">
                    {{ $store.getters.getUserJobInfo[jobInfoIndex]["result"]["notes"] }}</p>
                <br>
                <p class="title is-4"
                   v-if="$store.getters.getUserJobInfo[jobInfoIndex]['errors'] && $store.getters.getUserJobInfo[jobInfoIndex]['errors'].length > 2">
                    Errors:</p>
                <p v-if="$store.getters.getUserJobInfo[jobInfoIndex]['errors']">
                    {{ $store.getters.getUserJobInfo[jobInfoIndex]["result"]["errors"] }}</p>
                <hr>

            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        id: Number,
    },
    data() {
        return {
            jobInfoIndex: 0,
            jobObj: null,
            active: true,
            deg: -135,
            detailed_view: false
        }
    },

    methods: {
        jumpToDir(jobObj) {
            this.$store.commit('UPDATE_CURRENT_DIRECTORY', jobObj.result_dirpath);
            this.$store.dispatch('fetchUserStorage');
            this.$store.dispatch('fetchDirContent', jobObj.result_dirpath);
            this.$store.commit('CHANGE_DISPLAYED_VIEW', 'files');
        },
        listDetails() {
            if (this.active)
                this.deg = 45;
            else
                this.deg = -135;
            this.active = !this.active;
        },
        async displayDetails() {
            this.detailed_view = true;
        },
        closeDetails() {
            // this.$store.commit('DELETE_USER_JOB_INFO', this.jobInfoIndex);
            this.detailed_view = false;
        }
    },
    computed: {
        rotateStyle() {
            return {transform: 'rotate(' + this.deg + 'deg)'}
        }
    },
    async created() {
        this.jobObj = this.$store.getters.userJobs[this.id];
        await this.$store.dispatch('fetchJobInfo', this.id);
        this.jobInfoIndex = this.$store.getters.getUserJobInfo.length - 1;
    },

}
</script>

<style scoped>
.accordion-header {
    display: flex;
    align-items: center;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    background: #7b525a;
    padding: 10px;
    cursor: pointer;
}


.accordion-hdr-text {
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    color: #fffcf2;
}


.accordion-content {
    background-color: white;
    border-radius: 0px 0px 5px 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.arrow {
    margin-left: auto;
    cursor: pointer;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transition: all .4s linear;
}

.closeViewBtn {
    position: relative;
    left: 97%;
    background: none;
    border: none;
    color: #7b525a;
}

.closeViewBtn:hover {
    color: #eb4e6a;
}


.jobDetailsBtn {
    height: 3em;
    width: 8em;
    font-size: 1em;
    margin-top: 1em;
    border: none;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}


.jobDetailsBtn:hover {
    background: #7b525a;
}

</style>