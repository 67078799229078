<template xmlns="http://www.w3.org/1999/html">

    <div>
        <div class="download_bookmark" @click="downloadsActive=true" v-if="!downloadsActive">
            <svg style="width: 2rem" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download"
                 class="svg-inline--fa fa-download fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 512 512">
                <path fill="#eb4e6a"
                      d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"/>
            </svg>
        </div>
        <div class="downloadPanel" v-if="downloadsActive">
            <div class="download_item"
                 v-for="[name, details] in $store.getters.downloads">
                <p style="color:white;">{{ name }}</p>
                <hr>
                <div :class="status(details.progress) === 'Done' ? 'progress_bar_done': ''" class="progress_bar"
                     :style="{ 'max-width':  details.progress + '%' }"></div>
                <p style="color:white;">STATUS:
                    <span :class="{blinking : status(details.progress) === 'Preparing download'}">
                    {{ " " + status(details.progress) + " " + details.progress + "%" }}
                    </span>
                </p>
                <p v-if="details.note" style="color:rgba(255,255,255,0.68); font-size: 0.7rem;">{{ details.note }}</p>

                <button class="closeViewBtn" @click="$store.commit('DELETE_DOWNLOAD', name)">
                    <svg style="height: 2em" aria-hidden="true" focusable="false" data-prefix="fas"
                         data-icon="times-circle"
                         class="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 512 512">
                        <path fill="#eb4e6a"
                              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
                    </svg>
                </button>
            </div>
            <button class="deselectBtn" @click="$store.commit('EMPTY_DOWNLOADS'); downloadsActive=false">Close</button>
            <button class="deselectBtn" @click="downloadsActive=false">Hide</button>

        </div>

        <!--        <progress class="progress is-medium" :class="{'is-danger': $store.getters.spaceUsed > 85}"-->
        <!--                  v-bind:value="value" max="100" v-for="[key, value] in $store.getters.downloads">-->
        <!--        </progress>-->
    </div>
</template>

<script>
export default {
    name: "DownloadPanel",
    data() {
        return {
            downloadsActive: false
        }
    },
    methods: {
        status(progress) {
            if (progress === 0) {
                return "Preparing download";
            } else if (progress < 99) {
                return "Downloading";
            }
            return "Done";

        }
    }
}
</script>

<style scoped>

.download_item {
    overflow: auto;
    padding: 1rem 0.5rem;
    background: #5e5757;
    border-radius: 1rem;
    margin: 0.5rem 0 0.5rem 0;
    max-width: 100%;
}

.progress_bar {
    margin: 0.5rem 15%;
    height: 1rem;
    background: #eb4e6a;
    border-radius: 1rem;
}


.progress_bar_done {
    background: #8eb72a;
}

.download_bookmark {
    z-index: 100;
    display: flex;
    justify-content: center;
    text-align: center;
    height: 4rem;
    width: 4rem;
    border-radius: 1.5em 0 0 1.5em;
    background: rgb(61 57 57);
    position: fixed;
    top: 10%;
    right: 0;
}

.downloadPanel {
    overflow-y: auto;
    padding: 1rem;
    z-index: 100;
    border-radius: 1.5em 0 0 1.5em;
    text-align: center;
    height: 90%;
    width: 20%;
    background: rgb(61 57 57);
    position: fixed;
    top: 5%;
    right: 0;
}

.closeViewBtn {
    float: right;
    position: relative;
    background: none;
    border: none;
    color: #eb4e6a;
}

.closeViewBtn:hover {
    color: #7b525a;
}

.deselectBtn {
    margin: 1rem;
    width: 6em;
    height: 3em;
    border: none;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}

.deselectBtn:hover {
    background: #7b525a;

}

.blinking {
    animation: blinker 4s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
</style>