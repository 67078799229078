<template>
    
    
    
    <!-- LEGACY COMPONENT. WILL BE REMOVED !!! -->

    
    
    <!-- TODO: MOVE TO GRID, DEL BULMA-->
    <!--    <section class="basic-grid">-->
    <!--        <div class="card card-tall">1</div>-->
    <!--        <div class="card">2</div>-->
    <!--        <div class="card">3</div>-->
    <!--        <div class="card">4</div>-->
    <!--        <div class="card">5</div>-->
    <!--        <div class="card">6</div>-->
    <!--        <div class="card">7</div>-->
    <!--        <div class="card card-wide">8</div>-->
    <!--        <div class="card">9</div>-->
    <!--        <div class="card">10</div>-->
    <!--        <div class="card">11</div>-->
    <!--        <div class="card">12</div>-->
    <!--    </section>-->

    
        <div style="display: flex; justify-content: center" v-if="displayedView === 'home'">

            <LogInComponent v-if="!$store.getters.loggedIn"/>
            <LoggedInComponent v-if="$store.getters.loggedIn"/>

        </div>

        <FileView v-if="displayedView === 'files'"></FileView>
        <LayerView v-if="displayedView === 'layers'"></LayerView>
        <JobView v-if="displayedView === 'jobs'"></JobView>
        <LutView v-if="displayedView === 'luts'"></LutView>

        <!--        <div class="column is-half" v-if="$store.getters.imageEditorActive">-->
        <!--            <Tile>-->
        <!--                <template v-slot:body>-->
        <!--                    <ImageEditor></ImageEditor>-->
        <!--                </template>-->
        <!--            </Tile>-->
        <!--        </div>-->
        <!--        <Vegetation_modal v-if="$store.getters['veg_store/get_is_active_veg_modal']"/>-->
        <!--        <LutModal v-if="$store.getters['veg_store/get_is_active_lut_modal']"/>-->

    
    <div class="nav-wrap" v-if="$store.getters.loggedIn">
        <!--            home-->
        <div class="navbar-btm">
            <a class="nav-link-main" @click="displayedView='home'">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home"
                     class="svg-inline--fa fa-home fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 576 512">
                    <path fill="currentColor"
                          d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"/>
                </svg>
            </a>
            <!--            files-->
            <a class="nav-link-main" @click="displayedView='files'">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="folder"
                     class="svg-inline--fa fa-folder fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512">
                    <path fill="currentColor"
                          d="M464 128H272l-64-64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48z"/>
                </svg>
            </a>
            <!--            layers-->
            <a class="nav-link-main" @click="displayedView='layers'">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="layer-group"
                     class="svg-inline--fa fa-layer-group fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512">
                    <path fill="currentColor"
                          d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"/>
                </svg>
            </a>
            <!--            jobs-->

            <a class="nav-link-main" @click="displayedView='jobs'">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="briefcase"
                     class="svg-inline--fa fa-briefcase fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512">
                    <path fill="currentColor"
                          d="M320 336c0 8.84-7.16 16-16 16h-96c-8.84 0-16-7.16-16-16v-48H0v144c0 25.6 22.4 48 48 48h416c25.6 0 48-22.4 48-48V288H320v48zm144-208h-80V80c0-25.6-22.4-48-48-48H176c-25.6 0-48 22.4-48 48v48H48c-25.6 0-48 22.4-48 48v80h512v-80c0-25.6-22.4-48-48-48zm-144 0H192V96h128v32z"/>
                </svg>
            </a>
            <!--            lut-->
            <a class="nav-link-main" @click="displayedView='luts'">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="database"
                     class="svg-inline--fa fa-database fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 448 512">
                    <path fill="currentColor"
                          d="M448 73.143v45.714C448 159.143 347.667 192 224 192S0 159.143 0 118.857V73.143C0 32.857 100.333 0 224 0s224 32.857 224 73.143zM448 176v102.857C448 319.143 347.667 352 224 352S0 319.143 0 278.857V176c48.125 33.143 136.208 48.572 224 48.572S399.874 209.143 448 176zm0 160v102.857C448 479.143 347.667 512 224 512S0 479.143 0 438.857V336c48.125 33.143 136.208 48.572 224 48.572S399.874 369.143 448 336z"/>
                </svg>
            </a>
            <!--            <a href="#vparam" class="nav-link"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="pagelines" class="svg-inline&#45;&#45;fa fa-pagelines fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M384 312.7c-55.1 136.7-187.1 54-187.1 54-40.5 81.8-107.4 134.4-184.6 134.7-16.1 0-16.6-24.4 0-24.4 64.4-.3 120.5-42.7 157.2-110.1-41.1 15.9-118.6 27.9-161.6-82.2 109-44.9 159.1 11.2 178.3 45.5 9.9-24.4 17-50.9 21.6-79.7 0 0-139.7 21.9-149.5-98.1 119.1-47.9 152.6 76.7 152.6 76.7 1.6-16.7 3.3-52.6 3.3-53.4 0 0-106.3-73.7-38.1-165.2 124.6 43 61.4 162.4 61.4 162.4.5 1.6.5 23.8 0 33.4 0 0 45.2-89 136.4-57.5-4.2 134-141.9 106.4-141.9 106.4-4.4 27.4-11.2 53.4-20 77.5 0 0 83-91.8 172-20z"/></svg></a>-->
            <!--            map-->

            <a @click="goToMap()" class="nav-link-main">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map"
                     class="svg-inline--fa fa-map fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 576 512">
                    <path fill="currentColor"
                          d="M0 117.66v346.32c0 11.32 11.43 19.06 21.94 14.86L160 416V32L20.12 87.95A32.006 32.006 0 0 0 0 117.66zM192 416l192 64V96L192 32v384zM554.06 33.16L416 96v384l139.88-55.95A31.996 31.996 0 0 0 576 394.34V48.02c0-11.32-11.43-19.06-21.94-14.86z"/>
                </svg>
            </a>
            <!--          settings-->
            <a class="nav-link-main" @click="displayedView='settings'">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cog"
                     class="svg-inline--fa fa-cog fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512">
                    <path fill="currentColor"
                          d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"/>
                </svg>
            </a>
            <!--            admin-->
            <a v-show="is_admin" :href="adminUrl" class="nav-link-main">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-lock"
                     class="svg-inline--fa fa-user-lock fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 640 512">
                    <path fill="currentColor"
                          d="M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm96 64a63.08 63.08 0 0 1 8.1-30.5c-4.8-.5-9.5-1.5-14.5-1.5h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h280.9a63.54 63.54 0 0 1-8.9-32zm288-32h-32v-80a80 80 0 0 0-160 0v80h-32a32 32 0 0 0-32 32v160a32 32 0 0 0 32 32h224a32 32 0 0 0 32-32V320a32 32 0 0 0-32-32zM496 432a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm32-144h-64v-80a32 32 0 0 1 64 0z"/>
                </svg>
            </a>

        </div>
    </div>
</template>

<script>
import router from "@/router";

// Components
import LogInComponent from "@/components/user_auth/LogInComponent";
import LoggedInComponent from "@/components/user_auth/LoggedInComponent";
import TileMini from "@/components/tiles/TileMini";
import Tile from "@/components/tiles/Tile";
import Vegetation_modal from "@/components/Vegetation_modal";
import LutModal from "@/components/LutModal";
import Nested_sortable from "@/components/tiles/NestedSortable";
import OuterBox from "@/components/tiles/OuterBox";
import ImageEditor from "@/components/ImageEditor";
import FileView from "@/components/system_views/FileView";
import LayerView from "@/components/system_views/LayerView";
import JobView from "@/components/system_views/JobView";
import LutView from "@/components/system_views/LutView";
import {API} from "@/assets/js/axio-api";

export default {
    data() {
        return {
            displayedView: "home",
        };
    },
    computed: {
        
        is_admin() {
            return this.$store.getters.userDetails ? this.$store.getters.userDetails["is_admin"] : false;
        },
    },
    components: {
        LutView,
        JobView,
        LayerView,
        ImageEditor,
        OuterBox,
        Nested_sortable,
        Vegetation_modal,
        LutModal,
        Tile,
        FileView,
        LogInComponent,
        LoggedInComponent,
        TileMini
    }
    ,
    methods: {
        goToMap() {
            router.push("map");
        },
    },
    

}
;
</script>

<style lang="scss">
/* Place the navbar at the bottom of the page, and make it stick */


tr {
    cursor: pointer;
}

.backButton {
    float: right;
    height: 25px;
}

#mapHover:hover {
    box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
    inset -6px -6px 10px 0 rgba(255, 255, 255, 0.5);
}


.table .is-scrollable tbody {
    overflow-y: scroll;
    width: auto;
    position: absolute;
}

// prototype
.basic-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-auto-rows: 240px;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #353535;
    font-size: 3rem;
    color: #fff;
    box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem, rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    transition: all 500ms;
    overflow: hidden;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media screen and (min-width: 600px) {
    .card-tall {
        grid-row: span 2 / auto; // start / end   1 / 2 should span across two rows
    }

    .card-wide {
        grid-column: span 2 / auto;
    }
}

.card:hover {
    box-shadow: rgba(2, 8, 20, 0.1) 0px 0.35em 1.175em, rgba(2, 8, 20, 0.08) 0px 0.175em 0.5em;
    transform: translateY(-3px) scale(1.1);
}

</style>
