<template>
    <div class="action-menu">
        <img v-if="hideButton" class="icons arrow" src="@/assets/imgs/up-arrow.svg"
             :class="{'rotated': isOpened}" @click="toggle" alt="V"/>
        <img v-if="addLayer" class="icons" src="@/assets/imgs/add-layer.svg" @click="customLayer" alt="+"/>
        <p class="last-item"><strong>{{ name }}</strong></p>
    </div>
</template>

<script>
export default {
    name: "LayerHeaderMenu",
    props: {
        "name": String,
        "addLayer": Boolean,
        "hideButton": Boolean
    },
    data() {
        return {
            isOpened: true,
        }
    },

    methods: {

        // EVENTS TO BE HANDLED BY PARENT
        toggle() {
            // arrow clicked
            this.isOpened = !this.isOpened;
            this.$emit("arrow-click", this.isOpened);
        },

        customLayer() {
            // custom layer clicked
            this.$emit("add-layer-click");
        }

    }
}
</script>

<style scoped>

/* Header styles */
.action-menu {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around;
    flex-grow: 1;
    gap: 50px;
    width: 100%;
    height: 30px;
    background-color: rgba(255,235,235,.8);
    padding-left: 12px;
    padding-right: 12px;
}

.icons {
    height: 20px;
    cursor: pointer;
    transform: rotate(0deg);
    transition: .5s transform ease;
}

.rotated {
    transform: rotate(180deg);
    transition: .5s transform ease;
}

.last-item {
    margin-right: auto;
}


</style>