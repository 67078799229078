<template>
    <div class="accordion-header" @click="listDetails">
        <label class="accordion-hdr-text">{{ layerObj.name }}</label>
        <i class="arrow" :style="rotateStyle"></i>
    </div>
    <transition>
        <div class="accordion-content" v-show="active">
            <p><strong>File name: </strong> {{ layerObj.storeName }} </p>
            <p><strong>File type: </strong> {{ layerObj.type }} </p>
            <p><strong>Bounding box: </strong></p>
            <p>&ensp; <strong>CRS:</strong> {{ layerObj.latLonBoundingBox.crs }}</p>
            <p>&ensp; <strong>maxx:</strong> {{ layerObj.latLonBoundingBox.maxx }} </p>
            <p>&ensp; <strong>maxy:</strong> {{ layerObj.latLonBoundingBox.maxy }} </p>
            <p>&ensp; <strong>minx:</strong> {{ layerObj.latLonBoundingBox.minx }} </p>
            <p>&ensp; <strong>miny:</strong> {{ layerObj.latLonBoundingBox.miny }} </p>

            <!--Controls-->
            <div>
                <button style="display: inline-block" class="renameBtn"
                        v-show="renameActive===false && layerObj.type !== 'WMS'"
                        @click="renameActive=true;">
                    Rename
                </button>
                <button style="display: inline-block" class="renameBtn"
                        v-show="editAttsActive===false && layerObj.type !== 'WMS'"
                        @click="editAttsActive=true; prefillAttributions()">Edit attributions
                </button>
                <button style="display: inline-block" class="renameBtn"
                        v-show="editingAccessRights===false && layerObj.type !== 'WMS'"
                        @click="editingAccessRights=true;">Edit access rights
                </button>
            </div>

            <!--            Rename-->
            <div style=" width:100%; text-align: left; padding: 1em" v-if="renameActive">
                <button class="closeRenameBtn" style="float: right"
                        @click="renameActive=false; contextMessage=''">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas"
                         style="height: 1.5rem; float: right"
                         data-icon="times-circle"
                         class="close_svg svg-inline--fa fa-times-circle fa-w-16" role="img"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 512 512">
                        <path fill="currentColor"
                              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
                    </svg>
                </button>
                <p style="font-size: 1.5rem; font-weight: 600"><b>Rename</b></p>
                <hr>
                <p><b>From: </b>{{ layerObj.name }}</p>
                <input class="text_input_field"
                       v-model="newName"
                       placeholder="To"
                       type="text">
                <button
                    @click="renameLayer"
                    class="renameBtn"> {{ 'Rename' }}
                </button>
            </div>

            <!--            Attributions-->
            <div style=" width:100%; text-align: left; padding: 1em" v-if="editAttsActive">
                <button class="closeRenameBtn" style="float: right"
                        @click="editAttsActive=false;contextMessage=''">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas"
                         style="height: 1.5rem; float: right"
                         data-icon="times-circle"
                         class="close_svg svg-inline--fa fa-times-circle fa-w-16" role="img"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 512 512">
                        <path fill="currentColor"
                              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
                    </svg>
                </button>
                <p style="font-size: 1.5rem; font-weight: 600"><b>Attributions</b></p>
                <hr>
                <input class="text_input_field" style="width: 28%"
                       v-model="attributions.title"
                       placeholder="Attribution text"
                       type="url">
                <input class="text_input_field" style="width: 28%"
                       v-model="attributions.href"
                       placeholder="Attribution link"
                       type="url">
                <input class="text_input_field" style="width: 28%"
                       v-model="attributions.logoURL"
                       placeholder="Logo URL"
                       type="url">

                <button
                    @click="saveAttributions"
                    class="renameBtn"> Save
                </button>
            </div>

            <!--            Access rights-->
            <div style=" width:100%; text-align: left; padding: 1em" v-if="editingAccessRights">
                <button class="closeRenameBtn" style="float: right"
                        @click="editingAccessRights=false;contextMessage=''">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas"
                         style="height: 1.5rem; float: right"
                         data-icon="times-circle"
                         class="close_svg svg-inline--fa fa-times-circle fa-w-16" role="img"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 512 512">
                        <path fill="currentColor"
                              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
                    </svg>
                </button>
                <p style="font-size: 1.5rem; font-weight: 600"><b>Layer access rights</b></p>

                <!--                <hr>-->
                <!--                <p style="font-size: 1.2rem; font-weight: 500"><b>Shared with</b></p>-->
                <button
                    style="background: #b4b4b4; margin-bottom: 0.1rem; margin-left: 0"
                    @click="sharedWith='User_Group'"
                    class="renameBtn"> All
                </button>
                <button style="background: #b4b4b4; margin-bottom: 0.1rem"
                        @click="sharedWith='User'"
                        class="renameBtn"> Users
                </button>
                <button style="background: #b4b4b4; margin-bottom: 0.1rem"
                        @click="sharedWith='Group'"
                        class="renameBtn"> Groups
                </button>
                <hr style="margin: 1rem 0">
                <!--                Sharing with-->
                <table class="table is-hoverable is-fullwidth"
                       style="text-align: left; overflow-y: auto border-radius: 0.8em;">
                    <tbody>
                    <tr
                        v-for="(item, index) in sharedWithIdentities"
                        :key="index">
                        <span v-if="item && sharedWith.includes(item.identity)"><td style="width: 100%"
                                                                                    class="table_cell">{{
                                item.name
                            }}</td>
                        <td style="width: 100%" class="table_cell">{{ item.identity }}</td>
                        <td style="width: 100%; color: #f43b3b; font-weight: bold; font-size: 1.2rem"
                            @click="stopSharingWith(item)">X</td></span>

                    </tr>
                    </tbody>
                </table>
                <!--                 Share with new entities-->
                <p style="font-size: 1.5rem; font-weight: 600"><b>Grant sharing rights to</b></p>
                <hr style="margin: 1rem 0">
                <table class="table is-hoverable is-fullwidth"
                       style="text-align: left; overflow-y: auto border-radius: 0.8em;">
                    <tbody>
                    <tr
                        v-for="(item, index) in grantSharingTo"
                        :key="index">
                        <td>
                            <select v-model="item.identity">
                              <option disabled value="">Entity type</option>
                              <option>User</option>
                              <option>Group</option>
                            </select>
                        </td>
                        <td v-if="item.identity === 'User'">
                            <input type="message" v-model="item.name" :placeholder="[item.identity === 'User' ? 'User email': 'Group name']">
                        </td>
                        <td v-if="item.identity === 'Group'">
                            <select v-model="item.name">
                              <option disabled value="">Available groups</option>
                              <option v-for="group in $store.getters.userGroups">{{group.group_id}}</option>
                            </select>
                        </td>
                        <td style="width: 100%; color: #f43b3b; font-weight: bold; font-size: 1.2rem"
                            @click="grantSharingTo.splice(grantSharingTo.indexOf(item), 1);">X</td>
                    </tr>
                    </tbody>
                </table>
                <!-- start sharing with -->
                <button style="float: right" class="renameBtn"
                        @click="grantSharingTo.push({identity: 'User', name: ''})">
                        Add
                </button>
                <br>
                <p style="font-size: 1.5rem; font-weight: 600"><b>Revoke sharing rights for</b></p>
                <hr style="margin: 1rem 0">
<!--                Revoke sharing rights to-->
                <table class="table is-hoverable is-fullwidth"
                       style="text-align: left; overflow-y: auto border-radius: 0.8em;"
                       v-if="revokeSharingWith.length">
                    <tbody>
                    <tr
                        v-for="(item, index) in revokeSharingWith"
                        :key="index">
                        <span v-if="item && sharedWith.includes(item.identity)"><td style="width: 100%"
                                                                                    class="table_cell">{{
                                item.name
                            }}</td>
                        <td style="width: 100%" class="table_cell">{{ item.identity }}</td>
                        <td style="width: 100%; color: #f43b3b; font-weight: bold; font-size: 1.2rem"
                            @click="undoAccessRightsRevocation(item)">X</td></span>

                    </tr>
                    </tbody>
                </table>

                <input class="access_rights_checkbox" type="checkbox" :id="authenticated_share"
                       :value="true"
                       v-model="shareWithAuthenticated">
                <label :for="authenticated_share">Share with authenticated users withing the portal</label>
                <br>
                <input class="access_rights_checkbox" type="checkbox" :id="public_share"
                       :value="true"
                       v-model="sharePublic">
                <label :for="public_share">Make public</label>
                <button style="float: right"
                        @click="saveAccessRights"
                        class="renameBtn"> Save
                </button>
            </div>
            <p class="has-text-danger" v-show="contextMessage">{{ contextMessage }}</p>

            <!--            <p style="margin: 1rem 0 0.5rem 0"><strong>Source for WMS requests</strong></p>-->
            <!--            <p><strong>Url:</strong> https://envision.cerit-sc.cz/geoserver/wms <strong><a-->
            <!--                @click="copyToClipboard('https://envision.cerit-sc.cz/geoserver/wms')"> Copy</a></strong></p>-->
            <!--            <p><strong>Layer:</strong> {{ layerObj.workspace + ":" + layerObj.name }} <strong><a-->
            <!--                @click="copyToClipboard(layerObj.workspace + ':' + layerObj.name)"> Copy</a></strong></p>-->
        </div>
    </transition>
</template>

<script>
import WMSConnections from "@/components/system_views/WMSConnections";

export default {
    components: {WMSConnections},
    props: {
        id: Number,
    },
    data() {
        return {
            layerObj: null,
            active: true,
            deg: -135,
            renameActive: false,
            newName: "",
            contextMessage: "",
            editAttsActive: false,
            attributions: {title: "", href: "", logoURL: ""},
            editingAccessRights: false,
            sharePublic: false, // no credentials neccessary to access layer
            shareWithAuthenticated: false, // only with registered members of portal service
            sharedWith: "User_Group",
            sharedWithIdentities: [], // already sharing with
            revokeSharingWith: [], // sharing with these identities will be revoked when saved (allows for undo potentially),
            grantSharingTo: [] // when saved, the entities (users and groups) will be granted sharing rights to the layer
        }
    },
    watch: {
        sharePublic: function (newVal, _) {
            // it makes sense only for one of these to be active, not both at the same time...
            if (newVal === true) {
                this.shareWithAuthenticated = false;
            }
        },
        shareWithAuthenticated: function (newVal, _) {
            if (newVal === true) {
                this.sharePublic = false;
            }
        }
    },

    methods: {
        async prefillAttributions() {
            this.layerObj = await this.$store.getters.userLayers[this.id];
            this.attributions = {
                title: this.layerObj.attribution.title,
                href: this.layerObj.attribution.href,
                logoURL: this.layerObj.attribution.logoURL
            };
        },
        async saveAttributions() {
            this.contextMessage = "";
            let obj = {...this.attributions};
            obj.type = this.layerObj.type;
            obj.layer_name = this.layerObj.name;
            let response = await this.$store.dispatch("saveLayerAttributions", obj);
            if (response) {
                this.editAttsActive = false;
                this.$store.dispatch('fetchUserLayers');
            } else if (obj.type === "WMS") {
                this.contextMessage = "It is not allowed to edit attributions for WMS layers"
            } else {
                this.contextMessage = "Could not save specified attributions configuration";
            }
        },
        copyToClipboard(content) {
            window.navigator.clipboard.writeText(content);
        },
        listDetails() {
            if (this.active)
                this.deg = 45;
            else
                this.deg = -135;
            this.active = !this.active;
        },
        renameLayer() {
            if (!this.newName) {
                this.contextMessage = "Name cannot be empty."
                return;
            }
            this.$store.dispatch('renameLayer', {
                layer_name: this.layerObj.name,
                new_name: this.newName,
                store_name: this.layerObj.storeName,
                type: this.layerObj.type,
            });
            this.contextMessage = "";
            this.newName = "";
            this.renameActive = false;
        },
        stopSharingWith(obj) {
            // stop sharing layer with specified user or group
            // just visually removes them from sharedWithIdentities list and adds them to revokeSharingWith
            // deletion is executed along with other changes when new settings are saved
            let index = this.sharedWithIdentities.indexOf(obj);
            this.sharedWithIdentities.splice(index, 1);
            this.revokeSharingWith.push(obj);
        },
        undoAccessRightsRevocation(obj) {
            // put back to sharedWithIdentities, no revoking takes place when saved
            let index = this.revokeSharingWith.indexOf(obj);
            this.revokeSharingWith.splice(index, 1);
            this.sharedWithIdentities.push(obj);
        },
        async saveAccessRights() {
            // request to execute all changes (save new settings)

            let config = {
                sharePublicly: this.sharePublic,
                shareWithAuthenticated: this.shareWithAuthenticated,
                startSharingWith: this.grantSharingTo,
                revokeSharingWith: this.revokeSharingWith,
                layer_name: this.layerObj.name
            }

            let response = await this.$store.dispatch("saveLayerAccessRights", config);
            if (response) {
                this.editingAccessRights = false;
                this.$store.dispatch('fetchUserLayers');
            } else {
                this.contextMessage = "Could not save specified access rights configuration";
            }
        },
        async fetchGeofenceRules(){
            let rules = await this.$store.dispatch("get_geofence_rules", this.layerObj.name);

            if (!rules){
                this.contextMessage = "Could not fetch access rights for this layer";
            }
            for (let i = 0; i < rules.length; i++) {
                if (rules[i].userName || rules[i].roleName) {
                    let rule = {
                        id: rules[i]["@id"],
                        name: rules[i].userName ? rules[i].userName: rules[i].roleName,
                        identity: rules[i].userName ? "User": "Group",
                    }
                    this.sharedWithIdentities.push(rule);
                }
            }
        }
    },

    computed: {
        rotateStyle() {
            return {transform: 'rotate(' + this.deg + 'deg)'}
        }
    },

    async created() {
        this.layerObj = this.$store.getters.userLayers[this.id];
        await this.fetchGeofenceRules();
    },

}
</script>

<style scoped>
.access_rights_checkbox {
    margin: 0.5rem;
}

.text_input_field {
    border-radius: 0.5em;
    border: none;
    background: #e2e2e2;
    height: 2rem;
    margin: 0.5rem 1rem 0.5rem 0;

}

.accordion-header {
    display: flex;
    align-items: center;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    background: #7b525a;
    padding: 10px;
    cursor: pointer;
}


.accordion-hdr-text {
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    color: #fffcf2;
}


.accordion-content {
    background-color: white;
    border-radius: 0px 0px 5px 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.arrow {
    margin-left: auto;
    cursor: pointer;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transition: all .4s linear;
}

.closeRenameBtn {
    position: relative;
    background: none;
    border: none;
    color: #FF7673FF;
}

.closeRenameBtn:hover {
    color: #7b525a;
}


.renameBtn {
    height: 3em;
    width: auto;
    /*margin-top: 1em;*/
    border: none;
    margin: 10px;
    padding: 5px 10px;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}

.renameBtn:hover {
    background: #7b525a;

}
</style>