<template>
<!--    <div v-if="!$store.getters.loggedIn" class="comp">-->
<!--        <LogInComponent></LogInComponent>-->
<!--    </div>-->

    <div class="wrapper">
        <!-- Router in the future ? -->
        <!--        <div class="custom-shape-divider-top-1633810516">-->
        <!--            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"-->
        <!--                 preserveAspectRatio="none">-->
        <!--                <path-->
        <!--                    d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"-->
        <!--                    class="shape-fill"></path>-->
        <!--            </svg>-->
        <!--        </div>-->

        <!--        <div class="custom-shape-divider-bottom-1633810130">-->
        <!--            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"-->
        <!--                 preserveAspectRatio="none">-->
        <!--                <path-->
        <!--                    d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"-->
        <!--                    class="shape-fill"></path>-->
        <!--            </svg>-->
        <!--        </div>-->
        <DownloadPanel v-if="$store.getters.downloads.size > 0"></DownloadPanel>
        <HomeView v-if="$store.getters.displayedView === 'home'"/>
        <FileView v-if="$store.getters.displayedView === 'files'"/>
        <LayerView v-if="$store.getters.displayedView === 'layers'"/>
        <GroupView v-if="$store.getters.displayedView ==='groups'"></GroupView>
        <JobView v-if="$store.getters.displayedView === 'jobs'"/>
        <LutView v-if="$store.getters.displayedView === 'luts'"/>
        <SettingsView v-if="$store.getters.displayedView === 'settings'"/>
        <div class="padd"></div>
        <NavBar/>


    </div>
</template>

<script>
// @ is an alias to /src
import WidgetsPanel from "@/components/WidgetsPanel.vue";
import FileView from "@/components/system_views/FileView";
import LayerView from "@/components/system_views/LayerView";
import JobView from "@/components/system_views/JobView";
import LutView from "@/components/system_views/LutView";
import HomeView from "@/components/system_views/HomeView";
import NavBar from "@/components/system_views/NavBar";
import DownloadPanel from "@/components/DownloadPanel";
import SettingsView from '@/components/system_views/SettingsView';
import LogInComponent from "@/components/user_auth/LogInComponent";
import GroupView from "@/components/system_views/GroupView";

export default {
    name: "Dashboard",

    data() {
        return {}
    },

    components: {
        GroupView,
        LogInComponent,
        DownloadPanel,
        NavBar,
        HomeView,
        FileView,
        JobView,
        LayerView,
        LutView,
        WidgetsPanel,
        SettingsView
    },

    methods: {

        goToMap() {
            router.push("map");
        },
    },

    computed: {

        adminUrl() {
            return process.env.VUE_APP_BASE_URL + "/admin";
        },
        is_admin() {
            return this.$store.getters.userDetails ? this.$store.getters.userDetails["is_admin"] : false;
        },
    },

    created() {
        // TODO: DO THIS ASYNC -> CHECK Promise.allSettled() (Promise.all fail problem)
        // This call will be deprecated since we store it in local storage and update only on changes and login
        if (this.$store.getters.loggedIn) {
            this.$store.dispatch('fetchUserDetails');
            this.$store.dispatch("fetchAppNews");
            this.$store.dispatch('fetchUserStorage');
            this.$store.dispatch('fetchUserLayers');
            // this.$store.dispatch('fetchUserJobs');
            this.$store.dispatch('fetchStyles');
            // this.$store.dispatch("veg_store/fetch_lut_databases");
        }
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    padding: 25px;
    background-color: var(--bg);
}


.custom-shape-divider-bottom-1633810130 {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1633810130 svg {
    position: relative;
    display: block;
    width: calc(116% + 1.3px);
    height: 300px;
}

.custom-shape-divider-bottom-1633810130 .shape-fill {
    fill: #7B525A;
}

.custom-shape-divider-top-1633810516 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1633810516 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 274px;
}

.custom-shape-divider-top-1633810516 .shape-fill {
    fill: #eb4e6a;
}

.wrapper {
    height: 100%;
}

.padd {
    height: 140px;
}
</style>
