<template>
    <!-- SAMPLE USING BULMA -->
    <li>
        <div class="outer">
            <!--Front face of layer box-->
            <div class="layerDefault">
                <img class="layer-handler" src="@/assets/imgs/layer-handler.svg">
                <div class="basicInfo" @click="toggle">
                    <p><strong>{{ name }}</strong></p>
                </div>
            </div>

            <!-- Additional interactions -->
            <transition name="slide-fade">
                <div class="detailsInfo" v-if="active">
                    <button class="button" v-if="!pinned" @click="pin(false)">Pin left</button>
                    <button class="button" v-if="!pinned" @click="pin(true)">Pin right</button>
                    <button class="button" v-if="pinned" @click="unpin">Unpin</button>
                    <input type="range" id="opacity" name="opacity"
                           min="0" max="100" step="1" v-model="opacity">
                    <label for="opacity">{{ $t("map.opacity") }}</label>
                    <select v-model="style"
                            class="">
                        <option disabled value="">Public
                        </option>
                        <option v-for="pstyle in $store.getters.publicStyles">{{ pstyle.name }}</option>
                        <option disabled value="">Personal
                        </option>
                        <option v-for="prstyle in $store.getters.personalStyles">{{ prstyle.name }}</option>
                    </select>
                    <button style="margin: 0 10px" class="" @click="editStyleModal=true">Edit style</button>
                    <div v-if="colorMapEntries.length !== 0 && colorMapDisplayed" class="layer_details_div">
                        <ColorMap v-if="colorMapEntries.length !== 0"
                                  v-on:colorMapUpdated="updateColorMap"
                                  :init_colormap="{colorMapEntries: colorMapEntries, colorMapMode: colorMapMode}"
                                  :override="overrideColorMap" :locked="true"></ColorMap>
                        <button style="margin: 2rem 0 0 0" @click="colorMapDisplayed=false">Close</button>
                    </div>
                    <button v-else-if="colorMapEntries.length > 0" @click="colorMapDisplayed=true">Edit color map
                    </button>
                    <br>
                    <StyleEditor v-on:closeStyleEditor="editStyleModal=false"
                                 v-on:modified="resetStyle(style)"
                                 v-if="editStyleModal"
                                 :styleName="style"
                                 :styleType="$store.getters.publicStyles.map(style => style.name).includes(style) ? 'public': 'personal'">
                    </StyleEditor>
<!--                    <button @click="wmsDetails=true">WMS request details</button>-->
                    <!--                    <div v-if="wmsDetails" class="layer_details_div" style="padding: 0.5rem">-->
                    <!--                        <p style="margin: 1rem 0 0.5rem 0"><strong>Source for WMS requests</strong></p>-->
                    <!--                        <span style="text-align: left">-->
                    <!--                        <p><strong>Url: </strong><a-->
                    <!--                            @click="copyToClipboard('https://envision.cerit-sc.cz/geoserver/wms')"> Copy</a></p>-->
                    <!--                        <p><strong>Layer: </strong><a-->
                    <!--                            @click="copyToClipboard($store.getters.userLayers[myId].workspace + ':' + name)"> Copy</a></p>-->
                    <!--                        <p><strong>Style:</strong> <a @click="copyToClipboard(style)"> Copy</a></p>-->
                    <!--                        <p><strong>Env: </strong><a @click="copyToClipboard(formatEnv)"> Copy</a></p>-->
                    <!--                            &lt;!&ndash;                            <p><strong>Url:</strong> https://envision.cerit-sc.cz/geoserver/wms</p>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                        <p><strong>Layer:</strong> {{ this.$store.getters.userLayers[this.myId].workspace + ":" + name }}</p>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                        <p><strong>Style:</strong> {{ style }}</p>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                        <p><strong>Env:</strong> {{ formatEnv }}</p>&ndash;&gt;-->
                    <!--                        </span><br>-->
                    <!--                        <button style="margin: 2rem 0 0 0" @click="wmsDetails=false">Close</button>-->

                    <!--                    </div>-->
                </div>
            </transition>
        </div>
    </li>
</template>

<script>
import ColorMap from "@/components/ColorMap";
import StyleEditor from "@/components/StyleEditor";


export default {
    name: "LayerItem",
    components: {ColorMap, StyleEditor},
    props: {
        "name": String,
        "myId": Number,
    },

    data() {
        return {
            active: false,
            opacity: 100,
            pinned: false,
            style: this.$store.getters.userLayers[this.myId].defaultStyle,
            colorMapEntries: [],
            colorMapMode: "ramp",
            overrideColorMap: false,
            editStyleModal: false,
            wmsDetails: false,
            colorMapDisplayed: true
        }
    },
    computed: {
        formatEnv() {
            let env = "";
            for (let i = 0; i < this.colorMapEntries.length; i++) {
                let obj = this.colorMapEntries[i];
                env += "color_" + i + ":" + obj.color + ";quantity_" + i + ":" + obj.quantity + ";opacity_" + i + ":" + obj.opacity + ";"
            }
            return env;
        }
    },

    methods: {
        copyToClipboard(content) {
            if (!content) content = "empty";
            window.navigator.clipboard.writeText(content);
        },
        async resetStyle(style) {
            const delay = ms => new Promise(res => setTimeout(res, ms));
            let temp = style;
            this.style = this.$store.getters.userLayers[this.myId].defaultStyle;
            await delay(500);
            this.style = temp;
        },
        updateColorMap(content) {
            this.colorMapEntries = content.entries;
            this.colorMapMode = content.mode;
            this.updateMapObjStyle(this.style);
        },
        toggle() {
            this.active = !this.active;
            this.$emit('listDetails');
        },

        pin(isRight) {
            this.pinned = true;
            this.$store.dispatch("map_store/pinLayer", {isRight: isRight, myId: this.myId});
            console.log("active comp layers:", this.$store.getters['map_store/getLayerComp'].layers.length);
        },

        unpin() {
            if (!this.pinned)
                return;
            this.$store.dispatch("map_store/unpinLayer", {myId: this.myId});
            this.pinned = false;
        },

        updateMapObjStyle(newStyle) {
            let source = this.$store.getters['map_store/mapLayerObjects'][this.myId].getSource();
            let params = source.getParams();
            params.STYLES = newStyle;
            params.ENV = ""
            for (let i = 0; i < this.colorMapEntries.length; i++) {
                let obj = this.colorMapEntries[i];
                params.ENV += "color_" + i + ":" + obj.color + ";quantity_" + i + ":" + obj.quantity + ";opacity_" + i + ":" + obj.opacity + ";"
            }
            source.updateParams();
            this.$store.getters['map_store/mapLayerObjects'][this.myId].setSource(source);
            this.$store.dispatch("map_store/update_map_layers");
        },


    },

    watch: {
        opacity: function (newValue, _) {
            this.$store.getters['map_store/mapLayerObjects'][this.myId].setOpacity(newValue / 100);
        },
        style: async function (new_style, _) {
            let styleType = "personal";
            for (let i = 0; i < this.$store.getters.publicStyles.length; i++) {
                if (this.$store.getters.publicStyles[i].name === new_style) {
                    styleType = "public";
                    break;
                }
            }

            try {
                let response = await this.$store.dispatch("fetchStyleSld", {
                    styleName: new_style,
                    type: styleType
                });
                let config = response.config;

                // check band boundaries with file details (those are fetched from layer.filePath reference)
                let fileStatistics = await this.$store.dispatch("fetchFileStatistics", this.$store.getters['userLayers'][this.myId].filePath);
                if (fileStatistics) {
                    fileStatistics = fileStatistics.stats;
                    if (config.channelSelection.type === "grayscale" && config.channelSelection.gray > fileStatistics.length) {
                        alert("Band number of grayscale channel for this style does not match layer band count");
                        this.style = this.$store.getters.userLayers[this.myId].defaultStyle;
                        return;
                    }
                    if (config.channelSelection.type === "rgb" && (config.channelSelection.red > fileStatistics.length ||
                        config.channelSelection.green > fileStatistics.length || config.channelSelection.blue > fileStatistics.length)) {
                        alert("Band numbers of rgb channels for this style do not match layer band count");
                        this.style = this.$store.getters.userLayers[this.myId].defaultStyle;
                        return;

                        // roll back to raster
                        // allow edit in the modal (?)
                    }
                    if (config.jiffleStatus.script) {
                        let malformed = [];
                        let regex = /src\[[0-9]+]/g;
                        let num_reg = /[0-9]+/;

                        const found = config.jiffleStatus.script.match(regex);
                        for (let i = 0; i < found.length; i++) {
                            if (parseInt(found[i].match(num_reg)) > fileStatistics.length) {
                                malformed.push(found[i]);
                            }
                        }
                        if (malformed.length > 0) {
                            alert("Band numbers " + JSON.stringify(malformed) + " specified in Jiffle expression for this style do not match layer band count");
                            this.style = this.$store.getters.userLayers[this.myId].defaultStyle;
                            return;
                        }
                    }
                }

                // preload new colormap
                if (config.colorMapEntries) {
                    this.colorMapEntries = config.colorMapEntries
                }
                if (config.colorMapMode) {
                    this.colorMapMode = config.colorMapMode
                }
                this.overrideColorMap = true;
            } catch (err) {
                console.log("Could not fetch style configuration", err);
                this.overrideColorMap = false;
                return;
            }
            this.updateMapObjStyle(new_style);
        },
    }
}
</script>

<style scoped>

li {
    margin: 5px 0 5px 0;
    padding: 5px 5px 5px 5px;
    font-size: .8rem;
    border-style: solid;
    border-radius: 5px;
    border-width: 2px;
    cursor: pointer;
}

/*This is for the layer line that is always visible*/
.layerDefault {
    display: flex;
    align-items: center;
    gap: 7px;
    flex-direction: row;
}

.outer {
    display: flex;
    flex-direction: column;
}

.layer-handler {
    width: 20px;
    height: 30px;
}


.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}

.layer_details_div {
    background: #f1f1f1;
    margin: 10px;
    border-radius: 10px;
    padding: 0 0 1rem 0;
}
</style>