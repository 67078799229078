import "ol/ol.css";
import {API, getAPI} from "../assets/js/axio-api";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import {OSM, Vector as VectorSource} from "ol/source";
import {View} from "ol";
import {Map as MapOl} from "ol";
import {fromLonLat, transform, transformExtent} from "ol/proj";
import {defaults as defaultControls} from "ol/control";
import ZoomSlider from "ol/control/ZoomSlider";
import ScaleLine from "ol/control/ScaleLine";
import MousePosition from "ol/control/MousePosition";
import Rotate from "ol/control/Rotate";
import FullScreen from "ol/control/FullScreen";
import Geolocation from "ol/Geolocation";
import Feature from "ol/Feature";
import Swipe from "ol-ext/control/Swipe";
import {Circle as CircleStyle, Fill, Stroke, Style} from "ol/style";
import Point from "ol/geom/Point";
import {Vector as VectorLayer} from "ol/layer";
import Polygon, {circular} from "ol/geom/Polygon";
import Control from "ol/control/Control";
import Draw, {createBox} from "ol/interaction/Draw";
import GeoJSON from "ol/format/GeoJSON";
import ol_filter_Crop from "ol-ext/filter/Crop";
import czechiaGEOJSON from "@/assets/js/czechiaGEOJSON";
import czechiaCoordinates from "@/assets/js/czechiaGEOJSON";
import MultiPolygon from "ol/geom/MultiPolygon";
import {tile} from "ol/loadingstrategy";
import {olGeoTiff} from "@/assets/js/olGeoTiff";
import noUiSlider from "nouislider/dist/nouislider";
import "nouislider/dist/nouislider.css";
import {forEach} from "ol/geom/flat/segments";
import XYZ from "ol/source/XYZ";
import {Attribution} from 'ol/control';
import {log} from "geotiff/src/logging";
import proj4 from 'proj4';

export const map_store = {
    namespaced: true,
    state: {
        active_layers: [], // indices of layers to be displayed on the map
        mapLayerObjects: [], // layers to be displayed on the map
        selectedLayers: [], // layers selected for editing
        mapObject: null,  // map
        navigationLayer: null,
        // Drawing features
        draw: null, // so we ca remove it later
        drawingLayer: null, // VectorLayer
        analyticView: false, // working with color maps
        source: null,
        fake_layer: null,
        sliderino: null,
        graphView: false,
        layerComparison: null, // pointer to an ol layer comparison control
        baseLayer: "OSM",
        graphData: new Map(),
    },
    mutations: {
        UPDATE_BASE_LAYER_TYPE(state, type) {
            // if (state.baseLayer === "OSM") {
            //     state.baseLayer = "SATELITE";
            //     return;
            // }
            // state.baseLayer = "OSM";
            // When more layers are added, the functionality should be switched to the one bellow
            state.baseLayer = type;
        },
        UPDATE_ACTIVE_LAYERS(state, obj) {
            state.active_layers = obj;
        },
        PUSH_NEW_MAP_LAYER(state, layerObj) {
            state.mapLayerObjects.push(layerObj);
        },
        DELETE_MAP_LAYER(state, layerObj) {
            let index = state.mapLayerObjects.indexOf(layerObj);
            state.mapLayerObjects.splice(index, 1);
        },
        UPDATE_MAP_OBJECT(state, newMapObjectState) {
            state.mapObject = newMapObjectState;
        },
        DELETE_MAP_LAYER_OBJECTS(state) {
            state.mapLayerObjects = [];
        },
        UPDATE_NAVIGATION_LAYER(state, obj) {
            state.navigationLayer = obj;
        },
        UPDATE_DRAW(state, obj) {
            state.draw = obj;
        },
        UPDATE_DRAWING_LAYER(state, obj) {
            state.drawingLayer = obj;
        },
        UPDATE_SOURCE(state, obj) {
            state.source = obj;
        },
        DELETE_DRAWING_LAYER(state) {
            state.mapObject.getLayers().pop(); // drawing features are always on the top
            state.source = new VectorSource({wrapX: false});
            state.drawingLayer = new VectorLayer({
                source: state.source,
            });
            state.mapObject.addLayer(state.drawingLayer);
        },
        PUSH_LAYER_TO_SELECTED(state, index) {
            state.selectedLayers.push(index);
        },
        DELETE_LAYER_FROM_SELECTED(state, index) {
            state.selectedLayers.splice(index, 1);
        },
        CLEAR_SELECTED_LAYERS(state) {
            state.selectedLayers = [];
        },
        REMOVE_SELECTED_LABELS(state, inactiveLayers) {
            state.selectedLayers = state.selectedLayers.filter(layer => !inactiveLayers.includes(layer));
        },
        UPDATE_SLIDER_RANGE(state) {
            let slider = document.getElementById('slider');
            if (slider.noUiSlider === undefined) {
                noUiSlider.create(slider, {
                    start: [0.0, 0.2],
                    tooltips: true,
                    connect: true,
                    range: {
                        'min': state.sliderino.plotOptions.domain[0],
                        'max': state.sliderino.plotOptions.domain[1],
                    }
                });
            } else {
                let min = parseFloat(state.sliderino.plotOptions.domain[0]);
                let max = parseFloat(state.sliderino.plotOptions.domain[1]);
                if (isNaN(min) || isNaN(max) || min === max) return;
                slider.noUiSlider.updateOptions({
                    range: {
                        'min': min,
                        'max': max
                    }
                });
            }
        },

        UPDATE_ANALYTIC_VIEW(state) {
            // Changing it early to show the slider, if true we go to false branch
            if (!state.analyticView && state.selectedLayers.length === 1) {
                state.analyticView = !state.analyticView;

                // toggle color map on selected  layer
                state.fake_layer = state.mapLayerObjects[state.selectedLayers[0]];
                console.log(typeof (state.fake_layer));
                let params = state.fake_layer.get('source').getParams();
                params['FORMAT'] = 'image/geotiff';
                state.fake_layer.get('source').updateParams(params);
                // Activate geotiffjs on layer
                let olgt_s2map = new olGeoTiff(state.fake_layer);
                olgt_s2map.plotOptions.domain = [0, 1];
                olgt_s2map.plotOptions.noDataValue = 0;
                olgt_s2map.plotOptions.palette = 'yiorrd';
                state.fake_layer.getSource().refresh();
                // olgt_s2map.plotOptions.dataFunction = datafunctions['NDVI'];
                state.sliderino = olgt_s2map;

                let slider = document.getElementById('slider');
                if (slider.noUiSlider === undefined) {
                    noUiSlider.create(slider, {
                        start: [0, 1],
                        tooltips: true,
                        connect: true,
                        range: {
                            'min': 0,
                            'max': 1
                        }
                    });

                    slider.noUiSlider.on('change', function (values) {
                        let olg = state.sliderino;
                        olg.plotOptions.domain = [values[0], values[1]];
                        olg.redraw();
                    });

                }

            } else if (state.analyticView) {
                state.analyticView = !state.analyticView;
                state.sliderino = null;
                state.fake_layer.get('source').setTileLoadFunction(function (tile, src) {
                    tile.getImage().src = src;
                });
                let params = state.fake_layer.get('source').getParams();
                params['FORMAT'] = 'image/png';
                state.fake_layer.get('source').updateParams(params);
                state.fake_layer.getSource().refresh();
            }
        },

        UPDATE_FAKE_LAYER(state, l) {
            state.fake_layer = l;
        },

        UPDATE_SLIDERINO(state, sl) {
            state.sliderino = sl;
        },
        UPDATE_SHOW_GRAPH(state) {
            state.graphView = !state.graphView;
        },
        UPDATE_LAYER_COMP(state, obj) {
            state.layerComparison = obj;
        },
        DELETE_GRAPH_DATA(state, key) {
            console.log("BEFORE ", state.graphData);
            console.log("DELETING ", key);
            state.graphData.delete(key);
            console.log("AFTER ", state.graphData);
        },
        UPDATE_GRAPH_DATA(state, obj) {
            let processedData = [];
            if (obj.type === "area_feature") {
                processedData = obj.value;
            } else {
                for (const [key, value] of Object.entries(obj.value)) {
                    if (value && !isNaN(value)) {
                        processedData.push({key: key, value: value});
                    }
                }
            }
            state.graphData.set(obj.key, {data: processedData, type: obj.type, info: obj.info});
        }
    },
    getters: {
        graphData(state) {
            return state.graphData;
        },
        baseLayer(state) {
            return state.baseLayer;
        },
        getActiveLayers(state) {
            return state.active_layers;
        },
        mapLayerObjects(state) {
            return state.mapLayerObjects;
        },
        mapObject(state) {
            return state.mapObject;
        },
        navigationLayer(state) {
            return state.navigationLayer;
        },
        getDraw(state) {
            return state.draw;
        },
        getSource(state) {
            return state.source;
        },
        getDrawingLayer(state) {
            return state.drawingLayer;
        },
        getLastFeature(state) {
            // Used to retrieve bounds from the latest feature (polygon,... )
            let i = state.source.getFeatures().length;
            if (i !== 0) {
                return state.source.getFeatures()[i - 1];
            }
            return null;
            // let feature = this.$store.getters["map_store/getLastFeature"].getGeometry().getCoordinates();
        },
        getSelectedLayers(state) {
            return state.selectedLayers;
        },
        getFakeLayer(state) {
            return state.fake_layer;
        },
        getSliderino(state) {
            return state.sliderino;
        },
        getAnalyticView(state) {
            return state.analyticView;
        },
        getShowGraphView(state) {
            return state.graphView;
        },
        getLayerComp(state) {
            return state.layerComparison;
        }
    },
    actions: {

        update_map_layers(context) {
            // Get openlayers layer objects based on indices in getActiveLayers
            let map_layer_objects = context.getters.getActiveLayers.map(index =>
                context.getters.mapLayerObjects[index]
            );
            // Erase currently active layers and refresh it with the new layers (OSM is our base)
            context.getters.mapObject.getLayers().clear();
            let source = null;

            if (context.getters.baseLayer === "OSM") {
                source = new OSM();
            } else if (context.getters.baseLayer === "ESRI") {
                source = new XYZ({
                    attributions: ['Powered by Esri',
                        'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
                    attributionsCollapsible: false,
                    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    maxZoom: 23
                });
            } else {
                alert("SOON");
                source = new OSM();
            }

            context.getters.mapObject.addLayer(new TileLayer({
                source: source
            }));
            map_layer_objects.reverse().forEach(obj => {
                context.getters.mapObject.addLayer(obj)
            });
            // Always put these layers on top
            // context.getters.mapObject.addLayer(context.getters.navigationLayer);
            context.getters.mapObject.addLayer(context.getters.getDrawingLayer);
        },

        update_basemap(context, type) {
            context.commit("UPDATE_BASE_LAYER_TYPE", type);
            context.dispatch("update_map_layers");
        },

        create_map({dispatch, commit, getters, rootGetters}) {

            // let f = new Feature(new Polygon(czechiaCoordinates));
            // f.getGeometry().transform('EPSG:4326', 'EPSG:900913'); extent of layer as well ?
            // let crop = new ol_filter_Crop({ feature: f, inner:false });
            // tile_layer.addFilter(crop);
            let sliderOpacity = document.getElementById('opacitySlider');
            noUiSlider.create(sliderOpacity, {
                start: 1,
                tooltips: true,
                connect: true,
                range: {
                    'min': 0,
                    'max': 1
                }
            });

            sliderOpacity.noUiSlider.on('slide', function (values) {
                let layers = getters.getSelectedLayers;
                layers.forEach(index => {
                    getters.mapLayerObjects[index].setOpacity(values[0] * 1);
                })
            });

            let source = null;

            if (getters.baseLayer === "OSM") {
                source = new OSM();
            } else {
                source = new XYZ({
                    attributions: ['Powered by Esri',
                        'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
                    attributionsCollapsible: false,
                    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    maxZoom: 23
                });
            }

            const tile_layer = new TileLayer({
                source: source
            });

            // //Example layer
            // const raster_layer = new TileLayer({
            //     source: new TileWMS({
            //         url: 'http://localhost:8080/geoserver/wms',
            //         params: {
            //             'LAYERS': 'igor_krwyfms6kw9f4frgu2ry24_workspace:33UUR_rgb_layer_16_03_2021_00_12_30',
            //             'FORMAT': 'image/geotiff',
            //             'TILED': true
            //         },
            //         serverType: 'geoserver',
            //     }),
            // });
            const attribution = new Attribution({
                collapsible: false,
            });
            let base_map = new MapOl({
                target: "map",
                layers: [tile_layer],
                view: new View({
                    center: transform([14.42, 49.8], "EPSG:4326", "EPSG:900913"),
                    // extent: transformExtent(
                    //     [10.63, 47.2, 20.5, 52.06713],
                    //     "EPSG:4326",
                    //     "EPSG:900913"
                    // ),
                    zoom: 7
                }),
                controls: defaultControls({attribution: false}).extend([
                    new ZoomSlider(),
                    new ScaleLine(),
                    new MousePosition(),
                    new Rotate(),
                    attribution,
                    new FullScreen()
                ])
            });

            // Triggered by shift-click on map -> to retrieve point features at coordinates
            base_map.on('singleclick', function (evt) {
                if (!evt.originalEvent.shiftKey) {
                    return;
                }
                let viewResolution = base_map.getView().getResolution();

                let url = getters.mapLayerObjects[getters.getActiveLayers[getters.getActiveLayers.length - 1]].getSource().getFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857',
                    {'INFO_FORMAT': 'application/json'});
                proj4.defs("EPSG:32633", "+proj=utm +zone=33 +ellps=WGS84 +datum=WGS84 +units=m +no_defs");
                let fromProj = new proj4.Proj('EPSG:900913');
                let toProj = new proj4.Proj('EPSG:32633');
                let coordinates = proj4(fromProj, toProj, [evt.coordinate[0], evt.coordinate[1]]);

                if (url) {
                    // Authorization needs to be automatic...
                    API.get(url, {headers: {Authorization: "Basic " + window.btoa(rootGetters.userDetails.email + ":" + rootGetters.userDetails.uid)}}).then(response => response.data
                    ).then(data => {
                        commit('UPDATE_GRAPH_DATA', {
                            key: 'view' + getters.graphData.size,
                            value: data.features[0].properties,
                            type: "point_feature",
                            info: {coordinates: coordinates}
                        })
                    })
                }

                commit('UPDATE_SHOW_GRAPH');

            });

            commit('UPDATE_LAYER_COMP', new Swipe());
            commit('UPDATE_MAP_OBJECT', base_map);
        },

        createDrawingInteraction(context) {
            context.commit('UPDATE_SOURCE', new VectorSource({wrapX: false}));
            context.commit('UPDATE_DRAWING_LAYER', new VectorLayer({
                source: context.getters.getSource,
            }));
            context.getters.mapObject.addLayer(context.getters.getDrawingLayer);
        },

        addInteraction(context, options) {
            if (options.type !== 'None') {
                let geometryFunction = null;
                if (options.type === 'Box') {
                    options.type = 'Circle';
                    geometryFunction = createBox();
                }
                if (geometryFunction == null) {
                    context.commit('UPDATE_DRAW', new Draw({
                        source: context.getters.getSource,
                        type: options.type,
                        freehand: options.freeHand
                    }));
                } else {
                    context.commit('UPDATE_DRAW', new Draw({
                        source: context.getters.getSource,
                        type: options.type,
                        geometryFunction: geometryFunction,
                        freehand: options.freeHand
                    }));
                }
                context.getters.mapObject.addInteraction(context.getters.getDraw);
            }
        },

        createNavigator(context) {
            let geolocation = new Geolocation({
                // enableHighAccuracy must be set to true to have the heading value.
                trackingOptions: {
                    enableHighAccuracy: true
                },
                projection: context.getters.mapObject.getView().getProjection(),
                tracking: true
            });

            let accuracyFeature = new Feature();
            geolocation.on("change:accuracyGeometry", function () {
                accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
            });
            // Create point and style
            let positionFeature = new Feature();
            positionFeature.setStyle(
                new Style({
                    image: new CircleStyle({
                        radius: 6,
                        fill: new Fill({
                            color: "#3399cc"
                        }),
                        stroke: new Stroke({
                            color: "#fff",
                            width: 2
                        })
                    })
                })
            );

            geolocation.on("change:position", function () {
                let coordinates = geolocation.getPosition();
                positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
            });

            const source = new VectorSource();
            const layer = new VectorLayer({
                source: source
            });
            context.commit('UPDATE_NAVIGATION_LAYER', layer);
            context.getters.mapObject.addLayer(layer);

            navigator.geolocation.watchPosition(
                function (pos) {
                    const coords = [pos.coords.longitude, pos.coords.latitude];
                    const accuracy = circular(coords, pos.coords.accuracy);
                    source.clear(true);
                    source.addFeatures([
                        new Feature(
                            accuracy.transform(
                                "EPSG:4326",
                                context.getters.mapObject.getView().getProjection()
                            )
                        ),
                        new Feature(new Point(fromLonLat(coords)))
                    ]);
                },
                function (error) {
                    alert(`ERROR: ${error.message}`);
                },
                {
                    enableHighAccuracy: true
                }
            );

            const locate = document.createElement("div");
            locate.className = "ol-control ol-unselectable locate";
            locate.innerHTML =
                '<button id="locate-btn" style="position: fixed !important; top:60% !important; left: 0.5em !important" title="Locate me">◎</button>';
            locate.addEventListener("click", function () {
                if (!source.isEmpty()) {
                    context.getters.mapObject.getView().fit(source.getExtent(), {
                        maxZoom: 18,
                        duration: 500
                    });
                }
            });
            context.getters.mapObject.addControl(
                new Control({
                    element: locate
                })
            );
        },

        // LAYER COMPARISON SECTION

        toggleComapreLayers(context) {
            // Enable or disable object for direct layer comparison
            let map = context.getters.mapObject;
            let layerC = context.getters.getLayerComp;

            // too lazy for boolean
            if (!map.removeControl(layerC)) {
                // remove control is undefined therefore we add Swipe control
                map.addControl(layerC);
                console.log("Added toggle");
            }
        },

        unpinLayer(context, {myId: myId}) {
            // Remove object from layer comparison object
            let layerObj = context.getters.mapLayerObjects[myId];
            context.getters.getLayerComp.removeLayer(layerObj);
            if (context.getters.getLayerComp.layers.length === 0) {
                context.dispatch("toggleComapreLayers");
            }
        },

        pinLayer(context, {isRight: isRight, myId: myId}) {
            // Add object to layer comparison object
            if (context.getters.getLayerComp.layers.length === 0) {
                context.dispatch("toggleComapreLayers");
            }
            let layerObj = context.getters.mapLayerObjects[myId];
            if (isRight)
                context.getters.getLayerComp.addLayer(layerObj, true);
            else
                context.getters.getLayerComp.addLayer(layerObj, false);
        }

    },
    modules: {}
};
