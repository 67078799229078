<template>
    <div class="tile">
        <ul>
            <li v-for="(elem, index) in outer_boxes" :key="elem.index">
                <slot name="outer_box">
                </slot>
            </li>
        </ul>
    </div>
</template>

<script>
import Modal from "@/components/tiles/modal";
import Tile from "@/components/tiles/Tile";
import Sortable from 'sortablejs';

export default {
    name: "nested_sortable",
    data() {
        return {
            outer_boxes: [{"tree": "oak", "is-alive": false}, {"tree": "birch", "is-alive": true}]
        }
    },
    props: {
        outer_boxes: Array,
    },
    computed: {},
    watch: {},
    methods: {},
    components: {Modal, Tile},
    mounted() {
    }
}
</script>


<style scoped lang="scss">

</style>