<template>
    <main style="padding-bottom: 10%;">
        <div class="file-top">
            <FileUploader/>
            <DirectoryBrowser v-on:FilesUpdated="updateSelectedFiles" :currentDir="$store.getters.getCurrentDirectory" :mode="'multi-select'" :controls="['default']" :global="true"></DirectoryBrowser>
            <!--            <table class="table is-hoverable is-fullwidth" style="text-align: left; border-radius: 0.8em;">-->
            <!--                <p v-show="filesEmpty" style="margin: 0.5rem">-->
            <!--                    <strong>No subfolders in this directory yet.</strong>-->
            <!--                </p>-->
            <!--                <thead style="background-color: #7b525a;color: #fffcf2;">-->
            <!--                <tr>-->
            <!--                    <th style="color: #fffcf2">{{ $t("type") }}</th>-->
            <!--                    <th style="color: #fffcf2">{{ $t("name_obj") }}</th>-->
            <!--                    <th-->
            <!--                        @click=" $store.dispatch( 'fetchDirContent',$store.getters.getCurrentDirectory)">-->
            <!--                        <p style="color: #fffcf2; background: rgb(255, 118, 115); border-radius: 0.5em; padding: 0.4em; display: inline-block">-->
            <!--                            {{ $store.getters.getCurrentDirectory === "" ? "/" : $store.getters.getCurrentDirectory }}-->
            <!--                        </p>-->
            <!--                    </th>-->
            <!--                    <th style="color: #fffcf2">-->
            <!--                        <button-->
            <!--                            v-show="$store.getters.getCurrentDirectory !== ''"-->
            <!--                            @click="exitDirectory"-->
            <!--                            class="button customBtn"> {{ $t("back") }}-->
            <!--                        </button>-->
            <!--                    </th>-->
            <!--                </tr>-->
            <!--                </thead>-->
            <!--                <tbody>-->
            <!--                <tr-->
            <!--                    @click="selectItem(item, index)"-->
            <!--                    @dblclick="enterDirectory(item)"-->
            <!--                    :class="{ selected: selectedFiles.includes(index) }"-->
            <!--                    v-for="(item, index) in $store.getters.userStorage"-->
            <!--                    :key="index">-->
            <!--                    <td class="table_cell">-->
            <!--                        <img-->
            <!--                            style="height: 2em;position:absolute;"-->
            <!--                            v-if="item.type === 'file'"-->
            <!--                            src="@/assets/imgs/file_icon.svg"-->
            <!--                            alt="File icon"-->
            <!--                        /><img-->
            <!--                        style="height: 2em; position:absolute;"-->
            <!--                        v-if="item.type === 'dir'"-->
            <!--                        src="@/assets/imgs/directory_icon.svg"-->
            <!--                        alt="Directory icon"-->
            <!--                    />-->
            <!--                    </td>-->

            <!--                    <td class="table_cell">{{ item.name }}</td>-->
            <!--                    <td class="table_cell" style="text-align: end">-->
            <!--                        <button-->
            <!--                            class="customBtn"-->
            <!--                            v-show="-->
            <!--                  selectedFiles.includes(index)"-->
            <!--                            @click.stop="enterDirectory(item)">-->
            <!--                            Open-->
            <!--                        </button>-->
            <!--                        &lt;!&ndash;                    && $store.getters.dirContents.get(item.path) &&&ndash;&gt;-->
            <!--                        &lt;!&ndash;                    $store.getters.dirContents&ndash;&gt;-->
            <!--                        &lt;!&ndash;                      .get(item.path)&ndash;&gt;-->
            <!--                        &lt;!&ndash;                      .filter((obj) => obj.type === 'dir').length > 0&ndash;&gt;-->
            <!--                        &lt;!&ndash;                "&ndash;&gt;-->
            <!--                    </td>-->
            <!--                    <td class="table_cell"></td>-->
            <!--                </tr>-->
            <!--                </tbody>-->
            <!--            </table>-->


            <!--            <button-->
            <!--                class="button"-->
            <!--                v-if="selectedFiles.length > 0"-->
            <!--                @click="deleteSelectedDirs">-->
            <!--                Delete selected directories-->
            <!--            </button>-->
            <!--            <button class="button"-->
            <!--                    v-if="selectedFiles.length > 0"-->
            <!--                    @click="$store.dispatch('download_selected_files', selectedFiles)">-->
            <!--                Prepare download-->
            <!--            </button>-->
        </div>
        <ul class="jobsinfo">
            <li v-show="$store.getters.dirContents.size === 0">
                <strong>Please select at least one directory to see the details</strong>
            </li>
            <li v-for="(item, index) in Array.from($store.getters.dirContents)">
                <!-- v-for selected send id to component and inside parse the data and show it -->
                <DirectoryInfo :dirPath="item[0]" :content="item[1]" v-on:closed="dirViewClose" @open-img-editor="toggleImgEditor" />
            </li>
        </ul>
    </main>
    <ImageEditorWindow v-if="imgEditor"/>
    <Vegetation_modal
        v-if="$store.getters['veg_store/get_is_active_veg_modal']"
    />
    <LutModal v-if="$store.getters['veg_store/get_is_active_lut_modal']"/>
</template>

<script>
import FileUploader from "@/components/FileUploader.vue";
import router from "@/router";
import DirectoryInfo from "@/components/system_views/file_view_components/DirectoryInfo";
import Vegetation_modal from "@/components/Vegetation_modal";
import LutModal from "@/components/LutModal";
import ImageEditorWindow from "@/components/image_editor/ImgEditorWindow";
import DirectoryBrowser from "@/components/system_views/file_view_components/DirectoryBrowser";

export default {
    name: "FileView",
    data() {
        return {
            imgEditor: false,
            selectedFiles: [],
        };
    },
    computed: {
        filesEmpty() {
            return this.$store.getters.userStorage.length === 0;
        },
    },
    methods: {

        toggleImgEditor() {
                console.log("Toggle: ", this.imgEditor);
                this.imgEditor = !this.imgEditor;
            },

        updateSelectedFiles(newSelectedFiles) {
            this.selectedFiles = newSelectedFiles;
        },
        dirViewClose(path) {
            for (let i = 0; i < this.selectedFiles.length; i++) {
                let fileIndex = this.selectedFiles[i];
                if (this.$store.getters.userStorage[fileIndex].path === path) {
                    this.selectItem(this.$store.getters.userStorage[fileIndex], fileIndex);
                    return;
                }
            }
        },
        enterDirectory(item) {
            if (item.type === "dir") {
                this.$store.commit("UPDATE_CURRENT_DIRECTORY", item.path);
                this.$store.dispatch("fetchUserStorage");
                for (let i = 0; i < this.selectedFiles.length; i++) {
                    let file_index = this.selectedFiles[i];
                    this.$store.commit(
                        "DELETE_DIR_CONTENT",
                        this.$store.getters.userStorage[file_index].path
                    );
                }
                this.selectedFiles = [];
                this.$store.dispatch("fetchDirContent", item.path);
            }
        },

        exitDirectory() {
            if (this.$store.getters.getCurrentDirectory === "") {
                return; // button hidden but just in case
            }
            let dir = this.$store.getters.getCurrentDirectory;
            dir = dir
                .split("/")
                .slice(0, -1)
                .join("/");

            for (let i = 0; i < this.selectedFiles.length; i++) {
                let file_index = this.selectedFiles[i];
                this.$store.commit(
                    "DELETE_DIR_CONTENT",
                    this.$store.getters.userStorage[file_index].path
                );
            }
            this.selectedFiles = [];
            this.$store.commit(
                "DELETE_DIR_CONTENT",
                this.$store.getters.getCurrentDirectory
            );
            this.$store.commit("UPDATE_CURRENT_DIRECTORY", dir);
            this.$store.dispatch("fetchUserStorage");

        },

        selectItem(item, index) {
            if (this.selectedFiles.includes(index)) {
                let i = this.selectedFiles.indexOf(index);
                this.selectedFiles.splice(i, 1);
                this.$store.commit("DELETE_DIR_CONTENT", item.path);
            } else {
                this.selectedFiles.push(index);
                this.$store.dispatch("fetchDirContent", item.path);
            }

            // this.$store.commit("veg_store/UPDATE_SELECTED_FILES",
            //     this.selectedFiles.map(index => this.$store.getters.dirContents[this.dirPath][index]));
        },

        deleteSelectedDirs() {
            this.$store.dispatch("deleteFiles", this.selectedFiles);
            // this.$store.commit("veg_store/UPDATE_SELECTED_FILES", this.selectedFiles.map(index => this.$store.getters.dirContents[this.dirPath][index]));
        },
    },
    components: {
        DirectoryBrowser,
        Vegetation_modal,
        LutModal,
        DirectoryInfo,
        FileUploader,
        ImageEditorWindow,
    },
    created() {
        this.$store.dispatch('fetchUserStorage');
        this.$store.dispatch("fetchDirContent", "");
        this.$store.commit("PIN_DIR_VIEW", "");
    },
};
</script>

<style scoped>
main {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    gap: 0 10px;
    /* align-items: */
}

@media only screen and (max-width: 800px) {
    main {
        display: grid;
        grid-template-columns: 1fr;
        padding: 1em;
        grid-row-gap: 25px;
    }
}

li {
    margin-bottom: 5px;
}

.selected {
    background-color: #a9485a;
    color: #fffcf2;
}

.table_cell {
    border: none;
}

.customBtn {
    border: none;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}
</style>
