<template>
    <modal>
        <!--HEADER-->
        <template v-slot:header>
            <p class="title is-left">Image Editor</p>
        </template>

        <!--BODY-->
        <template v-slot:body>
            <main>

                <!--NEW FILES CONTAINER-->
                <!--iterate over each new file, file is made of ImgEditorWindowLayer(s) component(s)-->
                <div>
                    <ul :class="[selectedNewFile === index ? 'new-selected' : '']" class="new-file"
                        v-for="(file, index) in files" :key="index" :id="'new-files_' + file.id" :data-id="file.id"
                        @click="selectNewFile(index)">
                        <p><b>{{ files[index].name }}</b></p>
                        <!--layers holds file indices, item is the index, based on this index we pass the file obj
                        and its meta data to the component, item is index in selectedFiles _index is index of layer -->
                        <ImgEditorItem v-for="(item, _index) in files[index].layers" :file="selectedFiles[item].path"
                                       :meta="selectedFiles[item].meta" :index-in-file="_index"
                                       :new-file-index="index" @update-meta="selectedFiles[item].meta = $event"
                                       @delete-layer="layerDeleteHandle($event)"/>
                    </ul>
                </div>

                <!--PICKED FILES CONTAINER-- FILES THAH HAS BEEN PICKED INSIDE FILE VIEW :)-->
                <div>
                    <button @click="addImage(name)">Add new file</button>
                    <input type="text" v-model="name"/>
                    <ul>
                        <li v-for="(obj, index) in selectedFiles" :key="index" @dblclick="insertItem(index)">
                            {{ obj.path }}
                        </li>
                    </ul>
                </div>
            </main>
        </template>

        <template v-slot:footer>
        </template>

    </modal>
</template>

<script>
import modal from '@/components/tiles/modal';
import ImageEditorElement from "@/components/system_views/file_view_components/ImageEditorElement";
import Sortable from 'sortablejs';
import ImgEditorItem from "./ImgEditorItem";

export default {
    name: "ImgEditorWindow",
    components: {
        ImgEditorItem,
        modal,
        ImageEditorElement
    },

    data() {
        return {
            numOfImages: 0,
            // structure of files obj -> {id:x, filenames:[]}
            files: [], // in this array we will store objects that contains files/bands names, number of these obj==numOfFiles
            // key is the id of file and value handler to the sortable object
            sortableObjs: {}, // {1: <obj>} idk if neccessary
            selectedFiles: [],
            name: "", // variable that is binded to the text input
            selectedNewFile: -1,
        }
    },

    methods: {
        addImage(name) {
            // --------- CHECKS --------------
            // no name
            if (name === "") {
                alert("Please provide name for the file");
                return;
            }

            // duplicate check
            let duplicate = false;
            this.files.forEach(obj => {
                if (obj.name === name) {
                    alert("name alerady exists!");
                    duplicate = true;
                    return;
                }
            });

            if (duplicate)
                return;

            // --------- CHECKS END --------------
            // increment file counter and 'create' new file
            this.numOfImages += 1; // mind - updating this is IMPORTANT

            // create same id as above in template and link it with sortable object
            let elemId = 'new-files_' + this.numOfImages; // I added it to the object, it is used to identify this object in set callback

            let new_file = {id: this.numOfImages, elemId: elemId, name: name, layers: []}
            // add it to the files state, this will trigger the rendering of a box where we can put the bands
            this.files.push(new_file);
            const ourThis = this;

            setTimeout(function () {
                let demo = document.getElementById(elemId);
                new Sortable(demo, {
                    group: {
                        name: 'shared',
                    },
                    animation: 150,

                    store: {
                        set: function (sortable) {
                            // layer/file has been added into new file box, update array, as I have mentioned
                            // to identify this object -- object that corresponds to this box -- we use the id of the ul
                            ourThis.files.forEach(obj => {
                                if (sortable.el.id === obj.elemId) {
                                    obj.bands = sortable.toArray().map(elem => parseInt(elem));
                                    return;
                                }
                            });
                            console.log(sortable.toArray().map(elem => parseInt(elem)));
                        }
                    }
                });
            }, 500);
        },

        selectNewFile(index) {
            this.selectedNewFile = this.selectedNewFile === index ? -1 : index;
            console.log(this.selectedNewFile);
        },

        insertItem(index) {
            if (this.selectedNewFile === -1) {
                alert("You will have to select file first");
                return;
            }
            this.files[this.selectedNewFile].layers.push(index);
            console.log("File index:", this.selectedNewFile, "layers:", this.files[this.selectedNewFile].layers,
                "pushed:", index);
        },

        layerDeleteHandle(event) {
            let fileIndex = event.fileIndex; // index if layers
            let layerIndex = event.layerIndex;
            console.log("Delete layer", layerIndex, "in file", fileIndex);
            console.log("Files:", JSON.stringify(this.files[fileIndex]));
            console.log("Layers:", JSON.stringify(this.files[fileIndex].layers));
            this.files[fileIndex].layers.splice(layerIndex, 1);
        }
    },

    created() {
        this.selectedFiles = this.$store.getters['file_store/selectedFilesAsArray'];
        // add meta field, meta init when file is being used for instance in image
        this.selectedFiles.forEach(f => f["meta"] = {});
    },

}
</script>

<style scoped>

main {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    gap: 0 10px;
    height: 100%;
}

.new-file {
    min-height: 35px;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    background-color: #ffebeb99;
}

.new-file p {
    text-align: left;
}

.new-selected {
    background-color: #635252AF;
}

</style>
