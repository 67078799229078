<template>
    <main>
        <div class="layers-top">
            <div :class="{placeholderView: groupsEmpty}" class="layerWrap">
                <p v-show="groupsEmpty">
                    <strong>You do not belong to any groups yet.</strong>
                </p>
                <button v-show="groupsEmpty" @click="newGroupModalOn=true" class="switchBtn">
                    Create new
                </button>
                <table class="table is-hoverable is-fullwidth"
                       style="text-align: left; overflow-y: auto border-radius: 0.8em;"
                       v-if="!groupsEmpty">
                    <thead style="background-color: #7b525a;color: #fffcf2;">
                    <tr>
                        <th style="color: #fffcf2">Group</th>
                        <th>
                            <button @click="newGroupModalOn=true" class="switchBtn">
                                Create new
                            </button>
                        </th>
                        <th style="color: #fffcf2">Membership</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="(item, index) in $store.getters.userGroups"
                        :class="{ 'selected': selectedGroups.includes(index) }"
                        :key="index"
                        @click="selectItem(index)">
                        <td class="table_cell">{{ item.group_id }}</td>
                        <td class="table_cell"></td>
                        <td class="table_cell">{{ membership(item) }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- GROUP DETAILS SECTION -->
        <ul class="layersinfo">
            <li v-show="selectedGroups.length === 0">
                <strong>If you want to display details, select a group.</strong>
            </li>
            <li v-for="(item, index) in selectedGroups" :key="item">
                <!-- v-for selected send id to component and inside parse the data and show it -->
                <GroupInfo :id="item" v-on:deleted="selectedGroups.splice(index, 1)"></GroupInfo>
            </li>
        </ul>
    </main>


    <div class="image_div_wrap" v-if="newGroupModalOn">
        <div class="image_div">
            <div class="image_div_header"><p>New group</p></div>
            <div class="wms_settings_div">
                <label :for="wms_name_input">Group name </label>
                <input type="url" id="wms_name_input"
                       :placeholder="'name'"
                       v-model="groupName"><br>
                <div>
                    <p style="font-weight: 400; color: #bd2424; margin: 1rem 0" v-if="createGroupResultStr">
                        {{ createGroupResultStr }}</p>
                </div>
            </div>
            <button class="renameBtn" @click="newGroupModalOn=false">Close</button>
            <button class="renameBtn" @click="createNewGroup"> Save</button>
        </div>
    </div>

</template>

<script>
import LayerInfo from "@/components/system_views/layer_view_components/LayerInfo";
import GroupInfo from "@/components/system_views/GroupInfo";

export default {
    name: "GroupView",
    components: {
        GroupInfo,
        LayerInfo,
    },
    data() {
        return {
            selectedGroups: [],
            newGroupModalOn: false,
            groupName: "",
            createGroupResultStr: "",
        };
    },
    methods: {
        selectItem(id) {
            if (this.selectedGroups.includes(id)) {
                // deselect item
                let i = this.selectedGroups.indexOf(id);
                this.selectedGroups.splice(i, 1);
            } else {
                this.selectedGroups.push(id);
            }
        },

        async createNewGroup() {
            let reg = /^[0-9a-zA-Z_-]+([0-9a-zA-Z _-]+)*[0-9a-zA-Z_-]+$/;
            if (!this.groupName.match(reg)) {
                this.createGroupResultStr = "Name of the group can contain only alpha numeric characters or characters -,_";
                return;
            }

            let resp = await this.$store.dispatch("createNewGroup", {name: this.groupName});

            switch (resp) {
                case 409:
                    this.createGroupResultStr = "Group name already exists.";
                    break;
                case 500:
                    this.createGroupResultStr = "Something went wrong on our side, please try again later.";
                    break;
                case 400:
                    this.createGroupResultStr = "Your request could not be processed.";
                    break;
                case 201:
                    this.createGroupResultStr = "Group created!";
                    break;
                default:
                    this.createGroupResultStr = "This is interesting, please try again later...";
                    break;
            }

            this.selectedGroups = [];
            this.$store.dispatch("fetchUserGroups");
        },

        membership(item) {
            if (item.is_owner) {
                return "Owner";
            } else if (item.is_admin) {
                return "Admin";
            }
            return "Member";
        }
    },
    computed: {
        groupsEmpty() {
            return this.$store.getters.userGroups.length === 0;
        },
    },
    created() {
        this.$store.dispatch('fetchUserGroups');
    }
};
</script>


<style scoped>
main {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    gap: 0 10px;
    height: 100%;
    /* align-items: */
}

@media only screen and (max-width: 800px) {
    main {
        display: grid;
        grid-template-columns: 1fr;
        padding: 1em;
        grid-row-gap: 25px;
        height: 100%
    }
}

.selected {
    background-color: #a9485a;
    color: #fffcf2;
}

li {
    margin-bottom: 5px;
}

.placeholderView {
    background: #ffebeb99;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 25px;
}

.table_cell {
    border: none;
}

/*.layers-top {*/
/*    display: grid;*/
/*    grid-template-rows: 1fr 1fr;*/
/*}*/


.switchBtn {
    height: 3em;
    width: auto;
    margin: 0.2em 0.2em;
    padding: 0 0.5em;
    border: none;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}

.switchBtn:hover {
    background: #7b525a;
    cursor: pointer;
}

.layerWrap {
    max-height: 40vh;
    margin-bottom: 1.5rem;
    overflow: auto;
}


.image_div_wrap {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
}

.image_div_header {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    width: 100%;
    height: 2rem;
    color: white;
    background: #7b525a;
    border-radius: 0.5rem;
}

.image_div {
    /*background: #232121;*/
    border-radius: 0.8rem;
    background: #bea4a4;
    color: white;
    margin-top: 10%;
    width: 60%;
    overflow: auto;
    /*height: 60%;*/
    margin: 10% auto 0px auto;
    padding: 20px 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.wms_settings_div {
    text-align: left;
    padding: 1rem;
    border-radius: 1rem;
    width: 100%;
    background: #b28888;
}

.renameBtn {
    height: 3em;
    width: auto;
    /*margin-top: 1em;*/
    border: none;
    margin: 10px;
    padding: 5px 10px;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}

.renameBtn:hover {
    background: #7b525a;
}


</style>
