let czechiaJSON = {
  "type": "FeatureCollection",

  "features": [
    {
      "type": "Feature",
      "properties": { "ADMIN": "Czech Republic", "ISO_A3": "CZE" },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
        [
            [14.39780806400006, 51.013114726000111],
            [14.425816691000136, 51.020943706000139],
            [14.462093547000109, 51.019651794000083],
            [14.473462362000106, 51.0233724980001],
            [14.482144002000069, 51.037195943000143],
            [14.48793176300012, 51.028721009000066],
            [14.49010217300011, 51.022700704000016],
            [14.493512818000056, 51.016706238000097],
            [14.502917928000102, 51.008515524000074],
            [14.515320272000082, 51.003037822000067],
            [14.542295369000101, 50.998748678000112],
            [14.554594360000038, 50.992650859000065],
            [14.566686645000118, 50.983400778000032],
            [14.574334758000106, 50.975494283000074],
            [14.577435343000047, 50.965727437000027],
            [14.575574992000043, 50.950689596000046],
            [14.567823527000144, 50.938648987000079],
            [14.555834594000089, 50.924282939000079],
            [14.550356893000099, 50.912087301000071],
            [14.561829061000111, 50.906351217000093],
            [14.617122843000118, 50.920872294000048],
            [14.629215128000112, 50.920717266000111],
            [14.634589478000066, 50.909400127000112],
            [14.628801717000101, 50.896119283000104],
            [14.611851847000111, 50.870952861000092],
            [14.608337850000055, 50.853072815000132],
            [14.61319543500008, 50.84557973200009],
            [14.647921997000111, 50.839378561000103],
            [14.662184692000068, 50.834365946000048],
            [14.700425252000059, 50.815969137000138],
            [14.737528931000043, 50.810698141000103],
            [14.759233032000054, 50.81018137600006],
            [14.775356079000119, 50.812971904000023],
            [14.785071248000065, 50.820051575000065],
            [14.794579712000143, 50.831627096000091],
            [14.810392700000136, 50.858447164000097],
            [14.831683390000137, 50.85798207600007],
            [14.867856893000067, 50.864389954000103],
            [14.982061808000083, 50.859067282000069],
            [14.984128865000059, 50.867697246000091],
            [14.982888631, 50.902423808000037],
            [14.981751749000097, 50.905266012000112],
            [14.981131632000142, 50.90909006800004],
            [14.982061808000083, 50.914671123000062],
            [14.984852335000141, 50.918081767000075],
            [14.994670857000102, 50.925109762000091],
            [14.997771444000051, 50.930174052000069],
            [14.996634562000054, 50.959216207000054],
            [14.981441691000015, 50.973220520000069],
            [14.965215291000106, 50.981333720000066],
            [14.960977824000054, 50.992650859000065],
            [14.961081176000079, 50.992650859000065],
            [14.965628703000021, 50.996061503000078],
            [14.970692993000085, 50.998697002000085],
            [14.976377401000036, 51.000402324000049],
            [14.982061808000083, 51.001177470000073],
            [14.996531209000125, 51.007843730000076],
            [15.001182088000093, 51.012442932000027],
            [15.003972616000055, 51.020685323000066],
            [15.022989543000108, 51.009652405000082],
            [15.073632446000062, 51.002624410000067],
            [15.082107381000071, 50.992754212000079],
            [15.093889607000079, 50.985416158000078],
            [15.107945597000082, 50.981023662000098],
            [15.119211059000065, 50.982470602000092],
            [15.122518351000053, 50.992650859000065],
            [15.122518351000053, 50.992754212000079],
            [15.131510051000106, 51.005828349000126],
            [15.144429158000094, 51.011564433000075],
            [15.156108032000077, 51.007843730000076],
            [15.16075891100013, 50.992650859000065],
            [15.170164022000108, 50.977974752000051],
            [15.227111450000081, 50.97740631100001],
            [15.253776489000103, 50.969034729000043],
            [15.269796183000068, 50.952653300000094],
            [15.268762655000074, 50.94298980700006],
            [15.260081014000036, 50.932499491000101],
            [15.253053019000106, 50.914102682000106],
            [15.256050252000136, 50.899891663000062],
            [15.265352010000072, 50.88227],
            [15.277134236000052, 50.865733541000054],
            [15.287779581000052, 50.85488149000011],
            [15.307726685000063, 50.844752910000068],
            [15.325813436000089, 50.8396886190001],
            [15.341006306000111, 50.831730449000133],
            [15.35299523900008, 50.812971904000023],
            [15.353305298000066, 50.803256735000076],
            [15.350514770000103, 50.793231506000069],
            [15.349687948000081, 50.783723043000094],
            [15.356095825000125, 50.775454814000028],
            [15.368578785000096, 50.772949110000098],
            [15.370255168000057, 50.772612610000067],
            [15.381313924000068, 50.780002340000067],
            [15.390822388000061, 50.790647685000067],
            [15.400124145000092, 50.797985738000051],
            [15.441775350000086, 50.800207825000058],
            [15.641866496000148, 50.75576609400008],
            [15.653235310000042, 50.750960185000082],
            [15.666464477000147, 50.738092753000075],
            [15.673802530000046, 50.733545227000064],
            [15.684344523000107, 50.731426494000061],
            [15.767440226000076, 50.744190572000036],
            [15.792244914000037, 50.742691956000044],
            [15.794415323000095, 50.735870667000086],
            [15.797619263000087, 50.729772848000039],
            [15.848158813000111, 50.67515085900007],
            [15.854566691000059, 50.673703919000076],
            [15.882265258000075, 50.674220683000044],
            [15.94148645000007, 50.68838002600009],
            [15.958022908000146, 50.68688140900008],
            [15.971458781000109, 50.678613180000127],
            [15.984481242000044, 50.662903544000045],
            [15.993162882000121, 50.649364319000085],
            [15.9986405840001, 50.635566712000127],
            [15.996366821000095, 50.623681132000087],
            [15.982000773000067, 50.616239726000089],
            [15.980037068000058, 50.612984111000088],
            [15.979210246000036, 50.609780172000114],
            [15.979933715000101, 50.606627910000071],
            [15.982000773000067, 50.603630677000041],
            [16.024478800000111, 50.60859161400009],
            [16.08649051900008, 50.646780498000084],
            [16.128658488000042, 50.644041647000023],
            [16.160284465000103, 50.628642070000041],
            [16.175477335000039, 50.624094544000116],
            [16.192323852000129, 50.626575013000092],
            [16.204519491000042, 50.636341858000037],
            [16.211754191000097, 50.649105937000115],
            [16.219092245000098, 50.659131165000133],
            [16.232011352000114, 50.660888164000113],
            [16.268288208000058, 50.660423076000086],
            [16.300947714000131, 50.655100403000034],
            [16.331643514000092, 50.644041647000023],
            [16.416806275000113, 50.586629130000119],
            [16.425901326000087, 50.56756052700004],
            [16.394482056000044, 50.559343974000114],
            [16.398306111000096, 50.551799215000074],
            [16.398306111000096, 50.548491923000057],
            [16.395515584000123, 50.545701396000112],
            [16.391174764000141, 50.539913635000033],
            [16.389624471000047, 50.534849345000055],
            [16.388280884000068, 50.527149557000058],
            [16.388384237000082, 50.521258443000079],
            [16.391174764000141, 50.52182688400012],
            [16.382596476000117, 50.514178772000037],
            [16.362029256000142, 50.501207988000033],
            [16.352624145000078, 50.49278472900005],
            [16.335467570000105, 50.490355937000061],
            [16.303428182000118, 50.496040344000107],
            [16.287718547000054, 50.49278472900005],
            [16.279967082000042, 50.479865621000016],
            [16.264980917000059, 50.47164906800009],
            [16.247410929000097, 50.464982809000077],
            [16.232011352000114, 50.456766256000037],
            [16.226533651000125, 50.45537099200007],
            [16.217335246000118, 50.451650289000071],
            [16.211237427000071, 50.451288554000143],
            [16.216095011000078, 50.440178122000134],
            [16.217955363000101, 50.437129211000098],
            [16.19800826000008, 50.440023092000104],
            [16.190153443000042, 50.424158427000094],
            [16.199558553000088, 50.406278381000021],
            [16.232011352000114, 50.402609355000038],
            [16.24493046000012, 50.376822815000111],
            [16.249684692000102, 50.37103505500005],
            [16.262500448000083, 50.364420472000063],
            [16.269838501000095, 50.365454001000046],
            [16.276969849000125, 50.369433085000111],
            [16.28864872200009, 50.37186187800009],
            [16.334227335000094, 50.37186187800009],
            [16.343735799000058, 50.369949850000069],
            [16.350763794000073, 50.360648092000133],
            [16.35148726400007, 50.351346334000112],
            [16.350660441000059, 50.34225128200012],
            [16.353244263000136, 50.333517965000055],
            [16.371020956000081, 50.318376770000043],
            [16.415566040000073, 50.3079381310001],
            [16.437786906000014, 50.297964580000098],
            [16.452152954000042, 50.284993795000076],
            [16.477577759000042, 50.255331523000109],
            [16.492047160000055, 50.242722473000072],
            [16.504759563000107, 50.227994691000077],
            [16.535041952000142, 50.20773752900007],
            [16.545894003000086, 50.188823955000032],
            [16.557262817000094, 50.154717510000069],
            [16.566667928000129, 50.142211812000085],
            [16.584237915000102, 50.127432353000074],
            [16.618654419000052, 50.107020163000101],
            [16.660615682000071, 50.093015850000114],
            [16.701233357000092, 50.094927877000032],
            [16.732032511000057, 50.121954652000085],
            [16.754253377000111, 50.132289938000071],
            [16.766449016000109, 50.143400371000098],
            [16.777301066000064, 50.156939596000086],
            [16.795284464000048, 50.174406230000017],
            [16.817712036000046, 50.186756897000066],
            [16.837555786000053, 50.188617249000089],
            [16.857606242000088, 50.187738750000051],
            [16.869762082000079, 50.189728865000035],
            [16.880963990000026, 50.191562806000093],
            [16.8903691000001, 50.19693715400004],
            [16.907008911000105, 50.211768290000066],
            [16.918997843000056, 50.217090963000032],
            [16.927782837000052, 50.217297669000089],
            [16.946283, 50.213060202000122],
            [16.955274699000114, 50.213473613000133],
            [16.957755167000101, 50.217142640000134],
            [16.957548462000148, 50.229234925000128],
            [16.960338989000093, 50.231973775000057],
            [16.975015096000078, 50.231818747000034],
            [16.981009563000015, 50.229183248000112],
            [16.985660441000078, 50.223137106000053],
            [16.996305786000079, 50.212905172000092],
            [17.008398071000073, 50.209959615000088],
            [17.014805949000106, 50.218486227000085],
            [17.012842244000069, 50.231663717000089],
            [16.999819783000135, 50.242722473000072],
            [16.998372843000141, 50.267010396000074],
            [16.987830851000069, 50.28494211900005],
            [16.969640747000113, 50.297757874000041],
            [16.945249471000096, 50.306904602000117],
            [16.926542602000012, 50.31925527000007],
            [16.916724080000051, 50.33863393200005],
            [16.909282674000025, 50.359924622000051],
            [16.897913859000141, 50.378218079000106],
            [16.865874471000041, 50.408448792000115],
            [16.865771118000112, 50.422401429000104],
            [16.892952921000102, 50.432943421000061],
            [16.915793904000083, 50.431083069000067],
            [16.944004629000119, 50.420266697000045],
            [16.958788696000084, 50.414598287000075],
            [16.981939738000079, 50.416355286000055],
            [17.084672485000084, 50.397958476000071],
            [17.09562788900007, 50.393669332000087],
            [17.11888228400008, 50.38116363600011],
            [17.131698038000053, 50.376822815000111],
            [17.145443970000088, 50.376719463000086],
            [17.175933065000066, 50.380750224000082],
            [17.187611939000135, 50.378476461000076],
            [17.185441528000069, 50.375685934000018],
            [17.1888521730001, 50.364833883000074],
            [17.195053345000076, 50.351914775000068],
            [17.201047811000109, 50.343233134000087],
            [17.21086633300007, 50.336411845000043],
            [17.246109659000041, 50.322149150000058],
            [17.269880819000093, 50.31775665300006],
            [17.316803019000048, 50.318635153000116],
            [17.337473592000066, 50.313002422000068],
            [17.319076782000081, 50.308248190000086],
            [17.321867309000112, 50.304475810000071],
            [17.325381307000072, 50.301116842000056],
            [17.3295154220001, 50.298222962000068],
            [17.334062947000035, 50.295690817000064],
            [17.332822713000098, 50.282668356000045],
            [17.334786418000135, 50.270007630000094],
            [17.341711060000108, 50.259724020000107],
            [17.355043579000039, 50.253264466000047],
            [17.365895630000097, 50.271557923000017],
            [17.388013143000109, 50.272643128000112],
            [17.409820597000135, 50.261325989000056],
            [17.41912235500007, 50.242722473000072],
            [17.424186645000134, 50.240552063000081],
            [17.429560994000099, 50.239776917000086],
            [17.434831991000124, 50.240500387000083],
            [17.439999634000117, 50.242722473000072],
            [17.469351847000098, 50.265563456000081],
            [17.516997518000039, 50.268353984000044],
            [17.606397746000141, 50.258225403000097],
            [17.629548787000118, 50.262101136000041],
            [17.646705363000081, 50.271092835000104],
            [17.676367635000133, 50.297396139000057],
            [17.68194869000007, 50.305561015000052],
            [17.684532511000072, 50.31238230400001],
            [17.691043741000044, 50.315069478000055],
            [17.707993611000035, 50.311038717000145],
            [17.713574666000056, 50.307679749000044],
            [17.731971476000069, 50.291763407000104],
            [17.734451945000046, 50.289489645000089],
            [17.734968709000071, 50.286802470000055],
            [17.734245239000074, 50.283805237000138],
            [17.731971476000069, 50.280652975000095],
            [17.721119425000097, 50.261946106000011],
            [17.719155721000078, 50.252799378000105],
            [17.721429484000083, 50.242722473000072],
            [17.731971476000069, 50.23600453800006],
            [17.747577759000109, 50.217504374000043],
            [17.738172648000045, 50.202363180000106],
            [17.717915487000141, 50.191097718000066],
            [17.675334106000065, 50.174716289000116],
            [17.648669067000128, 50.167998352000069],
            [17.600403279000119, 50.166809795000063],
            [17.589447876000122, 50.163244120000087],
            [17.582109823000053, 50.153167216000043],
            [17.584176880000086, 50.145880839000085],
            [17.632752726000092, 50.106348369000045],
            [17.648359009000046, 50.101800843000092],
            [17.658487589000089, 50.102989400000126],
            [17.666962524000098, 50.106141663000074],
            [17.677607869000099, 50.107743632000108],
            [17.690733683000047, 50.105469869000103],
            [17.717915487000141, 50.096788228000037],
            [17.731971476000069, 50.094876201000034],
            [17.73238488800007, 50.093894349000038],
            [17.732591594000041, 50.092964173000098],
            [17.73238488800007, 50.092189026000085],
            [17.731971476000069, 50.091465556000088],
            [17.722669719000123, 50.086349590000083],
            [17.719465780000064, 50.080871888000104],
            [17.722566365000091, 50.075135804000041],
            [17.731971476000069, 50.069089661000106],
            [17.743030232000052, 50.060563050000098],
            [17.749851522000114, 50.049504294000087],
            [17.755432577000136, 50.037153626000133],
            [17.763287394000059, 50.025216370000095],
            [17.774552857000117, 50.015191142000077],
            [17.839355102000127, 49.973643291000087],
            [17.875735311000113, 49.968682353000133],
            [17.91211551900011, 49.97581370000006],
            [17.941571085000106, 49.992763570000079],
            [17.959761190000052, 49.995812480000097],
            [17.999862101000133, 49.99658762700011],
            [18.032418253000088, 50.002840475000113],
            [18.037482544000056, 50.007232971000093],
            [18.035725545000076, 50.017206523000112],
            [18.028697550000089, 50.024131165000085],
            [18.020119262000065, 50.02428619500003],
            [18.012161092000099, 50.022684225],
            [18.006890096000063, 50.024131165000085],
            [18.002445923000039, 50.046765442000037],
            [18.019073899000148, 50.044087110000135],
            [18.0332450760001, 50.041804505000101],
            [18.091639445000084, 50.017206523000112],
            [18.085128214000122, 49.998758036000083],
            [18.098150675000056, 49.989042867000052],
            [18.162022746000105, 49.981239726000041],
            [18.17721561700003, 49.97581370000006],
            [18.208738241000106, 49.958295390000103],
            [18.220520467000085, 49.95488474600009],
            [18.243981567000077, 49.951732483000114],
            [18.255867147000089, 49.946048076000068],
            [18.260104614000056, 49.940570374000089],
            [18.267029256000029, 49.925067444000064],
            [18.272610311000051, 49.918297831000132],
            [18.292454061000058, 49.907807516000076],
            [18.306923462000071, 49.909667868000099],
            [18.334932088000073, 49.925429179000076],
            [18.333795206000076, 49.92713450100004],
            [18.332451619000011, 49.928271383000038],
            [18.330694621000134, 49.928839824000107],
            [18.328524211000058, 49.928839824000107],
            [18.368831827000093, 49.932043763000081],
            [18.407485800000103, 49.923155416000071],
            [18.481796509000077, 49.896645407000051],
            [18.505050903000097, 49.896697083000078],
            [18.544428345000114, 49.912975159000098],
            [18.559207804000039, 49.907187398000104],
            [18.5654089760001, 49.889048971000079],
            [18.562928507000038, 49.873701071000113],
            [18.567269328000123, 49.861453756000088],
            [18.593831014000045, 49.852203675000084],
            [18.566339152000069, 49.831584778000064],
            [18.566752564000069, 49.804764710000086],
            [18.584115845000099, 49.774378967000132],
            [18.607680298000105, 49.742959697000089],
            [18.617705526000123, 49.713865866000077],
            [18.624010050000038, 49.706372783000063],
            [18.637342570000044, 49.7003266400001],
            [18.668141724000122, 49.698569641000034],
            [18.68240441800009, 49.695779114000089],
            [18.695426880000127, 49.688854472000088],
            [18.715063924000077, 49.67381663],
            [18.727672974000086, 49.668907369000067],
            [18.742245727000068, 49.66968251600008],
            [18.757852010000107, 49.674075013000078],
            [18.773458293000118, 49.675832011000068],
            [18.7884444580001, 49.668597311000099],
            [18.792578573000128, 49.66079416900007],
            [18.799296508000054, 49.626377666000025],
            [18.803947387000107, 49.616765849000103],
            [18.815729614000105, 49.599299215000059],
            [18.820173788000119, 49.590255839000093],
            [18.834539836000118, 49.547622782000104],
            [18.837433716000106, 49.526952210000104],
            [18.833196249000082, 49.510260722000083],
            [18.792268514000057, 49.509537252000086],
            [18.773561645000058, 49.504886373],
            [18.732530558000121, 49.480288391000045],
            [18.704521932000091, 49.479254863000051],
            [18.675583130000035, 49.485042623000112],
            [18.642820272000051, 49.495791321000041],
            [18.63589563000005, 49.496721497000095],
            [18.628970988000077, 49.495791321000041],
            [18.600445598000135, 49.485972799000081],
            [18.556210571000094, 49.490158590000107],
            [18.535643351000118, 49.481683655000012],
            [18.530475708000012, 49.473467102000086],
            [18.527995239000035, 49.454863587000034],
            [18.522930949000056, 49.446078594000113],
            [18.514662719000114, 49.440600891000145],
            [18.481796509000077, 49.429077047000106],
            [18.439215129000019, 49.395073955000072],
            [18.416787557000134, 49.385462138000065],
            [18.387642049000107, 49.389751282000134],
            [18.385161580000045, 49.342260641000024],
            [18.36158636600004, 49.330191418000084],
            [18.324596802000087, 49.311254781000088],
            [18.190031372000107, 49.276941631000085],
            [18.160575806000111, 49.258699850000113],
            [18.136494588000062, 49.233068339000056],
            [18.117787719000091, 49.202579245000067],
            [18.105075318000047, 49.169764710000067],
            [18.101457967000044, 49.142944641000071],
            [18.101629686000138, 49.13692833200011],
            [18.102904907000038, 49.09225006200009],
            [18.096290323000062, 49.070287578000034],
            [18.075516398000104, 49.047188212000066],
            [18.046060832000109, 49.02915313800014],
            [18.012884562000096, 49.019127910000122],
            [17.959244426000112, 49.02186676000008],
            [17.935059855000134, 49.019386292000092],
            [17.914079223000044, 49.010497946000072],
            [17.900850057000127, 48.993031311000038],
            [17.894648885000066, 48.978200176000115],
            [17.886897420000139, 48.947245992000092],
            [17.87873254400003, 48.933551738000062],
            [17.860335734000046, 48.921717835000067],
            [17.841215454000036, 48.92099436500007],
            [17.820131469000103, 48.923371481000117],
            [17.795533488000046, 48.920580953000041],
            [17.779410441000039, 48.911744283000047],
            [17.744890584000075, 48.872573548000105],
            [17.727217244000059, 48.862910054000082],
            [17.535084269000066, 48.812990621000012],
            [17.49849735500004, 48.81676300100014],
            [17.467904907000104, 48.838105367000139],
            [17.453332153000133, 48.842756247000096],
            [17.429560994000099, 48.838157043000052],
            [17.411224719000131, 48.830216334000113],
            [17.391733846000108, 48.821775614000103],
            [17.374370565000106, 48.819605205000101],
            [17.260269002000086, 48.857794088000077],
            [17.212209920000134, 48.866062318000047],
            [17.167458130000085, 48.859757792000039],
            [17.113714641000087, 48.833919576000113],
            [17.104619588000105, 48.824617818000064],
            [17.098728475000087, 48.805755921000042],
            [17.084362426000098, 48.793715312000074],
            [17.049945923000053, 48.774026592000041],
            [17.025347941000064, 48.746328024],
            [16.974808390000135, 48.64987396300009],
            [16.963336222000123, 48.6359471640001],
            [16.947523234000101, 48.623157247000051],
            [16.945042766000142, 48.604166158000083],
            [16.910522908000075, 48.630779521000107],
            [16.896673625000091, 48.696977030000085],
            [16.87300581900007, 48.718991191000072],
            [16.856572713000105, 48.719818014000111],
            [16.818125447000057, 48.710774638000061],
            [16.798901814000118, 48.709224345000024],
            [16.780505005000123, 48.713771871000063],
            [16.744331502000108, 48.729636536000072],
            [16.729035279000044, 48.733098857000101],
            [16.69100142400012, 48.732065328000033],
            [16.675085083000084, 48.733925679000038],
            [16.661959269000135, 48.740023499000102],
            [16.654724569000052, 48.751857402000013],
            [16.651003866000053, 48.766223450000012],
            [16.643149047000122, 48.778264059000094],
            [16.624028767000112, 48.78338002600006],
            [16.605528605000103, 48.784826965000065],
            [16.545790650000072, 48.796299134000094],
            [16.528737426000134, 48.803533834000064],
            [16.519539022000117, 48.805600891000012],
            [16.510340617000026, 48.804774069000075],
            [16.492253865000123, 48.799709779000096],
            [16.481918579000109, 48.799399719000121],
            [16.453083130000067, 48.802190247000084],
            [16.435719848000048, 48.794542135000114],
            [16.38497359200008, 48.737077943000088],
            [16.374018188000093, 48.730308329000053],
            [16.358308553000114, 48.727259420000024],
            [16.352727498, 48.728447978000048],
            [16.339498331000101, 48.735579326000078],
            [16.318724406000058, 48.733512268000112],
            [16.317587524000146, 48.732840475000046],
            [16.177751098000073, 48.746534729000075],
            [16.085353638000072, 48.742865702000074],
            [16.032333618000052, 48.758058574000103],
            [15.931771281000124, 48.806427714000051],
            [15.93032434100013, 48.81138865200009],
            [15.93032434100013, 48.818313294000063],
            [15.925053345000094, 48.822602438000047],
            [15.908103475000104, 48.819708558000059],
            [15.907069946000121, 48.830250549000112],
            [15.899628540000094, 48.834746400000114],
            [15.888363077000122, 48.835056458000111],
            [15.875754028000102, 48.833092753000074],
            [15.885985961000102, 48.84203277600011],
            [15.877614379000107, 48.841671041000012],
            [15.870172973000081, 48.843893128000019],
            [15.859217570000112, 48.84916412400014],
            [15.840097290000131, 48.850094300000109],
            [15.833276001000058, 48.852006328000101],
            [15.828315064000037, 48.857122295000096],
            [15.82428430100012, 48.869421285000044],
            [15.818496541000059, 48.872315165000032],
            [15.787904093000037, 48.872263489000034],
            [15.779222453000074, 48.870868225000038],
            [15.743669067000042, 48.858465882000075],
            [15.726822550000037, 48.854951884000116],
            [15.703774861000085, 48.854951884000116],
            [15.681347290000076, 48.858465882000075],
            [15.603729288000096, 48.887353007000115],
            [15.54418668100007, 48.902611733000072],
            [15.521253703000099, 48.908488668000075],
            [15.471851033000064, 48.936704000000134],
            [15.450353638000109, 48.944817200000045],
            [15.405911906000142, 48.954687399000107],
            [15.357852823000087, 48.970810446000087],
            [15.335528605000036, 48.974996237000141],
            [15.288709757000106, 48.97535797200004],
            [15.283748820000142, 48.977631735000045],
            [15.279614706000132, 48.982592672000095],
            [15.27455041500005, 48.986726787000123],
            [15.26690230300008, 48.986675111000096],
            [15.262871542000084, 48.982592672000095],
            [15.260391073000108, 48.969156800000064],
            [15.257083781000119, 48.963885803000011],
            [15.24323449700006, 48.952930400000042],
            [15.238066854000039, 48.950759989000062],
            [15.161792440000113, 48.937220764000074],
            [15.141948689000117, 48.937479147000033],
            [15.148563273000036, 48.951741842000018],
            [15.148976684000047, 48.965332743],
            [15.14473921700008, 48.978923645000094],
            [15.137401164000096, 48.993031311000038],
            [15.059576457000048, 48.997217102000064],
            [15.003972616000055, 49.009774475000086],
            [14.982061808000083, 49.007914124000081],
            [14.978857869000109, 49.00626047800003],
            [14.97730757700009, 49.0029015100001],
            [14.97730757700009, 48.998250631000047],
            [14.978237753000144, 48.992824605000067],
            [14.964595174000038, 48.971740622000141],
            [14.964181763000028, 48.943266907000123],
            [14.968315877000066, 48.912674459000101],
            [14.968212524000108, 48.885027568000083],
            [14.947025187000065, 48.822395732000075],
            [14.939790487000096, 48.809786682000038],
            [14.938136840000141, 48.802810364000067],
            [14.940100545000092, 48.796505839000133],
            [14.944441366000149, 48.78859934500008],
            [14.94888553800007, 48.782139791000134],
            [14.951055949000079, 48.780331116000042],
            [14.939583781000039, 48.762812806000085],
            [14.919326620000049, 48.761572571000045],
            [14.867650187000095, 48.775576884000145],
            [14.815456991000104, 48.780331116000042],
            [14.800367472000119, 48.776507060000114],
            [14.794476359000015, 48.766998597000025],
            [14.790445597000115, 48.754854635000129],
            [14.780420370000087, 48.743124085000034],
            [14.775356079000119, 48.724003805000052],
            [14.722749471000043, 48.693411357000031],
            [14.70383589700009, 48.673102519000111],
            [14.700218546000116, 48.646101583000075],
            [14.700735310000141, 48.61553497400007],
            [14.695671020000077, 48.589541728000114],
            [14.691140439000037, 48.586534963000105],
            [14.675620565000116, 48.576235046000079],
            [14.659187459000066, 48.576958517000094],
            [14.651332641000124, 48.583495585000065],
            [14.645441529000038, 48.5929006960001],
            [14.634692831000109, 48.602331645000078],
            [14.628181600000033, 48.603287659000046],
            [14.612885376000094, 48.599747823000087],
            [14.605133911000081, 48.600316264000128],
            [14.601929973000097, 48.604527893000068],
            [14.601826619000065, 48.611194153000099],
            [14.600586385000042, 48.617576193000019],
            [14.594178507000095, 48.621322734000046],
            [14.578778930000112, 48.620495911000035],
            [14.548806599000073, 48.610935771000101],
            [14.533820434000091, 48.608713684000122],
            [14.522038208000083, 48.610625712000029],
            [14.482144002000069, 48.624474997000107],
            [14.458166137000148, 48.643181865000088],
            [14.443593384000081, 48.636515605000056],
            [14.421165812000083, 48.597060649000042],
            [14.405352823000101, 48.586286113000114],
            [14.353366333000082, 48.571429139000088],
            [14.333212525000079, 48.560706279000073],
            [14.325357706000148, 48.558639221000107],
            [14.31574589000013, 48.557915752000014],
            [14.216527140000096, 48.581170146000034],
            [14.074830363000075, 48.591712139000094],
            [14.040827270000136, 48.601168925000053],
            [14.032455688000084, 48.606129863],
            [14.015195760000097, 48.620392558000077],
            [14.010854939000097, 48.625870260000084],
            [14.00620406000013, 48.639512838000087],
            [14.008064412000039, 48.642406718000075],
            [14.013748820000103, 48.643801982000042],
            [14.020466756000104, 48.653155416000089],
            [14.023463989000049, 48.654602356000083],
            [14.028321574000046, 48.653982239000101],
            [14.032869100000084, 48.65491241400008],
            [14.034729451000089, 48.661165263000072],
            [14.032145630000088, 48.667263082000034],
            [14.026151164000055, 48.670053610000082],
            [14.019226521000093, 48.671913961000087],
            [14.014162231000114, 48.675066223000044],
            [14.00775435400007, 48.683282776000084],
            [13.991114543000037, 48.700180970000076],
            [13.98232954900007, 48.706485494000077],
            [13.915046834000066, 48.730980124000041],
            [13.893136027000111, 48.743020732000105],
            [13.87494592300007, 48.752425843000054],
            [13.855928996000102, 48.75929880800004],
            [13.815724731000074, 48.766430156000069],
            [13.796191040000082, 48.779814352000102],
            [13.747925252000073, 48.843376363000061],
            [13.724670858000138, 48.867302552000069],
            [13.710614868000022, 48.872470195000091],
            [13.673356160000111, 48.876345928000035],
            [13.654442586000073, 48.882237040000035],
            [13.637389363000125, 48.893450826000077],
            [13.621783081000103, 48.909057109000116],
            [13.611137736000103, 48.927247213000072],
            [13.608657267000126, 48.94616078700011],
            [13.590467163000085, 48.944817200000045],
            [13.573413940000137, 48.951018372000107],
            [13.55646407100005, 48.959958395000058],
            [13.538894083000059, 48.967038066000072],
            [13.522395447000093, 48.969341930000098],
            [13.515949747000036, 48.970242005000046],
            [13.50210046400008, 48.966056214000105],
            [13.492592, 48.955049133000017],
            [13.482256713000083, 48.937634176000074],
            [13.458898966000049, 48.945023906000102],
            [13.427272990000034, 48.959958395000058],
            [13.398540893000131, 48.977683411000072],
            [13.384174845000132, 48.993031311000038],
            [13.383554728000149, 49.021091614000056],
            [13.372392618000049, 49.038506572000102],
            [13.32991459100009, 49.066928610000019],
            [13.299528849000126, 49.093645325000068],
            [13.287333211000117, 49.100569967000069],
            [13.267282755000082, 49.105634257000133],
            [13.206821330000139, 49.107494609000128],
            [13.178502645000066, 49.118346660000071],
            [13.162586303000126, 49.135141500000145],
            [13.149460489000148, 49.154830221000083],
            [13.055822795000012, 49.238184306000051],
            [13.043420451000145, 49.242783509000105],
            [13.043213745000088, 49.242886861000017],
            [13.043213745000088, 49.242938538000033],
            [13.011277710000115, 49.267794902000077],
            [13.006730184000077, 49.277096659000023],
            [13.005386597000012, 49.286760153000046],
            [12.999805542000104, 49.294925029000069],
            [12.982442261000074, 49.299627584000035],
            [12.954330282000058, 49.319109599000029],
            [12.939860881000101, 49.326757711000113],
            [12.92291101100011, 49.332700501000033],
            [12.884670451000119, 49.339573467000093],
            [12.870924519000084, 49.336731263000019],
            [12.875368693000098, 49.32386383100004],
            [12.856661825000089, 49.322210185000074],
            [12.797750692000108, 49.327842917000112],
            [12.777906941000111, 49.332545472000078],
            [12.762093953000118, 49.343242493000076],
            [12.729331095000106, 49.391146546000101],
            [12.709177286000028, 49.404737447000088],
            [12.663081909000113, 49.418896790000048],
            [12.643548218000092, 49.429490458000032],
            [12.632179402000105, 49.443959859000046],
            [12.627218465000055, 49.460186259000068],
            [12.624324585000068, 49.493000794000068],
            [12.622980998000116, 49.509847310000055],
            [12.61553959200009, 49.513774720000129],
            [12.604997599000114, 49.512792867000059],
            [12.593732137000075, 49.515014954000065],
            [12.58288008700012, 49.522404683000076],
            [12.575025268000076, 49.529897766],
            [12.568720744000075, 49.538837789000127],
            [12.562312867000117, 49.550826722000096],
            [12.558282104000028, 49.562557272000078],
            [12.553631225000061, 49.584726461000088],
            [12.546603230000073, 49.595578512000145],
            [12.536061239000105, 49.603123271000101],
            [12.512186727000113, 49.611649882000023],
            [12.501954793000039, 49.619711406000107],
            [12.502678263000121, 49.622140198000068],
            [12.507432495000103, 49.629995016000095],
            [12.499474324000062, 49.632527161000098],
            [12.496890503000145, 49.633870748000049],
            [12.505365437000137, 49.646118063000074],
            [12.504641968000072, 49.659398905000103],
            [12.49637373900012, 49.669992575000066],
            [12.482524454000043, 49.67469513000006],
            [12.44924483200009, 49.685030416000046],
            [12.433948608000065, 49.691800029000092],
            [12.419582560000038, 49.700223287000085],
            [12.398911987000133, 49.71949859700004],
            [12.388576700000016, 49.732314351000099],
            [12.38361576300008, 49.742856344000074],
            [12.395397990000077, 49.757894186000044],
            [12.436739135000096, 49.769263001000041],
            [12.452655477000121, 49.77970164000007],
            [12.455859415000106, 49.796134746000035],
            [12.456376180000035, 49.81711537700005],
            [12.46247399900011, 49.83122304300008],
            [12.482524454000043, 49.827243958000082],
            [12.489862508000044, 49.842695211000091],
            [12.51291019700011, 49.870238749000066],
            [12.520971720000091, 49.885534973000034],
            [12.524072306000051, 49.904965312000115],
            [12.517871135000064, 49.912200012000085],
            [12.503608439000089, 49.915765687000061],
            [12.462680705000139, 49.931268616000068],
            [12.463817586000062, 49.942120666000108],
            [12.470742228000063, 49.955608216000087],
            [12.468158406000043, 49.970232646000056],
            [12.451311889000067, 49.980567932000071],
            [12.414518270000059, 49.983048401000133],
            [12.399222046000119, 49.992763570000079],
            [12.398188517000051, 49.992763570000079],
            [12.246879923000108, 50.044956767000144],
            [12.243675984000049, 50.051312969000065],
            [12.243469279000095, 50.06040802000004],
            [12.241402221000044, 50.070639954000029],
            [12.232513875000109, 50.080510152000016],
            [12.218044474000095, 50.0885716760001],
            [12.202024780000045, 50.094514465000117],
            [12.187865438000074, 50.102834372000075],
            [12.179183797000121, 50.117975566000098],
            [12.178563680000053, 50.132961731000051],
            [12.185591675000069, 50.155699361000131],
            [12.182697795000081, 50.170737203000044],
            [12.175049682000093, 50.182622783000056],
            [12.163784220000139, 50.193784892000082],
            [12.139289592000097, 50.211044820000069],
            [12.089886922000062, 50.230836894000049],
            [12.079551635000144, 50.242722473000072],
            [12.092057333000071, 50.254814758000066],
            [12.102392619000057, 50.259155579000065],
            [12.109110555000086, 50.263806458000033],
            [12.110970906000091, 50.276622213000081],
            [12.107250203000092, 50.290884908000066],
            [12.099188680000083, 50.299773255000076],
            [12.076140991000045, 50.315172831000069],
            [12.101343547000027, 50.313980338000079],
            [12.149314819000011, 50.31171051100003],
            [12.168538452000121, 50.302977193000061],
            [12.174429565000139, 50.293727112000056],
            [12.174119507000057, 50.276570537000083],
            [12.178046916000113, 50.268767395000054],
            [12.189209025000139, 50.262462871000139],
            [12.20378177900011, 50.259568990000076],
            [12.232513875000109, 50.259103902000049],
            [12.239645223000139, 50.256571758000064],
            [12.24295251500007, 50.252954407000061],
            [12.242435750000112, 50.24835520400012],
            [12.237991577000116, 50.242722473000072],
            [12.23644128400008, 50.242567445000134],
            [12.235097697000128, 50.242257385000073],
            [12.233754110000064, 50.241688944],
            [12.232513875000109, 50.24096547500011],
            [12.229826701000093, 50.239053447000089],
            [12.228896525000039, 50.237038066000139],
            [12.229930054000107, 50.234815979000132],
            [12.232513875000109, 50.2324905400001],
            [12.249980510000057, 50.221173401000044],
            [12.254321330000039, 50.217401021000114],
            [12.258455445000067, 50.210889791000028],
            [12.260212443000057, 50.205050355000139],
            [12.26124597200004, 50.199882711000043],
            [12.273234904000077, 50.172339173000069],
            [12.28336348400012, 50.162210592000093],
            [12.300416707000068, 50.160815328000126],
            [12.314369344000056, 50.167326559000088],
            [12.314369344000056, 50.176214905000109],
            [12.308581583000091, 50.18691192700004],
            [12.305584351000078, 50.199314270000087],
            [12.308684936000105, 50.217090963000032],
            [12.314059286000088, 50.226496074000067],
            [12.333799683000052, 50.242722473000072],
            [12.336486857000068, 50.258587138000081],
            [12.344651733000092, 50.26639028000011],
            [12.355813842000117, 50.27140289400009],
            [12.367182658000104, 50.279051005000071],
            [12.398188517000051, 50.315172831000069],
            [12.408937215000066, 50.321994121000017],
            [12.438289429000122, 50.332536113000089],
            [12.450175008000144, 50.339357402000132],
            [12.453792359000147, 50.340700990000101],
            [12.462784058000096, 50.340494283000055],
            [12.466814819000092, 50.341889547000051],
            [12.468985229000083, 50.345558574000023],
            [12.46836511300009, 50.349485983000108],
            [12.467021525000121, 50.352844951000094],
            [12.466814819000092, 50.354912008000071],
            [12.469501994000098, 50.359407858000083],
            [12.471569051000074, 50.364472148000061],
            [12.475083048000045, 50.369433085000111],
            [12.482524454000043, 50.373567200000139],
            [12.510223022000076, 50.388760071000064],
            [12.550530640000119, 50.396356507000121],
            [12.625358114000051, 50.399922181000107],
            [12.678791544000063, 50.393721008000114],
            [12.691814006000101, 50.394651184000082],
            [12.702976115000041, 50.401524150000029],
            [12.725093628000138, 50.423434958000087],
            [12.737909383000044, 50.431548157000094],
            [12.754239136000081, 50.43532053600012],
            [12.770258830000046, 50.435940654000092],
            [12.788138875000072, 50.434338684000068],
            [12.794340047000077, 50.435888977000076],
            [12.80839603700008, 50.441831767000082],
            [12.817387736000029, 50.443020325000106],
            [12.825759318000109, 50.441418356000071],
            [12.918363485000071, 50.405399882000097],
            [12.952573283000078, 50.404159648000046],
            [12.960631103000111, 50.40923412300009],
            [12.982442261000074, 50.42296987000006],
            [12.996601603000045, 50.433666891000058],
            [13.003112833000102, 50.451908672000116],
            [13.00962406400015, 50.492681376000093],
            [13.017892293000102, 50.496350404000111],
            [13.026263875000097, 50.497642314000046],
            [13.03453210400005, 50.496505432000049],
            [13.042283570000052, 50.49278472900005],
            [13.051068563000115, 50.491131084000074],
            [13.06037032100005, 50.490614319000144],
            [13.069775431000096, 50.491079407000058],
            [13.078870483000088, 50.492681376000093],
            [13.078973836000102, 50.492681376000093],
            [13.079180542000074, 50.49278472900005],
            [13.107395874000133, 50.498985901000111],
            [13.160105835000138, 50.497022197000092],
            [13.184703817000127, 50.508752747000074],
            [13.199069865000126, 50.52534088200008],
            [13.232349487000079, 50.582029928000082],
            [13.251779825000142, 50.580893047000075],
            [13.268006226000068, 50.573658346000116],
            [13.284646037000101, 50.568955790000132],
            [13.305626668000087, 50.57577708000008],
            [13.316272013000088, 50.596034241000098],
            [13.321853068000109, 50.602390443000104],
            [13.359370158000104, 50.619340312000105],
            [13.365364624000108, 50.623474427000133],
            [13.365984741000091, 50.627091777000032],
            [13.368775268000149, 50.628332011000055],
            [13.380970906000073, 50.625489807000093],
            [13.386345256000112, 50.621614075000139],
            [13.400401245000126, 50.606576234000045],
            [13.407015828000056, 50.601305237000105],
            [13.429340048000114, 50.594328919000105],
            [13.447943563000052, 50.597274476000024],
            [13.464893432000054, 50.607092998000098],
            [13.482256713000083, 50.620890605000056],
            [13.492178589000076, 50.6244562790001],
            [13.498173055000109, 50.629158834000094],
            [13.49910323000006, 50.635204977000029],
            [13.493315471000074, 50.643214823000108],
            [13.509955281000117, 50.65122467100008],
            [13.523287801000038, 50.662180075000066],
            [13.527215210000094, 50.675667623000038],
            [13.515639689000068, 50.691015524000079],
            [13.556567423000075, 50.706725159000058],
            [13.601939331000096, 50.712151185000039],
            [13.691752970000096, 50.711841126000138],
            [13.71102828000005, 50.714269918000099],
            [13.74906213400007, 50.72320994100005],
            [13.770662882000039, 50.724605204000113],
            [13.816551554000114, 50.721349589000027],
            [13.834534953000087, 50.723675029000077],
            [13.863473755000143, 50.735147197000089],
            [13.869571574000105, 50.735250549000114],
            [13.87535933400008, 50.736542461000056],
            [13.882180624000057, 50.742743632000042],
            [13.882697388000082, 50.748014628000078],
            [13.880320272000034, 50.755042623000094],
            [13.879080037000108, 50.763517558000103],
            [13.883317505000065, 50.77323272700005],
            [13.892515910000043, 50.780415752000096],
            [13.900990844000148, 50.78062245700005],
            [13.910706014000084, 50.778762105000027],
            [13.923315064000121, 50.779950664000069],
            [13.933546997000093, 50.784808248000076],
            [13.948843221000061, 50.797210592000056],
            [13.959591919000076, 50.802068177000081],
            [13.979125610000096, 50.804755351000097],
            [14.015712524000037, 50.801758118000095],
            [14.034936157000061, 50.802584941000106],
            [14.190792277000099, 50.847905172000111],
            [14.202987915000108, 50.855139873000098],
            [14.221281372000078, 50.872606507000029],
            [14.232133423000022, 50.879376119000085],
            [14.268100220000093, 50.884130351000067],
            [14.34654504400001, 50.880202942000096],
            [14.375897257000076, 50.895964254000091],
            [14.381891724000127, 50.920872294000048],
            [14.355226685000076, 50.930639140000096],
            [14.318329712000065, 50.937512106000071],
            [14.292801554000107, 50.953480124000095],
            [14.293731730000076, 50.963970439000065],
            [14.302206665000057, 50.967691142000064],
            [14.303756958000093, 50.969706523000099],
            [14.283809855000072, 50.974822490000093],
            [14.250013469000095, 50.977613017000067],
            [14.238334595000111, 50.982470602000092],
            [14.249496704000137, 50.992650859000065],
            [14.263552694000055, 51.02143463200008],
            [14.287530558000071, 51.036834208000045],
            [14.319363240000115, 51.040012309000105],
            [14.356570272000056, 51.03233835900005],
            [14.364115031000097, 51.027997538000051],
            [14.369386027000104, 51.022442322000131],
            [14.375897257000076, 51.016990459000056],
            [14.386749308000049, 51.013269756000057],
            [14.39780806400006, 51.013114726000111]

        ]]
      }
    }
  ]
}
let czechiaCoordinates = czechiaJSON.features[0].geometry.coordinates;
export default czechiaCoordinates;