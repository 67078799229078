<template>
    <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Registration</p>
                <button class="delete" aria-label="close"></button>
            </header>
            <section class="modal-card-body">
                <label>Nickname</label>
                <input class="input" v-model="username"/>
                <label>Email</label>
                <input class="input"
                       v-bind:class="{ 'is-success': (emailValidation(email) === 1), 'is-danger': (emailValidation(email) === 2)}"
                       v-model="email" type="email"/>
                <label>Password</label>
                <input v-bind:class="passwordClass" class="input" v-model="password" type="password"/>
                <label>Please repeat password</label>
                <input v-bind:class="passwordClass" class="input" v-model="password_vefication" type="password"/>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-success" @click="register">Register</button>
                <button class="button" @click="close">Cancel</button>
                <p :class=textClass>{{ auth_result }}</p>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RegistrationModal',
    data() {
        return {
            username: null,
            email: null,
            password: null,
            password_vefication: null,
            auth_result: "",
            textClass: "has-text-danger"
        }
    },
    computed: {
        passwordClass() {
            if (this.password_vefication === null) {
                return "";
            } else if (this.password.length < 8) {
                this.auth_result = "Choose password at least 8 characters long"
                return 'is-danger';
            } else if (this.password === this.password_vefication) {
                this.auth_result = "";
                return 'is-success';
            }
            this.auth_result = "Passwords don't match";
            return 'is-danger';
        }
    },
    methods: {
        close() {
            this.$emit("close");
        }
        ,

        register() {
            this.textClass = "has-text-danger";
            if (this.username == null || this.email == null || this.password == null || this.password_vefication == null ||
                this.username == "" || this.email == "" || this.password == "" || this.password_vefication == "") {
                this.auth_result = "Please fill all fields.";
                return;
            }

            const validEmail = this.emailValidation(this.email);
            if (validEmail == 0 || validEmail == 2) {
                this.auth_result = "Incorrect email format!"
                return;
            }

            if (this.passwordClass !== "is-success") {
                return;
            }

            this.$store
                .dispatch("userRegister", {
                    email: this.email,
                    username: this.username,
                    password: this.password
                })
                .then(response => {
                    // do we need response ?
                    this.textClass = "has-text-success";
                    this.auth_result = "Registration successful";
                })
                .catch(err => {
                    this.auth_result = "You can try logging in!"
                    //this.auth_result = "Account with email already exists!";
                });
        }
        ,

        emailValidation(email) {
            if (email == null)
                return 0;
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email) ? 1 : 2;
        }
        ,
    }

}
;
</script>


<style lang="css" scoped>

.modal {
    background-color: rgba(0, 0, 0, 0.7);
}

.delete {
    visibility: hidden;
}
</style>