<template>
    <!--    <div style="height: 40rem; overflow: auto;">-->
    <!--        <p class="title is-4"-->
    <!--           v-show="$store.getters.userJobs.length === 0 && !$store.getters.getJobInfoViewOn">-->
    <!--            No active jobs</p>-->
    <!--        <div v-if="$store.getters.getJobInfoViewOn" style="text-align: left">-->
    <!--            <p class="title is-2">Job info</p>-->
    <!--            <hr>-->
    <!--            <p class="title is-3">Job metadata:</p>-->
    <!--            <br><br>-->
    <!--            <p class="title is-4">Name:</p>-->
    <!--            <p>{{ $store.getters.getUserJobInfo["name"] }}</p>-->
    <!--            <br>-->
    <!--            <p class="title is-4">Progress:</p>-->
    <!--            <p>{{ $store.getters.getUserJobInfo["progress"] }}</p>-->
    <!--            <br>-->
    <!--            <p class="title is-4">Created:</p>-->
    <!--            <p>{{ $store.getters.getUserJobInfo["created_on"] }}</p>-->
    <!--            <br><br>-->
    <!--            <hr>-->
    <!--            <p class="title is-3">Execution details:</p>-->
    <!--            <br><br>-->
    <!--            &lt;!&ndash;            <p class="title is-4">Elapsed time:</p>&ndash;&gt;-->
    <!--            &lt;!&ndash;            <p>{{ $store.getters.getUserJobInfo["result"]["time"] }}</p>&ndash;&gt;-->
    <!--            &lt;!&ndash;            <br>&ndash;&gt;-->
    <!--            &lt;!&ndash;            <p class="title is-4">Request parameters: (to be further specialized)</p>&ndash;&gt;-->
    <!--            &lt;!&ndash;            <p>{{ $store.getters.getUserJobInfo["result"]["request"] }}</p>&ndash;&gt;-->
    <!--            &lt;!&ndash;            <br>&ndash;&gt;-->
    <!--            &lt;!&ndash;            <p class="title is-4" v-if="$store.getters.getUserJobInfo['result']['notes']">Notes for&ndash;&gt;-->
    <!--            &lt;!&ndash;                user: (to be&ndash;&gt;-->
    <!--            &lt;!&ndash;                further specialized)</p>&ndash;&gt;-->
    <!--            &lt;!&ndash;            <p>{{ $store.getters.getUserJobInfo["result"]["notes"] }}</p>&ndash;&gt;-->
    <!--            &lt;!&ndash;            <br>&ndash;&gt;-->
    <!--            &lt;!&ndash;            <p class="title is-4"&ndash;&gt;-->
    <!--            &lt;!&ndash;               v-if="$store.getters.getUserJobInfo['errors'] && $store.getters.getUserJobInfo['errors'].length > 2">&ndash;&gt;-->
    <!--            &lt;!&ndash;                Errors:</p>&ndash;&gt;-->
    <!--            &lt;!&ndash;            <p>{{ $store.getters.getUserJobInfo["result"]["errors"] }}</p>&ndash;&gt;-->
    <!--            &lt;!&ndash;            <hr>&ndash;&gt;-->
    <!--            <br><br>-->
    <!--            <button @click.prevent="closeJobView" class="button backButton">Back</button>-->
    <!--        </div>-->

    <!--        <table v-show="$store.getters.userJobs.length !== 0 && !$store.getters.getJobInfoViewOn"-->
    <!--               class="table is-hoverable is-fullwidth is-scrollable" style="text-align:left">-->
    <!--            <thead>-->
    <!--            <tr>-->
    <!--                <th>Name</th>-->
    <!--                <th>Created</th>-->
    <!--                <th>Status</th>-->
    <!--            </tr>-->
    <!--            </thead>-->
    <!--            <tbody style="height: 40%">-->
    <!--            <tr @click="selectItem(2, index)" @dblclick="$store.dispatch('fetchJobInfo', index)"-->
    <!--                :class="{'is-selected': (selectedJobs.includes(index))}"-->
    <!--                v-for="(item, index) in $store.getters.userJobs" :key="index">-->
    <!--                <td>{{ item.name }}</td>-->
    <!--                <td>{{ item.created_on }}</td>-->
    <!--                <td :class="{'is-danger': (item.progress === 'FAILED'), 'is-success': (item.progress === 'DONE'),-->
    <!--                                 'is-info': (item.progress === 'RUNNING'), 'is-danger': (item.progress === 'QUEUED')}">-->
    <!--                    {{ item.progress }}-->
    <!--                </td>-->
    <!--            </tr>-->
    <!--            </tbody>-->
    <!--        </table>-->
    <!--    </div>-->
    <!--    <br>-->
    <!--    <button class="button" v-if="selectedJobs.length > 0" @click="deleteSelectedJobs">-->
    <!--        Delete selected jobs-->
    <!--    </button>-->


    <main>
        <div class="jobs_top">
            <p v-show="jobsEmpty"><strong>No jobs in the database yet.</strong></p>
            <table class="table is-hoverable is-fullwidth" style="text-align: left; border-radius: 0.8em;"
                   v-if="!jobsEmpty">
                <thead style="background-color: #7b525a;color: #fffcf2;">
                <tr>
                    <th style="color: #fffcf2">Name</th>
                    <th style="color: #fffcf2">Created</th>
                    <th style="color: #fffcf2">Status</th>
                    <th>
                        <button
                            class="jobDeleteBtn"
                            v-if="selectedJobs.length > 0"
                            @click="deleteSelectedJobs"
                        >
                            Delete selected jobs
                        </button>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    @click="selectItem(index)"
                    :class="{ 'selected': selectedJobs.includes(index) }"
                    v-for="(item, index) in $store.getters.userJobs"
                    :key="index"
                >
                    <td class="table_cell">{{ item.name }}</td>
                    <td class="table_cell">{{ item.created_on }}</td>
                    <td class="table_cell">{{ item.progress }}</td>
                    <td class="table_cell"></td>
                </tr>
                </tbody>
            </table>
        </div>
        <ul class="jobsinfo">
            <li v-show="selectedJobs.length === 0"><strong>Please select at least one job to see the
                details</strong></li>
            <li v-for="(item, index) in selectedJobs">
                <!-- v-for selected send id to component and inside parse the data and show it -->
                <JobInfo :id="item"></JobInfo>
            </li>
        </ul>
    </main>


</template>

<script>
import JobInfo from "@/components/system_views/job_view_components/JobInfo";

export default {
    name: "JobsView",
    components: {
        JobInfo,
    },
    data() {
        return {
            selectedJobs: [],
        };
    }, methods: {
        closeJobView() {
            this.$store.dispatch('fetchUserJobs');
            this.$store.commit("SWITCH_JOB_VIEW");
        },

        formatJobDate(date) {
            // 2021-03-23T18:49:32.600388Z
            return date;
        },

        selectItem(id) {
            if (this.selectedJobs.includes(id)) {
                let i = this.selectedJobs.indexOf(id);
                this.selectedJobs.splice(i, 1);
            } else {
                this.selectedJobs.push(id);
            }
        }
        ,
        deleteSelectedJobs() {
            this.$store.dispatch('deleteJobs', {jobs: this.selectedJobs});
            this.selectedJobs = [];
        }
    }
    , computed: {
        jobsEmpty() {
            return this.$store.getters.userJobs.length === 0;
        }
    },
    created() {
        this.$store.dispatch('fetchUserJobs');
    }
}
</script>

<style scoped>
main {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    gap: 0 10px;
    /* align-items: */
}

@media only screen and (max-width: 800px) {
    main {
        display: grid;
        grid-template-columns: 1fr;
        padding: 1em;
        grid-row-gap: 25px;
    }
}

.selected {
    background-color: #a9485a;
    color: #fffcf2;
}

li {
    margin-bottom: 5px;
}

.table_cell {
    border: none;
}

.jobDeleteBtn {
    height: 3em;
    width: auto;
    margin: 0.2em 0.2em;
    padding: 0 0.5em;
    border: none;
    background: rgb(255, 118, 115) none repeat scroll 0% 0%;
    color: rgb(251, 247, 241);
    border-radius: 0.5em;
}

.jobDeleteBtn:hover {
    background: #7b525a;
    cursor: pointer;
}

</style>